import CompanyBusinessTypeService from "../../services/Company/BusinessType";
import { store } from "../../store";
import { setRecordsProductType } from "../Reducer";

const { dispatch } = store;

export const productType = async (companyId: number) => {
  
  try {
    const response = await CompanyBusinessTypeService.getCompanyProductType(
      companyId
    );
    dispatch(setRecordsProductType(response));
  } catch (err) {
    // Handle error appropriately
    console.error("Error fetching product types:", err);
  }
};
