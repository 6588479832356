import Http from "../../utils/http";

type DeleteResponse = {
  message?: string;
};
class GlobalService extends Http {
  public constructor() {
    super();
  }

  public getglCompanies = () =>
    this.instance.get(`/global/company`);

  public create = (parameter: any): Promise<void> => {
    console.log(parameter)
    

    return this.instance.post(`/global/company`, parameter);
  }

  public update = (parameter: any): Promise<void> => {
    return this.instance.put(`/global/company/${parameter.id}`, parameter);
  }

  public updateAcessUser = (parameter: any): Promise<void> => {
    return this.instance.put(`/global/user/owner/${parameter.id}`, parameter);
  }

  public updateModules = (parameter: any): Promise<void> => {
    console.log('Sending request to update modules:', parameter);
    return this.instance.put(`/global/company/modules/${parameter.id}`, parameter);
  };

 
  
  public deleteById = async (id: number | null | undefined): Promise<DeleteResponse | void> => {
    try {
      const response = await this.instance.delete(`/global/company/${id}`);
      
      // If there's a message, return it in the response data
      return response.data || { message: "Exclusão concluída com sucesso." }; // Default success message if no response data
    } catch (error) {
      console.error("Error deleting company:", error);
      throw new Error("Erro ao excluir empresa.");
    }
  };

  public postglCompanies = () =>
    this.instance.get(`/global/company`);

  public getCompanies = (id?: string) =>
    this.instance.get(`/global/user/${id}/companies`);

  public getModules = (companyId: number) =>
    this.instance.get(`/company/${companyId}/module`);

  public getParameter = (companyId: number) =>
    this.instance.get(`/company/${companyId}/parameter`);

  public relatorios = (companyId: number, type:string) =>
    this.instance.get(`/company/${companyId}/log/type?type=${type}`);


  public logout = (companyId: number,) =>
    this.instance.get(`/company/${companyId}/user/logout`);
}

export default new GlobalService();
