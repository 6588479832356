import { useEffect, useState, useCallback } from "react";
import { Select, Form, FormInstance } from "antd";
import { useAppSelector } from "../../../hooks";

interface BusinessTypeProps {
  label: string;
  value: number;
}

interface BusinessTypeComponentProps {
  formRef?: React.RefObject<FormInstance>;
}

export function BusinessType({ formRef }: BusinessTypeComponentProps) {
  const recordsBusinessType = useAppSelector(
    (state) => state.entities.businessType
  );

  const [businessTypeOptions, setBusinessTypeOptions] = useState<BusinessTypeProps[]>([]);

  // Populate businessType options
  useEffect(() => {
    if (recordsBusinessType?.length > 0) {
      const options = recordsBusinessType.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      setBusinessTypeOptions(options);

      // If only one option exists, set it as default value in formRef
      if (options.length === 1) {
        formRef?.current?.setFieldsValue({ businessType: options[0]?.value });
      }
    } else {
      setBusinessTypeOptions([]);
    }
  }, [recordsBusinessType, formRef]);

  // Handle selection change
  const onChange = useCallback(
    (value: number | undefined) => {
      if (value) {
        formRef?.current?.setFieldsValue({ businessType: value });
      } else {
        formRef?.current?.setFieldsValue({ businessType: null });
      }
    },
    [formRef]
  );

  return (
    <Form.Item
      name="businessType"
      rules={[{ required: true, message: "Please select a business type." }]}
    >
      <Select
        placeholder="Tipo de Negócio"
        allowClear
        onChange={onChange}
        disabled={!businessTypeOptions.length}
        style={{ width: "100%" }}
        defaultValue={
          recordsBusinessType.length > 0 
            ? recordsBusinessType[0]?.id
            : undefined
        } 
      >
        {businessTypeOptions.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}