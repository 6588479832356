import { useEffect, useState, useCallback } from "react";
import { Select, Form, FormInstance } from "antd";
import { useAppSelector } from "../../../hooks";

interface ProductTypeProps {
  label: string;
  value: number;
}

interface ProductTypeComponentProps {
  formRef?: React.RefObject<FormInstance>;
}

export function ProductType({ formRef }: ProductTypeComponentProps) {
  const recordsProductType = useAppSelector(
    (state) => state.entities.productType
  );

  const [ProductTypeOptions, setProductTypeOptions] = useState<
    ProductTypeProps[]
  >([]);

  // Populate ProductType options
  useEffect(() => {
    if (recordsProductType?.length > 0) {
      const options = recordsProductType.map((type: any) => ({
        label: type.product_name,
        value: type.id,
      }));
      setProductTypeOptions(
        options.sort((a, b) => a.label.localeCompare(b.label))
      );

      // If only one option exists and no value is set in formRef, set default
      if (
        options.length === 1 &&
        !formRef?.current?.getFieldValue("productType")
      ) {
        formRef?.current?.setFieldsValue({ productType: options[0]?.value });
      }
    } else {
      setProductTypeOptions([]);
    }
  }, [recordsProductType, formRef]);

  // Handle selection change
  const onChange = useCallback(
    (value: number | undefined) => {
      formRef?.current?.setFieldsValue({ productType: value ?? null });
    },
    [formRef]
  );

  return (
    <Form.Item
      name="productType"
      rules={[{ required: true, message: "Selecione um produto." }]}
    >
      <Select
        placeholder="Produto"
        allowClear
        onChange={onChange}
        disabled={!ProductTypeOptions.length}
        style={{ width: "100%" }}
        options={ProductTypeOptions}
        filterOption={(input, option) => {
          const label = option?.label as string | undefined;
          return label
            ? label.toLowerCase().includes(input.toLowerCase())
            : false;
        }}
        showSearch
        defaultValue={
          formRef?.current?.getFieldValue("productType") ??
          (recordsProductType.length > 0
            ? recordsProductType[0]?.id
            : undefined)
        }
      />
    </Form.Item>
  );
}
