/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Table,
  Tag,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import SalesForecastService from "../../../services/Global/SalesForecast";
import ReactEcharts from "echarts-for-react";
import "./index.css";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../../styles/ThemeContext";
const Option = Select.Option;

export function BottomUPDashboardPGC() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [valores, setValores] = useState<any>("R$");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [OptionsPresidente, setOptionsPresidente] = useState<any>([]);
  const [filterPresidente, setFilterPresidente] = useState<any>([]);
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataTipoNegocio, setDataTipoNegocio] = useState<any>([]);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [LoadingRegional, setLoadingRegional] = useState<boolean>(false);
  const [LoadingFilial, setLoadingFilial] = useState<boolean>(false);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterDiretoria, setFilterDiretoria] = useState<number>(0);
  const [filterFilialModal, setFilterFilialModal] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [isFullScreen3, setIsFullScreen3] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [DataFilial, setDataFilial] = useState<any>([]);
  const [filialOptions, setFilialOptions] = useState([]);
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const [level4Label, setLevel4Label] = useState<string | undefined>("");
  const [contador, setContador] = useState<any>();
  const [DataVendedor, setDataVendedor] = useState<any>([]);
  const [Vendedor, setVendedor] = useState<number>(0);
  const [cliente, setCliente] = useState<number>(0);
  const [categoria, setCategoria] = useState<number>(0);
  const [optionsCliente, setOptionsCliente] = useState<any>([]);
  const [optionsCategoria, setOptionsCategoria] = useState<any>([]);
  const { theme } = useTheme();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };
  const toggleFullScreen3 = () => {
    setIsFullScreen3(!isFullScreen3);
  };
  useEffect(() => {
    GetTipoNegocio();
    handleFilter();
  }, [theme]);
  const handleFilter = async () => {
    GetDataLevel1();
    GetDataLevel0();
  };
  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);
  const GetProduct = async (
    vendedor: number,
    level3_id: number,
    level2_id: number,
    level1_id: number,
    level0_id: number,
    customer_group_id: number,
    category_id?: number,
    valores?: string,
    TipoNegocio?: number,
    customer_category_id?: number
  ) => {
    setValoresProdutos([]);
    const response = await SalesForecastService.getBudgetDashboard(
      companyId,
      customer_group_id,
      category_id ? category_id : 0,
      vendedor,
      level3_id,
      level2_id,
      level1_id,
      level0_id,
      TipoNegocio,
      customer_category_id !== undefined ? customer_category_id : 0
    );

    const data = response.data
      .sort((a: any, b: any) =>
        valores === "R$"
          ? b.total_volume_base_line - a.total_volume_base_line
          : b.total_volume_base_line - a.total_volume_base_line
      )
      .map((item: any) => ({
        id: item.id,
        name: item.produto,
        tv_base_line: item.total_volume_base_line,
        tv_year1: item.total_volume_year1,
        tv_vol_base_line: item.volume_base_line,
        tv_vol_year1: item.volume_year1,
      }));

    setValoresProdutos(data.splice(0, 10));

    return data;
  };

  async function GetDataButtomUpFilial(
    filtro: number,
    filterFilial?: number,
    year?: any,
    Business_type_id?: number,
    valores?: any,
    filterDiretoria?: number
  ) {
    setLoadingFilial(true);
    // setDataFilialTable([]);
    // setDataFilial([]);
    const response =
      await CompanyCommercialStructureService.getCompleteCommercialStrucuteLevel3(
        companyId,
        filtro
      );

    const updatedData = response.map((item: any) => {
      return {
        id: item.id,
        name: item.level3_external_name,
      };
    });

    setLoadingFilial(false);
    const seen = new Set();
    const uniqueFilialOptions = updatedData.filter((item: any) => {
      if (!seen.has(item.name)) {
        seen.add(item.name);
        return true;
      }
      return false;
    });

    if (filialOptions.length === 0) {
      setFilialOptions(uniqueFilialOptions);
    }
    filterFilial !== 0 ||
      (filterFilial !== undefined &&
        filtro !== 0 &&
        setFilialOptions(uniqueFilialOptions));
  }

  const GetRegional = async (
    vendedor: number,
    level3_id: number,
    level2_id: number,
    customer_group_id: number,
    category_id?: number,
    level1_id?: number,
    level0_id?: number,
    valores?: any,
    TipoNegocio?: number
  ) => {
    setDataProjecaodevendas([]);
    setLoadingRegional(true);
    const response = await SalesForecastService.getRegionalDashboard(
      companyId,
      customer_group_id,
      category_id ? category_id : 0,
      vendedor,
      level3_id,
      level2_id,
      level1_id,
      level0_id,
      TipoNegocio
    );
    setLoadingFilial(true);
    setDataFilialTable([]);
    setDataFilial([]);
    const data = response.data
      .sort((a: any, b: any) =>
        valores !== "R$"
          ? b.total_volume_year1 - a.total_volume_year1
          : b.total_price_year1 - a.total_price_year1
      )
      .map((item: any) => ({
        id: item.level2_id,
        name: item.level2_external_name,
        tv_price_base_line: item.total_price_base_line,
        tv_base_line:
          valores === "R$"
            ? item.total_price_base_line
            : item.total_volume_base_line,
        tv_year1:
          valores === "R$" ? item.total_price_year1 : item.total_volume_year1,
        tv_price_year1: item.total_price_year1,
        tv_variation: item.variation,
      }));

    setDataProjecaodevendas(data);
    setLoadingRegional(false);

    const responseFilial = await SalesForecastService.getFilialDashboard(
      companyId,
      customer_group_id,
      category_id,
      vendedor,
      level3_id,
      level2_id,
      level1_id,
      TipoNegocio,
      0,
      0,
      level0_id
    );

    const data_responseFilial = responseFilial.data
      .sort((a: any, b: any) =>
        valores === "R$"
          ? b.total_price_base_line - a.total_price_base_line
          : b.total_volume_base_line - a.total_volume_base_line
      )
      .filter((item: any) => item.level3_external_name !== null)
      .map((item: any) => ({
        id: item.level3_id,
        name: item.level3_external_name,
        tv_price_base_line: Number(item.total_price_base_line),
        tv_base_line:
          valores === "R$"
            ? Number(item.total_price_base_line)
            : Number(item.total_volume_base_line),
        tv_price_year1: Number(item.total_price_year1),
        tv_year1:
          valores === "R$"
            ? Number(item.total_price_year1)
            : Number(item.total_volume_year1),
        tv_variation: Number(item.total_variation),
      }));
    setDataFilial(data_responseFilial);
    setDataFilialTable(data_responseFilial);

    setLoadingFilial(false);
  };

  const GetRegional2 = async (
    vendedor: number,
    level3_id: number,
    level2_id: number,
    customer_group_id: number,
    category_id?: number,
    level1_id?: number,
    level0_id?: number,
    valores?: any,
    TipoNegocio?: number
  ) => {
    setDataProjecaodevendas([]);
    setLoadingRegional(true);
    const response = await SalesForecastService.getRegionalDashboard(
      companyId,
      customer_group_id,
      category_id ? category_id : 0,
      vendedor,
      level3_id,
      level2_id,
      level1_id,
      level0_id,
      TipoNegocio
    );
    setLoadingFilial(true);
    setDataFilialTable([]);
    setDataFilial([]);
    const data = response.data
      .sort((a: any, b: any) =>
        valores !== "R$"
          ? b.total_volume_year1 - a.total_volume_year1
          : b.total_price_year1 - a.total_price_year1
      )
      .map((item: any) => ({
        id: item.level2_id,
        name: item.level2_external_name,
        tv_price_base_line: item.total_price_base_line,
        tv_base_line:
          valores === "R$"
            ? item.total_price_base_line
            : item.total_volume_base_line,
        tv_year1:
          valores === "R$" ? item.total_price_year1 : item.total_volume_year1,
        tv_price_year1: item.total_price_year1,
        tv_variation: item.variation,
      }));
    setDataProjecaodevendas(data);
    setLoadingRegional(false);
    setLoadingFilial(false);
  };
  const GetDataLevel0 = async () => {
    const response = await PowerBi_all.GetButtomUpLevel0(
      companyId,
      Number(idUser)
    );
    if (response.length === 1) {
      setFilterPresidente(response[0]?.id);
      setOptionsPresidente(response);
      GetProduct(Vendedor, 0, 0, 0, response[0]?.id, 0, 0, valores, 0);
    } else {
      GetProduct(Vendedor, 0, 0, 0, 0, 0, 0, valores, 0);
      setOptionsPresidente(response);
    }
  };
  const GetDataLevel1 = async (value?: number) => {
    const response = await PowerBi_all.GetButtomUpLevel1(
      companyId,
      Number(idUser),
      value ? value : filterPresidente
    );

    setOptionsDiretoria(response);
    if (response.length === 1) {
      setFilterDiretoria(response[0]?.id);
      // await GetDataBottomUpFindRegional(response[0]?.id, Number(idUser));
    } else {
      //setFilterDiretoria(0);
      //  await GetDataBottomUpFindRegional(0, Number(idUser));
    }
  };

  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) =>
      acc +
      (valores === "R$" ? curr.tv_price_base_line : curr.tv_base_line || 0),
    0
  );
  const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) =>
      acc + (valores === "R$" ? curr.tv_price_year1 : curr.tv_year1 || 0),
    0
  );
  const totalProjection1 = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.tv_variation || 0),
    0
  );
  const LimparFiltros = () => {
    setCategoria(0);
    setCliente(0);
    setVendedor(0);
    setFilterRegional(0);
    setDataFilial([]);
    setDataProjecaodevendas([]);
    setFilterFilialModal(0);
    setFilterDiretoria(0);
    setContador(null);
    GetTipoNegocio();
    GetRegional(0, 0, 0, 0, 0, 0, 0, valores, TipoNegocio);
    GetDataButtomUpFilial(
      filterRegional,
      filterFilialModal,
      years,
      TipoNegocio,
      valores,
      filterDiretoria || 0
    );
  };

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)).slice(0, 2));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);

  const handleValores = async (value: any) => {
    setValores(value);
    await GetProduct(
      Vendedor,
      filterFilialModal,
      filterRegional,
      filterDiretoria,
      filterPresidente,
      cliente,
      categoria,
      value,
      TipoNegocio
    );

    await GetDataButtomUpFilial(
      filterRegional,
      filterFilialModal,
      years,
      TipoNegocio,
      value,
      filterDiretoria
    );
    if (filterFilialModal > 0) {
      await GetRegional2(
        Vendedor,
        0,
        filterRegional,
        0,
        0,
        filterDiretoria,
        filterPresidente,
        value,
        TipoNegocio
      );
      const responseFilial = await SalesForecastService.getFilialDashboard(
        companyId, //companyId: number,
        Vendedor, //customer_group_id: number,
        categoria, //category_id?: number,
        Vendedor, //level4_id?: number,
        filterFilialModal, //level3_id?: number,
        filterRegional, // level2_id?: number,
        filterDiretoria, // level1_id?: number,
        TipoNegocio === undefined ? 0 : TipoNegocio,
        categoria === undefined ? 0 : categoria,
        cliente === undefined ? 0 : cliente
      );
      const data_responseFilial = responseFilial.data
        .sort((a: any, b: any) =>
          value === "R$"
            ? b.total_price_base_line - a.total_price_base_line
            : b.total_volume_base_line - a.total_volume_base_line
        )
        .map((item: any) => ({
          id: item.level3_id,
          name: item.level3_external_name,
          tv_price_base_line: item.total_price_base_line,
          tv_base_line:
            value === "R$"
              ? item.total_price_base_line
              : item.total_volume_base_line,
          tv_price_year1: item.total_price_year1,
          tv_year1:
            value === "R$" ? item.total_price_year1 : item.total_volume_year1,
          tv_variation: Number(item.total_variation),
        }));

      setDataFilial(data_responseFilial);
      setDataFilialTable(data_responseFilial);
    } else {
      await GetRegional(
        Vendedor,
        0,
        filterRegional,
        0,
        0,
        filterDiretoria,
        filterPresidente,
        value,
        TipoNegocio
      );
    }
  };
  const handleRegionChange = async (value: any) => {
    setFilterFilialModal(0);
    setVendedor(0);
    setFilterRegional(value);
    setCategoria(0);
    setCliente(0);
    GetProduct(
      Vendedor,
      filterFilialModal,
      value,
      filterDiretoria,
      filterPresidente,
      cliente,
      categoria,
      valores,
      TipoNegocio
    );
    GetRegional(0, 0, value, 0, 0, filterDiretoria, 0, valores, TipoNegocio);
    await GetDataButtomUpFilial(
      value,
      0,
      years,
      TipoNegocio,
      valores,
      filterDiretoria
    );
  };
  const handlePresidenteChange = async (value: any) => {
    await GetProduct(Vendedor, 0, 0, 0, value, 0, 0, valores, 0);
    setFilterPresidente(value);
    await GetDataLevel1(value);
    setFilterDiretoria(0);
    setFilterRegional(0);
    setCategoria(0);
    setCliente(0);
    setVendedor(0);
    setFilterFilialModal(0);
    setContador(null);
    GetTipoNegocio();
    //GetRegional2(0, 0, 0, 0, 0, 0,value, valores, TipoNegocio);
    GetRegional(0, 0, 0, 0, 0, 0, value, "R$", TipoNegocio);
    //  GetDataButtomUpFilial(0, 0, years, TipoNegocio, valores, 0);
    // const responseFilial = await SalesForecastService.getFilialDashboard(
    //   companyId, //companyId: number,
    //   0, //customer_group_id: number,
    //   0, //category_id?: number,
    //   0, //level4_id?: number,
    //   0, //level3_id?: number,
    //   0, // level2_id?: number,
    //   0, // level1_id?: number,
    //   TipoNegocio,
    //   0,
    //   0,
    //   value
    // );
    // console.log(responseFilial)
    // const data_responseFilial = responseFilial.data
    //   .sort(
    //     (a: any, b: any) => b.total_volume_base_line - a.total_volume_base_line
    //   )
    //   .map((item: any) => ({
    //     id: item.level3_id,
    //     name: item.level3_external_name,
    //     tv_price_base_line: Number(item.total_price_base_line),
    //     tv_base_line:
    //       valores === "R$"
    //         ? Number(item.total_price_base_line)
    //         : Number(item.total_volume_base_line),
    //     tv_price_year1: Number(item.total_price_year1),
    //     tv_year1:
    //       valores === "R$"
    //         ? Number(item.total_price_year1)
    //         : Number(item.total_volume_year1),
    //     tv_variation: Number(item.total_variation),
    //   }));
    //   console.log(data_responseFilial)
    // setDataFilial(data_responseFilial);
    // setDataFilialTable(data_responseFilial);
    setLoadingFilial(false);
  };
  const handleDiretoriaChange = async (value: any) => {
    setFilterDiretoria(value);
    setCategoria(0);
    setCliente(0);
    setVendedor(0);
    setFilterRegional(0);
    GetDataBottomUpFindRegional(value, Number(idUser));
    setFilterRegional(0);
    setFilterFilialModal(0);
    GetRegional(0, 0, 0, 0, 0, value, 0, "R$");
    await GetDataButtomUpFilial(
      value,
      0,
      years,
      TipoNegocio,
      valores,
      filterDiretoria
    );
    GetProduct(0, 0, 0, value, 0, 0, 0, valores, 0);
    //setDataFilial([]);
  };

  const handleVendedorChange = async (value: any) => {
    setVendedor(value);
    setCategoria(0);
    setCliente(0);
    if (value !== 0) {
      const response = await SalesForecastService.getBudgetLevel4(
        companyId,
        value
      );

      setOptionsCliente(response);
    }
    const response2 = await SalesForecastService.GetCategory(companyId);
    setOptionsCategoria(response2);
    GetProduct(
      value,
      filterFilialModal,
      filterRegional,
      filterDiretoria,
      filterPresidente,
      0,
      0,
      valores,
      TipoNegocio
    );
    const responseFilial = await SalesForecastService.getFilialDashboard(
      companyId, //companyId: number,
      0, //customer_group_id: number,
      categoria, //category_id?: number,
      Vendedor, //level4_id?: number,
      filterFilialModal, //level3_id?: number,
      filterRegional, // level2_id?: number,
      filterDiretoria, // level1_id?: number,
      TipoNegocio
    );
    const data_responseFilial = responseFilial.data
      .sort(
        (a: any, b: any) => b.total_volume_base_line - a.total_volume_base_line
      )
      .map((item: any) => ({
        id: item.level3_id,
        name: item.level3_external_name,
        tv_price_base_line: Number(item.total_price_base_line),
        tv_base_line:
          valores === "R$"
            ? Number(item.total_price_base_line)
            : Number(item.total_volume_base_line),
        tv_price_year1: Number(item.total_price_year1),
        tv_year1:
          valores === "R$"
            ? Number(item.total_price_year1)
            : Number(item.total_volume_year1),
        tv_variation: Number(item.total_variation),
      }));

    setDataFilial(data_responseFilial);
    setDataFilialTable(data_responseFilial);
    setLoadingFilial(false);
  };
  const handleClienteChange = async (value: any) => {
    setCliente(value);
    GetProduct(
      Vendedor,
      filterFilialModal,
      filterRegional,
      filterDiretoria,
      filterPresidente,
      value,
      categoria,
      valores,
      TipoNegocio
    );
    const responseFilial = await SalesForecastService.getFilialDashboard(
      companyId, //companyId: number,
      Vendedor, //customer_group_id: number,
      categoria, //category_id?: number,
      Vendedor, //level4_id?: number,
      filterFilialModal, //level3_id?: number,
      filterRegional, // level2_id?: number,
      filterDiretoria, // level1_id?: number,
      TipoNegocio,
      categoria,
      value
    );
    const data_responseFilial = responseFilial.data
      .sort((a: any, b: any) =>
        valores === "R$"
          ? b.total_price_base_line - a.total_price_base_line
          : b.total_volume_base_line - a.total_volume_base_line
      )
      .map((item: any) => ({
        id: item.level3_id,
        name: item.level3_external_name,
        tv_price_base_line: item.total_price_base_line,
        tv_base_line:
          valores === "R$"
            ? item.total_price_base_line
            : item.total_volume_base_line,
        tv_price_year1: item.total_price_year1,
        tv_year1:
          valores === "R$" ? item.total_price_year1 : item.total_volume_year1,
        tv_variation: Number(item.total_variation),
      }));
    setDataFilial(data_responseFilial);
    setDataFilialTable(data_responseFilial);
    setLoadingFilial(false);
  };
  const handleCategoriaChange = async (value: number) => {
    setCategoria(value);
    setCliente(0);
    if (value !== 0) {
      const response = await SalesForecastService.getBudgetLevel4Category(
        companyId,
        Vendedor,
        value
      );
      setOptionsCliente(response);
    } else {
      const response = await SalesForecastService.getBudgetLevel4(
        companyId,
        Vendedor
      );
      setOptionsCliente(response);
    }
    GetProduct(
      Vendedor ? Vendedor : 0,
      filterFilialModal,
      filterRegional,
      filterDiretoria,
      filterPresidente,
      0,
      value,
      valores,
      TipoNegocio
    );
    const responseFilial = await SalesForecastService.getFilialDashboard(
      companyId,
      0,
      0,
      Vendedor,
      filterFilialModal,
      filterRegional,
      filterDiretoria,
      TipoNegocio,
      value
    );

    const data_responseFilial = responseFilial.data
      .sort(
        (a: any, b: any) => b.total_volume_base_line - a.total_volume_base_line
      )
      .map((item: any) => ({
        id: item.level3_id,
        name: item.level3_external_name,
        tv_price_base_line: Number(item.total_price_base_line),
        tv_base_line:
          valores === "R$"
            ? Number(item.total_price_base_line)
            : Number(item.total_volume_base_line),
        tv_price_year1: Number(item.total_price_year1),
        tv_year1:
          valores === "R$"
            ? Number(item.total_price_year1)
            : Number(item.total_volume_year1),
        tv_variation: Number(item.total_variation),
      }));
    setDataFilial(data_responseFilial);
    setDataFilialTable(data_responseFilial);
    setLoadingFilial(false);
  };

  const handleFilialChangeModal = async (value: any) => {
    await GetDataButtomUpFilial(
      filterRegional,
      value,
      years,
      TipoNegocio,
      valores,
      filterDiretoria
    );
    GetProduct(
      0,
      value,
      filterRegional,
      filterDiretoria,
      filterPresidente,
      cliente,
      categoria,
      valores,
      TipoNegocio
    );
    setLoadingFilial(true);
    setDataFilialTable([]);
    setDataFilial([]);
    const responseFilial = await SalesForecastService.getFilialDashboard(
      companyId,
      0,
      0,
      0,
      value,
      filterRegional,
      filterDiretoria
    );
    const data_responseFilial = responseFilial.data
      .sort(
        (a: any, b: any) => b.total_volume_base_line - a.total_volume_base_line
      )
      .map((item: any) => ({
        id: item.level3_id,
        name: item.level3_external_name,
        tv_price_base_line: Number(item.total_price_base_line),
        tv_base_line:
          valores === "R$"
            ? Number(item.total_price_base_line)
            : Number(item.total_volume_base_line),
        tv_price_year1: Number(item.total_price_year1),
        tv_year1:
          valores === "R$"
            ? Number(item.total_price_year1)
            : Number(item.total_volume_year1),
        tv_variation: Number(item.total_variation),
      }));
    setDataFilial(data_responseFilial);
    setDataFilialTable(data_responseFilial);
    setLoadingFilial(false);
    setVendedor(0);
    setCategoria(0);
    setCliente(0);
    setFilterFilialModal(value);
    const response =
      await CompanyCommercialStructureService.getAllCommercialStrucuteLevel4(
        companyId,
        value
      );
    setDataVendedor(response);
    if (response.length === 1) {
      setVendedor(response[0]?.id);
    }
  };

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    // setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    // setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    // setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12).toFixed(1)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`;
    } else {
      return value.toFixed(1);
    }
  };
  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      width: "35%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Base Line",
      dataIndex: "tv_base_line",
      key: "tv_base_line",
      width: "100px",
      sorter: (a: any, b: any) =>
        valores === "R$"
          ? a.tv_price_base_line - b.tv_price_base_line
          : a.tv_base_line - b.tv_base_line,
      render: (text: any, record: any) => {
        return (
          <span>
            {valores === "R$"
              ? formatValue(record.tv_price_base_line)
              : formatValue(record.tv_base_line)}
          </span>
        );
      },
    },
    {
      title: "Proj Y+1",
      dataIndex: "tv_year1",
      key: "tv_year1",
      width: "90px",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        valores === "R$"
          ? a.tv_price_year1 - b.tv_price_year1
          : a.tv_year1 - b.tv_year1,
      render: (text: any, record: any) => {
        return (
          <span>
            {valores === "R$"
              ? formatValue(record.tv_price_year1)
              : formatValue(record.tv_year1)}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "90px",
      render: (text: any, record: any) => {
        const variation =
          record.tv_base_line !== null && record.tv_year1 !== null
            ? ((record.tv_year1 - record.tv_base_line) / record.tv_base_line) *
              100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter(
    (column: Column) =>
      column.key !== "tv_year2" ||
      DataFilialTable.some((record: any) => record.tv_year2 > 0)
  );

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year - 1; i++) {
      yearss.push(`year${i}`);
    }
    return yearss;
  };
  const handleChangeTipoNegocio = async (value: any) => {
    setTipoNegocio(value);
  };
  async function GetDataBottomUpFindRegional(level1: number, idUser: number) {
    const response = await PowerBi_all.getButtomUpFindRegional(
      companyId,
      level1,
      idUser
    );
    setOptionsRegional(response);
    if (response.length === 1) {
      await GetDataButtomUpFilial(
        response[0]?.level2_id,
        0,
        years,
        TipoNegocio,
        valores,
        0
      );
      setFilterRegional(response[0]?.level2_id);
    } else {
      setFilterRegional(0);
      await GetDataButtomUpFilial(0, 0, years, TipoNegocio, valores, 0);
    }
  }

  async function GetTipoNegocio() {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    setDataTipoNegocio(response);
    if (response.length === 1) {
      setTipoNegocio(response[0]?.id);
    }
  }

  const configPorcentagemBottomUP = {
    title: {
      text: "Produto",
      x: "center",
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Base Line" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      center: "scroll",
      top: "bottom",
      data: valoresProdutos.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) =>
            valores !== "R$"
              ? b.tv_base_line - a.tv_base_line
              : b.tv_vol_base_line - a.tv_vol_base_line
          )
          .map((item: any) => ({
            name: item.name,
            value: valores !== "R$" ? item.tv_base_line : item.tv_vol_base_line,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) =>
            valores !== "R$"
              ? b.tv_year1 - a.tv_year1
              : b.tv_vol_year1 - a.tv_vol_year1
          )
          .map((item: any) => ({
            name: item.name,
            value: valores !== "R$" ? item.tv_year1 : item.tv_vol_year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;
  const configRegional: any = {
    title: {
      text: "Regional",
      x: "center",
    },
    backgroundColor: "transparent",
    legend: { show: true, left: "left", top: "25px" },
    grid: { left: "-5%", right: "8%", bottom: "10%", containLabel: true },
    xAxis: {
      type: "category",
      axisLabel: { interval: 0, rotate: 30 },
      inverse: true,
      data: Array.from(
        new Set(DataProjecaodevendas.map((item: any) => item.name as string))
      ),
    },
    yAxis: { show: false },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["stack", "line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Base Line",
        type: "bar",
        data: DataProjecaodevendas.map((item: any) => ({
          name: item.name,
          value: valores === "R$" ? item.tv_price_base_line : item.tv_base_line,
        })),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const formattedValue = formatValue(params.value);
            return `${formattedValue}`;
          },
        },
      },
      {
        name: "Projeção Y+1",
        type: "bar",
        data: DataProjecaodevendas.map((item: any) => ({
          name: item.name,
          value: valores === "R$" ? item.tv_price_year1 : item.tv_year1,
        })),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const formattedValue = formatValue(params.value);
            return `${formattedValue}`;
          },
        },
      },
    ],
  };

  const configFilial: any =
    DataFilial.length > 0
      ? {
          title: {
            text: "Filial",
            x: "center",
          },
          backgroundColor: "transparent",
          legend: { show: true, top: "25px" },
          grid: { left: "3%", right: "10%", bottom: "5%", containLabel: true },
          yAxis: {
            type: "category",
            inverse: true,
            axisLabel: { interval: 0, rotate: 0 },
            data: DataFilial.sort((a: any, b: any) => b.tv_year1 - a.tv_year1)
              .filter((item: any) => item.name)
              .map((item: any) => item.name),
          },
          xAxis: { show: false },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "Salvar" },
              magicType: { show: true, type: ["line", "bar"] },
              dataView: {
                show: true,
                readOnly: false,
                title: "Visualização de Dados",
                lang: ["Visualização de Dados", "Fechar", "Atualizar"],
              },
            },
          },
          series: [
            {
              name: "Base Line",
              type: "bar",
              data: DataFilial.map((item: any) => item.tv_base_line),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            {
              name: "Projeção Y+1",
              type: "bar",
              data: DataFilial.map((item: any) => item.tv_year1),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            ...[1].map((i) => {
              const projectionKey = `projection${i}`;
              if (DataFilial.some((item: any) => item[projectionKey])) {
                return {
                  name: `Projeção Y+${i + 1}`,
                  type: "bar",
                  data: DataFilial.map((item: any) => item[projectionKey]),
                  label: {
                    show: true,
                    position: "right",
                    formatter: (params: any) => {
                      const formattedValue = formatValue(params.value);
                      return `${formattedValue}`;
                    },
                  },
                };
              }
              // return {
              //   name: `Projeção +${i}`,
              //   type: "bar",
              //   data: [],
              //   label: {
              //     show: true,
              //     position: "right",
              //     formatter: (params: any) => {
              //       return "";
              //     },
              //   },
              // };
            }),
          ],
        }
      : {};

  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={3} xs={3} sm={3}>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={4} xs={4} sm={4} />
                <Col xl={9} xs={9} sm={9}>
                  <div className="header-title">
                    <span>Base Line vs Projeção de Cliente</span>
                  </div>
                  <div className="header-title-sub">
                    <span>Modúlo: Projeção de Vendas</span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ paddingTop: 5 }}>
            <Col xl={24} xs={24}>
              <div style={{ display: "flex" }}>
                <Card
                  title="Ano"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      mode="multiple"
                      disabled
                      maxTagCount={2}
                      value={years.slice(0, 2)}
                      style={{
                        width: "230px",
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        marginLeft: 5,
                      }}
                      options={yearsOptions(Number(qdt_year))
                        .slice(0, 2)
                        .map((year: string, index: number) => ({
                          value: year,
                          label: eval(`year${index}`),
                          disabled: index < 2,
                        }))}
                    />
                  </div>
                </Card>
                <Card
                  title="Unidade de Medida"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Segmented
                      options={["R$", "KG/LT"]}
                      value={valores}
                      onChange={handleValores}
                    />
                  </div>
                </Card>

                <Card
                  title="Negócio"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Negócio"
                      onChange={handleChangeTipoNegocio}
                      value={TipoNegocio}
                      defaultValue={TipoNegocio}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...DataTipoNegocio.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })),
                      ]}
                    />
                  </div>
                </Card>
                <Card
                  title={level0Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level0Label}
                      onChange={handlePresidenteChange}
                      value={filterPresidente}
                      defaultValue={filterPresidente}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...OptionsPresidente.sort((a: any, b: any) =>
                          compareLabelsTables("level0_external_name")(a, b)
                        ).map((item: any) => ({
                          value: item.id,
                          label: item.level0_external_name,
                        })),
                      ]}
                    />
                  </div>
                </Card>
                {filterPresidente !== 0 && (
                  <Card
                    title={level1Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level1Label}
                        onChange={handleDiretoriaChange}
                        value={filterDiretoria}
                        defaultValue={filterDiretoria}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsDiretoria.sort((a: any, b: any) =>
                            compareLabelsTables("level1_external_name")(a, b)
                          ).map((item: any) => ({
                            value: item.id,
                            label: item.level1_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {filterDiretoria !== 0 && (
                  <Card
                    title={level2Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level2Label}
                        onChange={handleRegionChange}
                        value={filterRegional}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsRegional.sort((a: any, b: any) =>
                            compareLabelsTables("name")(a, b)
                          ).map((item: any) => ({
                            value: item.level2_id,
                            label: item.level2_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {filterRegional !== 0 && (
                  <Card
                    title={level3Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level3Label}
                        defaultValue={{
                          label: "Todos",
                          value: 0,
                        }}
                        onChange={handleFilialChangeModal}
                        value={filterFilialModal}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...filialOptions
                            .sort((a, b) => compareLabelsTables("name")(a, b))
                            .map((item: any) => ({
                              label: item.name,
                              value: item.id,
                            })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {filterFilialModal !== 0 && (
                  <Card
                    title={level4Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level4Label}
                        style={{ width: "100%" }}
                        onChange={handleVendedorChange}
                        defaultValue={0}
                        value={Vendedor}
                        options={[
                          { value: 0, label: "Todos" },
                          ...DataVendedor.map((item: any) => ({
                            value: item.level4_id,
                            label: item.level4_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {Vendedor !== 0 && (
                  <Card
                    title="Categoria"
                    size="small"
                    bordered={false}
                    className="card-filter"
                    style={{ maxWidth: 130 }}
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Categoria"
                        style={{ width: "100%" }}
                        onChange={handleCategoriaChange}
                        value={categoria}
                        options={[
                          { value: 0, label: "Todos" },
                          ...optionsCategoria.map((item: any) => ({
                            value: item.id,
                            label: item.name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {categoria !== 0 && (
                  <Card
                    title="Cliente"
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Cliente"
                        style={{ width: "100%" }}
                        onChange={handleClienteChange}
                        value={cliente}
                        options={[
                          { value: 0, label: "Todos" },
                          ...optionsCliente.map((item: any) => ({
                            value: item.id,
                            label: item.group_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}

                <Card
                  bordered={true}
                  size="small"
                  title="Base Line"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalBaseLine)}
                    />
                  </div>
                </Card>
                <Card
                  bordered={true}
                  size="small"
                  title="Projeção Y+1"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalProjection)}
                    />
                  </div>
                </Card>
                <Card
                  title="BL vs Y+1"
                  size="small"
                  bordered={true}
                  className="card-statistic"
                  style={{
                    margin: "5px",
                    minWidth: "150px",
                  }}
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{
                        color:
                          totalProjection - totalBaseLine > 0
                            ? "green"
                            : totalProjection - totalBaseLine < 0
                            ? "red"
                            : "gray",
                      }}
                      value={
                        totalBaseLine !== 0
                          ? `${(
                              ((totalProjection - totalBaseLine) /
                                totalBaseLine) *
                              100
                            ).toFixed(1)}%`
                          : "0%"
                      }
                      prefix={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {totalProjection - totalBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : totalProjection - totalBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null}
                        </span>
                      }
                    />
                  </div>
                </Card>
              </div>

              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={8} xs={8}>
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6, height: 419 }}
                    >
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingFilial ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : valoresProdutos.length !== 0 ? (
                          <ReactEcharts
                            option={configPorcentagemBottomUP}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen3 ? "100vh" : 400,
                              width: isFullScreen3 ? "100vw" : "100%",
                              padding: isFullScreen3 ? 50 : 0,
                            }}
                          />
                        ) : cliente !== 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                este Cliente. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    optionsCliente.find(
                                      (item: any) => item.id === cliente
                                    )?.group_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : categoria !== 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Categoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    optionsCategoria.find(
                                      (item: any) => item.id === categoria
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : filterFilialModal > 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Regional. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsRegional.find(
                                      (item: any) => item.id === filterRegional
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>

                    <Card
                      bordered={true}
                      style={{
                        height: 415,
                      }}
                    >
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingRegional ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : DataProjecaodevendas.length > 0 ? (
                          <ReactEcharts
                            option={configRegional}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen ? "100vh" : 400,
                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        ) : categoria !== 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Categoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    optionsCategoria.find(
                                      (item: any) => item.id === categoria
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : filterFilialModal > 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Regional. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsRegional.find(
                                      (item: any) => item.id === filterRegional
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}

                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={8} xs={8}>
                    <Card
                      bordered={true}
                      style={{
                        height: 840,
                      }}
                    >
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingFilial ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : DataFilial.length > 0 ? (
                          <ReactEcharts
                            option={configFilial}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen2 ? "100vh" : 840,
                              width: isFullScreen2 ? "100vw" : "100%",
                              padding: isFullScreen2 ? 50 : 0,
                            }}
                          />
                        ) : cliente !== 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                este Cliente. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    optionsCliente.find(
                                      (item: any) => item.id === cliente
                                    )?.group_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : categoria !== 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Categoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    optionsCategoria.find(
                                      (item: any) => item.id === categoria
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : filterFilialModal > 0 ? (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Regional. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsRegional.find(
                                      (item: any) => item.id === filterRegional
                                    )?.name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={8} xs={8}>
                    <Table
                      columns={filteredColumns}
                      size="small"
                      pagination={false}
                      dataSource={DataFilialTable}
                      scroll={{ y: 760 }}
                      footer={() => {
                        return (
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td
                                  style={{ fontWeight: "bold", width: "38%" }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    width: "100px",
                                  }}
                                >
                                  {formatValue(totalBaseLine)}
                                </td>
                                <td
                                  style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    width: "90px",
                                  }}
                                >
                                  {formatValue(totalProjection)}
                                </td>
                                {totalProjection1 > 0 && (
                                  <td
                                    style={{
                                      textAlign: "start",
                                      fontWeight: "bold",
                                      width: "70px",
                                    }}
                                  >
                                    {formatValue(totalProjection1)}
                                  </td>
                                )}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    width: "110px",
                                  }}
                                >
                                  {totalProjection1 > 0 ? (
                                    totalProjection +
                                      totalProjection1 -
                                      totalBaseLine >
                                    0 ? (
                                      <ArrowUpOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : totalProjection +
                                        totalProjection1 -
                                        totalBaseLine <
                                      0 ? (
                                      <ArrowDownOutlined
                                        style={{ color: "red" }}
                                      />
                                    ) : null
                                  ) : totalProjection - totalBaseLine > 0 ? (
                                    <ArrowUpOutlined
                                      style={{ color: "green" }}
                                    />
                                  ) : totalProjection - totalBaseLine < 0 ? (
                                    <ArrowDownOutlined
                                      style={{ color: "red" }}
                                    />
                                  ) : null}

                                  {totalProjection1 > 0
                                    ? `${(
                                        ((totalProjection +
                                          totalProjection1 -
                                          totalBaseLine) /
                                          totalBaseLine) *
                                        100
                                      ).toFixed(2)}%`
                                    : totalBaseLine !== 0
                                    ? `${(
                                        ((totalProjection - totalBaseLine) /
                                          totalBaseLine) *
                                        100
                                      ).toFixed(1)}%`
                                    : "~%"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
