import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Table,
  Form,
  Select,
  Tooltip,
} from "antd";
import { useState } from "react";
import { FiFilter, FiMenu, FiPlus, FiSearch } from "react-icons/fi";

import { Breadcrumb } from "../../../components/Breadcrumb";
import { Map } from "../../../components/Map";
import { MapScript } from "../../../components/MapScript";

import { Buttons, Header } from "./styles";
import { compareLabelsTables } from "../../../hooks";

export function BranchMapping() {
  const [filter, setFilter] = useState(false);

  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      sorter: compareLabelsTables("name"),
    },
    {
      title: "UF",
      dataIndex: "age",
      key: "age",
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Cidade",
      dataIndex: "city",
      key: "city",
      sorter: (a: any, b: any) => a.city.localeCompare(b.city),
    },
  ];

  return (
    <MapScript>
      <Map>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Breadcrumb
              path="Mapeamento de Filial"
              background
              actions={[
                {
                  children: "Finalizar",
                  type: "primary",
                },
                {
                  children: "Alocar",
                },
                {
                  children: "Excluir",
                },
              ]}
            >
              <Row justify="end" align="top" gutter={[16, 16]}>
                {filter && (
                  <Col span={4}>
                    <Card bordered={false}>
                      <Form>
                        <Form.Item>
                          <Select placeholder="Presidente" />
                        </Form.Item>
                        <Form.Item>
                          <Select placeholder="Diretoria" />
                        </Form.Item>

                        <Form.Item>
                          <Select placeholder="Filial" />
                        </Form.Item>
                        <Form.Item>
                          <Select placeholder="Vendedor" />
                        </Form.Item>

                        <Button type="primary" block>
                          Buscar
                        </Button>
                      </Form>
                    </Card>
                  </Col>
                )}
                <Col span={7}>
                  <Card bordered={false}>
                    <Header>
                      <Buttons>
                        <Tooltip title="Menu">
                          <Button
                            type="text"
                            style={{ marginLeft: 16 }}
                            icon={
                              <FiMenu
                                size={25}
                                color="#FFF"
                                style={{
                                  margin: "15px 10px 10px 10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setFilter(!filter)}
                              />
                            }
                          />
                        </Tooltip>
                        <Button type="primary" icon={<FiPlus />}>
                          Novo
                        </Button>
                      </Buttons>
                      <Input
                        placeholder="Pesquisar"
                        prefix={<FiSearch color="#00CC66" />}
                        suffix={<FiFilter color="#00CC66" />}
                      />
                    </Header>
                    <Table size="small" columns={columns} />
                  </Card>
                </Col>
              </Row>
            </Breadcrumb>
          </Col>
        </Row>
      </Map>
    </MapScript>
  );
}
