import { Row, Col, Card, message, Modal, Button } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import { CardProgressRebate } from "../../components/CardProgressRebate";
import { Loading } from "../../components/Loading";
import { TableStatus } from "../../components/TableStatus";
import { useAppSelector } from "../../hooks";
import PgcService from "../../services/Pgc";
import AcesseType from "../../services/Rebate/AcesseType";
import { NoticeType } from "antd/lib/message/interface";

interface DashboardProps {
  [name: string]: {
    permission?: boolean;
    rebate_setup?: boolean;
    rebate_extract?: boolean;
    rebate_setup_campaigns?: boolean;
    rebate_setup_campaigntype?: boolean;
    rebate_setup_eligibleproduct?: boolean;
    status: string;
    label: string;
    color: string;
    percent: number;
    period_open: boolean;
    period_past: boolean;
    initial_date: string;
    final_date: string;
  };
}
interface RecordsProps {
  id: number;
  name: string;
  filial: string;
  status: string;
}
interface StatusProps {
  id: number;
  level4_external_name: string;
  level3_external_name: string;
  pgc_action_plan: string;
  pgc_wallet_customer_mapping: string;
  color: string;
}

export function Rebate() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<DashboardProps>({});
  const [records, setRecords] = useState<RecordsProps[]>([]);
  const [loadingRecordsBudget, setLoadingRecordsBudget] = useState(false);
  const [localName, setLocalName] = useState("");
  const [loadingRecordsLevel3Mapping, setLoadingRecordsLevel3Mapping] =
    useState(false);
  const [loadingRecordsActionPlan, setLoadingRecordsActionPlan] =
    useState(false);
  const [loadingRecordsCustomerMapping, setLoadingRecordsCustomerMapping] =
    useState(false);
  const [visible, setVisible] = useState(false);
  const user_id = useAppSelector((state) => state.auth.user.id);
  const [messageApi, contextHolder] = message.useMessage();

    const ShowMSG = (type: NoticeType, test: string) => {
      messageApi.open({
        type,
        content: test,
      });
    };
  useEffect(() => {
    loadDashboard();
    
    async function loadDashboard() {
      try {
        setLoading(true);
        const response = await AcesseType.getAcessType(companyId, user_id);
        setDashboard(response);
      } catch (err) {
        ShowMSG("error", err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId]);

  async function handleDetailActionPlan() {
    setRecords([]);
    setLocalName("Plano de Ação");
    try {
      setLoadingRecordsActionPlan(true);
      const response: StatusProps[] = await PgcService.getActionPlainStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
      setLoadingRecordsActionPlan(false);
    }
  }

  async function handleBudget() {
    setRecords([]);
    setLocalName("Previsão de Vendas");
    try {
      setLoadingRecordsBudget(true);
      const response: StatusProps[] = await PgcService.getBudgetStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
      setLoadingRecordsBudget(false);
    }
  }

  async function handleCustomerMapping() {
    try {
      setLoadingRecordsCustomerMapping(true);
      const response: StatusProps[] = await PgcService.getCustomerMappingStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
      setLoadingRecordsCustomerMapping(false);
    }
  }

  async function handleLevel3Mapping() {
    setLocalName("Mapeamento de Cliente");
    setRecords([]);
    try {
      setLoadingRecordsLevel3Mapping(true);
      const response: StatusProps[] = await PgcService.getCustomMappingStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
      setLoadingRecordsLevel3Mapping(false);
    }
  }
  async function handleWalletMapping() {
    setLocalName("Mapeamento de Carteira");
    setRecords([]);
    try {
      setLoadingRecordsCustomerMapping(true);
      const response: StatusProps[] = await PgcService.getWalletMappingDetail(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });
      setRecords(array);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
      setLoadingRecordsCustomerMapping(false);
    }
  }
  console.log("dashboard", dashboard[0]?.rebate_setup);
  console.log("dashboard", dashboard);

  return (
    <Breadcrumb path="Rebate">
      {contextHolder}
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card bordered={false}>
              <Row justify="center" gutter={[8, 8]}>
                <Col xl={5} sm={24} xs={24}>
                  <CardProgressRebate
                    //  cardInfo={dashboard?.setup}
                    label="Setup"
                    disabled={!dashboard[0]?.rebate_setup}
                    actions={[
                      {
                        children: "Setup",
                        type: "primary",
                        onClick: () => navigate("/rebate/setup"),
                      },
                    ]}
                  />
                </Col>

                <Col xl={19} sm={24} xs={24}>
                  <CardProgressRebate
                    //     cardInfo={dashboard?.customer_mapping}
                    label="Extrato"
                    disabled={!dashboard[0]?.rebate_extract}
                    actions={[
                      {
                        children: "Meu Extrato",
                        type: "primary",
                        about: "",
                        onClick: () => navigate("/rebate/my-extract"),
                      },
                      // {
                      //   children: "Detalhes",
                      //   // onClick: handleLevel3Mapping,
                      //   // loading: loadingRecordsLevel3Mapping,
                      //   style: { marginTop: 8 },
                      // },
                    ]}
                  />
                </Col>

                {/* <Col span={5}>
                  <CardProgressRebate
                   label="Metas"
                  //  cardInfo={dashboard?.customer_mapping}
                    disabled={!dashboard?.customer_mapping?.permission}
                    actions={[
                      {
                        children: "Metas",
                        type: "primary",
                        about: "",
                        // onClick: () => navigate("/pgc/customer-mapping"),
                      },
                      // {
                      //   children: "Detalhes",
                      //   // onClick: handleLevel3Mapping,
                      //   // loading: loadingRecordsLevel3Mapping,
                      //   style: { marginTop: 8 },
                      // },
                    ]}
                  />
                </Col> */}
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            {records.length > 0 && (
              <TableStatus
                loading={
                  loadingRecordsBudget ||
                  loadingRecordsActionPlan ||
                  loadingRecordsCustomerMapping ||
                  loadingRecordsLevel3Mapping
                }
                records={records}
                name={localName}
              />
            )}
          </Col>
        </Row>
      )}
    </Breadcrumb>
  );
}
