import { Setup_pgc } from "../../Pgc/Setup";
import { Setup_pgv } from "../../Pgv/Setup";
import { Setup_rebate } from "../../Rebate/Setup";
import { MyExtract } from "../../Rebate/MyExtract";
import { Campaign } from "../../Rebate/Setup/Campaign";
import { Office } from "../Office";
import { User } from "../User";
import { Region } from "../Region";
import { BusinessType } from "../BusinessType";
import { Crop } from "../Crop";
import { GroupProduct } from "../GroupProduct";
import { Product } from "../Product";
import { Parameter } from "../Parameter";
import { CommercialStructure } from "../CommercialStructure";
import { CommercialStructureUser } from "../CommercialStructureUser";
import AccessType from "../Accesstype";
import { CreateClient } from "../CreateClient";
import { ProductPotential } from "../ProductPotential";
import { UpdateSeller } from "../UpdateSeller";
import { Metas } from "../../Rebate/Setup/Metas";

export const SetupRoutes = [
  { path: "pgv/setup", element: <Setup_pgv /> },
  { path: "pgc/setup", element: <Setup_pgc /> },
  { path: "rebate/setup", element: <Setup_rebate /> },
  { path: "/my-extract", element: <MyExtract /> },
  { path: "/setup/campaign", element: <Campaign /> },
  { path: "/setup/metas", element: <Metas /> },
  { path: "/setup/office", element: <Office /> },
  { path: "/setup/user", element: <User /> },
  { path: "/setup/region", element: <Region /> },
  { path: "/setup/business-type", element: <BusinessType /> },
  { path: "/setup/crop", element: <Crop /> },
  { path: "/setup/access-type", element: <AccessType /> },
  { path: "/setup/create-client", element: <CreateClient /> },
  { path: "/setup/product-potential", element: <ProductPotential /> },
  { path: "/setup/update-seller", element: <UpdateSeller /> },
  { path: "/setup/group-product", element: <GroupProduct /> },
  { path: "/setup/product", element: <Product /> },
  { path: "/setup/parameter", element: <Parameter /> },
  { path: "/setup/commercial-structure", element: <CommercialStructure /> },
  {
    path: "/setup/commercial-structure-user",
    element: <CommercialStructureUser />,
  },
];
