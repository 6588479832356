import { Button, Card, Col, message, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import { CardProgress } from "../../components/CardProgress";
import { Loading } from "../../components/Loading";
import { TableStatus } from "../../components/TableStatus";
import { useAppSelector } from "../../hooks";
import SalesPlanningService from "../../services/SalesPlanning";
import { NoticeType } from "antd/lib/message/interface";

interface DashboardProps {
  [name: string]: {
    permission?: boolean;
    status: string;
    label: string;
    color: string;
    percent: number;
    period_open: boolean;
    period_past: boolean;
    initial_date: string;
    final_date: string;
  };
}
interface StatusProps {
  id: number;
  level3_external_name: string;
  pgv_bottomup: string;
  color: string;
}
interface RecordsProps {
  id: number;
  name: string;
  filial: string;
  status: string;
}

export function SalesPlanning() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const [dashboard, setDashboard] = useState<DashboardProps>({});
  const [loading, setLoading] = useState(false);
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [loadingRecordsActionPlan, setLoadingRecordsActionPlan] =
    useState(false);
  const [records, setRecords] = useState<RecordsProps[]>([]);
  const [visible, setVisible] = useState(false);
  const [Produtopotencial, setProdutoPotencial] = useState<string | undefined>("");
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  useEffect(() => {
    loadDashboard();

    async function loadDashboard() {
      try {
        console.log();
        setLoading(true);
        const response = await SalesPlanningService.getDashboard(companyId);
        setDashboard(response);
      } catch (err) {
        ShowMSG("error",err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId]);
  

  useEffect(() => {
    setProdutoPotencial(
      parameters.find((value: any) => value.name === "PRODUTO_POTENCIAL")
        ?.value
    );
    
  }, [parameters]);

  async function handleDetailBottomUp() {
    try {
      setLoadingRecords(true);
      const response: StatusProps[] =
        await SalesPlanningService.getBotoomUpStatus(companyId);
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level3_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecords(false);
    }
  }

  async function handleDetailActionPlan() {
    try {
      setLoadingRecordsActionPlan(true);
      const response: StatusProps[] =
        await SalesPlanningService.getActionPlainStatus(companyId);
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level3_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsActionPlan(false);
    }
  }
  console.log(JSON.stringify(parameters))
  const { Title } = Typography;
  return (
    <Breadcrumb path="Planejamento Gestão de Vendas">
      {contextHolder}
      <>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row align="middle" style={{ marginBottom: 16 }}>
              {/* Breadcrumb on the left */}

              {/* Title in the center */}
              <Col
                style={{ textAlign: "center", flexGrow: 1, background: "none" }}
              >
                <Title level={4} style={{ paddingBottom: "5px" }}>
                  Planejamento Gestão de Vendas
                </Title>
              </Col>
            </Row>
            <Row justify="center" gutter={[8, 8]}>
              <Col span={24}>
                <Card bordered={false}>
                  <Row justify="center" align="top" gutter={[8, 8]}>
                    <Col xl={5} xs={24}>
                      <CardProgress
                        cardInfo={dashboard?.setup}
                        height={568}
                        disabled={!dashboard?.setup?.permission}
                        actions={[
                          {
                            children: "Setup",
                            type: "primary",
                            onClick: () => navigate("/pgv/setup"),
                          },
                        ]}
                      />
                    </Col>

                    <Col xl={14} xs={24}>
                      <Row justify="center" align="top" gutter={[8, 8]}>
                        <Col xl={8} xs={24}>
                          <CardProgress
                            cardInfo={dashboard?.topdown_company}
                            disabled={!dashboard?.topdown_company?.permission}
                            actions={[
                              {
                                children: "Projeções Empresa",
                                type: "primary",
                                onClick: () => navigate("topdown-company"),
                              },
                            ]}
                          />
                        </Col>

                        <Col xl={8} xs={24}>
                          <CardProgress
                            cardInfo={dashboard?.topdown_board}
                            disabled={!dashboard?.topdown_board?.permission}
                            actions={[
                              {
                                children: "Projeções Diretoria-Regional",
                                type: "primary",
                                onClick: () => navigate("topdown-board"),
                              },
                            ]}
                          />
                        </Col>

                        

                        <Col xl={8} xs={24}>
                          <CardProgress
                            cardInfo={dashboard?.topdown_product}
                            disabled={!dashboard?.topdown_product?.permission}
                            actions={[
                              {
                                children: "Projeções Produto",
                                type: "primary",
                                onClick: () => navigate("topdown-product"),
                              },
                            ]}
                          />
                        </Col>

                        {Produtopotencial === "1" ? (
                        <>
                          <Col xl={12} span={12}>
                            <CardProgress
                              cardInfo={dashboard?.bottomup}
                              disabled={!dashboard?.acp?.permission}
                              actions={[
                                {
                                  children: "Projeção de Vendas - ACP",
                                  type: "primary",
                                  onClick: () => navigate("product-potential"),
                                },
                                // {
                                //   children: "Detalhes",
                                //   type: "default",
                                //   onClick: handleDetailBottomUp,
                                //   loading: loadingRecords,
                                //   style: { marginTop: 8 },
                                // },
                              ]}
                            />
                          </Col>

                          <Col xl={12} span={12}>
                            <CardProgress
                              cardInfo={dashboard?.bottomup}
                              disabled={!dashboard?.bottomup?.permission}
                              actions={[
                                {
                                  children: "Bottom Up",
                                  type: "primary",
                                  onClick: () => navigate("bottom-up"),
                                },
                                {
                                  children: "Detalhes",
                                  type: "default",
                                  onClick: handleDetailBottomUp,
                                  loading: loadingRecords,
                                  style: { marginTop: 8 },
                                },
                              ]}
                            />
                          </Col>
                        </>
                      ) : (
                        <Col xl={24} span={24}>
                          <CardProgress
                            cardInfo={dashboard?.bottomup}
                            disabled={!dashboard?.bottomup?.permission}
                            actions={[
                              {
                                children: "Bottom Up",
                                type: "primary",
                                onClick: () => navigate("bottom-up"),
                              },
                              {
                                children: "Detalhes",
                                type: "default",
                                onClick: handleDetailBottomUp,
                                loading: loadingRecords,
                                style: { marginTop: 8 },
                              },
                            ]}
                          />
                        </Col>
                      )}
                        
                      </Row>
                    </Col>

                    <Col xl={5} xs={24}>
                      <CardProgress
                        cardInfo={dashboard?.action_plan}
                        disabled={!dashboard?.action_plan?.permission}
                        height={568}
                        actions={[
                          {
                            children: "Plano de Ação",
                            type: "primary",
                            onClick: () => navigate("action-plain"),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                {records.length > 0 && (
                  <TableStatus
                    loading={loadingRecords || loadingRecordsActionPlan}
                    records={records}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </>
    </Breadcrumb>
  );
}
