import Http from "../../../utils/http";
import { CommercialStructureProps } from "../../../entities/DTO";

class CompanyCommercialStructureService extends Http {
  public constructor() {
    super();
  }

  public getAllStructure = (companyId: number) =>
    this.instance.get(`/company/${companyId}/commercial-structure`);

  public getAllCommercialStrucute = (companyId: number) =>
    this.instance.get(`/company/${companyId}/user-commercial-structure`);

  public getAllCommercialStrucuteUser = (companyId: number, user_id: any) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/findbyuser/${user_id}`
    );

  public getAllCommercialStrucuteLevel0 = (companyId: number) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/find/level0`
    );


    public findAllCommercialStrucuteLevel0 = (companyId: number) =>
      this.instance.get(
        `/company/${companyId}/commercial-structure/all/level0`
      );
  

    public findAllCommercialStrucuteLevel1 = (companyId: number) =>
      this.instance.get(
        `/company/${companyId}/commercial-structure/all/level1`
      );

      public findAllCommercialStrucuteLevel2 = (companyId: number) =>
        this.instance.get(
          `/company/${companyId}/commercial-structure/all/level2`
        );

        public findAllCommercialStrucuteLevel3 = (companyId: number) =>
          this.instance.get(
            `/company/${companyId}/commercial-structure/all/level3`
          );

          public findAllCommercialStrucuteLevel4 = (companyId: number) =>
            this.instance.get(
              `/company/${companyId}/commercial-structure/all/level4`
            );

  public getAllCommercialStrucuteLevel1 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/find/level1/${levelId}`
    );

  public getAllCommercialStrucuteLevel2 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/find/level2/${levelId}`
    );

  public getAllCommercialStrucuteLevel3 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/find/level3/${levelId}`
    );

  public getAllCommercialStrucuteLevel4 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/user-commercial-structure/find/level4/${levelId}`
    );
  public createCommercialStrucuteUser = (companyId: number, body: any) =>
    this.instance.post(`/company/${companyId}/user-commercial-structure`, body);

  public updateCommercialStrucuteUser = (
    companyId: number,
    id: number,
    body: any
  ) =>
    this.instance.put(
      `/company/${companyId}/user-commercial-structure/${id}`,
      body
    );

  public deleteCommercialStrucuteUser = (companyId: number, id: number) =>
    this.instance.delete(
      `/company/${companyId}/user-commercial-structure/${id}`
    );
  //**************** ESTRUTURA COMPLETA ****************
  public getCompleteCommercialStrucute = (companyId: number) =>
    this.instance.get(`/company/${companyId}/commercial-structure`);
  public getCompleteCommercialStrucuteId = (
    companyId: number,
    user_id: number | undefined
  ) =>
    this.instance.get(
      `/company/${companyId}/commercial-structure/find/byuser/${user_id}`
    );
  public getCompleteCommercialStrucuteLevel0 = (companyId: number) =>
    this.instance.get(`/company/${companyId}/commercial-structure/find/level0`);

  public getCompleteCommercialStrucuteLevel1 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/commercial-structure/find/level1/${levelId}`
    );

  public getCompleteCommercialStrucuteLevel2 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/commercial-structure/find/level2/${levelId}`
    );

  public getCompleteCommercialStrucuteLevel3 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/commercial-structure/find/level3/${levelId}`
    );

  public getCompleteCommercialStrucuteLevel4 = (
    companyId: number,
    levelId: number
  ) =>
    this.instance.get(
      `/company/${companyId}/commercial-structure/find/level4/${levelId}`
    );

  public createCommercialStrucuteItem = (
    companyId: number,
    body: CommercialStructureProps
  ) => this.instance.post(`/company/${companyId}/commercial-structure`, body);

  public updateCommercialStrucuteItem = (
    companyId: number,
    id: number,
    body: CommercialStructureProps
  ) =>
    this.instance.put(`/company/${companyId}/commercial-structure/${id}`, body);

  public deleteCommercialStrucuteItem = (companyId: number, id: number) =>
    this.instance.delete(`/company/${companyId}/commercial-structure/${id}`);
}

export default new CompanyCommercialStructureService();
