import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useParameterName = (name: string) => {
  const parameter = useAppSelector((state) => state.entities.parameter);
  return parameter.find((parameter) => parameter.name === name);
};

export const useButtonActiveYears = (year: number) => {
  const parameter = useAppSelector((state) => state.entities.parameter);
  const years = parameter.find((parameter) => parameter.name === "QTD_YEARS");
  return years?.value && parseInt(years.value) === year ? true : false;
};

export const compareLabels = (a: any, b: any) => {
  const regex = /(\D+)(\d+)/;
  const [, aText, aNumber] = a.label?.match(regex) || [];
  const [, bText, bNumber] = b.label?.match(regex) || [];

  if (aText === bText) {
    return (parseInt(aNumber) || 0) - (parseInt(bNumber) || 0);
  }
  return (aText || '').localeCompare(bText || '');
};

export const compareLabelsTables = (key: string) => (a: any, b: any) => {
  const regex = /(\D+)?(\d+)?/;
  const [, aText = a[key], aNumber] = a[key]?.match(regex) || [];
  const [, bText = b[key], bNumber] = b[key]?.match(regex) || [];

  if (aText === bText) {
    return (parseInt(aNumber) || 0) - (parseInt(bNumber) || 0);
  }
  return (aText || '').localeCompare(bText || '');
};
