import {
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  Switch,
  Tooltip,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  FiBell,
  FiChevronDown,
  FiLogOut,
  FiMaximize2,
  FiMenu,
  FiShield,
} from "react-icons/fi";
import ParameterService from "../../../services/Global";
import { useTheme } from "../../../styles/ThemeContext";
import { Logo, Container, Notification, Account } from "./styles";
import logo from "../../../assets/logo.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { logout } from "../../../modules/Auth/Reducer";
import { persistor } from "../../../store";
import { decrement } from "../../../modules/Parameters/Reducer";
import { MenuProps } from "antd/lib";
import { BarChartOutlined, MoonOutlined, SunOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import Industry from "../../../services/Industry";
export function Header() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();
  const { name } = useAppSelector((state) => state.auth.user);
  const user_id = useAppSelector((state) => state.auth.user.id);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [messageApi, contextHolder] = message.useMessage();
  const [dataProfile, setDataProfile] = useState<any>("0");
  const url = window.location.origin;
  // const companyLogo = useAppSelector((state) => state.parameters.company.logo);
  const sleep = (ms: any) => new Promise((resolve) => setTimeout(resolve, ms));
  const info = () => {
    messageApi.info("Selecione a empresa Para acessar o Dashboard");
  };

  const getProfile = async () => {
    const response2 = await Industry.getProfile(companyId, user_id);
    setDataProfile(response2[0].profile);
  };
  const logoutRedirect = async () => {
    try {
      localStorage.setItem("historico", "logout");
      if (companyId) {
        await ParameterService.logout(companyId);
      } else {
        await ParameterService.getglCompanies();
      }
      await sleep(200);
      dispatch(decrement());
      dispatch(logout());
      persistor.purge();
      navigate("/");
    } catch (error) {
      console.error("Error during logout process:", error);
    }
  };

  useEffect(() => {
    if (user_id && companyId) {
      getProfile();
    }
  }, [companyId]);

  <div style={{ display: "flex", alignItems: "center" }}>
    <Tooltip title="Menu">
      <Button
        type="text"
        style={{ marginLeft: 16 }}
        onClick={() => setDrawerVisible(true)}
        icon={
          <FiMenu
            size={25}
            style={{
              margin: "15px 10px 10px 10px",
              cursor: "pointer",
            }}
          />
        }
      />
    </Tooltip>
  </div>;
  const items: MenuProps["items"] = [
    {
      key: "company",
      label: "Trocar empresa",
      icon: <FiMaximize2 />,
    },
    {
      key: "logout",
      label: "Sair",
      icon: <FiLogOut />,
    },
  ];
  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === "company") {
      navigate("/");
    } else if (key === "logout") {
      logoutRedirect();
    } else if (key === "Tema") {
      toggleTheme();
    }
  };

  return (
    <Container>
      {contextHolder}
      <Logo
        src={logo}
        className="logo"
        alt="Logo Cropland"
        onClick={() => navigate("/select-module")}
      />
      <div className="mobile">
        <Tooltip title="Menu">
          <Button
            type="text"
            style={{ marginLeft: 16 }}
            onClick={() => setDrawerVisible(true)}
            icon={
              <FiMenu
                size={25}
                style={{
                  margin: "15px 10px 10px 10px",
                  cursor: "pointer",
                }}
              />
            }
          />
        </Tooltip>
      </div>
      <div className="desktop-menu">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: 50,
          }}
        >
          {companyName !== undefined ? (
            <>
              <Avatar
                icon={<FiShield size={16} />}
                alt={"Logo " + companyName}
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 5,
                }}
              />
            </>
          ) : null}

          <div style={{ display: "block", userSelect: "none" }}>
            <div>{companyName}</div>
            {/*<div style={{ marginTop: -45 }}>
              {companyCnpj?.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1$2$3/$4-$5"
              )}
            </div>*/}
          </div>
        </div>
        {companyId ? (
          <Button
            type="primary"
            style={{ position: "absolute", left: 250 }}
            onClick={() => (window.location.href = "/Dashboard")}
          >
            <img
              width={40}
              style={{ marginLeft: "-20px", display: "none" }}
              alt="Dashboard"
            />
            Dashboard
          </Button>
        ) : null}
        {companyId ? (
          <Button
            type="primary"
            style={{ position: "absolute", left: 410 }}
            onClick={() => (window.location.href = "/Relatorio")}
          >
            <img
              width={40}
              style={{ marginLeft: "-20px", display: "none" }}
              alt="Dashboard"
            />
            Relatórios
          </Button>
        ) : null}

        <Notification>
          <FiBell style={{ marginRight: 10 }} size={18} />
          {url !== "https://sistema.cropland.com.br" ? (
            <Switch
              unCheckedChildren={<SunOutlined />}
              checkedChildren={<MoonOutlined />}
              defaultChecked
              checked={theme === "dark"}
              onChange={() => {
                toggleTheme();
              }}
              style={{ marginRight: 10 }}
            />
          ) : null}

          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <Account>
              <Avatar
                style={{
                  backgroundColor: "#c4c4c4",
                  paddingLeft: 8,
                  fontSize: 18,
                }}
              >
                {name ? name[0].toUpperCase() : ""}
              </Avatar>
              {name}
              <FiChevronDown />
            </Account>
          </Dropdown>
        </Notification>
      </div>
      <Drawer
        title="Menu"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <Menu
          items={[
            {
              key: "dashboard",
              label: "Dashboard",
              icon: <BarChartOutlined />,
              onClick: () => {
                if (companyId) {
                  setDrawerVisible(false);
                  window.location.href = "/Dashboard";
                } else {
                  setDrawerVisible(false);
                  info();
                }
              },
            },
            {
              key: "company",
              label: "Trocar Empresa",
              icon: <FiMaximize2 />,
              onClick: () => {
                navigate("/");
                setDrawerVisible(false);
              },
            },
            {
              key: "Tema",
              label: theme === "light" ? "Modo Escuro" : "Modo Claro",
              icon: theme === "light" ? <MoonOutlined /> : <SunOutlined />,
              onClick: () => {
                toggleTheme();
              },
            },
            {
              key: "logout",
              label: "Sair",
              icon: <FiLogOut />,
              onClick: () => {
                logoutRedirect();
                setDrawerVisible(false);
              },
            },
          ]}
        />
      </Drawer>
    </Container>
  );
}
