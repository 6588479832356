import Http from "../../../utils/http";

class ActionPlanService extends Http {
  public constructor() {
    super();
  }

  public getFindAll = (id: number, level4_id: number) =>
    this.instance.get(`/company/${id}/pgc/action-plan?level4_id=${level4_id}`);

  public findAllInitiatives = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/initiative`);
  };

  public findAllCustomers = (companyId: number, level4_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/action-plan/customers?level4_id=${level4_id}`
    );
  };

  public PostActionPlain = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgc/action-plan`, body);

  public getStatus = (id: number, level4_id: number) =>
    this.instance.get(
      `/company/${id}/pgc/action-plan/status?level4_id=${level4_id}`
    );

  public getDashboard = (id: number, level3_id?: number, level2_id?: number) =>
    this.instance.get(`/company/${id}/pgc/action-plan/Dashboard`);

  public putStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgc/action-plan/status`, body);

  public DeleteActionPlan = (companyId: number, id: number) => {
    return this.instance.delete(
      `/company/${companyId}/pgc/action-plan/${id}`
    );
  };
  public getFilesPGC = (companyId: number, id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/action-plan/pgc_file/${id}`
    );
  };
  public uploadFiles = (companyId: number, id: number, body: object) => {
    return this.instance.post(
      `/company/${companyId}/pgc/action-plan/upload/${id}`,
      body
    );
  };
  public deleteFilePGC = (companyId: number, fileName: string) => {
    return this.instance.delete(
      `/company/${companyId}/pgc/action-plan/upload/${fileName}`
    );
  };

  public downloadFilePGC = (companyId: number, fileName: string) => {
    return this.instance.get(
      `/company/${companyId}/pgc/action-plan/download/${fileName}`
    );
  }
}

export default new ActionPlanService();
