
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import { store } from "../../../store";
import { setCommercialStructureLevel0 } from "../../Reducer";

const { dispatch } = store;

export const level0 = async (companyId: number) => {
  try {
    const response =
      await CompanyCommercialStructureService.getAllCommercialStrucuteLevel0(
        companyId
      );
    dispatch(setCommercialStructureLevel0(response));
  } catch (err) {
    // Handle error appropriately
    console.error("Error fetching level 0 data:", err);
  }
};
