import { useEffect, useState, useCallback } from "react";
import { Select, Form, FormInstance } from "antd";
import { useAppSelector } from "../../../hooks";

interface AccessTypeProps {
  label: string;
  value: number;
}

interface AccessTypeComponentProps {
  formRef?: React.RefObject<FormInstance>;
}

export function AccessType({ formRef }: AccessTypeComponentProps) {
  const recordsAccessType = [
    { id: 0, name: "Todos" }, // Ensure "Todos" is always first
    ...useAppSelector((state) => state.entities.accessType).filter(
      (type) => type.id !== 0
    ),
  ];
  


  const [AccessTypeOptions, setAccessTypeOptions] = useState<AccessTypeProps[]>([]);

  // Populate AccessType options
  useEffect(() => {
    if (recordsAccessType?.length > 0) {
      const options = recordsAccessType.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      setAccessTypeOptions(options);

      // If only one option exists, set it as default value in formRef
      if (options.length === 1) {
        formRef?.current?.setFieldsValue({ AccessType: options[0]?.value });
      }
    } else {
      setAccessTypeOptions([]);
    }
  }, [formRef]);

  // Handle selection change
  const onChange = useCallback(
    (value: number | undefined) => {
      if (value) {
        formRef?.current?.setFieldsValue({ AccessType: value });
      } else {
        formRef?.current?.setFieldsValue({ AccessType: null });
      }
    },
    [formRef]
  );

  return (
    <Form.Item
      name="accessType"
      rules={[{ required: true, message: "Selecione um Tipo de Cliente." }]}
    >
                 <Select
        placeholder="Tipo de Cliente"
        allowClear
        onChange={onChange}
        disabled={!AccessTypeOptions.length}
        style={{ width: "100%" }}
        defaultValue={
          recordsAccessType.length > 0 
            ? recordsAccessType[0]?.id
            : undefined
        } 
      >
        {AccessTypeOptions.map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}