import styled from "styled-components";
import {
  Card,
  Col,
  Row,
  Button,
  Radio,
  RadioChangeEvent,
  DatePicker,
  Avatar,
  Modal,
  message,
} from "antd";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  svg {
    margin-right: 8px;
    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-right: 8px;
    cursor: pointer;
  }
`;
export const Actions = styled.div`
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
  }
  
`;
export const logo = styled.div`
display: inline-block;
background-color: #3c8dbc;
color: #fff;
font-size: 24px;
font-weight: bold;
padding: 10px 15px;
border-radius: 5px;
  
`;

export const logo_text = styled.div`
display: inline-block;
  margin-right: 10px;
  text-shadow: 1px 1px #000;
  
`;

export const AvatarStyled = styled(Avatar)`
  && {
    ${logo} {
      display: inline-block;
      background-color: #3c8dbc;
      color: #fff;
      font-size: 24px;
      font-weight: bold;
      padding: 10px 15px;
      border-radius: 5px;
        
    }

    ${logo_text} {
      display: inline-block;
  margin-right: 10px;
  text-shadow: 1px 1px #000;
    }
  }
`;