import styled from "styled-components";

export const Content = styled.div`
 [data-theme="light"] {
  --bg-color: #f5f5f5;
  --text-color:rgb(41, 41, 41);
  --bg-color-ligth: rgb(160, 160, 160);
  --white-color: #FFF;
  --primary-color:rgb(35, 217, 120);
  --secondary-color: #FFF;
}

/* Tema Escuro */
[data-theme="dark"] {
  --bg-color: rgb(41, 41, 41);
  --text-color: #f5f5f5;
  --bg-color-ligth: rgb(99, 99, 99);
  --primary-color:rgb(30, 148, 85);
  --secondary-color: #1f1f1f;
}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  color: var(--text-color);
  padding: 50px;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  font-size: 14px;
  margin-bottom: 10px;
`;
