import {
  Card,
  Col,
  Row,
  Button,
  Radio,
  DatePicker,
  Avatar,
  Modal,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { Breadcrumb } from "../../../components/Breadcrumb";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Industry from "../../../services/Industry";
import { useAppSelector } from "../../../hooks";
import moment from "moment";
import { AvatarStyled } from "./styles";
import { Loading } from "../../../components/Loading";
import pgtIcon from "../../../assets/pgt_icon.svg";

import { UserOutlined } from "@ant-design/icons";
dayjs.extend(customParseFormat);

const { Option } = Select;

export function MyExtract() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [loading, setLoading] = useState(false);
  const [dataIndustry, setDataIndustry] = useState([]);
  const [dataType, setDataType] = useState([]);
  const [dataIndustryId, setDataIndustryId] = useState([]);
  const [dataIndustryProducts, setDataIndustryIdProducts] = useState([]);
  const [dataVendasProducts, setDataVendasProducts] = useState([]);
  const [dataVendasProductsSeparado, setDataVendasProductsSeparado] = useState(
    []
  );
  const [dataPerfil, setDataPerfil] = useState([]);
  const [dataProfile, setDataProfile] = useState();
  const [Perfil, setPerfil] = useState();
  const [dateSelect, setDateSelect] = useState("");
  const [tipo, setTipo] = useState([]);
  console.log("🚀 ~ file: index.tsx:29 ~ MyExtract ~ tipo:", tipo);
  const [slected, setSlected] = useState("Industrias");
  const options = [
    { label: "Vendas", value: "Industrias" },
    { label: "Produtos", value: "Produtos" },
  ];
  const [visible, setVisible] = useState(false);

  const parameters = useAppSelector((state) => state.auth.user);
  console.log("parameters", JSON.stringify(parameters));

  const [selectedCampaign, setselectedCampaign] = useState([]);

  const [selectedIndustryid, setSelectedIndustryId] = useState([]);
  const [selectedTypeid, setSelectedTypeId] = useState([]);
  const [selectedDateid, setSelectedDateId] = useState([]);
  const [selectedStatusid, setSelectedStatusId] = useState([]);
  const [isListLoading, setListLoading] = useState(false);

  const user_id = useAppSelector((state) => state.auth.user.id);

  const showModal = (tipo) => {
    //console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ tipo:", tipo);
    setTipo(tipo);
    console.log(dataIndustryProducts);
    setVisible(true);
  };

  const getIndustry = async () => {
    setLoading(true);
    const response = await Industry.getIndustry(companyId);
    setDataIndustry(response);
    setLoading(false);
  };
  const getIndustryId = async (id) => {
    setLoading(true);
    const response = await Industry.getIndustryId(companyId, id);
    setDataIndustryId(response);

    // const response2 = await Industry.getIndustryProducts(companyId);
    // setDataIndustryIdProducts(response2);
    setLoading(false);
  };
  const getCampaignAll = async () => {
    setLoading(true);
    const response = await Industry.getCampaignAll(companyId);
    setDataIndustryId(response);

    setLoading(false);
  };

  // const getCampaingWait = async () => {

  //   setLoading(true);
  //   getCampaignAllteste()

  // };

  const getPerfil = async () => {
    const response2 = await Industry.getPerfil(companyId, user_id);
    setDataPerfil(response2);
  };

  const getProfile = async () => {
    const response2 = await Industry.getProfile(companyId, user_id);
    setDataProfile(response2);
  };

  useEffect(() => {
    if (dataPerfil.length > 1) {
      setPerfil(1);
    } else if (dataPerfil.length === 1) {
      setPerfil(2);
    }
  }, [dataPerfil]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await Industry.getCampaignTeste(companyId, {
        industry: selectedIndustryid,
        type: selectedTypeid,
        date: dateSelect,
        // perfil:Perfil,
      });
      setDataIndustryId(response);
      setLoading(false);
    };
    fetchData();
  }, [selectedIndustryid, selectedTypeid, dateSelect, Perfil]);

  const getCampaignAllteste = async () => {
    setLoading(true);
    const response = await Industry.getCampaignTeste(companyId, {
      industry: selectedIndustryid,
      type: selectedTypeid,
      date: dateSelect,
      perfil: Perfil,
    });
    setDataIndustryId(response);

    setLoading(false);
  };

  const getProductsAll = async () => {
    const response2 = await Industry.getIndustryProducts(companyId);
    setDataIndustryIdProducts(response2);
  };

  const getVendasAll = async () => {
    const response2 = await Industry.getVendasProductsAll(companyId);
    setDataVendasProductsSeparado(response2);
  };
  const getVendas = async () => {
    const response2 = await Industry.getVendasProducts(companyId);
    setDataVendasProducts(response2);
  };
  const getTypeAll = async () => {
    setLoading(true);
    const response = await Industry.getTypeAll(companyId);
    setDataType(response);
    setLoading(false);
  };
  const getType = async (id) => {
    setLoading(true);
    const response = await Industry.getType(companyId, id);
    setDataIndustryId(response);
    setLoading(false);
  };
  const getDate = async (Ano_mes) => {
    setLoading(true);
    const response = await Industry.getDate(companyId, Ano_mes);
    setDataIndustryId(response);
    setLoading(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    getPerfil();
    getProfile();
    getProductsAll();
    getVendasAll();
    getVendas();
    getIndustry();
    getTypeAll();
  }, []);

  const onChange = ({ target: { value } }) => {
    setSlected(value);
  };
  const onChangeDate = (date, dateString) => {
    setDateSelect(dateString);
    //getCampaingWait();
  };
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const getSelect = async (id) => {
    if (slected === "Industrias") {
      getIndustryId(id);
    } else if (slected === "tpCompra") {
      getType(id);
    }
  };

  // console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ Produtos:", dataIndustryProducts);
  console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ dataPerfil:", dataProfile);
  console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ dataPerfil:", dataPerfil);
  // console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ Perfil:", Perfil);
  // console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ dataVendasProducts:", dataVendasProducts);
  // console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ dataIndustryId:", dataIndustryId);
  // console.log("🚀 ~ file: index.tsx:41 ~ showModal ~ dataIndustryId:", dataIndustryId);

  return (
    <Breadcrumb path="Meu Extrato">
      <Row>
        <Col span={24}>
          <Row justify="start" align="middle">
            <Col
              span={24}
              style={{ display: "", flexDirection: "row", marginTop: 10 }}
            >
              <div style={{ display: "flex", flexWrap: "wrap", marginTop: 10 }}>
                <Card
                  title="Industrias com Campanhas"
                  bordered={true}
                  style={{
                    minWidth: 300,
                    minHeight: 150,
                    maxWidth: "33%",
                    width: "33%",
                  }}
                >
                  <span></span>

                  <Select
                    placeholder="Todas as Industrias "
                    style={{ width: "100%", margin: "10px 0", minWidth: 0 }}
                    size="large"
                    showSearch
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    value={selectedIndustryid}
                    onChange={(value) => {
                      setSelectedIndustryId(value);
                    }}
                  >
                    <Option key="0" value="">
                      Todas as Industrias
                    </Option>
                    ,
                    {dataIndustry.map((campaign) => (
                      <Option key={campaign.id} value={campaign.id}>
                        {campaign.trading_name}
                      </Option>
                    ))}
                  </Select>
                </Card>

                <Card
                  title="Lista de Tipos"
                  bordered={true}
                  style={{
                    minWidth: 300,
                    minHeight: 150,
                    maxWidth: "33%",
                    width: "33%",
                  }}
                >
                  <Select
                    placeholder="Todos os tipos "
                    style={{ width: "100%", margin: "10px 0", minWidth: 250 }}
                    size="large"
                    showSearch
                    popupMatchSelectWidth={false}
                    optionFilterProp="children"
                    value={selectedTypeid}
                    onChange={(value) => {
                      setSelectedTypeId(value);
                    }}
                  >
                    <Option key="0" value="">
                      Todos os tipos
                    </Option>
                    ,
                    {dataType.map((tipo) => (
                      <Option key={tipo.id} value={tipo.id}>
                        {tipo.name}
                      </Option>
                    ))}
                  </Select>
                </Card>

                <Card
                  title="Escolha o Periodo"
                  bordered={true}
                  style={{
                    minWidth: 300,
                    minHeight: 150,
                    maxWidth: "33%",
                    width: "33%",
                  }}
                >
                  <DatePicker
                    onChange={onChangeDate}
                    picker="year"
                    style={{ width: "100%", margin: "10px 0", minWidth: 250 }}
                    //={disabledDate}
                  />
                </Card>
              </div>

              {dataIndustryId ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingTop: "20px",
                    paddingBottom: "100px",
                  }}
                >
                  {dataIndustryId?.map((item, index) => (
                    <Card
                      key={index}
                      title={
                        <>
                          <Avatar
                            style={{
                              padding: "10px",
                              marginRight: 10,
                              backgroundColor: "#f5f5f5",
                              borderRadius: "40%",
                            }}
                            src={pgtIcon}
                            size={{
                              xs: 14,
                              sm: 22,
                              md: 30,
                              lg: 44,
                              xl: 50,
                              xxl: 60,
                            }}
                          />
                          {item.campaign_name}
                        </>
                      }
                      bordered={true}
                      style={{
                        minWidth: 300,
                        minHeight: 130,
                        maxWidth: "31%",
                        width: "31%",

                        marginBottom: 10,
                        margin: "20px 1% 10px 1%",
                        padding: "10px",
                      }}
                    >
                      <span>
                        Data: {moment(item.dateinit).utc().format("DD/MM/YYYY")}{" "}
                        a {moment(item.dateend).utc().format("DD/MM/YYYY")}
                      </span>
                      <br></br>
                      <span>Industria: {item.industria}</span>
                      <br></br>
                      <span>
                        Status:{" "}
                        {item.status === "A"
                          ? "Ativa"
                          : item.status === "I"
                          ? "Inativa"
                          : "Finalizada"}
                      </span>
                      <br></br>
                      <span>Tipo de Campanha: {item.tipo}</span> <br></br>
                      <span>
                        Total de Volume de Vendas:{" "}
                        {dataVendasProducts
                          .filter(
                            (product) =>
                              product.campaign_id === item.campaign_id &&
                              product.sales_type !== "Pedido" &&
                              dataPerfil.some(
                                (perfil) =>
                                  perfil.level4_id === product.level4_id
                              )
                          )
                          .reduce((total, product) => {
                            return total + product.vendas_produto;
                          }, 0)
                          .toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                            minimumFractionDigits: 0,
                          })}
                      </span>
                      <br></br>
                      {item.tp_meta == "S" ? (
                        <span>
                          {/* Total Premiações:{" "}
                             {dataVendasProducts
                               .reduce((uniqueProducts[], product) => {
                                 const existingProduct = uniqueProducts.find((p) => p.product_id === product.product_id);
                                 if (!existingProduct) {
                                   uniqueProducts.push(product);
                                 }
                                 return uniqueProducts;
                               }, [])
                               .filter((product:any) =>
                                 product.campaign_id === item.campaign_id &&
                                 dataPerfil.some((perfil) => perfil.level4_id === product.level4_id) &&
                                 (product.vendas_produto > product.meta_produto ||
                                  (product.vendas_produto === product.meta_produto && product.vendas_produto !== 0))
                               )
                               .reduce((total:any, product:any) => {
                                 
                                const awardAmount = product.meta_produto !== 0 ? Math.floor(product.vendas_produto / product.meta_produto) * product.award_produto : 0;
                                 return total + awardAmount;
                               }, 0)
                               .toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, { style: "currency", currency: "BRL" })} */}
                          Total Premiações:{" "}
                          {dataVendasProducts

                            .filter(
                              (product) =>
                                product.campaign_id === item.campaign_id &&
                                dataPerfil.some(
                                  (perfil) =>
                                    perfil.level4_id === product.level4_id
                                ) &&
                                (product.vendas_produto >
                                  product.meta_produto ||
                                  (product.vendas_produto ===
                                    product.meta_produto &&
                                    product.vendas_produto !== 0))
                            )
                            .reduce((total, product) => {
                              const awardAmount =
                                product.meta_produto !== 0
                                  ? Math.floor(
                                      product.vendas_produto /
                                        product.meta_produto
                                    ) * product.award_produto
                                  : 0;
                              return total + awardAmount;
                            }, 0)
                            .toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                              style: "currency",
                              currency: "BRL",
                            })}
                        </span>
                      ) : (
                        <span>
                          Total Premiações:{" "}
                          {dataVendasProducts
                            .filter(
                              (product) =>
                                product.campaign_id === item.campaign_id &&
                                dataPerfil.some(
                                  (perfil) =>
                                    perfil.level4_id === product.level4_id
                                )
                            )
                            .reduce((total, product) => {
                              return total + product.total_award_produto;
                            }, 0)
                            .toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                              style: "currency",
                              currency: "BRL",
                            })}
                        </span>
                      )}
                      <Button
                        style={{
                          width: "100%",
                          marginTop: 10,
                          backgroundColor: "#23D978",
                          color: "#FFF",
                          height: 40,
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                        onClick={() => showModal(item)}
                      >
                        Ver Detalhes
                      </Button>
                    </Card>
                  ))}
                  <Modal
                    title="Detalhes da Campanha"
                    open={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={600}
                  >
                    <Card bordered={false}>
                      <Row justify="center" align="middle">
                        <Col>
                          <Radio.Group
                            options={options}
                            onChange={onChange}
                            value={slected}
                            optionType="button"
                            buttonStyle="solid"
                          />
                        </Col>
                      </Row>
                    </Card>

                    {tipo.tp_meta === "S" ? (
                      <>
                        {slected === "Industrias" ? (
                          <>
                            {/* {Perfil === 1 ? (
                           null
                          ) : (
                            null
                          )} */}

                            {dataPerfil

                              // .filter((lista) => {

                              //       if (dataProfile) {
                              //         if (dataProfile[0].id === 10) {
                              //           return lista.level4_id === parseInt(user_id? user_id: "");

                              //         } else {
                              //           return dataProfile;

                              //         }
                              //       } else {
                              //         console.log("dataProfile found, but id is not 10");
                              //         return dataProfile;

                              //       }

                              //     })

                              .sort((vendedorA, vendedorB) => {
                                const hasDataA = dataVendasProducts.some(
                                  (product) =>
                                    product.campaign_id === tipo.campaign_id &&
                                    product.level4_id === vendedorA.level4_id
                                );
                                const hasDataB = dataVendasProducts.some(
                                  (product) =>
                                    product.campaign_id === tipo.campaign_id &&
                                    product.level4_id === vendedorB.level4_id
                                );

                                if (hasDataA && !hasDataB) {
                                  return -1; // vendedorA has dataVendasProducts, vendedorB doesn't
                                } else if (!hasDataA && hasDataB) {
                                  return 1; // vendedorB has dataVendasProducts, vendedorA doesn't
                                } else {
                                  return 0; // Both vendedores have or don't have dataVendasProducts
                                }
                              })
                              .map((vendedores) => (
                                <div
                                  key={vendedores.level4_id}
                                  style={{
                                    paddingBottom: "2px",
                                    border: "1px solid #bcbcbc",
                                  }}
                                >
                                  {""}
                                  <span
                                    style={{
                                      paddingBottom: "2px",
                                      textDecorationLine: "underline",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Vendedor: {vendedores.level4_external_name}
                                  </span>
                                  <br></br>

                                  {visible &&
                                    dataVendasProducts
                                      .filter((product) => {
                                        if (
                                          product.campaign_id ===
                                            tipo.campaign_id &&
                                          product.level4_id ===
                                            vendedores.level4_id
                                        ) {
                                          return (
                                            product.campaign_id ===
                                              tipo.campaign_id &&
                                            product.level4_id ===
                                              vendedores.level4_id
                                          );
                                        }
                                      })

                                      .map((item) => (
                                        <div style={{ paddingBottom: "0px" }}>
                                          <span style={{ fontWeight: "600" }}>
                                            Prod. {item.product_name}
                                          </span>{" "}
                                          {" - "} Total Vol. vendas:{" "}
                                          {item.vendas_produto.toLocaleString(
                                            "pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          },
                                            { minimumFractionDigits: 0 }
                                          )}
                                          ,{" "}
                                          {item.meta_produto ? (
                                            <span>
                                              {" "}
                                              meta:{" "}
                                              {Math.round(
                                                (item.vendas_produto /
                                                  item.meta_produto) *
                                                  100
                                              )}
                                              %
                                            </span>
                                          ) : (
                                            <span> meta: N/A</span>
                                          )}
                                          <br></br>
                                          {/* <span style={{ fontWeight:"600" }}>Detalhes:</span> */}
                                          {visible &&
                                            dataVendasProductsSeparado
                                              .filter((product) => {
                                                if (
                                                  product.campaign_id ===
                                                    tipo.campaign_id &&
                                                  product.level4_id ===
                                                    vendedores.level4_id &&
                                                  product.product_id ===
                                                    item.product_id
                                                ) {
                                                  return (
                                                    product.campaign_id ===
                                                      tipo.campaign_id &&
                                                    product.level4_id ===
                                                      vendedores.level4_id
                                                  );
                                                }
                                              })
                                              .sort((a, b) => {
                                                if (
                                                  a.sales_type === "Venda" &&
                                                  b.sales_type !== "Venda"
                                                ) {
                                                  return -1;
                                                }
                                                if (
                                                  a.sales_type !== "Venda" &&
                                                  b.sales_type === "Venda"
                                                ) {
                                                  return 1;
                                                }
                                                return 0;
                                              })
                                              .map((item) => (
                                                <div>
                                                  <span>
                                                    Vol. vendas:{" "}
                                                    {item.vendas_produto}
                                                  </span>
                                                  {item.sales_type ===
                                                    "Venda" && (
                                                    <span
                                                      style={{ color: "green" }}
                                                    >
                                                      {" "}
                                                      Faturados
                                                    </span>
                                                  )}
                                                  {item.sales_type ===
                                                    "Devolução" && (
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      {" "}
                                                      Devolução
                                                    </span>
                                                  )}
                                                  {item.sales_type ===
                                                    "Pedido" && (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                      }}
                                                    >
                                                      {" "}
                                                      Pedido
                                                    </span>
                                                  )}
                                                </div>
                                              ))}
                                          {/* {item.meta_produto ? (
                                  <span> meta: {Math.round((item.vendas_produto / item.meta_produto) * 100)}%</span>
                                ) : (
                                  <span>% meta: N/A</span>
                                )}
                                  <br></br> */}
                                          {/* <span> - Meta: 0,00</span>  */}
                                        </div>
                                      ))}

                                  {visible &&
                                    dataVendasProducts.filter((product) => {
                                      if (
                                        product.campaign_id ===
                                          tipo.campaign_id &&
                                        product.level4_id ===
                                          vendedores.level4_id
                                      ) {
                                        return (
                                          product.campaign_id ===
                                            tipo.campaign_id &&
                                          product.level4_id ===
                                            vendedores.level4_id
                                        );
                                      }
                                    }).length === 0 && (
                                      <span> Nenhuma Venda. </span>
                                    )}
                                </div>
                              ))}
                          </>
                        ) : null}

                        {slected === "Produtos" ? (
                          <>
                            {visible &&
                              dataIndustryProducts
                                .filter((product) => {
                                  if (
                                    product.campaign_id === tipo.campaign_id
                                  ) {
                                    return (
                                      product.campaign_id === tipo.campaign_id
                                    );
                                  }
                                })
                                .map((item) => (
                                  <div
                                    style={{
                                      paddingBottom: "5px",
                                      border: "1px solid #bcbcbc",
                                    }}
                                  >
                                    <span>Prod. {item.product_name}</span>
                                    <span style={{ color: "green" }}>
                                      - Premiação:{" "}
                                      {item.award
                                        ? item.award.toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : "N/A"}{" "}
                                      {" - "}
                                    </span>
                                    <span style={{}}>
                                      Meta:{" "}
                                      {item.meta
                                        ? item.meta.toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                                            minimumFractionDigits: 0,
                                          })
                                        : "N/A"}{" "}
                                      - Vol. (kg/Lt/Sc){" "}
                                    </span>
                                  </div>
                                ))}
                          </>
                        ) : null}
                      </>
                    ) : (
                      // SEM META

                      <>
                        {slected === "Industrias" ? (
                          <>
                            {dataPerfil
                              .sort((vendedorA, vendedorB) => {
                                const hasDataA = dataVendasProducts.some(
                                  (product) =>
                                    product.campaign_id === tipo.campaign_id &&
                                    product.level4_id === vendedorA.level4_id
                                );
                                const hasDataB = dataVendasProducts.some(
                                  (product) =>
                                    product.campaign_id === tipo.campaign_id &&
                                    product.level4_id === vendedorB.level4_id
                                );

                                if (hasDataA && !hasDataB) {
                                  return -1; // vendedorA has dataVendasProducts, vendedorB doesn't
                                } else if (!hasDataA && hasDataB) {
                                  return 1; // vendedorB has dataVendasProducts, vendedorA doesn't
                                } else {
                                  return 0; // Both vendedores have or don't have dataVendasProducts
                                }
                              })
                              .map((vendedores) => (
                                <div
                                  key={vendedores.level4_id}
                                  style={{
                                    paddingBottom: "2px",
                                    border: "1px solid #bcbcbc",
                                  }}
                                >
                                  {""}
                                  <span
                                    style={{
                                      paddingBottom: "2px",
                                      textDecorationLine: "underline",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Vendedor: {vendedores.level4_external_name}
                                  </span>
                                  <br></br>

                                  {visible &&
                                    dataVendasProducts
                                      .filter((product) => {
                                        if (
                                          product.campaign_id ===
                                            tipo.campaign_id &&
                                          product.level4_id ===
                                            vendedores.level4_id
                                        ) {
                                          return (
                                            product.campaign_id ===
                                              tipo.campaign_id &&
                                            product.level4_id ===
                                              vendedores.level4_id
                                          );
                                        }
                                      })
                                      .map((item) => (
                                        <div style={{ paddingBottom: "0px" }}>
                                          <span style={{ fontWeight: "600" }}>
                                            Prod. {item.product_name}{" "}
                                          </span>{" "}
                                          <span>
                                            {" - "} Total Vol. vendas:{" "}
                                            {item.vendas_produto.toLocaleString(
                                              "pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          },
                                              { minimumFractionDigits: 0 }
                                            )}{" "}
                                          </span>
                                          <span>
                                            Premiação:{" "}
                                            {item.award_produto
                                              ? (
                                                  item.vendas_produto *
                                                  item.award_produto
                                                ).toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                                                  style: "currency",
                                                  currency: "BRL",
                                                })
                                              : "não disponível"}
                                          </span>
                                          <br></br>
                                          {visible &&
                                            dataVendasProductsSeparado
                                              .filter((product) => {
                                                if (
                                                  product.campaign_id ===
                                                    tipo.campaign_id &&
                                                  product.level4_id ===
                                                    vendedores.level4_id &&
                                                  product.product_id ===
                                                    item.product_id
                                                ) {
                                                  return (
                                                    product.campaign_id ===
                                                      tipo.campaign_id &&
                                                    product.level4_id ===
                                                      vendedores.level4_id
                                                  );
                                                }
                                              })
                                              .sort((a, b) => {
                                                if (
                                                  a.sales_type === "Venda" &&
                                                  b.sales_type !== "Venda"
                                                ) {
                                                  return -1;
                                                }
                                                if (
                                                  a.sales_type !== "Venda" &&
                                                  b.sales_type === "Venda"
                                                ) {
                                                  return 1;
                                                }
                                                return 0;
                                              })
                                              .map((item) => (
                                                <div>
                                                  <span>
                                                    Vol. vendas:{" "}
                                                    {item.vendas_produto}
                                                  </span>
                                                  {item.sales_type ===
                                                    "Venda" && (
                                                    <span
                                                      style={{ color: "green" }}
                                                    >
                                                      {" "}
                                                      Faturados
                                                    </span>
                                                  )}
                                                  {item.sales_type ===
                                                    "Devolução" && (
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      {" "}
                                                      Devolução
                                                    </span>
                                                  )}
                                                  {item.sales_type ===
                                                    "Pedido" && (
                                                    <span
                                                      style={{
                                                        color: "orange",
                                                      }}
                                                    >
                                                      {" "}
                                                      Pedido
                                                    </span>
                                                  )}
                                                </div>
                                              ))}
                                          {/* <span> - Meta: 0,00</span>  */}
                                        </div>
                                      ))}

                                  {visible &&
                                    dataVendasProducts.filter((product) => {
                                      if (
                                        product.campaign_id ===
                                          tipo.campaign_id &&
                                        product.level4_id ===
                                          vendedores.level4_id
                                      ) {
                                        return (
                                          product.campaign_id ===
                                            tipo.campaign_id &&
                                          product.level4_id ===
                                            vendedores.level4_id
                                        );
                                      }
                                    }).length === 0 && (
                                      <span> Nenhuma Venda. </span>
                                    )}
                                </div>
                              ))}
                          </>
                        ) : null}

                        {slected === "Produtos" ? (
                          <>
                            {visible &&
                              dataIndustryProducts
                                .filter((product) => {
                                  if (
                                    product.campaign_id === tipo.campaign_id
                                  ) {
                                    return (
                                      product.campaign_id === tipo.campaign_id
                                    );
                                  }
                                })
                                .map((item) => (
                                  <div
                                    style={{
                                      paddingBottom: "5px",
                                      border: "1px solid #bcbcbc",
                                    }}
                                  >
                                    <span>Prod. {item.product_name}</span>
                                    <span>
                                      - Premiação:{" "}
                                      {item.award
                                        ? item.award.toLocaleString("pt-BR",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }, {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : "N/A"}{" "}
                                      {" * "} Vol. (kg/Lt/Sc) Faturados
                                    </span>
                                  </div>
                                ))}
                          </>
                        ) : null}
                      </>
                    )}
                  </Modal>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingTop: "20px",
                  }}
                >
                  <Loading />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Breadcrumb>
  );
}
