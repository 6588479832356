import Http from "../../../utils/http";

class CompanyBusinessTypeService extends Http {
  public constructor() {
    super();
  }

  public getCompanyBusinessType = (companyId: number) =>
    this.instance.get(`/company/${companyId}/user-business-type`);

  public getCompanyProductType = (companyId: number) =>
    this.instance.get(`/company/${companyId}/product`);

  public getAccessType = (companyId: number) =>
    this.instance.get(`/global/access-type/company/${companyId}`);

  public getBusinessType = (companyId: number) =>
    this.instance.get(`/company/${companyId}/business-type`);

  public createTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/business-type`, body);
  };

  public updateTask = (companyId: number, body: object, taskId: number) => {
    return this.instance.put(
      `/company/${companyId}/business-type/${taskId}`,
      body
    );
  };

  public deleteTask = (companyId: number, taskId: number) => {
    return this.instance.delete(
      `/company/${companyId}/business-type/${taskId}`
    );
  };
}

export default new CompanyBusinessTypeService();
