import { Button, Card, Col, message, Row } from "antd";
import { useNavigate } from "react-router-dom";
import PgcService from "../../../services/Pgc";
import { Breadcrumb } from "../../../components/Breadcrumb";

import { Content, Title } from "./styles";
import { useAppSelector } from "../../../hooks";
import { useEffect, useState } from "react";
import { Loading } from "../../../components/Loading";
import { NoticeType } from "antd/lib/message/interface";

export function Setup_pgv() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [permission, setPermission] = useState<any>([]);
  const [parameter, setParameter] = useState<boolean>(false);
  const [user, setUser] = useState<boolean>(false);
  const [commercialStructure, setCommercialStructure] =
    useState<boolean>(false);
  const [commercialStructureUser, setCommercialStructureUser] =
    useState<boolean>(false);
  const [crop, setCrop] = useState<boolean>(false);
  const [groupProduct, setGroupProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<boolean>(false);
  const [accessType, setAccessType] = useState<boolean>(false);
  const [businessType, setBusinessType] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  
      const ShowMSG = (type: NoticeType, test: string) => {
        messageApi.open({
          type,
          content: test,
        });
      };
  useEffect(() => {
    loadPermission();
  }, [companyId]);
  async function loadPermission() {
    setLoading(true);
    try {
      const response = await PgcService.getPGVPermission(companyId);
      setPermission(response);
      setParameter(response.pgv_setup_parameter.permission);
      setUser(response.pgv_setup_user.permission);
      setCommercialStructure(response.pgv_setup_commercialStructure.permission);
      setCommercialStructureUser(
        response.pgv_setup_commercialStructure_user.permission
      );
      setCrop(response.pgv_setup_crop.permission);
      setGroupProduct(response.pgv_setup_groupProduct.permission);
      setProduct(response.pgv_setup_product.permission);
      setAccessType(response.pgv_setup_accessType.permission);
      setBusinessType(response.pgv_setup_businessType.permission);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Breadcrumb path="PGV / Setup">
      {contextHolder}
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={[16, 16]}>
          

          {user ? (
            <Col xl={6} xs={24} key={2}>
              <Card bordered={false}>
                <Content>
                  <Title>Usuário</Title>
                  <Button
                    disabled={!user}
                    type="primary"
                    onClick={() => navigate("/setup/user")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {commercialStructure ? (
            <Col xl={6} xs={24} key={3}>
              <Card bordered={false}>
                <Content>
                  <Title>Estrutura Comercial</Title>
                  <Button
                    disabled={!commercialStructure}
                    type="primary"
                    onClick={() => navigate("/setup/commercial-structure")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
          {commercialStructureUser ? (
            <Col xl={6} xs={24} key={4}>
              <Card bordered={false}>
                <Content>
                  <Title>Estrutura Comercial Usuário</Title>
                  <Button
                    disabled={!commercialStructureUser}
                    type="primary"
                    onClick={() => navigate("/setup/commercial-structure-user")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
          {crop ? (
            <Col xl={6} xs={24} key={5}>
              <Card bordered={false}>
                <Content>
                  <Title>Cultivo</Title>
                  <Button
                    disabled={!crop}
                    type="primary"
                    onClick={() => navigate("/setup/crop")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
          {businessType ? (
            <Col xl={6} xs={24} key={11}>
              <Card bordered={false}>
                <Content>
                  <Title>Tipo de Negócio</Title>
                  <Button
                    disabled={!businessType}
                    type="primary"
                    onClick={() => navigate("/setup/business-type")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}

          {groupProduct ? (
            <Col xl={6} xs={24} key={6}>
              <Card bordered={false}>
                <Content>
                  <Title>Grupo Produto</Title>
                  <Button
                    disabled={!groupProduct}
                    type="primary"
                    onClick={() => navigate("/setup/group-product")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
          {product ? (
            <Col xl={6} xs={24} key={7}>
              <Card bordered={false}>
                <Content>
                  <Title>Produto</Title>
                  <Button
                    disabled={!product}
                    type="primary"
                    onClick={() => navigate("/setup/product")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
          {accessType ? (
            <Col xl={6} xs={24} key={8}>
              <Card bordered={false}>
                <Content>
                  <Title>Tipo de Acesso</Title>
                  <Button
                    disabled={!accessType}
                    type="primary"
                    onClick={() => navigate("/setup/access-type")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
       
          {parameter ? (
                      <Col xl={6} xs={24} key={9}>
                        <Card bordered={false}>
                          <Content>
                            <Title>Cadastro de Clientes</Title>
                            <Button
                              disabled={!parameter}
                              type="primary"
                              onClick={() => navigate("/setup/create-client")}
                            >
                              Acessar
                            </Button>
                          </Content>
                        </Card>
                      </Col>
                    ) : null}
                       {parameter ? (
                      <Col xl={6} xs={24} key={9}>
                        <Card bordered={false}>
                          <Content>
                            <Title>Setup - ACP</Title>
                            <Button
                              disabled={!parameter}
                              type="primary"
                              onClick={() => navigate("/setup/product-potential")}
                            >
                              Acessar
                            </Button>
                          </Content>
                        </Card>
                      </Col>
                    ) : null}
          {parameter ? (
            <Col xl={6} xs={24} key={1}>
              <Card bordered={false}>
                <Content>
                  <Title>Parâmetro</Title>
                  <Button
                    disabled={!parameter}
                    type="primary"
                    onClick={() => navigate("/setup/parameter")}
                  >
                    Acessar
                  </Button>
                </Content>
              </Card>
            </Col>
          ) : null}
        </Row>
      )}
    </Breadcrumb>
  );
}
