import { message } from "antd";
import { NoticeType } from "antd/lib/message/interface";


export const useMessage = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = (type: NoticeType, content: string) => {
    messageApi.open({
      type,
      content,
    });
  };

  return { showMessage, contextHolder };
};
