import styled from "styled-components";
import { Layout } from "antd";

export const Container = styled(Layout.Header)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 20px;

  .desktop-menu {
    display: flex;
    width: 40%;
    height: 50px;
    flex-flow: row nowrap;
    justify-content: end;
    align-items: center;
    background-color: #fff;
    padding: 0 5px;
  }
  .ant-dropdown-trigger {
    cursor: pointer;
  }
  .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .desktop-menu {
      display: none;
    }
    .mobile {
      display: flex;
      align-items: center;
    }
  }
`;

export const Logo = styled.img`
  cursor: pointer;
  height: 40px;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

export const Notification = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-right: 8px;
  }
`;
