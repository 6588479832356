import { Layout as LayoutAntd } from "antd";

import { Header } from "./Header";

import { Content } from "./styles";
interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <LayoutAntd>
      <Header />
      <Content>{children}</Content>
    </LayoutAntd>
  );
}
