import Http from "../../../utils/http";

class ActionPlanService extends Http {
  public constructor() {
    super();
  }

  public findAllTasks = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/pgv/action-plan`);
  };

  public findAllInitiatives = (companyId: number) => {
    return this.instance.get(`/company/${companyId}/initiative`);
  };

  public findLevel3 = (companyId: number, id: number) => {
    return this.instance.get(`/company/${companyId}/pgv/action-plan/${id}`);
  };

  public findAllLevel3 = (companyId: number) => {
    return this.instance.get(
      `/company/${companyId}/commercial-structure/level3`
    );
  };

  public createTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/pgv/action-plan`, body);
  };

  public updateTask = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/pgv/action-plan`, body);
  };

  public deleteTask = (companyId: number, taskId: number) => {
    return this.instance.delete(
      `/company/${companyId}/pgv/action-plan/${taskId}`
    );
  };
  public getFilesPGV = (companyId: number, id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgv/action-plan/pgv_file/${id}`
    );
  };
  public uploadFiles = (companyId: number, id: number, body: object) => {
    return this.instance.post(
      `/company/${companyId}/pgv/action-plan/upload/${id}`,
      body
    );
  };
  public deleteFilePGV = (companyId: number, fileName: string) => {
    return this.instance.delete(
      `/company/${companyId}/pgv/action-plan/upload/${fileName}`
    );
  };

  public downloadFilePGV = (companyId: number, fileName: string) => {
    return this.instance.get(
      `/company/${companyId}/pgv/action-plan/download/${fileName}`
    );
  }
}

export default new ActionPlanService();
