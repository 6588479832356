import styled from "styled-components";

export const Container = styled.div`
  padding: 0 40px;
`;

export const Card = styled.div`
  [data-theme="light"] {
    --bg-color: #f5f5f5;
    --text-color: rgb(41, 41, 41);
    --bg-color-ligth: rgb(160, 160, 160);
    --white-color: #fff;
    --primary-color: rgb(35, 217, 120);
    --secondary-color: #fff;
  }

  /* Tema Escuro */
  [data-theme="dark"] {
    --bg-color: rgb(41, 41, 41);
    --text-color: #f5f5f5;
    --bg-color-ligth: rgb(99, 99, 99);
    --primary-color: rgb(30, 148, 85);
    --secondary-color: #1f1f1f;
  }

  transition: background-color 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 48px 96px;
  height: 300px;
  margin-top: 48px;

  background: var(--secondary-color);
  border-radius: 8px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  font-size: 32px;
  line-height: 30px;
`;

export const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #727272;
`;

export const Icon = styled.img`
  color: #727272;
`;
