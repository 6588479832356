import {SalesPlanning} from "../index";
import {TopDownCompany} from "../TopDownCompany";
import {TopDownProduct} from "../TopDownProduct";
import {TopDownBoard} from "../TopDownBoard";
import {BottomUp} from "../BottomUp";
import {ActionPlan} from "../ActionPlan";
import {ProductPotential} from "../ProductPotential";

export const SalesPlanningRoutes = [
    {path: "/pgv", element: <SalesPlanning/>},
    {path: "/pgv/topdown-company", element: <TopDownCompany/>},
    {path: "/pgv/topdown-product", element: <TopDownProduct/>},
    {path: "/pgv/topdown-board", element: <TopDownBoard/>},
    {path: "/pgv/bottom-up", element: <BottomUp/>},
    {path: "/pgv/action-plain", element: <ActionPlan/>},
    {path: "/pgv/product-potential", element: <ProductPotential/>},
];