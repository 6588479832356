import {
  Card,
  Col,
  Row,
  Table,
  Tag,
  Select,
  DatePicker,
  Statistic,
  Button,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  FullscreenExitOutlined,
  FullscreenOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { compareLabelsTables, useAppSelector } from "../../../../hooks";
import ActionPlanService from "../../../../services/SalesPlanning/ActionPlan";
import PowerBi_all from "../../../../services/Global/PowerBi";
import "./index.css";
import dayjs from "dayjs";
import ReactEcharts from "echarts-for-react";
import { useTheme } from "../../../../styles/ThemeContext";
const { Option } = Select;
const { RangePicker } = DatePicker;
export function ActionPlanDashboard() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [timeAutoUpdate, setTimeAutoUpdate] = useState<number>(0);
  const [counter, setCounter] = useState<number | null>(null);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [dataIniciativeConfig, setDataIniciativeConfig] = useState<any[]>([]);
  const [dataIniciativeStatusConfig, setDataIniciativeStatusConfig] = useState<
    any[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);
  const [filterRegional, setFilterRegional] = useState<number | null>(0);
  const [filterFiliall, setFilterFiliall] = useState<number | 0>(0);
  const [filialOptions, setFilialOptions] = useState([]);
  const [startDate1, setStartDate1] = useState<any>(null);
  const [startDate2, setStartDate2] = useState<any>(null);
  const [endDate1, setEndDate1] = useState<any>(null);
  const [endDate2, setEndDate2] = useState<any>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const { theme } = useTheme();
  const [statusTotals, setStatusTotals] = useState({
    totalInitiatives: 0,
    concluida: 0,
    naoIniciada: 0,
    emAndamento: 0,
    vencida: 0,
    atrasada: 0,
    cancelada: 0,
  });

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Tarefa",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Atividade",
      dataIndex: "Atividade",
      key: "Atividade",
      sorter: (a: any, b: any) => a.Atividade.localeCompare(b.Atividade),
    },
    {
      title: "Filial",
      dataIndex: "filial",
      key: "filial",
      sorter: (a: any, b: any) => a.filial.localeCompare(b.filial),
      onCell: (record: any) => {
        return {
          onClick: () => {
            setFilterFiliall(
              filterFiliall === record.filial_id ? 0 : record.filial_id
            );
          },
        };
      },
    },
    {
      title: "Data de início",
      dataIndex: "data_inicio",
      key: "data_inicio",
      sorter: (a: any, b: any) => a.data_inicio.localeCompare(b.data_inicio),
    },
    {
      title: "Data de entrega",
      dataIndex: "data_fim",
      key: "data_fim",
      sorter: (a: any, b: any) => a.data_fim.localeCompare(b.data_fim),
    },
    {
      title: "Valor Ação",
      dataIndex: "action_value",
      key: "action_value",
      sorter: (a: any, b: any) => a.action_value - b.action_value,
      render: (text: string | null) => {
        return text
          ? Number(text).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
      onCell: (record: any) => {
        return {
          onClick: () => {
            setFilteredStatus(
              filteredStatus === record.status ? null : record.status
            );
          },
        };
      },
    },
  ];

  const LimparFiltros = () => {
    setFilterRegional(0);
    setFilterFiliall(0);
    setFilteredStatus(null);
    setStartDate1(null);
    setStartDate2(null);
    setEndDate1(null);
    setEndDate2(null);
  };

  useEffect(() => {
    GetDataBottomUpFindRegional();
    FindData();
  }, [theme]);

  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return "Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }

  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }

  async function FindData() {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllTasks(companyId);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          ...rawTask,
          key: rawTask.id,
          filial_id: rawTask.level3_id,
          filial: rawTask.level3_external_name,
          description: rawTask.description,
          initiative_id: rawTask.initiative_id,
          data_inicio: moment(rawTask.initial_date).format("DD/MM/YYYY"),
          data_fim: moment(rawTask.final_date).format("DD/MM/YYYY"),
          Atividade: rawTask.initiative_name,
          action_value: rawTask.action_value,
        };
      });

      setSearchData(formattedTasks.sort((a: any, b: any) => b.key - a.key));
      setListTasks(formattedTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function GetDataBottomUpFindRegional(level0_id?: number) {
    const response = await PowerBi_all.getButtomUpFindRegional(companyId);
    setOptionsRegional(response);
  }
  useEffect(() => {
    // Se o tempo de atualização for 0, parar a contagem e a atualização
    if (timeAutoUpdate === 0) {
      setCounter(0);
      return;
    }

    const tempoInicial = Number(timeAutoUpdate);
    setCounter(tempoInicial);

    let countdownInterval: NodeJS.Timeout;
    let dataUpdateInterval: NodeJS.Timeout;

    // Função para iniciar a contagem regressiva
    const startCountdown = () => {
      countdownInterval = setInterval(() => {
        setCounter((prev: any) => {
          if (prev <= 1) {
            return tempoInicial; // Reinicia o contador
          }
          return prev - 1;
        });
      }, 1000);
    };

    // Função para chamar FindData periodicamente
    const startDataUpdate = () => {
      dataUpdateInterval = setInterval(() => {
        console.log("timeAutoUpdate", timeAutoUpdate);
        FindData();
      }, timeAutoUpdate * 1000);
    };

    // Inicia ambos os intervalos
    startCountdown();
    startDataUpdate();

    // Limpeza dos intervalos
    return () => {
      clearInterval(countdownInterval);
      clearInterval(dataUpdateInterval);
    };
  }, [timeAutoUpdate]);

  const handleFilialChange = (value: any) => {
    setFilterFiliall(value);
  };

  function handleChangeStatus(e: any) {
    if (e === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(e);
    }
  }

  useEffect(() => {
    updateFilteredDataAndCharts();
  }, [
    filteredStatus,
    filterRegional,
    filterFiliall,
    listTasks,
    startDate1,
    startDate2,
    endDate1,
    endDate2,
  ]);

  const updateFilteredDataAndCharts = () => {
    const filteredTasks = listTasks.filter((record) => {
      const matchesSearch =
        !search ||
        record.description?.toLowerCase().includes(search.toLowerCase());

      const matchesStatus = !filteredStatus || record.status === filteredStatus;

      const matchesFilial =
        !filterFiliall || record.level3_id === filterFiliall;

      const matchesDateInitial =
        (!startDate1 ||
          moment(record.data_inicio, "DD/MM/YYYY").isSameOrAfter(
            moment(startDate1, "DD/MM/YYYY")
          )) &&
        (!startDate2 ||
          moment(record.data_inicio, "DD/MM/YYYY").isSameOrBefore(
            moment(startDate2, "DD/MM/YYYY")
          ));

      const matchesDateFinal =
        (!endDate1 ||
          moment(record.data_fim, "DD/MM/YYYY").isSameOrAfter(
            moment(endDate1, "DD/MM/YYYY")
          )) &&
        (!endDate2 ||
          moment(record.data_fim, "DD/MM/YYYY").isSameOrBefore(
            moment(endDate2, "DD/MM/YYYY")
          ));

      return (
        matchesSearch &&
        matchesStatus &&
        matchesFilial &&
        matchesDateInitial &&
        matchesDateFinal
      );
    });

    setSearchData(filteredTasks);

    // Atualizar gráficos e totais
    const initiativeCount = filteredTasks.reduce((acc, task) => {
      const initiativeName = task.Atividade;
      if (initiativeName) {
        acc[initiativeName] = (acc[initiativeName] || 0) + 1;
      }
      return acc;
    }, {});

    const initiativeChartData = Object.entries(initiativeCount)
      .map(([name, count]) => ({
        name: name,
        value: count,
      }))
      .sort((a: any, b: any) => b.value - a.value);
    setDataIniciativeConfig(initiativeChartData);

    const totals = calculateStatusTotals(filteredTasks);
    setStatusTotals(totals);

    const statusValueSum = filteredTasks.reduce((acc, task) => {
      const status = task.status;
      const actionValue = parseFloat(task.action_value) || 0;
      if (status) {
        acc[status] = (acc[status] || 0) + actionValue;
      }
      return acc;
    }, {});

    const statusChartData = Object.entries(statusValueSum).map(
      ([status, total]) => ({
        name: status,
        value: Number(total).toFixed(2),
      })
    );
    setDataIniciativeStatusConfig(statusChartData);

    const updatedFilialOptions: any = filteredTasks.reduce(
      (acc: any[], item: any) => {
        if (!acc.some((option) => option.value === item.level3_id)) {
          acc.push({ value: item.level3_id, label: item.filial });
        }
        return acc;
      },
      []
    );
    !filterFiliall && setFilialOptions(updatedFilialOptions);
  };

  const configIniciative = {
    title: {
      text: "Iniciativa",
      x: "center",
    },
    backgroundColor: "transparent",
    grid: { left: "5%", right: "3%", bottom: "8%", containLabel: true },
    yAxis: {
      type: "category",
      inverse: true,
      data: dataIniciativeConfig.map((item) => item.name.slice(0, 30)),
    },
    xAxis: { show: false, type: "value" },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["line", "bar"] },
      },
    },
    series: [
      {
        type: "bar",
        data: dataIniciativeConfig,
        label: {
          show: true,
          position: "right",
          formatter: "{c}",
        },
      },
    ],
  } as any;

  const configIniciativeStatus = {
    title: {
      text: "Valor Iniciativas Por Status",
      x: "center",
    },
    backgroundColor: "transparent",
    color: ["#00cc66", "#0075ba", "#fb2f2e", "#8d66c1", "#ffcc00"],
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : R$ {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      left: "left",
      data: dataIniciativeStatusConfig.map((item) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
      },
    },
    series: [
      {
        name: "Valor",
        type: "pie",
        center: ["50%", "55%"],
        radius: "70%",
        label: {
          formatter: function (params: any) {
            const name = params.name;
            const value = params.value;
            const percent = params.percent;

            const formattedValue = new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(value);
            return `${name}:\n${formattedValue} (${percent}%)`;
          },
        },
        data: dataIniciativeStatusConfig,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;

  const calculateStatusTotals = (tasks: any[]) => {
    const totals = {
      totalInitiatives: tasks.length,
      concluida: tasks.filter((task) => task.status === "FINALIZADO").length,
      naoIniciada: tasks.filter((task) => task.status === "PENDENTE").length,
      emAndamento: tasks.filter((task) => task.status === "EM ANDAMENTO")
        .length,
      vencida: tasks.filter(
        (task) =>
          task.status === "PENDENTE" &&
          moment(task.data_fim, "DD/MM/YYYY").isBefore(moment())
      ).length,
      atrasada: tasks.filter(
        (task) =>
          task.status === "EM ANDAMENTO" &&
          moment(task.data_fim, "DD/MM/YYYY").isBefore(moment())
      ).length,
      cancelada: tasks.filter((task) => task.status === "CANCELADO").length,
    };
    return totals;
  };

  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={8} xs={8} sm={8}>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: "50px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div className="header-title" style={{ marginTop: "10px" }}>
                    <span className="span">Plano de Ações PGV</span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "50px",
                    }}
                  >
                    <span className="span">Atualizar: </span>
                    <Select
                      defaultValue={0}
                      value={timeAutoUpdate}
                      onChange={(value) => setTimeAutoUpdate(value)}
                      style={{ width: 120 }}
                    >
                      <Option value={0}>Não</Option>
                      <Option value={10}>10 Seg</Option>
                      <Option value={15}>15 Seg</Option>
                      <Option value={20}>20 Seg</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        {timeAutoUpdate !== 0 && (
          <div className="divtimer">
            <div className="timer">Atualiza em {counter} segundos</div>
          </div>
        )}
        <Card bordered={false}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {[
                  {
                    title: "Total Iniciativas",
                    color: "#000",
                    value: statusTotals.totalInitiatives,
                  },
                  {
                    title: "Concluída",
                    color: "#00cc66",
                    value: statusTotals.concluida,
                  },
                  {
                    title: "Não Iniciada",
                    color: "#fff",
                    value: statusTotals.naoIniciada,
                  },
                  {
                    title: "Em Andamento",
                    color: "#0075ba",
                    value: statusTotals.emAndamento,
                  },
                  {
                    title: "Vencida",
                    color: "#fb2f2e",
                    value: statusTotals.vencida,
                  },
                  {
                    title: "Atrasada",
                    color: "#fb2f2e",
                    value: statusTotals.atrasada,
                  },
                  {
                    title: "Cancelada",
                    color: "#8d66c1",
                    value: statusTotals.cancelada,
                  },
                ].map((item, index) => (
                  <Col key={index} className="report-item">
                    <Card
                      title={item.title}
                      size="small"
                      bordered={true}
                      className="card-filter2"
                      style={{ height: 120 }}
                    >
                      <div className="report-card-content">
                        <div
                          className="report-card-circle"
                          style={{ backgroundColor: item.color }}
                        />
                        <Statistic
                          value={item.value}
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xl={24} xs={24} sm={24}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card title="Filial" bordered={false} className="card-filter2">
                  <div className="report-card-content">
                    <Select
                      placeholder="Filial"
                      defaultValue={{
                        label: "Todos",
                        value: 0,
                      }}
                      value={filterFiliall}
                      onChange={handleFilialChange}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...filialOptions
                          .sort((a, b) => compareLabelsTables("label")(a, b))
                          .map((item: any) => ({
                            label: item.label,
                            value: item.value,
                          })),
                      ]}
                    />
                  </div>
                </Card>
                <Card title="Status" bordered={false} className="card-filter2">
                  <div className="report-card-content">
                    <Select
                      placeholder="Selecione o status"
                      onChange={(e) => handleChangeStatus(e)}
                      value={filteredStatus} // O estado atual dos filtros
                      style={{ width: "100%" }}
                      options={[
                        { label: "Todos", value: null },
                        { label: "CANCELADO", value: "CANCELADO" },
                        { label: "EM ANDAMENTO", value: "EM ANDAMENTO" },
                        { label: "FINALIZADO", value: "FINALIZADO" },
                        { label: "PENDENTE", value: "PENDENTE" },
                      ]}
                    />
                  </div>
                </Card>
                <Card
                  title="Data Inicial"
                  bordered={false}
                  className="card-filter2"
                >
                  <div className="report-card-content">
                    <RangePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={[
                        startDate1 ? dayjs(startDate1, "DD/MM/YYYY") : null,
                        startDate2 ? dayjs(startDate2, "DD/MM/YYYY") : null,
                      ]}
                      onChange={(value, dateString) => {
                        setStartDate1(dateString[0]);
                        setStartDate2(dateString[1]);
                      }}
                    />
                  </div>
                </Card>
                <Card
                  title="Data Final"
                  bordered={false}
                  className="card-filter2"
                >
                  <div className="report-card-content">
                    <RangePicker
                      style={{ width: "100%" }}
                      format="DD/MM/YYYY"
                      value={[
                        endDate1 ? dayjs(endDate1, "DD/MM/YYYY") : null,
                        endDate2 ? dayjs(endDate2, "DD/MM/YYYY") : null,
                      ]}
                      onChange={(value, dateString) => {
                        setEndDate1(dateString[0]);
                        setEndDate2(dateString[1]);
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={24} xs={24} style={{ marginTop: 5 }}>
              <Row>
                <Col xl={12} xs={12}>
                  <Card bordered={true} className="chart-card-2">
                    <div
                      className={
                        isFullScreen
                          ? "iframe-container fullscreen"
                          : "iframe-container"
                      }
                    >
                      <ReactEcharts
                        option={configIniciative}
                        lazyUpdate={true}
                        notMerge={true}
                        theme={localStorage.getItem("theme") || "light"}
                        style={{
                          height: isFullScreen ? "100vh" : 400,
                          width: isFullScreen ? "100vw" : "100%",
                          padding: isFullScreen ? 50 : 0,
                        }}
                      />
                      <Button
                        style={
                          isFullScreen
                            ? {
                                marginTop: 50,
                                marginLeft: "-50px",
                                width: 32,
                                right: 20,
                              }
                            : { width: 32, padding: "4px 15px" }
                        }
                        icon={
                          isFullScreen ? (
                            <FullscreenOutlined />
                          ) : (
                            <FullscreenExitOutlined />
                          )
                        }
                        onClick={toggleFullScreen}
                      />
                    </div>
                  </Card>
                </Col>
                <Col xl={12} xs={12}>
                  <Card bordered={true} className="chart-card-2">
                    <div
                      className={
                        isFullScreen2
                          ? "iframe-container fullscreen"
                          : "iframe-container"
                      }
                    >
                      <ReactEcharts
                        style={{
                          height: isFullScreen2 ? "100vh" : 350,
                          width: isFullScreen2 ? "100vw" : "100%",
                          padding: isFullScreen2 ? 50 : 0,
                        }}
                        lazyUpdate={true}
                        notMerge={true}
                        theme={localStorage.getItem("theme") || "light"}
                        option={configIniciativeStatus}
                      />
                      <Button
                        style={
                          isFullScreen2
                            ? {
                                marginTop: 50,
                                marginLeft: "-50px",
                                width: 32,
                                right: 20,
                              }
                            : { width: 32, padding: "4px 15px" }
                        }
                        icon={
                          isFullScreen2 ? (
                            <FullscreenOutlined />
                          ) : (
                            <FullscreenExitOutlined />
                          )
                        }
                        onClick={toggleFullScreen2}
                      />
                    </div>
                  </Card>
                </Col>
                <Col span={24} style={{ marginTop: 10 }}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={searchData}
                    pagination={false}
                    loading={{ indicator: Icon, spinning: isLoading }}
                    scroll={{ y: 210 }}
                    summary={(pageData) => {
                      const total = pageData.reduce((sum, record) => {
                        const value = parseFloat(record.action_value) || 0;
                        return sum + value;
                      }, 0);
                      return (
                        <Table.Summary fixed>
                          <Table.Summary.Row>
                            <Table.Summary.Cell
                              colSpan={6}
                              align="right"
                              index={-1}
                            >
                              <strong>Total:</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={0}>
                              <strong>
                                {total.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} />
                          </Table.Summary.Row>
                        </Table.Summary>
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
