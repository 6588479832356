import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Radio,
  Input,
  Popconfirm,
  message,
  Select,
  RadioChangeEvent,
  Typography,
  Tooltip,
  Upload,
  Divider,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import CompanyProductService from "../../../services/Company/Product";
import Customer from "../../../services/Pgc/Customer";
import CommercialStructure from "../../../services/Company/CommercialStructure";
import GeographyService from "../../../services/Global/Geography";
import { NoticeType } from "antd/lib/message/interface";
import { UploadOutlined } from "@ant-design/icons";
import {
  FiEdit2,
  FiEye,
  FiFilter,
  FiPlus,
  FiSearch,
  FiTrash,
} from "react-icons/fi";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
//import { mask } from "../../../components/MaskCPF_CNPJ/MaskCPF_CNPJ";
import { useAppSelector } from "../../../hooks";
import { useMessage } from "../../../hooks/useMessage";
const { Option } = Select;
export function CreateClient() {
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [modalNewProduct2, setModalNewProduct2] = useState(false);
  const [slected, setSlected] = useState<string>("C");
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [RegName, setRegName] = useState<string>("");
  const [RegGroupName, setRegGroupName] = useState<any>(undefined);
  const [NomeGrupo, setNomeGrupo] = useState<string>("");
  const [nameCategory, setNameCategory] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [listTasks2, setListTasks2] = useState<any[]>([]);
  const [states, setStates] = useState<any>(undefined);
  const [cities, setCities] = useState<any>(undefined);
  const [estado, setEstado] = useState<any>(undefined);
  const [accessTypes, setAccessTypes] = useState<any>(undefined);
  const [accessType, setAccessType] = useState<any>(undefined);
  const [category, setCategory] = useState<any>(undefined);
  const [structure, setStructure] = useState<any>(undefined);
  const [geography, setGeography] = useState<any>(undefined);
  const [category_id, setCategory_id] = useState<any>(undefined);
  const [city_Latitude, setCity_Latitude] = useState<any>(undefined);
  const [city_Longitude, setCity_Longitude] = useState<any>(undefined);
  const [register_number, setRegister_number] = useState<any>(undefined);
  const [city_id, setCity_id] = useState<any>(undefined);
  const [groupList, setGroupList] = useState<any>(undefined);
  const [searchData, setSearchData] = useState<any>([]);
  const [searchData2, setSearchData2] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");

  const [showTable, setShowTable] = useState(false);
   const [tableLoading, setTableLoading] = useState<boolean>(false);
   const [showModalImportar, setShowModalImportar] = useState(false);
   const [messageApi, contextHolder] = message.useMessage();

    const [data, setData] = useState<any[]>([]);
    const [data2, setData2] = useState<any[]>([]);
    const [dataerro, setDataDerro] = useState<any[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [emails, setEmails] = useState<any[]>([]);
    const [totalValues, setTotalValues] = useState(0);
    const [totalRegistrados, setTotalRegistrados] = useState(0);
    const [duplicateValues, setDuplicateValues] = useState(0);
    const [errorEntries, setErrorEntries] = useState(0);
    const [totalImportar, setTotalImportar] = useState(0);
    const [dataDuplicados, setDataDuplicados] = useState<any[]>([]);
    const [dataRegistrados, setDataRegistrados] = useState<any[]>([]);
    const [groupId, setGroupId] = useState<any[]>([]);
    const [groupName, setGroupName] = useState<string>("");
    const [registeredgroups, setRegisteredGroups] = useState<CustomerItem[]>([]);
    const [registeredcategory, setRegisteredCategory] = useState<TipoItem[]>([]);
    const [searchDataC, setSearchDataC] = useState<any>([]);
    const [level0Label, setLevel0Label] = useState<any>("");
  const [level1Label, setLevel1Label] = useState<any>("");
  const [level2Label, setLevel2Label] = useState<any>("");
  const [level3Label, setLevel3Label] = useState<any>("");
  const [level4Label, setLevel4Label] = useState<any>("");
     const parameters = useAppSelector((state) => state.entities.parameter);
     const ShowMSG = (type: NoticeType, test: string) => {
      messageApi.open({
        type,
        content: test,
      });
    };
     useEffect(() => {
      setLevel0Label(
        parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
      );
      setLevel1Label(
        parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
      );
      setLevel2Label(
        parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
      );
      setLevel3Label(
        parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
      );
      setLevel4Label(
        parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
      );
    }, [parameters]);
  const options = [
    { label: "Cliente", value: "C" },
    { label: "Grupo", value: "G" },
  ];
    
  useEffect(() => {
    onAccessTypes();
    getCategory();
    getStructure();
    getGeography();
    getListgroup();
    getListC();
    if (city_id) {
      for (let i = 0; i < cities.length; i++) {
        if (cities[i].id === city_id) {
          setCity_Latitude(cities[i].city_latitude);
          setCity_Longitude(cities[i].city_longitude);
        }
      }
    }
  }, [city_id]);
  async function getListTasks(value: string) {
    try {
      setLoading(true);
      const response: any[] = await Customer.getFindAll(companyId, value);
      console.log(response);
      const formattedTasks = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          access_type_id: rawTask.access_type_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          customer_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          state_initials: rawTask.state_initials ? rawTask.state_initials : "",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : "",
          customer_latitude: rawTask.customer_latitude
            ? rawTask.customer_latitude
            : "",
          customer_group_category_id: rawTask.customer_group_category_id
            ? rawTask.customer_group_category_id
            : "",
          customer_category_id: rawTask.customer_category_id
            ? rawTask.customer_category_id
            : "",
          customer_longitude: rawTask.customer_longitude
            ? rawTask.customer_longitude
            : "",
          customer_name: rawTask.customer_name ? rawTask.customer_name : "",
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : "",
          access_name: rawTask.access_name ? rawTask.access_name : "",
          customer_type: rawTask.customer_type ? rawTask.customer_type : "",
          customer_group_category: rawTask.customer_group_category
            ? rawTask.customer_group_category
            : "",
          group_city_id: rawTask.group_city_id ? rawTask.group_city_id : "",
          group_latitude: rawTask.group_latitude ? rawTask.group_latitude : "",
          group_longitude: rawTask.group_longitude
            ? rawTask.group_longitude
            : "",
          group_name: rawTask.group_name ? rawTask.group_name : "",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : "",
          city_name: rawTask.city_name ? rawTask.city_name : "",
        };
      });
      setListTasks(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  async function getListTasksOnlyGroup(id_group: number) {
    try {
      setLoading(true);
      const response: any[] = await Customer.getCustomerGroup(
        companyId,
        id_group
      );
      console.log(response);
      const formattedTasks2 = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          access_type_id: rawTask.access_type_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          customer_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          state_initials: rawTask.state_initials ? rawTask.state_initials : "",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : "",
          customer_latitude: rawTask.customer_latitude
            ? rawTask.customer_latitude
            : "",
          customer_longitude: rawTask.customer_longitude
            ? rawTask.customer_longitude
            : "",
          customer_group_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          Customer_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          customer_name: rawTask.customer_name ? rawTask.customer_name : "",
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : "",
          access_name: rawTask.access_name ? rawTask.access_name : "",
          customer_type: rawTask.customer_type ? rawTask.customer_type : "",
          customer_group_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          group_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          group_latitude: rawTask.group_latitude ? rawTask.group_latitude : "",
          group_longitude: rawTask.group_longitude
            ? rawTask.group_longitude
            : "",
          group_name: rawTask.customer_name ? rawTask.customer_name : "",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : "",
          city_name: rawTask.city_name ? rawTask.city_name : "",
        };
      });
      setListTasks2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    } finally {
      setModalNewProduct2(true);
      setLoading(false);
    }
  }
  async function getListgroup() {
    try {
      const response: any[] = await Customer.getFindAll(companyId, "G");
      console.log(response);
      setGroupList(response);
    } catch (e) {
      console.error("Falha ao buscar");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    }
  }
  async function getListC() {
    try {
      const response: any[] = await Customer.getFindAll(companyId, "C");
      console.log(response);
      setSearchDataC(response);
    } catch (e) {
      console.error("Falha ao buscar");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    }
  }
  async function PostClinte() {
    const body_c = {
      access_type_id: accessType,
      customer_city_id: city_id,
      customer_name: RegName,
      customer_registered_number: register_number,
      customer_category_id: category_id,
      customer_latitude: city_Latitude,
      customer_longitude: city_Longitude,
      customer_type: slected,
      customer_group_id: RegGroupName ? RegGroupName : null,
    };
    const body_g = {
      access_type_id: accessType,
      group_city_id: city_id,
      group_name: NomeGrupo,
      group_registered_number: register_number,
      customer_group_category_id: category_id,
      group_latitude: city_Latitude,
      group_longitude: city_Longitude,
      customer_type: slected,
      //  customer_group_id: RegGroupName,
    };
    try {
      if (!selectedTaskId) {
        if (slected === "C") {
          await Customer.PostCustomerClient(companyId, body_c);
          ShowMSG("success","Cliente cadastrado com sucesso!");
        } else {
          await Customer.PostCustomerClient(companyId, body_g);
          ShowMSG("success","Grupo cadastrado com sucesso!");
        }
      } else {
        if (slected === "C") {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_c);
          ShowMSG("success","Cliente cadastrado com sucesso!");
        } else {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_g);
          ShowMSG("success","Grupo cadastrado com sucesso!");
        }
      }
    } catch (error) {
      console.log(error);
      ShowMSG("error",error.response.data.message);
    } finally {
      getListTasks(slected);
    }
  }
  const onAccessTypes = useCallback(async () => {
    try {
      const response = await Customer.GetAccessType();
      setAccessTypes(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getCategory = useCallback(async () => {
    try {
      const response = await Customer.GetCategory(companyId);
      setCategory(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getStructure = useCallback(async () => {
    try {
      const response = await CommercialStructure.getAllStructure(companyId);
      setStructure(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getGeography = useCallback(async () => {
    try {
      const response = await GeographyService.getCitiesall();
      setGeography(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    setSlected(value);
    getListTasks(value);
  };
  async function States() {
    try {
      const response = await GeographyService.getStates();
      setStates(response);
    } catch (error) {
      console.log(error);
    }
  }
  async function Cities(estados: any, value: any) {
    try {
      setCity_id(undefined);
      setCities(undefined);
      const response = await GeographyService.getCities(estados);
      setCities(response);
    } catch (error) {
      console.log(error);
    } finally {
      if (value) {
        setCity_id(
          slected === "G" ? value.group_city_id : value.customer_city_id
        );
      }
    }
  }

  //  function handleChangeMask(event: any) {
  //    const { value } = event.target;
  //    setRegister_number(mask(value));
  //  }

  async function confirmDelete(task: any) {
    try {
      if (modalNewProduct2) {
        await Customer.PatchGroup(companyId, +task.id);
      }
    } catch (e) {
      console.error("Falha ao excluir: ");
      ShowMSG("error",e.response.data.message + "Falha ao excluir.");
      console.error(e);
    } finally {
      if (modalNewProduct2) {
        await getListTasksOnlyGroup(task.customer_group_id);
      } else {
        await getListTasks(slected);
      }
    }
  }
  useEffect(() => {
    getListTasks(slected);
    States();
  }, [companyId]);

  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            !search ||
            (record["customer_type"].includes(slected) &&
              (record["customer_name"]
                .toLowerCase()
                .includes(search.toLowerCase()) ||
                record["group_name"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["group_registered_number"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["customer_category"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                record["city_name"]
                  ?.toLowerCase()
                  .includes(search.toLowerCase())))
        );
        return setSearchData(array);
      }
    }
  }, [search, listTasks, slected]);
  useEffect(() => {
    filterTable2();
    function filterTable2() {
      if (!search2) {
        setSearchData2(listTasks2);
      } else {
        const array = listTasks2.filter(
          (record) =>
            !search2 ||
            record["customer_name"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["group_registered_number"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["customer_category"]
              .toLowerCase()
              .includes(search2.toLowerCase()) ||
            record["city_name"]?.toLowerCase().includes(search2.toLowerCase())
        );
        return setSearchData2(array);
      }
    }
  }, [search2, listTasks2]);
  function showModalNewProduct() {
    setModalNewProduct(true);
  }
  function handleShowModalImportar() {
    setShowModalImportar(true);
  }

  function handleClickEdit(task: any) {
    console.log("Edite:");
    console.log(task);
    setEstado(task.state_initials);
    Cities(task.state_initials, task);
    if (cities) {
      setCity_id(slected === "G" ? task.group_city_id : task.customer_city_id);
    }
    setCategory_id(
      slected === "G"
        ? task.customer_group_category_id
        : task.customer_category_id
    );
    setRegGroupName(task.customer_group_id);
    setNomeGrupo(task.group_name);
    setRegister_number(
      slected === "G"
        ? task.group_registered_number
        : task.customer_registered_number
    );
    setSelectedTaskId(task.id);
    setRegName(slected === "G" ? task.group_name : task.customer_name);
    setNameCategory(
      slected === "G" ? task.group_category : task.customer_category
    );
    setAccessType(task.access_type_id);
    setModalNewProduct(true);
  }
  function handleCancel() {
    setCities(undefined);
    setEstado(undefined);
    setCity_id(undefined);
    setRegister_number(undefined);
    setRegName("");
    setRegGroupName(undefined);
    setCategory_id(undefined);
    setNameCategory("");
    setAccessType(undefined);
    setSelectedTaskId(null);
    setUploadedFile(null);
    setShowTable(false)
    getListgroup();
    if (modalNewProduct === true && modalNewProduct2 === true) {
      setModalNewProduct(false);
    } else {
      setModalNewProduct(false);
      setModalNewProduct2(false);
    }
    setShowModalImportar(false);
  }
  async function handleOk() {
    setModalNewProduct(false);
    PostClinte();
    handleCancel();

  }

  // function handleCancel() {
  //   // setShowModalNewUser(false);
  //   setShowModalImportar(false);
  //   // clearData();
  //   // setShowTable(false);
  //   // setUploadedFile(null);
  // }
  
 
  function removeAccents(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  const normalizeString = (str: string): string => {
    return removeAccents(str).toLowerCase();  // Remove accents and convert to lowercase
  };
  
  function disableSave(): boolean {
    return !estado || !city_id || !register_number || !category_id;
  }

  

        const parseExcel = (file: File): Promise<any[]> =>
            new Promise((resolve, reject) => {
             
              try {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  try {
                    if (e.target?.result) {
                      const workbook = new ExcelJS.Workbook();
                      await workbook.xlsx.load(e.target.result as ArrayBuffer);
                      const worksheet = workbook.worksheets[0];
                      const data: any[] = [];
                      let headers: string[] = [];
        
                      worksheet.eachRow((row, rowNumber) => {
                        const rowValues = Array.isArray(row.values)
                          ? row.values.slice(1) // Ignore the first value, usually metadata
                          : []; // Safeguard against null/undefined
        
                        if (rowNumber === 1) {
                          headers = rowValues.map((value) =>
                            typeof value === "string" ? value.trim() : String(value)
                          );
                        } else {
                          const obj: any = {};
                          headers.forEach((header, idx) => {
                            obj[header] = rowValues[idx] || null;
                          });
                          data.push(obj);
                        }
                      });
        
                      resolve(data);
                    } else {
                      alert("Formato de arquivo não suportado.");
                      //reject(new Error("File content could not be read."));
                    }
                  } catch (error) {
                    alert(`Erro ao processar o arquivo: ${error}`);
                    // reject(error);
                  }
                };
        
                reader.onerror = (error) => {
                  alert(`Erro ao carregar o arquivo: ${error}`);
                  //reject(error);
                };
        
                reader.readAsArrayBuffer(file);
              } catch (error) {
                alert(`Erro inesperado: ${error}`);
                // reject(error);
              }
            });


            const nameVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(normalizeString);
const cidadeVariants = ["cidade", "cidades", "municipio", "municipios"].map(normalizeString);
const cnpjVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(normalizeString);
const codeVariants = ["codigo", "codigo_cliente"].map(normalizeString);
const codeGroupVariants = ["codigo_grupo"].map(normalizeString);
const categoriaVariants = ["categoria", "produto", "segmento"].map(normalizeString);
const diretoriaVariants = ["estrutura1", "diretoria", "departamento", "setor",level0Label].map(normalizeString); 
const tipoVariants = ["tipo", "modelo", "formato", "tipo_de_cliente"].map(normalizeString); 
const grupoVariants = ["grupo"].map(normalizeString); 

                  const validateColumns = (data: any[]): string[] | boolean => {
                    const columnMappings: { [key: string]: string[] } = {
                nome: nameVariants,
                cidade: cidadeVariants,
                categoria: categoriaVariants,
                diretoria: diretoriaVariants,
                grupo: grupoVariants,
                codigo: codeVariants,
                codigo_grupo: codeGroupVariants,
              };
          
              if (data.length === 0) return false;
          
              const dataHeaders = Object.keys(data[0] || {}).map((header) =>
                header.trim().toLowerCase()
              );

              
          
              const missingColumns = Object.keys(columnMappings).filter((column) => {
                return !columnMappings[column].some((variation) =>
                  dataHeaders.some((header) => header.includes(variation))
                );
              });
            
              return missingColumns.length === 0 ? true : missingColumns;
            };

            type EmailType = string | { text?: string; [key: string]: any };
            type RowData = {
              Nome?: string;
              cidade?: string;
              cnpj?: string;
              codigo?: string;
              categoria?: string;
              diretoria?: string;
              tipo?: string;
              erro?:string;
              [key: string]: any; // Allow extra properties
            };

            interface StructureItem {
              id: number | string; // Adjust based on actual type
              level1_external_name: string;
              level_type: string;
            }
            interface CitiesItem {
              id: number | string; // Adjust based on actual type
              city_name: string;
            
            }

            interface CustomerItem {
              id: number | string; // Adjust based on actual type
              customer_name: string;
              group_name: string;
              customer_registered_number: string;
              customer_external_code: string;
            
            }


            interface TipoItem {
              id: number | string; // Adjust based on actual type
              name: string;
            
            }

         

        const processFile = async (file: File) => {
          setTableLoading(true);
      
          let parsedData: any[] = [];
          try {
            const fileExtension = file.name.split(".").pop()?.toLowerCase();
            if (fileExtension === "csv") {
              alert("Formato de arquivo não suportado, trocar xlsx.");
            } else if (fileExtension === "xlsx") {
              parsedData = await parseExcel(file);
            } else {
              alert("Formato de arquivo não suportado.");
              setTableLoading(false);
              return;
            }

            if (!parsedData || parsedData.length === 0) {   
              alert("O arquivo não contém dados");
              }
      
            
              if (validateColumns(parsedData) === true) {

              
              
              const normalizedData = (parsedData as RowData[])
                .map((row) => {
                  const lowerCaseRow = Object.fromEntries(
                    Object.entries(row).map(([key, value]) => [
                      removeAccents(key.toLowerCase()),
                      value,
                    ])
                  );
      

                  const findValue = (variants: string[]) => {
                    const matchedKey = Object.keys(lowerCaseRow).find((key) =>
                      variants.some((variant) => key.includes(variant))
                    );
                    return matchedKey ? lowerCaseRow[matchedKey] : "";
                  };
                  
                  // const nameVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(removeAccents);
                  // const cidadeVariants = ["cidade", "cidades", "municipio", "municipios"].map(removeAccents);
                  // const cnpjVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
                  // const codeVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(removeAccents);
                  // const categoriaVariants = ["cidade", "cidades", "municipio", "municipios"].map(removeAccents);
                  // const diretoriaVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
                  // const tipoVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
      

              const nome = findValue(nameVariants);
              const cidade = findValue(cidadeVariants);
              const cnpj = findValue(cnpjVariants);
              const codigo = findValue(codeVariants);
              const categoria = findValue(categoriaVariants);
              const diretoria = findValue(diretoriaVariants);
              const tipo = findValue(tipoVariants);
              const grupo = findValue(grupoVariants);
              const codigo_grupo = findValue(codeGroupVariants);

              
              // const nome =
             
                  //   nameVariants
                  //     .map((variant) => lowerCaseRow[variant])
                  //     .find((value) => value) || "";
      
                  //  const cidade = cidadeVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
                  //   const cnpj = cnpjVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
                  //   const codigo = codeVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
                  //   const categoria = categoriaVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
                  //   const diretoria = diretoriaVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
                  //   const tipo = tipoVariants
                  //   .map((variant) => lowerCaseRow[variant])
                  //   .find((value) => value);
      
                  // const email =
                  //   typeof emailRaw === "string"
                  //     ? emailRaw.trim()
                  //     : (emailRaw as { text?: string })?.text?.toString().trim() ||
                 
                  return {
                    nome: nome?.toString().trim() || "",
                    cidade: cidade?.toString().trim() || "",
                    cnpj: cnpj ? cnpj.toString().trim() : "",  // Handle null or undefined
                    codigo: codigo ? codigo.toString().trim() : "",  // Handle null or undefined
                    categoria: categoria?.toString().trim() || "",
                    diretoria: diretoria?.toString().trim() || "",
                    tipo: tipo ? tipo.toString().trim() : "Outros",
                    grupo: grupo ? grupo.toString().trim() : "",
                    codigo_grupo: codigo_grupo ? codigo_grupo.toString().trim() : ""
                    // Default to "Outros" if null/undefined
                  };
                })
                .filter((row) => row.nome !== "");
      
               
              const emailOccurrences = new Map<string, number>();
              const duplicates: { nome: string, cidade:string,cnpj:string,codigo:string,categoria:string,diretoria:string,tipo:string,grupo:string,codigo_grupo:string}[] = [];
              const uniqueEntries: { nome: string, cidade:string,cnpj:string,codigo:string,categoria:string,diretoria:string,tipo:string,grupo:string,codigo_grupo:string}[] = [];
              const registeredEmails: { nome: string, cidade:string,cnpj:string,codigo:string,categoria:string,diretoria:string,tipo:string,grupo:string,codigo_grupo:string}[] = []; // To track registered emails
              const erroEntries: { nome: string, cidade:string,cnpj:string,codigo:string,categoria:string,diretoria:string,tipo:string,erro:string,grupo:string,codigo_grupo:string}[] = [];
              // First pass: Identify unique and duplicate entries
              for (const row of normalizedData) {
               // const codigo = row.codigo.toLowerCase(); // Normalize email for comparison
      
               // if (emailOccurrences.has(codigo)) {
               //   duplicates.push(row); // Add to duplicates
               // } else {
               //   emailOccurrences.set(codigo, 1); // Mark email as seen
                  uniqueEntries.push(row); // Add the first occurrence to unique entries
               // }
              }

              const filteredStructure = structure
            .filter((item: StructureItem) => item.level_type === "1")
            .map(({ id, level1_external_name }: StructureItem) => ({ id, level1_external_name }));
          

            // Filter geography to only include city_name and id
            const filteredGeography = geography.map(({ id, city_name }:CitiesItem) => ({ id, city_name }));


            const toRemove2: any[] = [];
            const registrados: any[] = [];

          for (const row of uniqueEntries) {
            const codigo = row.codigo;
            console.log(codigo)
            console.log(searchData2)

            // const nomeExists = searchDataC.some(({ customer_external_code }: CustomerItem) =>
            //   (customer_external_code) === (codigo)
            // );
            console.log(codigo)
            // const grupoExists = searchData.some(({ group_name }: CustomerItem) =>
            //   removeAccents(group_name.toLowerCase()) === removeAccents(codigo)
            // );
           
            // if (nomeExists) {
              
              
            //   registrados.push(row);
            //   toRemove2.push(row); // Collect row to remove later
            // }
          }

          const vuniqueEntries = uniqueEntries.filter((row) => !toRemove2.includes(row));


          const toRemove: any[] = [];

          for (const row of vuniqueEntries) {
            const cidade = removeAccents(row.cidade.toLowerCase());
            const diretoria = removeAccents(row.diretoria.toLowerCase());
            const tipo = removeAccents(row.tipo.toLowerCase());

            const cidadeExists = filteredGeography.some(({ city_name }: CitiesItem) =>
              removeAccents(city_name.toLowerCase()) === removeAccents(cidade)
            );
            const diretoriaExists = filteredStructure.some(({ level1_external_name }: StructureItem) =>
              removeAccents(level1_external_name.toLowerCase()) === removeAccents(diretoria)
            );
            const tipoExists = accessTypes.some(({ name }: TipoItem) =>
              removeAccents(name.toLowerCase()) === removeAccents(tipo)
            );

            if (!cidadeExists || !diretoriaExists || !tipoExists) {
              const erroMessage = [
                !cidadeExists && "Não encontrado cidade/municipio",
                !diretoriaExists && "Não encontrado "+level1Label,
                !tipoExists && "Não encontrado tipo"
              ].filter(Boolean).join(", "); // Filter out falsy values and join with commas
          
              // Push the row with the error message into erroEntries
              erroEntries.push({
                ...row,  // Spread the original row to keep all original properties
                erro: erroMessage  // Add the erro column with the error message
              });
              toRemove.push(row); // Collect row to remove later
            }
          }

          const validUniqueEntries = vuniqueEntries.filter((row) => !toRemove.includes(row));
          let conterrors = erroEntries.length;
          setErrorEntries(conterrors)

      
              setTotalValues(normalizedData.length);
              setDuplicateValues(duplicates.length);
              setTotalRegistrados(registrados.length);
              setDataDuplicados(duplicates)
              // setErrorEntries(erroEntries);
      
              setTotalImportar(validUniqueEntries.length)
              setData(validUniqueEntries);
              setData2(duplicates);
              setDataRegistrados(registrados)
              setDataDerro(erroEntries)
              setTableLoading(true);
              setShowTable(true);
            } 
            else { 
              const missingColumns = validateColumns(parsedData) as string[];
            setShowTable(false);
            setUploadedFile(null);
          
            // Dynamically display the missing columns
            const missingColumnsStr = missingColumns.join(', ');
            alert(`O arquivo não contém colunas com os títulos: ${missingColumnsStr}.`);
          }} catch (error) {
            setShowTable(false);
            setUploadedFile(null);
            alert(`Erro ao processar o arquivo: ${error.message || error}`);
        }
        
      
          setTableLoading(false);
        };

        useEffect(() => {
          console.log("Updated registeredGroups:", registeredgroups);
        }, [registeredgroups]); 


        async function handleImportar() {
          try {
            let updatedGroups: CustomerItem[] = [...registeredgroups];
            for (const row of data) {
              try {
             
              
              let categoria = category.some(({ name }: TipoItem) =>
                removeAccents(name.toLowerCase()) === removeAccents(row.categoria.toLowerCase())
              );

              
              if (!categoria){
                const body ={
                  name: row.categoria
                }
                await Customer.PostCategory(companyId, body);
                const updatedCategories = await Customer.GetCategory(companyId);
                 
        
                setCategory(updatedCategories);
              }
      
              const categoriaid = category.find(({ name }: TipoItem) =>
                removeAccents(name.toLowerCase()) === removeAccents(row.categoria.toLowerCase())
              )?.id;

              let categorianame = category.find(({ name }: TipoItem) =>
                removeAccents(name.toLowerCase()) === removeAccents(row.categoria.toLowerCase())
              )?.name;
      
              const tipo_id = accessTypes.find(({ name }: TipoItem) =>
                removeAccents(name.toLowerCase()) === removeAccents(row.tipo.toLowerCase())
              )?.id;

              const cidade_id = geography.find(({ city_name }: CitiesItem) =>
                removeAccents(city_name.toLowerCase()) === removeAccents(row.cidade.toLowerCase())
              )?.id;

              const filteredStructure = structure
                .filter((item: StructureItem) => item.level_type === "1")
                .map(({ id, level1_external_name }: StructureItem) => ({ id, level1_external_name }));

              const diretoria_id = filteredStructure.find(({ level1_external_name }: StructureItem) =>
                removeAccents(level1_external_name.toLowerCase()) === removeAccents(row.diretoria.toLowerCase())
              )?.id;

              
              let grupo = groupList.some(({ group_name }: CustomerItem) =>
                removeAccents(group_name.toLowerCase()) === removeAccents(row.grupo.toLowerCase())
              );

              let grupoR = updatedGroups.some(({ group_name }: CustomerItem) =>
                removeAccents(group_name.toLowerCase()) === removeAccents(row.grupo.toLowerCase())
              );


              let groupid = 0; 
             

              

            if (!grupo && !grupoR) {
              const body_g = {
                access_type_id: tipo_id,
                group_city_id: cidade_id,
                group_name: row.grupo,
                group_registered_number: row.cnpj ? row.cnpj : "",
                group_external_code: row.codigo_grupo ? row.codigo_grupo : "",
                customer_group_category_id: categoriaid,
                customer_type: 'G',
                level1_id: diretoria_id,
                group_category:row.categoria
              };

              const responsegrupo = await Customer.PostCustomerClient(companyId, body_g);
              // const resp = await getListgroup()
              // console.log("teste"+resp)

              // Update state by appending the response
              // setSearchData((prevData: any) => [...prevData, responsegrupo]);
              
              setRegisteredGroups((prevGroups: CustomerItem[]) => [...prevGroups, responsegrupo]);
              groupid = responsegrupo.id;
              updatedGroups.push(responsegrupo);
              console.log(updatedGroups)
            }

          
            if (grupo) {
              
              groupid = groupList.find(({ group_name }: CustomerItem) =>
                removeAccents(group_name.toLowerCase()) === removeAccents(row.grupo.toLowerCase())
              )?.id || "";
            }

            if (!groupid && grupoR) {
              const checkgroupid = updatedGroups.find(({ group_name }: CustomerItem) =>
                removeAccents(group_name.toLowerCase()) === removeAccents(row.grupo.toLowerCase())
              )?.id || "";
              groupid = typeof checkgroupid === 'number' ? checkgroupid : 0; 
            }
              

               
              // console.log(updatedGroups)
              // console.log(categoriaid)
              // console.log(categorianame)
              if (!categorianame){
                categorianame = row.categoria
              }
              
              console.log("Group ID: ", groupid);

                            
               const body_c = {
                access_type_id: tipo_id,
                customer_city_id: cidade_id,
                customer_name: removeAccents(row.nome),
                customer_registered_number: row.cnpj?row.cnpj:"",
                customer_external_code: row.codigo?row.codigo:"",
                customer_category_id: categoriaid,
                customer_type: 'C',
                customer_group_id: groupid,
                customer_category: categorianame
                
              };
              await Customer.PostCustomerClient(companyId, body_c);
      
             
              ShowMSG("success","Cliente "+row.nome+" cadastrado");
              
            } catch (error) {
              alert(error.message)
              console.error(`Erro ao processar `, error);
              ShowMSG("error","erro em "+row.nome);
              // Continue to the next row even if there's an error
          }
        }
        getListTasks(slected);
        ShowMSG("success","Importação concluida");
        setUploadedFile(null);
        setShowModalImportar(false);
        setShowTable(false);
        handleCancel();
        getListgroup();
        getListC();
        setRegisteredGroups([])
      } catch (e) {
        console.error("Erro geral durante a importação:", e);
        
        setShowModalImportar(false);
        handleCancel();
      } finally {
        // Ensure states are cleared and loading is stopped
        // setShowModalImportar(false);
        // handleCancel();
        // setTableLoading(false);
      }
    }

        const columns2 = [
          {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            width: 130
          },
          {
            title: "Cidade",
            dataIndex: "cidade",
            key: "cidade",
            width: 130
          },
          {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
            width: 130
          },
          {
            title: "Código",
            dataIndex: "codigo",
            key: "codigo",
            width: 130
          },
          {
            title: "Categoria",
            dataIndex: "categoria",
            key: "categoria",
            width: 130
          },
          {
            title: "Grupo",
            dataIndex: "grupo",
            key: "grupo",
            width: 130
          },
          {
            title: "codigo_grupo",
            dataIndex: "codigo_grupo",
            key: "codigo_grupo",
            width: 130
          },
          {
            title: level0Label,
            dataIndex: "diretoria",
            key: "diretoria",
            width: 130
          },
          {
            title: "tipo_de_cliente",
            dataIndex: "tipo",
            key: "tipo",
            width: 130
          },
          // {
          //   title: "",
          //   dataIndex: "erro",
          //   key: "erro",
          // },
        ];

        const columnsErro = [
          {
            title: "Error",  // You can change the title as needed
            dataIndex: "erro",
            key: "erro",
            render: (text: string) => {
              // Check if there is an error message and apply the red color
              return (
                <span style={{ color: 'red' }}>
                  {text || "No error"}  {/* Display the error message in red, or a default message if there is no error */}
                </span>
              );
            }
          },
          {
            title: "Nome",
            dataIndex: "nome",
            key: "nome",
            width: 160,
          },
          {
            title: "Cidade",
            dataIndex: "cidade",
            key: "cidade",
            width: 160,
          },
          {
            title: "CNPJ",
            dataIndex: "cnpj",
            key: "cnpj",
            width: 160,
          },
          {
            title: "Código",
            dataIndex: "codigo",
            key: "codigo",
            width: 160,
          },
          {
            title: "Categoria",
            dataIndex: "categoria",
            key: "categoria",
            width: 160,
          },
          {
            title: level1Label,
            dataIndex: "diretoria",
            key: "diretoria",
            width: 160,
          },
          {
            title: "Tipo de Cliente",
            dataIndex: "tipo",
            key: "tipo",
            width: 160,
          },
          
        ];
        const capitalizeFirstLetter = (string:any) => {
          if (!string) return '';
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: 'Nome',
      dataIndex: slected === 'G' ? 'group_name' : 'customer_name',
      key: 'name',
      sorter: (a:any, b:any) => {
        const nameA = slected === 'G' ? a.group_name : a.customer_name;
        const nameB = slected === 'G' ? b.group_name : b.customer_name;
        return nameA.localeCompare(nameB);
      },
      render: (text:any) => capitalizeFirstLetter(text),
    },
    slected === "G"
      ? {
          title: "Categoria",
          dataIndex: "customer_group_category",
          key: "customer_group_category",
          sorter: (a: any, b: any) =>
            a.customer_group_category.localeCompare(b.customer_group_category),
        }
      : {
          title: "Categoria",
          dataIndex: "customer_category",
          key: "customer_category",
          sorter: (a: any, b: any) =>
            a.customer_category.localeCompare(b.customer_category),
        },

    {
      title: "Cidade",
      dataIndex: "city_name",
      key: "city_name",
      sorter: (a: any, b: any) => a.city_name.localeCompare(b.city_name),
    },
    {
      title: "UF",
      dataIndex: "state_initials",
      key: "state_initials",
      sorter: (a: any, b: any) =>
        a.state_initials.localeCompare(b.state_initials),
    },
    slected === "C"
      ? {
          title: "Nome do Grupo",
          dataIndex: "group_name",
          key: "group_name",
          sorter: (a: any, b: any) => a.group_name.localeCompare(b.group_name),
        }
      : {},
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            {slected === "G" && !modalNewProduct2 ? (
              <Tooltip title="Visualizar">
                <Button
                  type="text"
                  icon={
                    <FiEye
                      size={18}
                      color="#00CC66"
                      onClick={() => getListTasksOnlyGroup(record.id)}
                    />
                  }
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {slected === "G" && !modalNewProduct2 ? (
              <Tooltip title="Editar">
                <Button
                  type="text"
                  onClick={() => handleClickEdit(record)}
                  icon={<FiEdit2 size={18} color="#00CC66" />}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {slected === "C" ? (
              <Tooltip title="Editar">
                <Button
                  type="text"
                  onClick={() => handleClickEdit(record)}
                  icon={<FiEdit2 size={18} color="#00CC66" />}
                />
              </Tooltip>
            ) : (
              <></>
            )}
            {slected === "G" && modalNewProduct2 ? (
              <Tooltip title="Excluir">
                <Popconfirm
                  title="Tem certeza que deseja excluir essa tarefa?"
                  onConfirm={() => confirmDelete(record)}
                  okText="Excluir"
                  okButtonProps={{ danger: true }}
                  cancelText="Cancelar"
                >
                  <Button
                    type="text"
                    icon={<FiTrash size={18} color="#00CC66" />}
                  />
                </Popconfirm>
              </Tooltip>
            ) : (
              <></>
            )}
          </Actions>
        );
      },
    },
  ];

  const exportEmptyExcel = (columns2:any) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Template");
    
  
      const headers = columns2.map((col: any) => col.title);
  
    const headerRow = worksheet.addRow(headers);
  
  
    headerRow.eachCell((cell) => {
      cell.style = {
        font: { bold: true }, 
        alignment: { horizontal: "center" }, 
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D3D3D3" }, 
        },
      };
    });
      worksheet.columns = columns2.map((col:any) => ({
        header: col.title,
        key: col.dataIndex,
        width: col.width / 8 || 20, // Convert width to Excel format
      }));
    
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
    
        saveAs(blob, "template_Clientes.xlsx");
      });
    };
  const { Title } = Typography;

  return (
    <Breadcrumb path="Cadastro de Clientes" child={["Produto"]}>
      <>
      {contextHolder}
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Cadastro de Clientes
            </Title>
          </Col>
        </Row>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
          <Col xs={12} sm={6} md={6} lg={6}>
    <Button type="primary" icon={<FiPlus />} onClick={showModalNewProduct}>
      Novo
    </Button>
  </Col>

  <Col xs={24} sm={12} md={6} lg={6} style={{ textAlign: "center" }}>
    <Radio.Group
      options={options}
      onChange={onChange3}
      value={slected}
      optionType="button"
      buttonStyle="solid"
    />
  </Col>

  <Col xs={12} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
    <Button
      type="primary"
      onClick={handleShowModalImportar}
      style={{ width: "100%", maxWidth: "150px" }}
    >
      Importar
    </Button>
  </Col>

  <Col xs={24} sm={12} md={6} lg={6}>
    <Input
      allowClear
      placeholder="Pesquisar"
      prefix={<FiSearch color="#00CC66" />}
      suffix={<FiFilter color="#00CC66" />}
      onChange={(e) => setSearch(e.target.value)}
    />
  </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>
        <Modal
          title="Clientes nesse grupo"
          open={modalNewProduct2}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch2(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData2}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          open={modalNewProduct}
          onOk={handleOk}
          okText={"Salvar"}
          width={600}
          onCancel={handleCancel}
          okButtonProps={{ disabled: disableSave() }}
          title={
            selectedTaskId
              ? slected === "G"
                ? "Atualizar Grupo"
                : "Atualizar Cliente"
              : slected === "G"
              ? "Novo Grupo"
              : "Novo Cliente"
          }
        >
          <Row justify="center">
            {!selectedTaskId ? (
              <Col span={16} style={{ textAlign: "center" }}>
                <Radio.Group
                  options={options}
                  onChange={onChange3}
                  value={slected}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            ) : (
              <></>
            )}
            <Col span={12} style={{ textAlign: "center" }}>
              {states !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Estado
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      popupMatchSelectWidth={false}
                      //  disabled={modalShowEdite}
                      showSearch
                      placeholder="Estado"
                      optionFilterProp="children"
                      onChange={(value) => [
                        setEstado(value),
                        Cities(value, null),
                      ]}
                      value={estado}
                    >
                      {states.map((initiative: any) => (
                        <Option value={initiative.state_initials}>
                          {initiative.state_initials}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {cities !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cidade
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      popupMatchSelectWidth={false}
                      showSearch
                      placeholder="Cidade"
                      optionFilterProp="children"
                      onChange={(value) => setCity_id(value)}
                      value={city_id}
                    >
                      {cities.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.city_name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {accessTypes !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Tipo de Acesso
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      popupMatchSelectWidth={false}
                      showSearch
                      placeholder="Tipo de Acesso"
                      optionFilterProp="children"
                      onChange={(value) => setAccessType(+value)}
                      value={accessType}
                    >
                      {accessTypes.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Registro
                </Typography.Text>

                <Input
                  onChange={(e) => setRegister_number(e.target.value)}
                  size="large"
                  style={{ width: "100%", margin: "10px 0" }}
                  placeholder="Registro"
                  maxLength={18}
                  value={register_number}
                />
              </Col>
              {slected === "C" ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cliente
                    </Typography.Text>

                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Cliente"
                      value={
                        RegName != ""
                          ? RegName[0].toUpperCase() +
                            RegName.slice(1).toLowerCase()
                          : RegName
                      }
                      onChange={(e) => setRegName(e.target.value)}
                    />
                  </Col>
                  {groupList !== undefined ? (
                    <>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text
                          style={{
                            whiteSpace: "nowrap",
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Nome do grupo
                        </Typography.Text>
                        <Select
                          style={{ width: "100%", margin: "10px 0" }}
                          size="large"
                          popupMatchSelectWidth={false}
                          showSearch
                          placeholder="Nome do grupo"
                          optionFilterProp="children"
                          onChange={(value) => setRegGroupName(+value)}
                          value={RegGroupName}
                        >
                          {groupList.map((initiative: any) => (
                            <>
                              <Option value={initiative.id}>
                                {initiative.group_name}
                              </Option>
                            </>
                          ))}
                        </Select>
                      </Col>
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              ) : (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Nome do grupo
                    </Typography.Text>
                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Nome do grupo"
                      value={
                        NomeGrupo != ""
                          ? NomeGrupo[0].toUpperCase() +
                            NomeGrupo.slice(1).toLowerCase()
                          : NomeGrupo
                      }
                      onChange={(e) => setNomeGrupo(e.target.value)}
                    />
                  </Col>
                </>
              )}
              {category !== undefined ? (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Categoria
                  </Typography.Text>
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    popupMatchSelectWidth={false}
                    showSearch
                    placeholder="Categoria"
                    optionFilterProp="children"
                    onChange={(value) => setCategory_id(+value)}
                    value={category_id}
                  >
                    {category.map((initiative: any) => (
                      <>
                        <Option value={initiative.id}>{initiative.name}</Option>
                      </>
                    ))}
                  </Select>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal>
         <Modal
                  style={{
                    width: "900px",
                    height: "auto",
                  }}
                  title={
                    <div style={{ textAlign: "center" }}>
                      <b>Importar Clientes</b>
                    </div>
                  }
                  open={showModalImportar}
                  width={900}
                  onCancel={handleCancel}
                  footer={null} // Footer managed manually for customization
                >
                  {!showTable ? (
                    <>
                      {/* File upload UI */}
                      <div style={{ textAlign: "left", marginBottom: "20px" }}>
                        
                        <Button type="primary" onClick={() => exportEmptyExcel(columns2)}>
                            Exportar template
                          </Button>
                        </div>
                      {/* <div style={{ textAlign: "center", marginBottom: "20px" }}>
                        <img
                          src="/exemploclientes.png"
                          alt="Import Illustration"
                          style={{
                            width: "800px",
                            height: "500px",
                            objectFit: "contain",
                          }}
                        />
                      </div> */}
          <div>
      <Typography.Text
        style={{
          fontSize: "14px",
          lineHeight: 1.5,
          color: "#666",
          display: "block",
          marginBottom: "10px",
        }}
      >
        Escolha um arquivo na extensão.xlsx, que tenha colunas com título Nome, Cidade ou Munícipio, Categoria, {level1Label}(estrutura1), Cnpj(opcional), Codigo, Grupo, codigo_grupo, Tipo(opcional).
      </Typography.Text>
      <pre style={{ textAlign: "left", fontFamily: "monospace" }}>
{`
| Nome       | Cidade      | Categoria | Estrutura1 | CNPJ       | Código | Grupo         | codigo_grupo | Tipo          |
|------------|-------------|-----------|------------|------------|--------|---------------|--------------|---------------|
| João       | São Paulo   | Nenhum    | Estrutura1 | 123456789  | 001    | Grupo_João    | 10           | Outros        |
| Maria      | Uberlândia  | Nenhum    | Estrutura1 |            | 002    | Grupo_Maria   | 20           | Distrubuidor  |
| Carlos     | Goiânia     | Nenhum    | Estrutura1 | 987654321  | 003    | Grupo_Carlos  | 30           | Cooperativa   |
`}
      </pre>
    </div>
        
                      {tableLoading && (
                        <div style={{ textAlign: "center", margin: "20px 0" }}>
                          <Typography.Text>Carregando...</Typography.Text>
                        </div>
                      )}
        
                      {!tableLoading && (
                        <div>
                          {/* File upload input */}
                          <input
                            type="file"
                            accept=".csv, .xlsx"
                            onChange={(e) =>
                              setUploadedFile(e.target.files?.[0] || null)
                            }
                            style={{ marginBottom: "10px", display: "block" }}
                            id="fileInput"
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end", // Align to bottom-left
                              gap: "10px",
                              marginTop: "20px",
                            }}
                          >
                            <Button type="default" onClick={handleCancel}>
                              Cancelar
                            </Button>
                            <Button
                              type="primary"
                              disabled={!uploadedFile}
                              onClick={() => uploadedFile && processFile(uploadedFile)}
                            >
                              Seguir
                            </Button>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>Total: {totalValues}</Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total de duplicados: {duplicateValues}
                        </Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total de Erros: {errorEntries}
                        </Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total já cadastrados na empresa: {totalRegistrados}
                        </Typography.Text>
                      </div>
                      {/* Table Section */}

                      {duplicateValues > 0 && (
                    <>
                     <Divider style={{ marginBottom: "20px" }} />
                      <Typography.Title level={4} style={{ marginBottom: "10px", color:"red" }}>
                        Código do cliente duplicado em:
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <Col xs={24}>
                            <Table
                              dataSource={dataDuplicados}
                              columns={columns2}
                              size="small"
                              scroll={{ y: 100 }}
                              pagination={false}
                            />
                          </Col>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "20px" }} />
                    </>
                  )}
                      
                      {totalRegistrados > 0 && (
                    <>
                     <Divider style={{ marginBottom: "20px" }} />
                      <Typography.Title level={4} style={{ marginBottom: "10px", color:"red" }}>
                        Dados já cadastrados na empresa
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <Col xs={24}>
                            <Table
                              dataSource={dataRegistrados}
                              columns={columns2}
                              size="small"
                              scroll={{ y: 100 }}
                              pagination={false}
                            />
                          </Col>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "20px" }} />
                    </>
                  )}
                      {errorEntries > 0 && (
                    <>
                      <Typography.Title level={4} style={{ marginBottom: "10px", color: "red"}}>
                        Dados com Erro
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <Col span={24} style={{ overflowX: "auto", maxHeight: "800px", overflowY: "auto", whiteSpace: "nowrap" }}>
                            <Table
                              dataSource={dataerro}
                              columns={columnsErro}
                              size="small"
                              scroll={{x: "max-content", y: 160 }} 
                              pagination={false}
                            />
                          </Col>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "20px" }} />
                    </>
                  )}
                      <Typography.Title level={4} style={{ marginBottom: "10px" }}>
                        Dados a Importar: {totalImportar}
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <>
                              <Col span={24} style={{ overflowX: "auto", maxHeight: "800px", overflowY: "auto", whiteSpace: "nowrap" }}>
                              <Table
                                // Unique key generation
                                dataSource={data}
                                columns={columns2}
                                size="small"
                                scroll={{x: "max-content", y: 150 }} // Enable vertical scrolling
                                pagination={false} // Remove pagination if you want to show all rows in the scroll
                              />
                            </Col>
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end", // Align to bottom-left
                          gap: "10px",
                          marginTop: "20px",
                        }}
                      >
                        <Button type="default" onClick={handleCancel}>
                          Cancelar
                        </Button>
                        <Button type="primary" onClick={handleImportar}>
                          importar
                        </Button>
                      </div>
                    </div>
                  )}
                </Modal>
      </>
    </Breadcrumb>
  );
}
