import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Select,
  Typography,
  Divider,
  Tooltip,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import {
  FiCopy,
  FiEdit2,
  FiFilter,
  FiLink,
  FiMail,
  FiPlus,
  FiSearch,
  FiTrash,
} from "react-icons/fi";

import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
import { UserData } from "../../../services/Global/User/userDataTypes";
import UserService from "../../../services/Global/User";
import { Input } from "../Parameter/styles";
import { useAppSelector } from "../../../hooks";
import AcesseType from "../../../services/Global/AcesseType";
import { NoticeType } from "antd/es/message/interface";
import usuariocsv from "../../../../public/usuarioscsv.png";
import ExcelJS from "exceljs";
import Papa from "papaparse";

const { Option } = Select;
export function User() {
  const idCompany = useAppSelector((state) => state.parameters.company.id);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [showModalImportar, setShowModalImportar] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [listUsers, setListUsers] = useState<UserData[]>([]);
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emails, setEmails] = useState<any[]>([]);
  const [profile_id, setProfileId] = useState<any>(undefined);
  const [idCompanyUser, setIdCompanyUser] = useState<any>(undefined);
  const [createNew, setCreateNew] = useState<boolean>(false);
  const [exited, setExited] = useState<boolean>(false);
  const [picture, setPicture] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [disableName, setDisableName] = useState<boolean>(false);
  const [AccessTypes, setAccessTypes] = useState<any[]>([]);
  const [varificado, setVarificado] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>([]);
  const [showModalNewPassword, setShowModalNewPassword] = useState(false);
  const [randomSave, setRandomSave] = useState<string>("");
  const [EmailSave, setEmailSave] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();
  const [totalValues, setTotalValues] = useState(0);
  const [RegisteredEmails, setRegisteredEmails] = useState(0);
  const [duplicateValues, setDuplicateValues] = useState(0);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [showTable, setShowTable] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const MSG = (type: NoticeType, text: string) => {
    messageApi.open({
      type: type,
      content: text,
    });
  };

  useEffect(() => {
    AccessType();
  }, []);
  useEffect(() => {
    setDisableName(false);
    setVarificado(false);
    setCreateNew(false);
  }, [email]);
  useEffect(() => {
    getListUsers();
  }, [AccessTypes]);

  async function AccessType() {
    try {
      const response = await AcesseType.getAcessType();

      const formattedTasks = response.map((task: any) => {
        return {
          profile: task.name,
          profile_id: task.id,
        };
      });
      setAccessTypes(
        formattedTasks.sort((a: any, b: any) => a.profile_id - b.profile_id)
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      MSG("error", error.response.data.message);
    }
  }
  async function ComparaEmail() {
    setVarificado(false);
    try {
      for (let i = 0; i < emails.length; i++) {
        if (emails[i] === email) {
          MSG("error", "E-mail já cadastrado nessa empresa");
          setCreateNew(false);
          return true;
        }
      }
      MSG("info", "E-mail não cadastrado nessa empresa");
      for (let i = 0; i < emails.length; i++) {
        setCreateNew(true);
        setExited(false);
        for (let i = 0; i < allUsers.length; i++) {
          if (allUsers[i].email === email) {
            setName(allUsers[i].name);
            setSelectedUserId(allUsers[i].id);
            setDisableName(true);
            return false;
          }
        }
      }
    } catch (error) {
      setLoading(false);
      MSG("error", error.response.data.message);
    } finally {
      console.log("final");
      setVarificado(true);
    }
  }
  async function verifyEmail() {
    setName("");
    try {
      const response = await UserService.findByEmail(email);
      if (response) {
        setDisableSave(true);
        ComparaEmail();
      } else {
        setDisableSave(false);
      }
    } catch (error) {
      setCreateNew(true);
      setExited(false);
      MSG("info", "E-mail não cadastrado nessa empresa");
      setVarificado(true);
    }
  }

  async function verifyEmailImp(email: string): Promise<boolean> {
    try {
      const response = await UserService.findByEmail(email);
      return response ? true : false; // Email found
    } catch (error) {
      console.error("Error verifying email:", error);
      return false; // Email not found or error
    }
  }
  async function getListUsers() {
    try {
      setLoading(true);
      const response: UserData[] = await UserService.findAll();
      const response2: UserData[] = await UserService.getCompanyLinks(
        idCompany
      );
      var data: any = [];
      var Emails = [];
      for (let i = 0; i < response.length; i++) {
        if (i > response2.length + response.length) {
        } else {
          for (var j = 0; j < response2.length; j++) {
            if (response[i].id === response2[j].user_id) {
              Emails.push(response[i].email);
              var nun = response2[j].profile_id;
              data.push({
                id: response[i].id,
                profile_id: AccessTypes[nun - 1]?.profile,
                idCompanyUser: response2[j].id,
                email: response[i].email,
                name: response[i].name,
                picture: response[i].picture,
              });
            }
          }
        }
      }
      setListUsers(
        data.sort((a: any, b: any) => {
          if (!a.name) return 1;
          if (!b.name) return -1;
          return a.name.localeCompare(b.name);
        })
      );
      setAllUsers(response);
      setSearchData(
        data.sort((a: any, b: any) => {
          if (!a.name) return 1;
          if (!b.name) return -1;
          return a.name.localeCompare(b.name);
        })
      );
      setEmails(Emails);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: "Perfil",
      dataIndex: "profile_id",
      key: "profile_id",
      sorter: (a: any, b: any) => a.profile_id.localeCompare(b.profile_id),
    },
    {
      title: "Imagem URL",
      dataIndex: "picture",
      key: "picture",
    },
    {
      title: "Ações",
      dataIndex: "action",
      key: "action",
      width: 150,
      align: "center" as const,
      render: (_: null, record: UserData) => {
        return (
          <Actions>
            <Tooltip title="Resetar Senha">
              <Popconfirm
                title="Tem certeza que deseja Resetar a Senha do usuário?"
                onConfirm={() =>
                  confirmResetPassword(record.email ? record.email : "")
                }
                okText="Resetar"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button
                  type="text"
                  icon={<FiMail size={18} color="#00CC66" />}
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Editar">
              <Button
                type="text"
                onClick={() => handleClickEdit(record)}
                icon={<FiEdit2 size={18} color="#00CC66" />}
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir esse usuário?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button
                  type="text"
                  icon={<FiTrash size={18} color="#00CC66" />}
                />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  function clearData() {
    setSelectedUserId(null);
    setName("");
    setEmail("");
    setPicture("");
    setProfileId(undefined);
    setCreateNew(false);
    setDisableName(false);
    setIdCompanyUser(undefined);
    setUploadedFile(null);
    setShowTable(false);
  }

  function handleClickEdit(user: UserData) {
    //  verifyEmail();
    setSelectedUserId(user.id ?? null);
    setName(user.name);
    setEmail(user.email ?? "");
    setPicture(user.picture ?? "");
    setProfileId(user.profile_id ?? undefined);
    setIdCompanyUser(user.idCompanyUser ?? null);
    setShowModalNewUser(true);
  }

  async function confirmDelete(user: UserData) {
    try {
      const companyLinks: [] = await UserService.getCompanyLinks(idCompany);
      let linkId = 0;
      companyLinks.forEach((link: any) => {
        if (link.company_id === idCompany && link.user_id === user.id) {
          linkId = link.id;
        }
      });
      if (linkId) {
        console.log(linkId);
        console.log("deletando");
        try {
          await UserService.unlinkToCompany(idCompany, linkId);
        } catch {
          MSG("error", "Erro ao excluir usuário dessa empresa");
        }
      }
      await UserService.deleteById(user.id);
    } catch (e) {
      console.error(e);
    } finally {
      await getListUsers();
    }
  }
  const makeHalfUpperCase = () => {
    const str =
      Math.random().toString(36).slice(2, 8) +
      Math.random().toString(36).slice(2, 8);
    let result = "";
    let upperCount = Math.floor(str.length / 2);
    for (let i = 0; i < str.length; i++) {
      if (Math.random() < 0.5 && upperCount > 0) {
        result += str[i].toUpperCase();
        upperCount--;
      } else {
        result += str[i];
      }
    }
    return result;
  };

  const confirmResetPassword = async (email: string) => {
    const random = makeHalfUpperCase();
    try {
      await UserService.ResetPassword(email, random);
      setRandomSave(random);
      setEmailSave(email);
      setShowModalNewPassword(true);
      MSG("success", "Senha resetada com sucesso!");
    } catch (e) {
      console.error(e);
      MSG("error", "Erro ao resetar senha!");
    }
  };

  function handleShowModalNewUser() {
    setShowModalNewUser(true);
  }
  function handleShowModalImportar() {
    setShowModalImportar(true);
  }

  async function handleCreateUser() {
    try {
      if (await canUserThisEmail()) {
        exited === false && (await createUserInCognito());
        if (exited === false) {
          const response = await UserService.findByEmailImportar(email);
          if (response.message === "Usuário não encontrado!") {
            const userCreated = await createUserInDatabase();
            await UserService.linkToCompany(
              idCompany,
              userCreated.id,
              profile_id
            );
          } else {
            await UserService.linkToCompany(idCompany, response.id, profile_id);
          }
        } else {
          if (selectedUserId !== null) {
            await UserService.linkToCompany(
              idCompany,
              selectedUserId,
              profile_id
            );
          }
        }
      } else {
        MSG("error", "E-mail já vinculado à outro usuário");
      }
    } catch (e) {
      console.error("Falha ao salvar Usuário: ");
      console.error(e);
    }

    async function canUserThisEmail() {
      if (emails.indexOf(email) !== -1) {
        return false;
      } else {
        return true;
      }
    }

    async function createUserInDatabase() {
      return await UserService.create({ name, email, picture, profile_id });
    }

    async function createUserInCognito() {
      const password = "Mud@r123";
      //Create user in cognito
      /* await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
        },
      });*/
    }
  }

  async function handleSave() {
    if (varificado === false && selectedUserId === null) {
      await verifyEmail();
    } else {
      setShowModalNewUser(false);
      setDisableSave(true);
      try {
        if (selectedUserId && createNew === false) {
          await UserService.update({
            id: selectedUserId,
            name,
            email,
            picture,
            profile_id,
          });
          await UserService.updateCompanyUser(
            idCompany,
            idCompanyUser === undefined ? selectedUserId : idCompanyUser,
            {
              profile_id: profile_id,
            }
          );
        } else {
          await handleCreateUser();
        }

        setDisableSave(false);
      } catch (e) {
        console.log(e);
      } finally {
        await getListUsers();
        setShowModalNewUser(false);
       // await UserService.SendEmailWelcome(name, companyName || "Cropland");
        clearData();
      }
    }
  }

  async function handleImportar() {
    try {
      for (const row of data) {
        try {
          //const email = row.email.toLowerCase();
          const response = await UserService.findByEmailImportar(
            row.email.toLowerCase()
          );
          let profile_id = 8

          if (response.message === "Usuário não encontrado!") {
            const userCreated = await UserService.create({
              name: row.nome,
              email: row.email,
              picture: undefined, // Optional field
              profile_id: 11,
            });

            await UserService.linkToCompany(
              idCompany,
              userCreated.id,
              profile_id
            );

            MSG("success", `Usuário criado e vinculado: ${row.email}`);
          } else {
            await UserService.linkToCompany(idCompany, response.id, profile_id);

            MSG("success", `Usuário existente vinculado: ${row.email}`);
          }
        } catch (error) {
          console.error(`Erro ao processar ${row.email}:`, error);
          MSG("error", `Erro ao processar: ${row.email}`);
          // Continue to the next row even if there's an error
        }
      }

      // Refresh the user list and reset states after processing all rows
      await getListUsers();
      MSG("success", "Importação concluída com sucesso!");
      setUploadedFile(null);
      setShowModalImportar(false);
      setShowTable(false);
      clearData();
    } catch (e) {
      console.error("Erro geral durante a importação:", e);
      MSG("error", "Erro durante a importação.");
      setShowModalImportar(false);
      clearData();
    } finally {
      // Ensure states are cleared and loading is stopped
      setShowModalImportar(false);
      clearData();
      setTableLoading(false);
    }
  }

  function handleCancel() {
    setShowModalNewUser(false);
    setShowModalImportar(false);
    clearData();
    setShowTable(false);
    setUploadedFile(null);
  }

  function isSaveDisabled(): boolean {
    return !email || validaEmail(email) || !profile_id || !name;
  }
  function validaEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return !re.test(email);
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = listUsers.filter(
        (record) =>
          record["name"]?.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setSearchData(
        array.sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
    } else {
      setSearch("");
      setSearchData(
        listUsers.sort((a: any, b: any) => a.name.localeCompare(b.name))
      );
    }
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      MSG("success", "Texto copiado com sucesso!");
    } catch (err) {
      console.error("Falha ao copiar texto: ", err);
    }
  };
  const columns2 = [
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
  ];

  const { Title } = Typography;
  const [data, setData] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  type EmailType = string | { text?: string; [key: string]: any };

  type RowData = {
    Nome?: string;
    Email?: EmailType;
    [key: string]: any; // Allow extra properties
  };

  const processFile = async (file: File) => {
    setTableLoading(true);

    let parsedData: any[] = [];
    try {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (fileExtension === "csv") {
        parsedData = await parseCSV(file);
      } else if (fileExtension === "xlsx") {
        parsedData = await parseExcel(file);
      } else {
        alert("Formato de arquivo não suportado.");
        setTableLoading(false);
        return;
      }

      console.log();
      console.log(`Parsed Data: ${JSON.stringify(data, null, 2)}`);

      if (validateColumns(parsedData)) {
        const normalizedData = (parsedData as RowData[])
          .map((row) => {
            const lowerCaseRow = Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key.toLowerCase(),
                value,
              ])
            );

            const nameVariants = ["nome", "names", "nomes", "name"];
            const emailVariants = ["email", "e-mail", "emails", "e-mails"];
            const cargoVariants = ["perfil", "cargo", "perfis", "cargos"];

            const nome =
              nameVariants
                .map((variant) => lowerCaseRow[variant])
                .find((value) => value) || "";

            const emailRaw = emailVariants
              .map((variant) => lowerCaseRow[variant])
              .find((value) => value);

            const email =
              typeof emailRaw === "string"
                ? emailRaw.trim()
                : (emailRaw as { text?: string })?.text?.toString().trim() ||
                  "";

            return {
              nome: nome.toString().trim(),
              email,
            };
          })
          .filter((row) => row.email !== "");

        const emailOccurrences = new Map<string, number>();
        const duplicates: { nome: string; email: string }[] = [];
        const uniqueEntries: { nome: string; email: string }[] = [];
        const registeredEmails: { nome: string; email: string }[] = []; // To track registered emails

        // First pass: Identify unique and duplicate entries
        for (const row of normalizedData) {
          const email = row.email.toLowerCase(); // Normalize email for comparison

          if (emailOccurrences.has(email)) {
            duplicates.push(row); // Add to duplicates
          } else {
            emailOccurrences.set(email, 1); // Mark email as seen
            uniqueEntries.push(row); // Add the first occurrence to unique entries
          }
        }

        // Second pass: Verify if unique entries' emails are already registered
        const registeredEmailCounts = await Promise.all(
          uniqueEntries.map(async (row) => {
            const email = row.email.toLowerCase();
            let isRegistered = false;

            // Check if the email already exists in the registered emails list
            for (let i = 0; i < emails.length; i++) {
              if (emails[i].toLowerCase() === email) {
                // Email already registered
                isRegistered = true;
                break;
              }
            }

            if (isRegistered) {
              //MSG("error", "E-mail já cadastrado nessa empresa");
              setCreateNew(false); // Optionally set any state if needed
              registeredEmails.push(row); // Add to registeredEmails
              return null;
            }

            return row;
          })
        );

        const validUniqueEntries = registeredEmailCounts.filter(
          (entry) => entry !== null
        );

        setTotalValues(normalizedData.length);
        setDuplicateValues(duplicates.length);
        setRegisteredEmails(registeredEmails.length);

        setData(validUniqueEntries);
        setData2(duplicates);
        setTableLoading(true);
        setShowTable(true);
      } else {
        setShowTable(false);
        setUploadedFile(null);
        alert("O arquivo não contém colunas com os títulos 'Nome' e 'Email'.");
      }
    } catch (error) {
      setShowTable(false);
      setUploadedFile(null);
      alert("Erro ao processar o arquivo.");
    }

    setTableLoading(false);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      parseExcel(selectedFile)
        .then((data) => console.log("Parsed data:", data))
        .catch((error) => console.error("Error parsing file:", error));
    } else {
      alert("Nenhum arquivo selecionado.");
    }
  };

  const parseExcel = (file: File): Promise<any[]> =>
    new Promise((resolve, reject) => {
      console.log("??");
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          try {
            if (e.target?.result) {
              const workbook = new ExcelJS.Workbook();
              await workbook.xlsx.load(e.target.result as ArrayBuffer);
              const worksheet = workbook.worksheets[0];
              const data: any[] = [];
              let headers: string[] = [];

              worksheet.eachRow((row, rowNumber) => {
                const rowValues = Array.isArray(row.values)
                  ? row.values.slice(1) // Ignore the first value, usually metadata
                  : []; // Safeguard against null/undefined

                if (rowNumber === 1) {
                  headers = rowValues.map((value) =>
                    typeof value === "string" ? value.trim() : String(value)
                  );
                } else {
                  const obj: any = {};
                  headers.forEach((header, idx) => {
                    obj[header] = rowValues[idx] || null;
                  });
                  data.push(obj);
                }
              });

              resolve(data);
            } else {
              alert("Formato de arquivo não suportado.");
              //reject(new Error("File content could not be read."));
            }
          } catch (error) {
            alert(`Erro ao processar o arquivo: ${error}`);
            // reject(error);
          }
        };

        reader.onerror = (error) => {
          alert(`Erro ao carregar o arquivo: ${error}`);
          //reject(error);
        };

        reader.readAsArrayBuffer(file);
      } catch (error) {
        alert(`Erro inesperado: ${error}`);
        // reject(error);
      }
    });

  const parseCSV = (file: File): Promise<any[]> =>
    new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => resolve(results.data),
        error: (error) => reject(error.message), // Provide a more specific rejection
      });
    });

  const validateColumns = (data: any[]) => {
    const columnMappings = {
      nome: ["nome", "nomes", "name", "names"],
      email: ["email", "emails", "e-mail", "e-mails"],
    };

    if (data.length === 0) return false;

    const dataHeaders = Object.keys(data[0] || {}).map((header) =>
      header.trim().toLowerCase()
    );

    return Object.values(columnMappings).every((variations) =>
      variations.some((variation) => dataHeaders.includes(variation))
    );
  };

  return (
    <Breadcrumb path="Usuários" child={["Usuário"]}>
      <>
        {contextHolder}
        <Col flex="none" style={{ textAlign: "center", flexGrow: 1 }}>
          <Title level={4} style={{ paddingBottom: "5px" }}>
            Usuários
          </Title>
        </Col>
        <Card bordered={false}>
          <Row gutter={[16, 16]} align="middle" justify="space-between">
            <Col xs={12} sm={6} md={4} lg={3}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={handleShowModalNewUser}
                style={{
                  width: "100%",
                  maxWidth: "150px",
                }}
              >
                Novo
              </Button>
            </Col>
            <Col xs={12} sm={6} md={6} lg={13} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                // icon={<FiPlus />}
                onClick={handleShowModalImportar}
                style={{
                  width: "100%",
                  maxWidth: "150px",
                }}
              >
                Importar
              </Button>
            </Col>
            <Col xs={24} sm={12} md={14} lg={7}>
              <Input
                allowClear
                value={search}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                style={{
                  width: "100%",
                }}
              />
            </Col>
            <Col xs={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>
        <Modal
          open={showModalNewUser}
          okText={varificado ? "Salvar" : selectedUserId ? "Salvar" : "Validar"}
          onOk={handleSave}
          width={800}
          okButtonProps={{
            disabled: varificado ? isSaveDisabled() : email.length < 6,
          }}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedUserId ? "Atualizar" : "Novo"} Usuário
              </Typography.Title>

              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>E-mail</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="E-mail"
                  disabled={selectedUserId && !createNew}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              {createNew || selectedUserId ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <div className="textModel">
                        <b>Nome</b> <b className="required">*</b>
                      </div>
                    </Typography.Text>
                    <Input
                      size="large"
                      placeholder="Nome"
                      disabled={disableName}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      <div className="textModel">
                        <b>Perfil</b> <b className="required">*</b>
                      </div>
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      popupMatchSelectWidth={false}
                      showSearch
                      placeholder="Perfil"
                      optionFilterProp="children"
                      onChange={(e) => setProfileId(e)}
                      value={profile_id}
                    >
                      {AccessTypes.map((initiative) => (
                        <Option value={initiative.profile_id}>
                          {initiative.profile}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Imagem URL
                    </Typography.Text>
                    <Input
                      size="large"
                      placeholder="Imagem URL"
                      value={picture}
                      onChange={(e) => setPicture(e.target.value)}
                    />
                  </Col>
                </>
              ) : null}
            </Col>
          </Row>
        </Modal>
        <Modal
          style={{
            width: "800px",
            height: "auto",
          }}
          title={<b>Senha Resetada</b>}
          open={showModalNewPassword}
          footer={null}
          width={800}
          closable={false}
        >
          <Typography.Text
            style={{ fontSize: "18px", lineHeight: 1.5, color: "#333" }}
          >
            <br />
            E-mail: <b>{EmailSave}</b>
            <br />
            <br />
            <div
              onClick={() => copyToClipboard(randomSave)}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <Tooltip title="click para Copiar">
                Nova Senha: <b>{randomSave}</b>
                <FiCopy style={{ marginLeft: 20 }} />
              </Tooltip>
            </div>
          </Typography.Text>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div></div>
            <Button
              style={{
                color: "#fff",
                padding: "10px 20px",
                borderRadius: "5px",
                transition: "all 0.3s ease",
                height: "auto",
              }}
              type="primary"
              onClick={() => setShowModalNewPassword(false)}
            >
              OK
            </Button>
          </div>
        </Modal>

        <Modal
          style={{
            width: "800px",
            height: "auto",
          }}
          title={
            <div style={{ textAlign: "center" }}>
              <b>Importar Usuários</b>
            </div>
          }
          open={showModalImportar}
          width={800}
          onCancel={handleCancel}
          footer={null} // Footer managed manually for customization
        >
          {!showTable ? (
            <>
              {/* File upload UI */}
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img
                  src="/usuarioscsv.png"
                  alt="Import Illustration"
                  style={{
                    width: "400px",
                    height: "300px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <Typography.Text
                style={{
                  fontSize: "14px",
                  lineHeight: 1.5,
                  color: "#666",
                  display: "block",
                  marginBottom: "10px",
                }}
              >
                Escolha um arquivo na extensão .csv ou .xlsx, que tenha colunas
                com título Nome e Email.
              </Typography.Text>

              {tableLoading && (
                <div style={{ textAlign: "center", margin: "20px 0" }}>
                  <Typography.Text>Carregando...</Typography.Text>
                </div>
              )}

              {!tableLoading && (
                <div>
                  {/* File upload input */}
                  <input
                    type="file"
                    accept=".csv, .xlsx"
                    onChange={(e) =>
                      setUploadedFile(e.target.files?.[0] || null)
                    }
                    style={{ marginBottom: "10px", display: "block" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end", // Align to bottom-left
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Button type="default" onClick={handleCancel}>
                      Cancelar
                    </Button>
                    <Button
                      type="primary"
                      disabled={!uploadedFile}
                      onClick={() => uploadedFile && processFile(uploadedFile)}
                    >
                      Seguir
                    </Button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <div style={{ marginBottom: "10px" }}>
                <Typography.Text>Total: {totalValues}</Typography.Text>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Typography.Text>
                  Total de duplicados: {duplicateValues}
                </Typography.Text>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Typography.Text>
                  Total já cadastrados na empresa: {RegisteredEmails}
                </Typography.Text>
              </div>
              {/* Table Section */}
              <Typography.Title level={4} style={{ marginBottom: "10px" }}>
                Dados a Importar
              </Typography.Title>
              <Divider style={{ marginBottom: "20px" }} />
              <div
                style={{
                  maxHeight: "400px", // Adjust as per modal height
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
              >
                {tableLoading ? (
                  <div style={{ textAlign: "center", padding: "20px" }}>
                    <Typography.Text>Carregando...</Typography.Text>
                  </div>
                ) : (
                  <>
                    <Col xs={24}>
                      <Table
                        // Unique key generation
                        dataSource={data}
                        columns={columns2}
                        size="small"
                        scroll={{ y: 300 }} // Enable vertical scrolling
                        pagination={false} // Remove pagination if you want to show all rows in the scroll
                      />
                    </Col>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Align to bottom-left
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <Button type="default" onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button type="primary" onClick={handleImportar}>
                  importar
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </>
    </Breadcrumb>
  );
}
