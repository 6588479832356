/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Table,
  Tag,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import ReactEcharts from "echarts-for-react";
import "./index.css";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../../styles/ThemeContext";
const Option = Select.Option;
export function BottomUPAcessoDashboardPGV() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const [valores, setValores] = useState<string>("R$");
  const [qdt_year, setQdt_year] = useState<string | undefined>("Todos");
  const [OptionsPresidente, setOptionsPresidente] = useState<any>([]);
  const [filterPresidente, setFilterPresidente] = useState<number>(0);
  const [filterPresidenteLabel, setFilterPresidenteLabel] =
    useState<string>("0");
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataTipoNegocio, setDataTipoNegocio] = useState<any>([]);
  const [LoadingRegional, setLoadingRegional] = useState<boolean>(false);
  const [LoadingFilial, setLoadingFilial] = useState<boolean>(false);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [OptionsRegional, setOptionsRegional] = useState<any>([]);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterRegionalLabel, setFilterRegionalLabel] = useState<string>("0");
  const [filterDiretoria, setFilterDiretoria] = useState<number>(0);
  const [filterDiretoriaLabel, setFilterDiretoriaLabel] = useState<string>("0");
  const [filterFilialModal, setFilterFilialModal] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [DataFilialRegionalDashboard, setDataFilialRegionalDashboard] =
    useState<any>([]);
  const [DataDilialTableProducts, setDataDilialTableProducts] = useState<any>(
    []
  );
  const [DataFilialDashboard, setDataFilialDashboard] = useState<any>([]);
  const [filalLabel, setFilalLabel] = useState<string>("0");
  const [tipoNegocioLabel, setTipoNegocioLabel] = useState<string>("");
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [contador, setContador] = useState<any>();
  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const { theme } = useTheme();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
  }, [parameters]);
  const handleValores = (value: any) => {
    setValores(value);
    GetAdm(
      filterRegionalLabel,
      filalLabel,
      filterDiretoriaLabel,
      filterPresidenteLabel,
      tipoNegocioLabel,
      value
    );
  };
  const handleFilter = async () => {
    GetTipoNegocio();
  };
  const GetAdm = async (
    filterRegional: string,
    filterFilialModal: string,
    filterDiretoria: string,
    filterPresidente: string,
    TipoNegocio: string,
    valores: string,
    level?: string
  ) => {
    const response = await PowerBi_all.GetAdmDashboard(
      companyId,
      years,
      filterRegional === "Todos" ? "0" : filterRegional,
      filterFilialModal === "Todos" ? "0" : filterFilialModal,
      filterDiretoria === "Todos" ? "0" : filterDiretoria,
      filterPresidente === "Todos" ? "0" : filterPresidente,
      TipoNegocio === "Todos" ? "0" : TipoNegocio
    );
    const uniqueLevel2Array = response.reduce((acc: any[], item: any) => {
      const exists = acc.find((entry) => entry.level2 === item.level2);
      if (!exists) {
        acc.push({ level2: item.level2, id: item.id });
      }
      return acc;
    }, []);
    const resp =
      await CompanyCommercialStructureService.findAllCommercialStrucuteLevel3(
        companyId
      );
    const uniqueFilialOptions = resp.reduce((acc: any[], item: any) => {
      const exists = acc.find(
        (entry) => entry.level3_external_name === item.level3_external_name
      );
      if (!exists) {
        acc.push({
          level3_external_name: item.level3_external_name,
          id: item.id,
        });
      }
      return acc;
    }, []);
    if (filialOptions.length === 0) {
      setFilialOptions(uniqueFilialOptions);
    }
    const uniqueLevel1Array = response.reduce((acc: any[], item: any) => {
      const exists = acc.find((entry) => entry.level1 === item.level1);
      if (!exists) {
        acc.push({ level1: item.level1, id: item.id });
      }
      return acc;
    }, []);
    const uniqueLevel0Array = response.reduce((acc: any[], item: any) => {
      const exists = acc.find((entry) => entry.level0 === item.level0);
      if (!exists) {
        acc.push({ level0: item.level0, id: item.id });
      }
      return acc;
    }, []);

    if (level === "L3" || OptionsRegional.length === 0) {
      setFilterRegionalLabel("0");
      setOptionsRegional(uniqueLevel2Array);
      setOptionsDiretoria(uniqueLevel1Array);
      setOptionsPresidente(uniqueLevel0Array);
    }
    if (level === "L2" || OptionsDiretoria.length === 0) {
      setFilterDiretoriaLabel("0");
      setOptionsDiretoria(uniqueLevel1Array);
      setOptionsPresidente(uniqueLevel0Array);
    }
    if (level === "L1" || OptionsPresidente.length === 0) {
      setFilterPresidenteLabel("0");
      setOptionsPresidente(uniqueLevel0Array);
    }

    const updatedData = response
      .map((item: any) => {
        let newYearLabel = item.year_label;

        if (years && years.includes(item.year_label)) {
          newYearLabel = eval(item.year_label);
        }

        const abbreviatedTotalVolume =
          valores === "R$" ? item.total_volume : item.total_vol;

        return {
          ...item,
          year_label: newYearLabel,
          value: abbreviatedTotalVolume,
        };
      })
      .sort((a: any, b: any) => b.baseLine - a.baseLine);
    const groupedDataProduct = updatedData
      .reduce((acc: any, item: any) => {
        if (!years || item.produto === null) {
          return acc;
        }

        const existing = acc.find((i: any) => i.produto === item.produto);

        if (existing) {
          if (years.includes("year0") && item.year_label === year0)
            existing.baseLine = (existing.baseLine || 0) + item.value;
          if (years.includes("year1") && item.year_label === year1)
            existing.projection = (existing.projection || 0) + item.value;
          if (years.includes("year2") && item.year_label === year2)
            existing.projection1 = (existing.projection1 || 0) + item.value;
          if (years.includes("year3") && item.year_label === year3)
            existing.projection2 = (existing.projection2 || 0) + item.value;
          if (years.includes("year4") && item.year_label === year4)
            existing.projection3 = (existing.projection3 || 0) + item.value;
          if (years.includes("year5") && item.year_label === year5)
            existing.projection4 = (existing.projection4 || 0) + item.value;
        } else {
          acc.push({
            id: item.id,
            produto: item.produto,
            baseLine:
              years.includes("year0") && item.year_label === year0
                ? item.value
                : null,
            projection:
              years.includes("year1") && item.year_label === year1
                ? item.value
                : null,
            projection1:
              years.includes("year2") && item.year_label === year2
                ? item.value
                : null,
            projection2:
              years.includes("year3") && item.year_label === year3
                ? item.value
                : null,
            projection3:
              years.includes("year4") && item.year_label === year4
                ? item.value
                : null,
            projection4:
              years.includes("year5") && item.year_label === year5
                ? item.value
                : null,
          });
        }

        return acc;
      }, [])
      .sort((a: any, b: any) => b.baseLine - a.baseLine);
    setDataDilialTableProducts(groupedDataProduct);
    const groupedData = updatedData
      .reduce((acc: any, item: any) => {
        if (!years || item.id === null) {
          return acc;
        }

        const existing = acc.find((i: any) => i.id === item.id);

        if (existing) {
          if (years.includes("year0") && item.year_label === year0)
            existing.baseLine = (existing.baseLine || 0) + item.value;
          if (years.includes("year1") && item.year_label === year1)
            existing.projection = (existing.projection || 0) + item.value;
        } else {
          let baselineV =
            years.includes("year0") && item.year_label === year0
              ? item.value
              : null;
          let projectionV =
            years.includes("year1") && item.year_label === year1;
          acc.push({
            id: item.id,
            name: item.name,
            level0: item.level0,
            level1: item.level1,
            level2: item.level2,
            variation: ((projectionV - baselineV) / baselineV) * 100,

            baseLine:
              years.includes("year0") && item.year_label === year0
                ? item.value
                : null,
            projection:
              years.includes("year1") && item.year_label === year1
                ? item.value
                : null,
          });
        }

        return acc;
      }, [])
      .sort((a: any, b: any) => (b.baseLine || 0) - (a.baseLine || 0));

    setDataFilialTable(groupedData);
    const groupedDataRegional = response.reduce((acc: any[], item: any) => {
      const existing = acc.find(
        (entry: any) =>
          entry.year_label === item.year_label && entry.level2 === item.level2
      );
      if (existing) {
        valores !== "R$"
          ? (existing.total_vol += item.total_vol)
          : (existing.total_volume += item.total_volume);
      } else {
        acc.push({
          id: item.id,
          name: item.level2,
          level2: item.level2,
          year_label: item.year_label,
          total_volume: item.total_volume,
          total_vol: item.total_vol,
        });
      }
      return acc;
    }, []);

    const updatedDataRegional = groupedDataRegional.map((item: any) => {
      let newYearLabel = item.year_label;
      // Substitui o year_label com base nos anos
      if (item.year_label === "year0") newYearLabel = year0;
      if (item.year_label === "year1") newYearLabel = year1;
      if (item.year_label === "year2") newYearLabel = year2;
      if (item.year_label === "year3") newYearLabel = year3;
      if (item.year_label === "year4") newYearLabel = year4;
      if (item.year_label === "year5") newYearLabel = year5;
      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume =
        valores !== "R$" ? item.total_vol : item.total_volume;

      return {
        ...item,
        year_label: newYearLabel,
        value: abbreviatedTotalVolume,
      };
    });
    setDataFilialRegionalDashboard(updatedDataRegional);

    const groupedDataFilialDashboard = updatedData
      .reduce((acc: any, item: any) => {
        if (!years || item.name === null) {
          return acc;
        }

        const existing = acc.find((i: any) => i.name === item.name);

        if (existing) {
          if (years.includes("year0") && item.year_label === year0)
            existing.baseLine = (existing.baseLine || 0) + item.value;
          if (years.includes("year1") && item.year_label === year1)
            existing.projection = (existing.projection || 0) + item.value;
        } else {
          acc.push({
            id: item.id,
            name: item.name,
            level0: item.level0,
            level1: item.level1,
            level2: item.level2,
            baseLine:
              years.includes("year0") && item.year_label === year0
                ? item.value
                : null,
            projection:
              years.includes("year1") && item.year_label === year1
                ? item.value
                : null,
          });
        }

        return acc;
      }, [])
      .sort((a: any, b: any) => (b.baseLine || 0) - (a.baseLine || 0));
    setDataFilialDashboard(groupedDataFilialDashboard);
    // setDataFilial(updatedData);
    setLoadingFilial(false);
  };
  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );
  const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );
  const totalProjection1 = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection1 || 0),
    0
  );
  const totalBaseLinep = DataDilialTableProducts.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );
  const totalProjectionp = DataDilialTableProducts.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );
  const totalProjection1p = DataDilialTableProducts.reduce(
    (acc: any, curr: any) => acc + (curr.projection1 || 0),
    0
  );

  const LimparFiltros = () => {
    setFilialOptions([]);
    setFilterRegional(0);
    setFilterDiretoria(0);
    // setDataFilial([]);
    setDataFilialTable([]);
    setDataProjecaodevendas([]);
    setFilterFilialModal(0);
    setValoresProdutos([]);
    setContador(null);
    GetDataBottomUps(0, 0, 0, 0, 0, valores);
    GetAdm(
      filterRegionalLabel,
      filalLabel,
      filterDiretoriaLabel,
      filterPresidenteLabel,
      tipoNegocioLabel,
      valores
    );
  };

  useEffect(() => {
    handleFilter();
    GetAdm("0", "0", "0", "0", "0", valores, "L3");
    GetDataBottomUps(
      0,
      filterPresidente ? filterPresidente : 0,
      filterDiretoria ? filterDiretoria : 0,
      filterRegional,
      filterFilialModal,
      valores
    );
  }, [years]);

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)).slice(0, 2));
  }, [qdt_year, theme]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleFilialChangeModal = async (
    value: any,
    option: { label: string },
    level: string
  ) => {
    if (level === "TP") {
      setTipoNegocio(value);
      setTipoNegocioLabel(option.label);
      GetAdm(
        filterRegionalLabel,
        filalLabel,
        filterDiretoriaLabel,
        filterPresidenteLabel,
        option?.label,
        valores,
        level
      );
    } else if (level === "L3") {
      setFilalLabel(option.label);
      setFilterFilialModal(value);
      GetAdm("0", option.label, "0", "0", tipoNegocioLabel, valores, level);
      setFilterRegionalLabel("Todos");
      setFilterRegional(0);
      setFilterDiretoriaLabel("Todos");
      setFilterDiretoria(0);
      setFilterPresidenteLabel("Todos");
      setFilterPresidente(0);
    } else if (level === "L2") {
      setFilterRegionalLabel(option.label);
      setFilterRegional(value);
      GetAdm(option.label, filalLabel, "0", "0", "0", valores, level);
      setFilterDiretoriaLabel("Todos");
      setFilterDiretoria(0);
      setFilterPresidenteLabel("Todos");
      setFilterPresidente(0);
    } else if (level === "L1") {
      setFilterDiretoriaLabel(option.label);
      setFilterDiretoria(value);
      GetAdm(
        filterRegionalLabel,
        filalLabel,
        option.label,
        "0",
        "0",
        valores,
        level
      );
      setFilterPresidenteLabel("Todos");
      setFilterPresidente(0);
    } else if (level === "L0") {
      setFilterPresidenteLabel(option.label);
      setFilterPresidente(value);
      GetAdm(
        filterRegionalLabel,
        filalLabel,
        filterDiretoriaLabel,
        option.label,
        "0",
        valores,
        level
      );
    }
  };

  async function GetDataBottomUps(
    Business_type_id?: number,
    level0_id?: number,
    level1_id?: number,
    level2_id?: number,
    level3_id?: number,
    valores?: any
  ) {
    setValoresProdutos([]);
    const response = await BottomUpService.getTableProductDash(
      companyId,
      Business_type_id,
      level0_id,
      level1_id,
      level2_id,
      level3_id
    );

    // Transformar os dados no formato desejado
    const transformedData = response.records
      .map((item: any) => {
        // Mapeamento dos anos e valores correspondentes
        const years = [
          {
            year_label: "year0",
            total_volume:
              valores === "R$" ? item.total_base_line : item.volume_base_line,
          },
          {
            year_label: "year1",
            total_volume:
              valores === "R$" ? item.total_year1 : item.volume_year1,
          },
        ];

        // Criar o array de objetos com os dados transformados
        return years.map((yearData) => ({
          name: item.product_name,
          year_label: yearData.year_label,
          year0: yearData.year_label === "year0" ? yearData.total_volume : 0,
          year1: yearData.year_label === "year1" ? yearData.total_volume : 0,
          total_volume: yearData.total_volume,
        }));
      })
      .flat(); // Usando .flat() para achatar o array de arrays em um único array

    // Agrupar os dados pelo 'name' e somar os volumes por ano
    const groupedData = Object.values(
      transformedData.reduce((acc: any, curr: any) => {
        const key = curr.name;
        if (!acc[key]) {
          acc[key] = {
            name: curr.name,
            total_volume: 0,
            year0: 0,
            year1: 0,
          };
        }

        acc[key].total_volume = parseFloat(
          acc[key].total_volume + curr.total_volume
        );
        acc[key].year0 = parseFloat(acc[key].year0 + curr.year0);
        acc[key].year1 = parseFloat(acc[key].year1 + curr.year1);

        if (!isFinite(acc[key].year0)) {
          acc[key].year0 = 0;
        }
        if (!isFinite(acc[key].year1)) {
          acc[key].year1 = 0;
        }
        return acc;
      }, {})
    ).sort((a: any, b: any) => b.year1 - a.year1);

    // Obter os valores agrupados como um array
    const finalData = Object.values(groupedData);

    // Retornar ou configurar os valores do produto
    setValoresProdutos(finalData.splice(0, 10));
  }

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const [filialOptions, setFilialOptions] = useState([]);
  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12).toFixed(1)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`;
    } else {
      return value.toFixed(1);
    }
  };
  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      width: "110px",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: `${level2Label}`,
      dataIndex: "level2",
      key: "level2",
      width: "130px",
      sorter: (a: any, b: any) => a.level2.localeCompare(b.level2),
    },
    {
      title: `${level1Label}`,
      dataIndex: "level1",
      key: "level1",
      width: "110px",
      sorter: (a: any, b: any) => a.level1.localeCompare(b.level1),
    },
    {
      title: `${level0Label}`,
      dataIndex: "level0",
      key: "level0",
      width: "110px",
      sorter: (a: any, b: any) => a.level0.localeCompare(b.level0),
    },
    {
      title: "Base Line",
      dataIndex: "baseLine",
      key: "baseLine",
      width: "110px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "0,00"}</span>
        );
      },
    },

    {
      title: "Proj Y+1",
      dataIndex: "projection",
      key: "projection",
      width: "90px",

      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Proj Y+2",
      dataIndex: "projection1",
      key: "projection1",
      width: "90px",
      sorter: (a: any, b: any) => a.projection1 - b.projection1,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection1 && record.projection1 > 0
              ? formatValue(record.projection1)
              : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "90px",
      sorter: (a: any, b: any) => {
        const variationA =
          a.baseLine !== null && a.projection !== null
            ? ((a.projection - a.baseLine) / a.baseLine) * 100
            : 0.0;
        const variationB =
          b.baseLine !== null && b.projection !== null
            ? ((b.projection - b.baseLine) / b.baseLine) * 100
            : 0.0;
        return variationA - variationB;
      },
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  const columnsProdutos = [
    {
      title: "Produto",
      dataIndex: "produto",
      key: "produto",
      width: "35%",
      sorter: (a: any, b: any) => a.produto.localeCompare(b.produto),
    },
    {
      title: "Base Line",
      dataIndex: "baseLine",
      key: "baseLine",
      width: "110px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "0,00"}</span>
        );
      },
    },
    {
      title: "Projeção",
      dataIndex: "projection",
      key: "projection",
      width: "110px",
      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "110px",
      sorter: (a: any, b: any) => {
        const variationA =
          a.baseLine !== null && a.projection !== null
            ? ((a.projection - a.baseLine) / a.baseLine) * 100
            : 0.0;
        const variationB =
          b.baseLine !== null && b.projection !== null
            ? ((b.projection - b.baseLine) / b.baseLine) * 100
            : 0.0;
        return variationA - variationB;
      },
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter(
    (column: Column) =>
      column.key !== "projection1" ||
      DataFilialTable.some((record: any) => record.projection1 > 0)
  );

  const handleChange = async (value: any) => {
    setYears(value);
  };

  async function GetTipoNegocio() {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    setDataTipoNegocio(response);
    if (response.length === 1) {
      setTipoNegocio(response[0]?.id);
      setTipoNegocioLabel(response[0]?.name);
    }
  }
  const sortedCategories = Array.from(
    new Set(DataFilialRegionalDashboard.map((item: any) => item.name as string))
  )
    .map((name) => ({
      name,
      total: DataFilialRegionalDashboard.filter(
        (item: any) => item.name === name
      ).reduce(
        (sum: any, item: any) =>
          sum + (valores !== "R$" ? item.total_vol : item.total_volume),
        0
      ),
    }))
    .sort((a, b) => a.total - b.total) // Ordena pelo total de menor para maior
    .map((item) => item.name);
  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    yearss.push(`year${0}`);
    yearss.push(`year${1}`);
    return yearss;
  };
  const configRegional: any = {
    title: {
      text: "Regional",
      x: "center",
    },
    backgroundColor: "transparent",
    legend: {
      show: true,
      left: "left",
      top: "25px",
      // Ordena as legendas de forma invertida
      data: Array.from(
        new Set(DataFilialRegionalDashboard.map((item: any) => item.year_label))
      ).reverse(),
    },
    grid: { left: "-2%", right: "3%", bottom: "10%", containLabel: true },
    xAxis: {
      type: "category",
      inverse: false,
      axisLabel: { interval: 0, rotate: 30 },
      data: Array.from(
        new Set(DataFilialRegionalDashboard.map((item: any) => item.name))
      ),
    },
    yAxis: {
      show: false,
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["stack", "line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: Array.from(
      new Set(DataFilialRegionalDashboard.map((item: any) => item.year_label))
    )
      .reverse() // Inverte a ordem das séries
      .map((year) => ({
        name: year,
        type: "bar",
        data: Array.from(
          new Set(DataFilialRegionalDashboard.map((item: any) => item.name))
        ).map((category) => {
          const item = DataFilialRegionalDashboard.find(
            (data: any) => data.name === category && data.year_label === year
          );
          return item ? item.value : 0;
        }),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
        },
      })),
  };
  const configFilial: any =
    DataFilialDashboard.length > 0
      ? {
          title: {
            text: "Filial",
            x: "center",
          },
          backgroundColor: "transparent",
          legend: { show: true, top: "25px" },
          grid: { left: "3%", right: "3%", bottom: "3%", containLabel: true },
          xAxis: {
            type: "category",
            data: DataFilialDashboard.sort(
              (a: any, b: any) => b.baseLine - a.baseLine
            )
              .filter((item: any) => item.name !== null)
              .map((item: any) => item.name),
            axisLabel: {
              rotate: 90,
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
            axisTick: { alignWithLabel: true },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "Salvar" },
              magicType: { show: true, type: ["line", "bar"] },
              dataView: {
                show: true,
                readOnly: false,
                title: "Visualização de Dados",
                lang: ["Visualização de Dados", "Fechar", "Atualizar"],
              },
            },
          },
          series: [
            {
              name: "Base Line",
              type: "bar",
              data: DataFilialDashboard.sort(
                (a: any, b: any) => b.baseLine - a.baseLine
              )
                .filter((item: any) => item.name !== null)
                .map((item: any) => item.baseLine),
              label: {
                show: true,
                position: "top",
                formatter: (params: any) => formatValue(params.value),
                rotate: 90,
              },
            },
            {
              name: "Projeção Y+1",
              type: "bar",
              data: DataFilialDashboard.sort(
                (a: any, b: any) => b.baseLine - a.baseLine
              )
                .filter((item: any) => item.name !== null)
                .map((item: any) => item.projection),
              label: {
                show: true,
                position: "top",
                formatter: (params: any) => formatValue(params.value),
                rotate: 90,
              },
            },
          ],
        }
      : {};

  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={3} xs={3} sm={3}>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={4} xs={4} sm={4} />
                <Col xl={9} xs={9} sm={9}>
                  <div className="header-title">
                    <span>Base Line vs Projeção de Vendas</span>
                  </div>
                  <div className="header-title-sub">
                    <span>Modúlo: Projeção de Vendas Botton up</span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col xl={24} xs={24} sm={24}>
              <div style={{ display: "flex" }}>
                <Card
                  title="Ano"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      defaultValue="0"
                      maxTagCount={2}
                      mode="multiple"
                      placeholder="Selecione o ano"
                      onChange={handleChange}
                      maxCount={2}
                      value={years.slice(0, 2)}
                      style={{
                        width: years.length > 2 ? 280 : 230,
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        marginLeft: 5,
                      }}
                      options={yearsOptions(Number(qdt_year)).map(
                        (year: string, index: number) => ({
                          value: year,
                          label: eval(`year${index}`),
                          disabled: index < 2,
                        })
                      )}
                    />
                  </div>
                </Card>

                <Card
                  title="Unidade de Medida"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Segmented
                      options={["R$", "KG/LT"]}
                      value={valores}
                      onChange={handleValores}
                    />
                  </div>
                </Card>

                <Card
                  title="Tipo Negócio"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Negócio"
                      onChange={(value, option) =>
                        handleFilialChangeModal(value, option, "TP")
                      }
                      value={TipoNegocio}
                      defaultValue={TipoNegocio}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...DataTipoNegocio.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })),
                      ]}
                    />
                  </div>
                </Card>

                {/* )} */}
                {/* {filterRegional !== 0 && ( */}
                <Card
                  title={level3Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level3Label}
                      onChange={(value, option: any) =>
                        handleFilialChangeModal(value, option, "L3")
                      }
                      value={filterFilialModal}
                      style={{ width: "100%" }}
                      showSearch
                      options={[
                        { value: 0, label: "Todos" },
                        ...filialOptions
                          .sort((a, b) =>
                            compareLabelsTables("level3_external_name")(a, b)
                          )
                          .map((item: any) => ({
                            label: item.level3_external_name,
                            value: item.id,
                          })),
                      ]}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </Card>
                <Card
                  title={level2Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level2Label}
                      onChange={(value, option: any) =>
                        handleFilialChangeModal(value, option, "L2")
                      }
                      value={filterRegional}
                      defaultValue={filterRegional}
                      style={{ width: "100%" }}
                      showSearch
                      options={[
                        { value: 0, label: "Todos" },
                        ...OptionsRegional.map((item: any) => ({
                          label: item.level2,
                          value: item.id,
                        })),
                      ]}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </Card>
                <Card
                  title={level1Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level1Label}
                      onChange={(value, option: any) =>
                        handleFilialChangeModal(value, option, "L1")
                      }
                      value={filterDiretoria}
                      showSearch
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...OptionsDiretoria.map((item: any) => ({
                          label: item.level1,
                          value: item.id,
                        })),
                      ]}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </Card>
                <Card
                  title={level0Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level0Label}
                      onChange={(value, option: any) =>
                        handleFilialChangeModal(value, option, "L0")
                      }
                      value={filterPresidente}
                      showSearch
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...OptionsPresidente.map((item: any) => ({
                          label: item.level0,
                          value: item.id,
                        })),
                      ]}
                      filterOption={(input, option) =>
                        option?.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </div>
                </Card>

                <Card
                  bordered={true}
                  size="small"
                  title="Base Line"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalBaseLine)}
                    />
                  </div>
                </Card>
                <Card
                  bordered={true}
                  size="small"
                  title="Projeção Y+1"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalProjection)}
                    />
                  </div>
                </Card>
                {totalProjection1 !== 0 && (
                  <Card
                    bordered={true}
                    size="small"
                    title="Projeção Y+2"
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalProjection1)}
                      />
                    </div>
                  </Card>
                )}
                <Card
                  title="BL vs Y+1"
                  size="small"
                  bordered={true}
                  className="card-statistic"
                  style={{ minWidth: 150 }}
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{
                        color:
                          totalProjection - totalBaseLine > 0
                            ? "green"
                            : totalProjection - totalBaseLine < 0
                            ? "red"
                            : "gray",
                      }}
                      value={
                        totalBaseLine !== 0
                          ? `${(
                              ((totalProjection - totalBaseLine) /
                                totalBaseLine) *
                              100
                            ).toFixed(1)}%`
                          : "0%"
                      }
                      prefix={
                        totalProjection - totalBaseLine > 0 ? (
                          <ArrowUpOutlined style={{ color: "green" }} />
                        ) : totalProjection - totalBaseLine < 0 ? (
                          <ArrowDownOutlined style={{ color: "red" }} />
                        ) : null
                      }
                    />
                  </div>
                </Card>
                {totalProjection1 !== 0 && (
                  <Card
                    title="BL vs Y+2"
                    size="small"
                    bordered={true}
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            totalProjection1 - totalBaseLine > 0
                              ? "green"
                              : totalProjection1 - totalBaseLine < 0
                              ? "red"
                              : "yellow",
                        }}
                        value={
                          totalBaseLine !== 0
                            ? `${(
                                ((totalProjection1 - totalBaseLine) /
                                  totalBaseLine) *
                                100
                              ).toFixed(2)}%`
                            : "0%"
                        }
                        prefix={
                          totalProjection1 - totalBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : totalProjection1 - totalBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                )}
              </div>

              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={8} xs={8}>
                    <div style={{ height: 400 }}>
                      <Table
                        columns={columnsProdutos}
                        size="small"
                        pagination={false}
                        dataSource={DataDilialTableProducts}
                        scroll={{ y: 320 }}
                        footer={() => {
                          return (
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td
                                    style={{ fontWeight: "bold", width: "35%" }}
                                  >
                                    Total
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "start",
                                      fontWeight: "bold",
                                      width: "110px",
                                    }}
                                  >
                                    {formatValue(totalBaseLinep)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "start",
                                      fontWeight: "bold",
                                      width: "90px",
                                    }}
                                  >
                                    {formatValue(totalProjectionp)}
                                  </td>
                                  {totalProjection1 > 0 && (
                                    <td
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        width: "90px",
                                      }}
                                    >
                                      {formatValue(totalProjection1p)}
                                    </td>
                                  )}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      width: "90px",
                                    }}
                                  >
                                    {totalProjection1p > 0 ? (
                                      totalProjectionp +
                                        totalProjection1p -
                                        totalBaseLinep >
                                      0 ? (
                                        <ArrowUpOutlined
                                          style={{ color: "green" }}
                                        />
                                      ) : totalProjectionp +
                                          totalProjection1p -
                                          totalBaseLinep <
                                        0 ? (
                                        <ArrowDownOutlined
                                          style={{ color: "red" }}
                                        />
                                      ) : null
                                    ) : totalProjectionp - totalBaseLinep >
                                      0 ? (
                                      <ArrowUpOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : totalProjectionp - totalBaseLinep <
                                      0 ? (
                                      <ArrowDownOutlined
                                        style={{ color: "red" }}
                                      />
                                    ) : null}

                                    {totalProjection1p > 0
                                      ? `${(
                                          ((totalProjectionp +
                                            totalProjection1p -
                                            totalBaseLinep) /
                                            totalBaseLinep) *
                                          100
                                        ).toFixed(2)}%`
                                      : totalBaseLinep !== 0
                                      ? `${(
                                          ((totalProjectionp - totalBaseLinep) /
                                            totalBaseLinep) *
                                          100
                                        ).toFixed(2)}%`
                                      : "0%"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        }}
                      />
                    </div>

                    {/**
                     * <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemBottomUP}
                          style={{
                            height: isFullScreen3 ? "100vh" : 400,
                            width: isFullScreen3 ? "100vw" : "100%",
                            padding: isFullScreen3 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>

                     */}
                    <Card
                      bordered={true}
                      style={{
                        marginTop: 10,
                        height: 400,
                      }}
                    >
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configRegional}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen ? "100vh" : 400,
                            width: isFullScreen ? "100vw" : "100%",
                            padding: isFullScreen ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>

                  <Col xl={16} xs={16}>
                    <div style={{ height: 400 }}>
                      <Table
                        columns={filteredColumns}
                        size="small"
                        pagination={false}
                        dataSource={DataFilialTable}
                        scroll={{ y: 320 }}
                        footer={() => {
                          return (
                            <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td
                                    style={{ fontWeight: "bold", width: "62%" }}
                                  >
                                    Total
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "start",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {formatValue(totalBaseLine)}
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {formatValue(totalProjection)}
                                  </td>
                                  {totalProjection1 > 0 && (
                                    <td
                                      style={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {formatValue(totalProjection1)}
                                    </td>
                                  )}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {totalProjection1 > 0 ? (
                                      totalProjection +
                                        totalProjection1 -
                                        totalBaseLine >
                                      0 ? (
                                        <ArrowUpOutlined
                                          style={{ color: "green" }}
                                        />
                                      ) : totalProjection +
                                          totalProjection1 -
                                          totalBaseLine <
                                        0 ? (
                                        <ArrowDownOutlined
                                          style={{ color: "red" }}
                                        />
                                      ) : null
                                    ) : totalProjection - totalBaseLine > 0 ? (
                                      <ArrowUpOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : totalProjection - totalBaseLine < 0 ? (
                                      <ArrowDownOutlined
                                        style={{ color: "red" }}
                                      />
                                    ) : null}

                                    {totalProjection1 > 0
                                      ? `${(
                                          ((totalProjection +
                                            totalProjection1 -
                                            totalBaseLine) /
                                            totalBaseLine) *
                                          100
                                        ).toFixed(2)}%`
                                      : totalBaseLine !== 0
                                      ? `${(
                                          ((totalProjection - totalBaseLine) /
                                            totalBaseLine) *
                                          100
                                        ).toFixed(2)}%`
                                      : "~%"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          );
                        }}
                      />
                    </div>

                    <Card
                      bordered={true}
                      style={{
                        marginTop: 10,
                        height: 400,
                      }}
                    >
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingFilial ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : DataFilialDashboard.length > 0 ? (
                          <ReactEcharts
                            option={configFilial}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen2 ? "100vh" : 340,
                              width: isFullScreen2 ? "100vw" : "100%",
                              padding: isFullScreen2 ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
