import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Typography,
  Input,
  Popconfirm,
  message,
  Select,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import CompanyProductService from "../../../services/Company/Product";
import GlobalIndustryService from "../../../services/Global/Industry";
import GlobalBusinessType from "../../../services/Global/BusinessType";
import GroupProductService from "../../../services/Company/GroupProduct";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { NoticeType } from "antd/lib/message/interface";
interface TasksProps {
  id: number;
  business_type_id: number;
  segment_name: string;
  sub_segment_name: string;
  brand_name: string;
  industry_id: number;
  product_name: string;
  product_external_code: number;
  product_group_name: string;
  group_type_name: string;
  sku_external_code: string;
  sku_name: string;
  price_base_line: number;
  price_year1: number;
  price_year2: number;
  price_year3: number;
  price_year4: number;
  price_year5: number;
  type_name: string;
  KG_L: number | null;
  company_product_group_id: number;
  id_industry: number;
  name_industry: string;
  registered_number_industry: number;
  logo_industry: string | null;
  id_business_type: number;
  name_business_type: string;
}
interface InitiativeProps {
  id: number;
  name: string;
  business_type_id: number;
  group_type_name: string;
  product_group_name: string;
}
const { Option } = Select;
export function Product() {
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [textError, setTextError] = useState("");
  const [modalError, setModalError] = useState(false);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [seguimentName, setSeguimentName] = useState<string>("");
  const [subSeguimentName, setSubSeguimentName] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [industryId, setIndustryId] = useState<number | null>(null);
  const [productName, setProductName] = useState<string>("");
  const [productExternalCode, setProductExternalCode] = useState<any>();
  const [skuExternalCode, setSkuExternalCode] = useState<any>();
  const [skuName, setSkuName] = useState<string>("");
  const [priceBaseLine, setPriceBaseLine] = useState<number | undefined>();
  const [priceYear1, setPriceYear1] = useState<number | undefined>();
  const [priceYear2, setPriceYear2] = useState<number | undefined>();
  const [priceYear3, setPriceYear3] = useState<number | undefined>();
  const [priceYear4, setPriceYear4] = useState<number | undefined>();
  const [priceYear5, setPriceYear5] = useState<number | undefined>();
  const [typeName, setTypeName] = useState<string>();
  const [KG_L, setKG_L] = useState<number | undefined | string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listTasks, setListTasks] = useState<TasksProps[]>([]);
  const [listInitiatives, setListInitiatives] = useState<InitiativeProps[]>([]);
  const [groupProduct, setGroupProduct] = useState<InitiativeProps[]>([]);
  const [selectGgroupProduct, setSelectGgroupProduct] = useState<
    number | null | any[]
  >(null);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<
    number | null
  >(null);
  const [selectGlobalBusinessType, setSelectGlobalBusinessType] = useState<
    number | null | any[]
  >(null);
  const [globalBussinessType, setGlobalBussinessType] = useState<
    InitiativeProps[]
  >([]);
  const [searchData, setSearchData] = useState<any | TasksProps[]>([]);
  const [search, setSearch] = useState("");
  const getListInitiatives = useCallback(async () => {
    const response: InitiativeProps[] =
      await GlobalIndustryService.getParameter();
    setListInitiatives(response);
  }, []);
  const getGlobalBussinessType = useCallback(async () => {
    const response: InitiativeProps[] =
      await GlobalBusinessType.getGlobalBusinessType();
    setGlobalBussinessType(response);
  }, []);
  const getListGrouoProduct = useCallback(async () => {
    const response: InitiativeProps[] = await GroupProductService.getParameter(
      companyId
    );
    setGroupProduct(response);
  }, []);
  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  const getListTasks = useCallback(async () => {
    try {
      setLoading(true);
      const response: any[] = await CompanyProductService.getParameter(
        companyId
      );
      const formattedTasks = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          industry_id: rawTask.industry_id,
          business_type_id: rawTask.business_type_id,
          segment_name: rawTask.segment_name,
          sub_segment_name: rawTask.sub_segment_name,
          brand_name: rawTask.brand_name,
          product_name: rawTask.product_name,
          product_external_code: rawTask.product_external_code,
          sku_external_code: rawTask.sku_external_code,
          sku_name: rawTask.sku_name,
          price_base_line: rawTask.price_base_line,
          price_year1: rawTask.price_year1,
          price_year2: rawTask.price_year2,
          price_year3: rawTask.price_year3,
          price_year4: rawTask.price_year4,
          price_year5: rawTask.price_year5,
          type_name: rawTask.type_name,
          KG_L: rawTask.KG_L,
          company_product_group_id: rawTask.company_product_group_id,
          product_group_name: rawTask.company_product_group.product_group_name,
          group_type_name: rawTask.company_product_group.group_type_name,
          id_industry: rawTask.industry.id,
          name_industry: rawTask.industry.name,
          registered_number_industry: rawTask.industry.registered_number,
          logo_industry: rawTask.industry.logo,
          id_business_type: rawTask.business_type.id,
          name_business_type: rawTask.business_type.name,
          updatedAt: rawTask.updatedAt,
          createdAt: rawTask.createdAt,
        };
      });
      setListTasks(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      setModalError(true);
      ShowMSG("error","Falha ao buscar tarefas.");
      setTextError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
  function handleClickEdit(task: TasksProps) {
    setSelectedTaskId(task.id ?? null);
    setSelectGlobalBusinessType(task.business_type_id ?? null);
    setSelectedInitiativeId(task.industry_id ?? null);
    setSeguimentName(task.segment_name);
    setSubSeguimentName(task.sub_segment_name);
    setSelectGgroupProduct(task.company_product_group_id ?? null);
    setBrandName(task.brand_name);
    setIndustryId(task.industry_id ?? null);
    setProductName(task.product_name);
    setProductExternalCode(task.product_external_code);
    setSkuExternalCode(task.sku_external_code);
    setSkuName(task.sku_name);
    setPriceBaseLine(task.price_base_line);
    setPriceYear1(task.price_year1);
    setPriceYear2(task.price_year2);
    setPriceYear3(task.price_year3);
    setPriceYear4(task.price_year4);
    setPriceYear5(task.price_year5);
    setTypeName(task.group_type_name);
    setKG_L(task.KG_L ?? undefined);
    setModalNewProduct(true);
  }
  async function confirmDelete(task: TasksProps) {
    try {
      await CompanyProductService.deleteTask(companyId, task.id);
      ShowMSG("success","Produto excluído com sucesso.");
    } catch (e) {
      console.error("Falha ao excluir: ");
      ShowMSG("error","Falha ao excluir.");
      setTextError(e.message);
      console.error(e);
    }
    await getListTasks();
  }

  useEffect(() => {
    getListTasks();
    getGlobalBussinessType();
    getListInitiatives();
    getListGrouoProduct();
  }, [companyId]);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Nome Produto",
      dataIndex: "product_group_name",
      key: "product_group_name",
      sorter: compareLabelsTables("product_group_name"),
    },
    {
      title: "Nome SKU",
      dataIndex: "sku_name",
      key: "sku_name",
      sorter: compareLabelsTables("sku_name"),
    },
    {
      title: "Segmento",
      dataIndex: "segment_name",
      key: "segment_name",
      sorter: compareLabelsTables("segment_name"),
    },
    {
      title: "Sub Segmento",
      dataIndex: "sub_segment_name",
      key: "sub_segment_name",
      sorter: (a: any, b: any) =>
        a.sub_segment_name.localeCompare(b.sub_segment_name),
    },
    {
      title: "Marca",
      dataIndex: "brand_name",
      key: "brand_name",
      sorter: compareLabelsTables("brand_name"),
    },
    {
      title: "Tipo de Negócio",
      dataIndex: "name_business_type",
      key: "name_business_type",
      sorter: (a: any, b: any) =>
        a.name_business_type.localeCompare(b.name_business_type),
    },
    {
      title: "Preço Atual",
      dataIndex: "price_year1",
      key: "price_year1",
      sorter: (a: any, b: any) => a.price_year1 - b.price_year1,
    },
    {
      title: "Tipo de Produto",
      dataIndex: "group_type_name",
      key: "group_type_name",
      sorter: (a: any, b: any) =>
        a.group_type_name.localeCompare(b.group_type_name),
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: TasksProps) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                icon={<FiEdit2 size={18} color="#00CC66" />}
                onClick={() => handleClickEdit(record)}
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir essa tarefa?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button
                  type="text"
                  icon={<FiTrash size={18} color="#00CC66" />}
                />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];
  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search) {
        setSearchData(
          listTasks.sort(compareLabelsTables("product_group_name"))
        );
      } else {
        const array = listTasks.filter(
          (record) =>
            !search ||
            record["sku_name"].toLowerCase().includes(search.toLowerCase()) ||
            record["segment_name"]
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            record["sub_segment_name"]
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            record["brand_name"].toLowerCase().includes(search.toLowerCase()) ||
            record["product_group_name"]
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            record["group_type_name"]
              .toLowerCase()
              .includes(search.toLowerCase())
        );
        setSearchData(array);
      }
    }
  }, [search, listTasks]);
  function showModalNewProduct() {
    setModalNewProduct(true);
  }

  async function handleOk() {
    setModalNewProduct(false);
    if (selectedTaskId) {
      try {
        await CompanyProductService.updateTask(
          companyId,
          {
            id: selectedTaskId,
            industry_id: selectedInitiativeId,
            business_type_id: selectGlobalBusinessType,
            segment_name: seguimentName,
            sub_segment_name: subSeguimentName,
            brand_name: brandName,
            product_name: productName,
            product_external_code: productExternalCode ?? "",
            sku_external_code: skuExternalCode,
            sku_name: skuName,
            price_base_line: priceBaseLine,
            price_year1: priceYear1,
            price_year2: priceYear2,
            price_year3: priceYear3,
            price_year4: priceYear4,
            price_year5: priceYear5,
            type_name: typeName,
            KG_L: KG_L ?? 0,
            company_product_group_id: selectGgroupProduct,
          },
          selectedTaskId
        );
        ShowMSG("success","Produto atualizado com sucesso.");
      } catch (e) {
        console.log("Falha ao atualizar");
        setModalError(true);
        ShowMSG("error","Falha ao atualizar.");
        setTextError(e.message);
        console.error(e);
      }
    } else {
      try {
        console.log({
          industry_id: selectedInitiativeId,
          business_type_id: selectGlobalBusinessType,
          segment_name: seguimentName,
          sub_segment_name: subSeguimentName,
          brand_name: brandName,
          product_name: productName,
          product_external_code: productExternalCode,
          sku_external_code: skuExternalCode,
          sku_name: skuName,
          price_base_line: priceBaseLine,
          price_year1: priceYear1,
          price_year2: priceYear2,
          price_year3: priceYear3,
          price_year4: priceYear4,
          price_year5: priceYear5,
          type_name: typeName,
          KG_L: KG_L ?? 0,
          company_product_group_id: selectGgroupProduct,
        });
        await CompanyProductService.createTask(companyId, {
          industry_id: selectedInitiativeId,
          business_type_id: selectGlobalBusinessType,
          segment_name:
            seguimentName[0].toUpperCase() +
            seguimentName.slice(1).toLowerCase(),
          sub_segment_name:
            subSeguimentName[0].toUpperCase() +
            subSeguimentName.slice(1).toLowerCase(),
          brand_name:
            brandName[0].toUpperCase() + brandName.slice(1).toLowerCase(),
          product_name: productName,
          product_external_code: productExternalCode,
          sku_external_code:
            skuExternalCode[0].toUpperCase() +
            skuExternalCode.slice(1).toLowerCase(),
          sku_name: skuName[0].toUpperCase() + skuName.slice(1).toLowerCase(),
          price_base_line: priceBaseLine,
          price_year1: priceYear1,
          price_year2: priceYear2,
          price_year3: priceYear3,
          price_year4: priceYear4,
          price_year5: priceYear5,
          type_name: typeName,
          KG_L: KG_L ?? 0,
          company_product_group_id: selectGgroupProduct,
        });
        ShowMSG("success","Produto criado com sucesso.");
      } catch (e) {
        console.log("Falha ao criar");
        setModalError(true);
        ShowMSG("error","Falha ao criar.");
        setTextError(e.message);
        console.error(e);
      }
    }

    await getListTasks();
    handleCancel();
  }

  function handleCancel() {
    setSelectedTaskId(null);
    setModalNewProduct(false);
    setModalError(false);
    setSelectGgroupProduct(null);
    setSelectGlobalBusinessType(null);
    setSeguimentName("");
    setSubSeguimentName("");
    setBrandName("");
    setProductName("");
    setProductExternalCode("");
    setSkuExternalCode(undefined);
    setSkuName("");
    setPriceBaseLine(undefined);
    setPriceYear1(undefined);
    setPriceYear2(undefined);
    setPriceYear3(undefined);
    setPriceYear4(undefined);
    setPriceYear5(undefined);
    setTypeName(undefined);
    setKG_L(undefined);
    setSelectedInitiativeId(null);
  }
  function disableSave(): boolean {
    return (
      !KG_L ||
      !selectGgroupProduct ||
      !typeName ||
      !priceYear5 ||
      !priceYear4 ||
      !priceYear3 ||
      !priceYear2 ||
      !priceYear1 ||
      !priceBaseLine ||
      !skuName ||
      !skuExternalCode ||
      !brandName ||
      !subSeguimentName ||
      !seguimentName ||
      !selectGlobalBusinessType ||
      !selectedInitiativeId
    );
  }
  console.log(
    KG_L,
    selectGgroupProduct,
    typeName,
    priceYear5,
    priceYear4,
    priceYear3,
    priceYear2,
    priceYear1,
    priceBaseLine,
    skuName,
    skuExternalCode,
    brandName,
    subSeguimentName,
    seguimentName,
    selectGlobalBusinessType,
    selectedInitiativeId
  );

  const { Title } = Typography;
  return (
    <Breadcrumb path="Produto" child={["Produto"]}>
      {contextHolder}
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Produto
            </Title>
          </Col>
        </Row>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewProduct}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          open={modalNewProduct}
          onOk={handleOk}
          okText={"Salvar"}
          onCancel={handleCancel}
          width={850}
         // okButtonProps={{ disabled: disableSave() }}
          title={selectedTaskId ? "Atualizar Produto" : "Novo Produto"}
        >
          <Row justify="center">
            <Col span={12} style={{ textAlign: "center" }}>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Indústria</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Tipo de Indústria"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiativeId}
                >
                  {listInitiatives.map((initiative) => (
                    <Option value={initiative.id}>{initiative.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Negócio</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Tipo de Negócio"
                  optionFilterProp="children"
                  onChange={(value) => setSelectGlobalBusinessType(value)}
                  value={selectGlobalBusinessType}
                >
                  {globalBussinessType.map((initiative) => (
                    <Option value={initiative.id}>{initiative.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Nome Produto</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Nome Produto"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setTypeName(undefined);
                    setSelectGgroupProduct(value);

                    // Find the selected initiative and set product name
                    const selectedProduct = groupProduct.find(
                      (initiative: any) => initiative.id === value
                    );
                    if (selectedProduct) {
                      setProductName(selectedProduct.product_group_name);
                    }
                  }}
                  value={selectGgroupProduct}
                >
                  {groupProduct.map((initiative: any) => {
                    if (
                      typeName === undefined &&
                      selectGgroupProduct === initiative.id
                    ) {
                      setTypeName(initiative.group_type_name);
                    }
                    return (
                      <Option key={initiative.id} value={initiative.id}>
                        {initiative.product_group_name}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Segmento</b> <b className="required">*</b>
                  </div>
                </Typography.Text>

                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Segmento"
                  value={
                    seguimentName != ""
                      ? seguimentName[0].toUpperCase() +
                        seguimentName.slice(1).toLowerCase()
                      : seguimentName
                  }
                  onChange={(e) => setSeguimentName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Sub Segmento</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Sub Segmento"
                  value={
                    subSeguimentName != ""
                      ? subSeguimentName[0].toUpperCase() +
                        subSeguimentName.slice(1).toLowerCase()
                      : subSeguimentName
                  }
                  onChange={(e) => setSubSeguimentName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Marca</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Marca"
                  value={
                    brandName != ""
                      ? brandName[0].toUpperCase() +
                        brandName.slice(1).toLowerCase()
                      : brandName
                  }
                  onChange={(e) => setBrandName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Código SKU</b> <b className="required">*</b>
                  </div>
                </Typography.Text>

                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Código SKU"
                  value={
                    skuExternalCode
                      ? skuExternalCode[0].toUpperCase() +
                        skuExternalCode.slice(1).toLowerCase()
                      : skuExternalCode
                  }
                  onChange={(e) => setSkuExternalCode(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Nome SKU</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Nome SKU"
                  value={
                    skuName != ""
                      ? skuName[0].toUpperCase() +
                        skuName.slice(1).toLowerCase()
                      : skuName
                  }
                  onChange={(e) => setSkuName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Base</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Base"
                  value={priceBaseLine}
                  onChange={(e) => setPriceBaseLine(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Ano 1</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Ano 1"
                  value={priceYear1}
                  onChange={(e) => setPriceYear1(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Ano 2</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Ano 2"
                  value={priceYear2}
                  onChange={(e) => setPriceYear2(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Ano 3</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Ano 3"
                  value={priceYear3}
                  onChange={(e) => setPriceYear3(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Ano 4</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Ano 4"
                  value={priceYear4}
                  onChange={(e) => setPriceYear4(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Preço Ano 5</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="Preço Ano 5"
                  value={priceYear5}
                  onChange={(e) => setPriceYear5(Number(e.target.value))}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Produto</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  disabled={true}
                  placeholder="Tipo de Produto"
                  optionFilterProp="children"
                  onChange={(value) => setTypeName(value)}
                  value={typeName}
                >
                  <Option value={"ESTRATEGICO"}>{"ESTRATEGICO"}</Option>
                  <Option value={"NORMAL"}>{"NORMAL"}</Option>
                </Select>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>KG/L</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  type={"number"}
                  min="0"
                  placeholder="KG/L"
                  value={KG_L}
                  onChange={(e) => setKG_L(Number(e.target.value))}
                />
              </Col>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
