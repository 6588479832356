import { message } from "antd";

import CompanyBusinessTypeService from "../../services/Company/BusinessType";
import { store } from "../../store";
import { setRecordsAccessType } from "../Reducer";

const { dispatch } = store;

export const accessType = async (companyId: number) => {
  try {
    const response = await CompanyBusinessTypeService.getAccessType(
      companyId
    );
    dispatch(setRecordsAccessType(response));
  } catch (err) {
    // Handle error appropriately
    console.error("Error fetching access types:", err);
    message.error("Error fetching access types");
  }
};
