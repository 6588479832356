import { Button, ButtonProps, Modal, Tooltip } from "antd";

import lockOpen from "../../assets/lock_open.svg";
import lockDown from "../../assets/lock_down.svg";
import "./styles.css";
import { Actions, Container, Content, Percent, Icon } from "./styles";
import { useState } from "react";

interface CardInformation {
  status: string;
  label: string;
  color: string;
  percent: number;
  period_open: boolean;
  period_past: boolean;
  final_date: string;
  initial_date: string;
}

interface CardProgressProps {
  cardInfo: CardInformation;
  actions: ButtonProps[];
  height?: number;
  disabled?: boolean;
}

export function CardProgress({
  cardInfo,
  height,
  actions,
  disabled,
}: CardProgressProps) {
  const [visible, setVisible] = useState(false);
  return (
    <Container height={height}>
      <Content color={cardInfo?.color}>
        {cardInfo?.status === "F" ||
        (cardInfo?.period_past === false && cardInfo?.period_open === false) ? (
          <Icon src={lockDown} />
        ) : (
          <Icon src={lockOpen} />
        )}
        <text style={{ color: "#000" }}>{cardInfo?.label}</text>
        <Percent>{cardInfo?.percent}%</Percent>

        <Actions>
          <>
            {actions?.map((item, index) => (
              <Tooltip
                placement="topLeft"
                title={item.about ? item.about : null}
                color={"#fff"}
                arrow={{ pointAtCenter: true }}
              >
                {actions.length === 3 ? (
                  actions[1] !== item ? (
                    item.children === "Mapeamento de Carteira" ? (
                      <Button
                        disabled={disabled}
                        type="primary"
                        onClick={() => setVisible(true)}
                        key={index}
                      >
                        Mapeamento de Carteira
                      </Button>
                    ) : (
                      <Button disabled={disabled} key={index} {...item} />
                    )
                  ) : null
                ) : (
                  <Button disabled={disabled} key={index} {...item} />
                )}
              </Tooltip>
            ))}
            <Modal
              open={visible}
              onCancel={() => setVisible(false)}
              footer={false}
              width={400}
              title={"O que você deseja acessar?"}
            >
              <Actions>
                {actions?.map((item, index) => (
                  <Tooltip
                    placement="topLeft"
                    title={item.about ? item.about : null}
                    color={"#fff"}
                    arrow={{ pointAtCenter: true }}
                  >
                    {actions[2] !== item ? (
                      <>
                        <Button
                          title={item.about}
                          disabled={disabled}
                          key={index}
                          {...item}
                        />
                        {actions[1] !== item ? "ou" : null}
                      </>
                    ) : null}
                  </Tooltip>
                ))}
              </Actions>
            </Modal>
          </>
        </Actions>
      </Content>
    </Container>
  );
}
