import { Card, Col, Row, Table } from "antd";
import { Breadcrumb } from "../../../components/Breadcrumb";

export function Region() {
  const columns = [
    {
      title: "Cod. País",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "País",
      dataIndex: "age",
      key: "age",
      sorter: (a: any, b: any) => a.age - b.age,
    },
    {
      title: "Cod. Região",
      dataIndex: "offce",
      key: "offce",
      sorter: (a: any, b: any) => a.offce.localeCompare(b.offce),
    },
    {
      title: "Região",
      dataIndex: "parce",
      key: "parce",
      sorter: (a: any, b: any) => a.parce.localeCompare(b.parce),
    },
    {
      title: "Cod. UF",
      dataIndex: "codUF",
      key: "codUF",
      sorter: (a: any, b: any) => a.codUF.localeCompare(b.codUF),
    },
    {
      title: "UF",
      dataIndex: "uf",
      key: "uf",
      sorter: (a: any, b: any) => a.uf.localeCompare(b.uf),
    },
    {
      title: "Cod. Meso",
      dataIndex: "codMEso",
      key: "codMEso",
      sorter: (a: any, b: any) => a.codMEso.localeCompare(b.codMEso),
    },
    {
      title: "Meso",
      dataIndex: "meso",
      key: "meso",
      sorter: (a: any, b: any) => a.meso.localeCompare(b.meso),
    },
    {
      title: "Cod. Micro",
      dataIndex: "codMicro",
      key: "codMicro",
      sorter: (a: any, b: any) => a.codMicro.localeCompare(b.codMicro),
    },
    {
      title: "Micro",
      dataIndex: "micro",
      key: "micro",
      sorter: (a: any, b: any) => a.micro.localeCompare(b.micro),
    },
    {
      title: "Cod. Cidade",
      dataIndex: "codCidade",
      key: "codCidade",
      sorter: (a: any, b: any) => a.codCidade.localeCompare(b.codCidade),
    },
    {
      title: "Cidade",
      dataIndex: "cidade",
      key: "cidade",
      sorter: (a: any, b: any) => a.cidade.localeCompare(b.cidade),
    },
  ];

  return (
    <Breadcrumb path="Região" child={["Região"]}>
      <Row>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table size="small" columns={columns} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Breadcrumb>
  );
}
