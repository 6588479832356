import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Table,
  Tooltip,
  Typography,
  
} from "antd";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { useEffect, useState, createRef, useCallback, RefObject,useRef  } from "react";
import type { FormInstance } from "antd/es/form";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppDispatch,
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../hooks";

import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import SalesPlanningService from "../../../services/SalesPlanning";
import {setFiltercommercialStucture } from "../Reducer";
import TopDownBoardService from "../../../services/SalesPlanning/TopDownBoard";
import { Buttons, Header } from "./styles";
import { InputNumberTable, InputNumberTableRight } from "../../../components/TableDinamic/InputNumber";
import { formatBR, formatPercent } from "../../../utils";
import { FiFilter, FiMenu, FiSearch, FiSettings, FiX } from "react-icons/fi";
import { ProductType } from "../../../components/Filter/ProductType";
import { setFilterProductType, resetFilter } from "../Reducer";
import { productType } from '../../../entities/ProductType/index';
import { AccessType } from "../../../components/Filter/AccessType";
import { setFilterAccesType } from "../Reducer";
import { accessType } from '../../../entities/AccessType/index';
import { NoticeType } from "antd/lib/message/interface";


const { Column, ColumnGroup } = Table;

interface TotalProps {
  
  channel_cagr: number;
  industry_sale_year1: number;
  industry_sale_year2: number;
  industry_sale_year3: number;
  industry_sale_year4: number;
  industry_sale_year5: number;
  industry_base_line: number;
  industry_cagr: number;
  industry_year1: number;
  industry_year2: number;
  industry_year3: number;
  industry_year4: number;
  industry_year5: number;
  market_growth: number;
  potential_base_line: number;
  potential_year1: number;
  potential_year2: number;
  potential_year3: number;
  potential_year4: number;
  potential_year5: number;
  soc_base_line: number;
  industry_soc_base_line: number;
  share_target: number;
  traffic_base_line: number;
  traffic_year1: number;
  traffic_year2: number;
  traffic_year3: number;
  traffic_year4: number;
  traffic_year5: number;
  soc_year1: number;
  soc_year2: number;
  soc_year3: number;
  soc_year4: number;
  soc_year5: number;
  share_line: number;
  share_year1: number;
  share_year2: number;
  share_year3: number;
  share_year4: number;
  share_year5: number;
  industry_soc_year1: number;
  industry_soc_year2: number;
  industry_soc_year3: number;
  industry_soc_year4: number;
  industry_soc_year5: number;
}

export function ProductPotential() {
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const { productType,accessType } = useAppSelector(
    (state) => state.salesPlanning.filter
  );

  const [selectedproductType, setSelectedproductType] = useState<any>();
  const [selectedaccessType, setSelectedaccessType] = useState<any>();
  const [selectedLevel2, setSelectedLevel2] = useState<any>(undefined);
  const [selectedLevel3, setSelectedLevel3] = useState<any>(undefined);
  const [selectedLevel0, setSelectedLevel0] = useState<any>(undefined);
  const [selectedLevel1, setSelectedLevel1] = useState<any>(undefined);
  const [level3, setLevel3] = useState<any>();
  const years = (useParameterName("QTD_YEARS"));
  const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [status, setStatus] = useState<string>("");
  const [level3name, setlevel3name] = useState<any>("");

  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(true);
  const [total, setTotal] = useState<TotalProps | undefined>();
  const [totalRegional, setTotalRegional] = useState<any>();
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(true);
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(true);
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(true);
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(true);
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(true);
  const [year6, setYear6] = useState<string | undefined>("");
  const [year6Active, setYear6Active] = useState<boolean>(true);
  const [statusButtonUp, setStatusButtonUp] = useState<any[]>([]);
  // const [year6Active, setYear6Active] = useState<boolean>(
  //   useButtonActiveYears(7)
  // );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [dataMiniDash, setDataMiniDash] = useState<any | undefined>({});

  const [searched, setSearched] = useState<string>("");
const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );


  const recordsProductType = useAppSelector(
    (state) => state.entities.productType
  );

  
  const recordsAccessType = [
    { id: 0, label: "Todos" }, // Ensure "Todos" is always first
    ...useAppSelector((state) => state.entities.accessType).filter(
      (type) => type.id !== 0
    ),
  ];
  

  const yearLabels = ["YEAR1_LABEL", "YEAR2_LABEL", "YEAR3_LABEL", "YEAR4_LABEL", "YEAR5_LABEL"];
  const quarterLabels = ["Q1", "Q2", "Q3", "Q4", "Q5"];
  
  const labelsToUse = parameters.some((param) => quarterLabels.includes(param.name)) ? quarterLabels : yearLabels;
  
  async function btup() {
    try {
      const response = await TopDownBoardService.getProductPotentialStatus(companyId);
      setStatusButtonUp(response);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    } finally {
    }
  }
  if (status === "F") {
    var newStatus = "Reabrir";
    var disbled = true;
  } else {
    var newStatus = "Finalizar";
    var disbled = false;
  }


  useEffect(() => {
    btup();
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "Q1")?.value);
    setYear2(parameters.find((value) => value.name === "Q2")?.value);
    setYear3(parameters.find((value) => value.name === "Q3")?.value);
    setYear4(parameters.find((value) => value.name === "Q4")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear6(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
  }, [parameters]);

  useEffect(() => {
    //TopDownBoardStatus();
    return function clean() {
      dispatch(resetFilter());
    };
  }, [dispatch]);

  const loadTableCompany = useCallback(
    
    async (productType: number,accessType:number,level3: number,level1: number, showLoading: boolean) => {
      localStorage.setItem("historico", "buscou ACP");
      try {
        // setData([]);
        // if (showLoading) {
        //   setLoading(true);
        // }
        //const response = await TopDownBoardService.getTableBoard(companyId, productType,level2);
        const response = await TopDownBoardService.getTableProductPotential(companyId, productType,accessType,level3,level1);
        setData([]);
        setData(response.records);
        setTotal(response.total);
      } catch (err) {
        ShowMSG("error",err.response.data.message);
        setData([]);
        setTotal(undefined);
      } finally {
        if (showLoading) {
          setLoading(false);
        }
      }
    },
    [companyId]
  );

  const loadTableMedia = useCallback(
    
    async (productType: number,level2: number) => {
      //localStorage.setItem("historico", "buscou ACP");
      try {
        // setData([]);
        // if (showLoading) {
        //   setLoading(true);
        // }
        //const response = await TopDownBoardService.getTableBoard(companyId, productType,level2);
        const response = await TopDownBoardService.getTableProductPotentialMediaRegional(companyId, productType,level2);
        setTotalRegional(response.total?.share_year1 ?? 0);
      } catch (err) {
        ShowMSG("error",err.response.data.message);
        // setData([]);
        // setTotal(undefined);
      } finally {
          setLoading(false);
      }
    },
    [companyId]
  );
  async function TopDownBoardStatus() {
    try {
      const response = await TopDownBoardService.getProductPotentialStatus(companyId);
      setStatus(response.pgv_acp);
      console.log("status"+status)
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
    }
  }
  async function onSave(data: any) {
   
    try{
      
    
    if (selectedproductType){
    if (data.value && data.value.toString() !== data.initialValue.toString()) {
      const body = {
        product: selectedproductType,
        level3_id: selectedLevel3,
        customer_id: data.records.customer_id,
        //level2_id: commercialStructure.level2,
        //level1_id: data.records.level1_id,
        access_type_id: data.records.access_type_id,
        field_changed: data.name,
        new_value: data.value,
      };
      localStorage.setItem("historico", "Alterou valores ACP");
      //await TopDownBoardService.saveTableBoard(companyId, body);
      await TopDownBoardService.saveTableProductPotential(companyId, body);
      if(searched){
        onChange(searched)
      }
      else{
      loadTableCompany(selectedproductType,selectedaccessType,selectedLevel3,selectedLevel1, false);
      loadTableMedia(selectedproductType,selectedLevel2);
      }
    }
  }
  ShowMSG("success","Alteração salva com sucesso!");
  } catch (err) {
    ShowMSG("error",err.response.data.message);
  } finally {
    setLoading(false);
  }
  }

  function renderInput(record: number, records: object, field: string) {
    if (
      field === "industry_sale_year1" ||
      field === "industry_sale_year2" ||
      field === "industry_sale_year3" ||
      field === "industry_sale_year4" ||
      field === "industry_sale_year5"
    ) {
      var control = false;
    } else {
      var control = true;
    }

    return (
      <div style={{ textAlign: "center" }}>
      <InputNumberTableRight
        onSave={onSave}
        disbled={disbled}
        max={control}
        records={records}
        name={field}
        initialValue={record}
      />
      </div>
    );
  }

  async function onFinish(obj: {
    productType:number;
    accessType:number;
    level3: number;
    level2: number;
    level1: number;
    level0: number;
    
  }) {
    console.log(obj.accessType)
    setData([])
    setSelectedproductType(obj.productType)
    setSelectedaccessType(obj.accessType)
    setSelectedLevel3(obj.level3)
    setSelectedLevel0(obj.level0)
    setSelectedLevel1(obj.level1)
    setSelectedLevel2(obj.level2)
    dispatch(setFilterProductType(obj.productType));
    setLevel3(obj.level3);
    dispatch(setFilterAccesType(obj.accessType));
    loadTableCompany(obj.productType,obj.accessType,obj.level3,obj.level1 , true);
     loadTableMedia(obj.productType,obj.level2);
     for (let i = 0; i < statusButtonUp.length; i++) {
      if (obj.level3 == statusButtonUp[i].id) {
        setStatus(statusButtonUp[i].pgv_acp);
        setlevel3name(statusButtonUp[i].level3_external_name)
      }
    }
    // setFilter(false);
  }

  async function onChange(search: string) {
    setSearched(search)
    // setLoading(true);
    try {
      setData([])
      
     // const response = await TopDownBoardService.searchTableBoard(
      const response = await TopDownBoardService.searchTableProductPotential(
        companyId,
        selectedproductType,
        selectedaccessType,
        selectedLevel3,
        selectedLevel1,
        search
      );
      setData(response.records);
      setTotal(response.total);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  function MiniDashCard(year: any, mktShare: any, soc: any) {
    return (
      <Card 
        bordered={true} 
        style={{ 
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.08)", 
          borderColor: "#ddd",
          padding: "0px",
          minHeight: "80px",
          maxHeight: "100px",
          overflow: "hidden", // Ensures content stays inside
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {contextHolder}
        <Title level={5} style={{ margin: "0", fontSize: "14px", textAlign: "center" }}>
          {year}
        </Title>
        <Row justify="space-between" align="middle" style={{ marginTop: 10 }}>
        <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2 }} type="secondary">
              Potencial
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0 }}>
              {formatBR(soc)}
              </Title>
          </Col>
          <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2,marginLeft:10 }} type="secondary">
            Bayer (KGL)
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0,marginLeft:10  }}>
            {total?.industry_base_line &&
             formatBR(total?.industry_base_line)}
              </Title>
          </Col>
          <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2 }} type="secondary">
              Market Share
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0 }}>
            {(() => {
                            const industryBase = total?.industry_base_line || 0;
                            const potentialBase = total?.potential_base_line || 0;

                            const percentage = potentialBase > 0 ? (industryBase / potentialBase) * 100 : 0;
                            return `${formatPercent(percentage)}%`;
                          })()}
              </Title>
          </Col>
          
        </Row>
      </Card>
    );
  }

  function MiniDashCardProj(year: any, mktShare: any, soc: any) {
    return (
      <Card 
        bordered={true} 
        style={{ 
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.08)", 
          borderColor: "#ddd",
          padding: "0px",
          minHeight: "80px",
          maxHeight: "100px",
          overflow: "hidden", // Ensures content stays inside
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Title level={5} style={{ margin: "0", fontSize: "14px", textAlign: "center" }}>
          {year}
        </Title>
        <Row justify="space-between" align="middle" style={{ marginTop: 10 }}>
        <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2 }} type="secondary">
              Potencial
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0 }}>
              {formatBR(soc)}
              </Title>
          </Col>
          <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2,marginLeft:10 }} type="secondary">
            Bayer (KGL)
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0,marginLeft:10  }}>
            {total &&
                                    formatBR(
                                      (total.industry_sale_year1 || 0) +
                                      (total.industry_sale_year2 || 0) +
                                      (total.industry_sale_year3 || 0) +
                                      (total.industry_sale_year4 || 0)
                                    )}
              </Title>
          </Col>
          <Col>
            <Paragraph style={{ fontSize: "12px", marginBottom: 2 }} type="secondary">
              Market Share
            </Paragraph>
            <Title level={5} style={{ fontSize: "14px", margin: 0 }}>
            {(() => {

            const industrySum =
              ((total?.industry_sale_year1 || 0) +
                (total?.industry_sale_year2 || 0) +
                (total?.industry_sale_year3 || 0) +
                (total?.industry_sale_year4 || 0)) || 0;

            const potentialYear1 = total?.potential_year1 || 0;

            const shareYear1 = industrySum > 0 && potentialYear1 > 0 
              ? (industrySum / potentialYear1) * 100
              : 0; 

            const totalPotential = total?.potential_base_line ?? 0;

            return `${formatPercent(shareYear1)}% `;
          })()}
        </Title>
          </Col>
        
        </Row>
      </Card>
    );
  }


  
  function MiniDashCardReg(year: any, mktShare: any, soc: any) {
    return (
      <Card 
        bordered={true} 
        style={{ 
          borderRadius: "8px",
          boxShadow: "0 2px 6px rgba(0, 0, 0, 0.08)", 
          borderColor: "#ddd",
          padding: "0px",
          minHeight: "80px",
          maxHeight: "100px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Title level={5} style={{ margin: "0", fontSize: "14px", textAlign: "center" }}>
          {year}
        </Title>
        <Row style={{ marginTop: 2, justifyContent:"right" }}>
        <Col style={{}}>
      <Paragraph style={{ fontSize: "12px", marginBottom: 2 }} type="secondary">
        Market Share
      </Paragraph>
      <Title level={5} style={{ fontSize: "14px", margin: 0 }}>
        {formatPercent(soc)}%
      </Title>
    </Col>
        </Row>
      </Card>
    );
  }
  const { Title,Paragraph  } = Typography;
  return (
    <Breadcrumb
      path="Projeções ACP"
      child={[productType?.toString(),accessType?.toString()]}
      disabled={data.length === 0}
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level3,
          rootClassName: level3name,
        },
      ]}
    >
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
            Projeção de Vendas - ACP
               {/* {selectedproductType?selectedproductType:""}
               {selectedLevel2?selectedLevel2:""} */}
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row gutter={16}>
              {filter && (
                <Col xl={4} xs={24} style={{ marginBottom: 16 }}>
                  <Card bordered={false}>
                    <Form
                      initialValues={{ productType: recordsProductType?.[0]?.id || undefined, commercialStructure, accessType: recordsAccessType?.[0]?.id || 0 }}
                      onFinish={onFinish}
                      ref={formRef}
                    >
                      
                      <CommercialStructure formRef={formRef} level={3} />
                      <ProductType />
                      <AccessType />
                      <Button htmlType="submit" type="primary" block>
                        Buscar
                      </Button>
                    </Form>
                  </Card>
                </Col>
              )}
              <Col xl={filter ? 20 : 24} xs={24}>
                <Card bordered={false}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Header>
                        <Buttons>
                        {filter ? (
                            ""
                            // <Tooltip title="Fechar Menu">
                            //   <Button
                            //     type="text"
                            //     style={{ marginRight: 16 }}
                            //     onClick={() => setFilter(!filter)}
                            //     icon={
                            //       <FiX
                            //         size={25}
                            //         color="#00CC66"
                            //         style={{
                            //           margin: "15px 10px 10px 10px",
                            //           cursor: "pointer",
                            //         }}
                            //       />
                            //     }
                            //   />
                            // </Tooltip>
                          ) : (
                            <Tooltip title="Abrir Menu">
                              <Button
                                type="text"
                                style={{ marginRight: 16 }}
                                icon={
                                  <FiMenu
                                    size={25}
                                    color="#00CC66"
                                    style={{
                                      margin: "15px 10px 10px 10px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setFilter(!filter)}
                                  />
                                }
                              />
                            </Tooltip>
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              marginBottom: 16,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                marginBottom: 16,
                              }}
                            >
                              <Button
                                disabled
                                onClick={() => setYear0Active(!year0Active)}
                                type={year0Active ? "primary" : "default"}
                                style={{
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 1
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year0}
                              </Button>
                              <Button
                                onClick={() => setYear1Active(!year1Active)}
                                type={year1Active ? "primary" : "default"}
                                style={{
                                  borderRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 2
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year1}
                              </Button>
                              <Button
                                onClick={() => setYear2Active(!year2Active)}
                                type={year2Active ? "primary" : "default"}
                                style={{
                                  borderRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 3
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year2}
                              </Button>
                              <Button
                                onClick={() => setYear3Active(!year3Active)}
                                type={year3Active ? "primary" : "default"}
                                style={{
                                  borderRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 4
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year3}
                              </Button>
                              <Button
                                onClick={() => setYear4Active(!year4Active)}
                                type={year4Active ? "primary" : "default"}
                                style={{
                                  borderRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 5
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year4}
                              </Button>
                              <Button
                              disabled
                                onClick={() => setYear5Active(!year5Active)}
                                type={year5Active ? "primary" : "default"}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 5
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year5}
                              </Button>
                              <Button
                                onClick={() => setYear6Active(!year6Active)}
                                type={year6Active ? "primary" : "default"}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) >= 5
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year6}
                              </Button>
                              {/* <Button
                                onClick={() => setYear6Active(!year6Active)}
                                type={year6Active ? "primary" : "default"}
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                  display:
                                    years?.value && parseInt(years.value) > 5
                                      ? "inline"
                                      : "none",
                                }}
                              >
                                {year6}
                              </Button> */}
                            </div>
                          </div>
                        </Buttons>
                        <Input
                          allowClear
                          onChange={(e) => onChange(e.target.value)}
                          placeholder="Pesquisar Grupo de Cliente"
                          prefix={<FiSearch color="#00CC66" />}
                          suffix={<FiFilter color="#00CC66" />}
                          style={{ width: 350 }}
                          disabled={!selectedproductType}
                        />
                      </Header>
                    </Col>
                    {total && (
                    <>
                      <Col span={8}>
                        {dataMiniDash && MiniDashCard(year0, 0, total?.potential_base_line ?? 0)}
                      </Col>
                      <Col span={8}>
                        {dataMiniDash && MiniDashCardProj(year5, 0, total?.potential_year1 ?? 0)}
                      </Col>
                      <Col span={8}>
                        {dataMiniDash && MiniDashCardReg("Share Regional 2024/25", dataMiniDash.mkt_year1, total?.share_target??0)}
                      </Col>
                    </>
                  )}
                    <Col span={24}>
                      <Table
                        rowKey={(record: any) =>
                          record.customer_id
                        }
                        loading={{ indicator: Icon, spinning: loading }}
                        scroll={{ y: 400, x: "100vw" }}
                        size="small"
                        pagination={false}
                        dataSource={data}
                        summary={() => {
                          return (
                            <Table.Summary>
                              <Table.Summary.Row
                                style={{ background: "#f5f5f5" }}
                              >
                                <Table.Summary.Cell
                                  colSpan={param ? 3 : 1}
                                  index={0}
                                >
                                  <b>Total</b>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell
                                  colSpan={param ? 3 : 1}
                                  index={1}
                                >
                                 
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} align="center">
                                  <Typography.Text>
                                    <b>
                                      {total?.potential_base_line &&
                                        formatBR(total?.potential_base_line)}
                                    </b>
                                  </Typography.Text>
                                </Table.Summary.Cell>
                                {year5Active && (
                                  <Table.Summary.Cell index={3} align="center">
                                    <Typography.Text>
                                      <b>
                                        {total?.potential_year1 &&
                                          formatBR(total?.potential_year1)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                             
                                <Table.Summary.Cell index={4} align="center">
                                  <Typography.Text>
                                    <b>
                                      {" "}
                                      {total?.industry_base_line &&
                                        formatBR(total?.industry_base_line)}
                                    </b>
                                  </Typography.Text>
                                </Table.Summary.Cell>
                                {year1Active && (
                                  <Table.Summary.Cell index={5} align="center">
                                    <Typography.Text>
                                      <b>
                                        {total?.industry_sale_year2 &&
                                          formatBR(total?.industry_sale_year2)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year2Active && (
                                  <Table.Summary.Cell index={6} align="center">
                                    <Typography.Text>
                                      <b>
                                        {total?.industry_sale_year3 &&
                                          formatBR(total?.industry_sale_year3)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year3Active && (
                                  <Table.Summary.Cell index={7} align="center">
                                    <Typography.Text>
                                      <b>
                                        {total?.industry_sale_year4 &&
                                          formatBR(total?.industry_sale_year4)}
                                      </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year4Active && (
                                  <Table.Summary.Cell index={8} align="center">
                                    <b>
                                      {" "}
                                      {total?.industry_sale_year1 &&
                                        formatBR(total?.industry_sale_year1)}
                                    </b>
                                    <Typography.Text></Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                {year5Active && (
                                  <Table.Summary.Cell index={9} align="center">
                                    <Typography.Text>
                                    <b>
                                  {total &&
                                    formatBR(
                                      (total.industry_sale_year1 || 0) +
                                      (total.industry_sale_year2 || 0) +
                                      (total.industry_sale_year3 || 0) +
                                      (total.industry_sale_year4 || 0)
                                    )}
                                </b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )}
                                 <Table.Summary.Cell index={10} align="center">
                                 <Typography.Text>
                                 {(() => {
                                  const industrySum =
                                    (total?.industry_sale_year1 || 0) +
                                    (total?.industry_sale_year2 || 0) +
                                    (total?.industry_sale_year3 || 0) +
                                    (total?.industry_sale_year4 || 0);

                                  const base = total?.industry_base_line || 0;

                                  let percentageChange = 0;

                                  if (industrySum === 0 && base === 0) {
                                    percentageChange = 0; // No change when both are zero
                                  } else if (base === 0 && industrySum !== 0) {
                                    percentageChange = 100; // If base is zero but industrySum has value, assume 100% increase
                                  } else {
                                    percentageChange = ((industrySum - base) / base) * 100;
                                  }

                                  return `${formatPercent(percentageChange)}%`;
                                })()}
                                  </Typography.Text>
                                </Table.Summary.Cell>
                                {year6Active && (
                                <Table.Summary.Cell index={11} align="center">
                                <Typography.Text>
                                <b>
                                      {" "}
                                      {total?.industry_sale_year5 &&
                                        formatBR(total?.industry_sale_year5)}
                                    </b>
                              </Typography.Text>
                              </Table.Summary.Cell>
                                      )}
                                <Table.Summary.Cell index={12} align="center">
                                <Typography.Text>
                                {(() => {
                                    //share
                            const industryBase = total?.industry_base_line || 0;
                            const potentialBase = total?.potential_base_line || 0;

                            // Prevent division by zero: If potentialBase is 0, set percentage to 0
                            const percentage = potentialBase > 0 ? (industryBase / potentialBase) * 100 : 0;

                            return `${formatPercent(percentage)}%`;
                          })()}
                              </Typography.Text>
                              </Table.Summary.Cell>
                              <Table.Summary.Cell index={13} align="center">
                                <Typography.Text>
                                {(() => {
                                  const industrySum =
                                    (total?.industry_sale_year1 || 0) +
                                    (total?.industry_sale_year2 || 0) +
                                    (total?.industry_sale_year3 || 0) +
                                    (total?.industry_sale_year4 || 0);

                                  const potentialYear1 = total?.potential_year1;

                                  // If potentialYear1 is zero or invalid, return 0%
                                  if (!potentialYear1 || potentialYear1 <= 0) {
                                    return `${formatPercent(0)}%`;
                                  }

                                  const percentage = (industrySum / potentialYear1) * 100;

                                  return `${formatPercent(percentage)}%`;
                                })()}
                              </Typography.Text>
                                </Table.Summary.Cell>
                                {year5Active && (
                                  <Table.Summary.Cell index={14} align="center">
                                    <Typography.Text>
                                    <b>{formatPercent(total?.industry_soc_year1)}%</b>
                                    </Typography.Text>
                                  </Table.Summary.Cell>
                                )} 


                                 {/* {year5Active && (
                                  <Table.Summary.Cell index={14} align="center">
                                  <b>
                                  {total?.industry_soc_year1 !== undefined && (() => {
                                
                                const industrySum =
                                (total?.industry_sale_year1 || 0) +
                                (total?.industry_sale_year2 || 0) +
                                (total?.industry_sale_year3 || 0) +
                                (total?.industry_sale_year4 || 0);
                              
                              const potentialYear1 = total?.potential_year1 > 0 ? total.potential_year1 : 1;                             
                              const industryPercentage = potentialYear1 > 0 ? (industrySum / potentialYear1) * 100 : 0;
                              const industrySoc = total?.industry_soc_year1 ?? 0; 
                              let percentageDifference = 
                                industrySoc !== 0 
                                  ? ((industryPercentage - industrySoc) / Math.abs(industrySoc)) * 100 
                                  : 0; 
                              
                              const clampedDifference = Math.max(Math.min(percentageDifference, 500), -500);

                              const displayValue = isFinite(clampedDifference) ? `${formatPercent(clampedDifference)}%` : "0%";
                              return (
                                <span
                                  style={{
                                    backgroundColor:
                                      clampedDifference < -10
                                        ? "rgba(255, 0, 0, 0.3)" 
                                        : clampedDifference >= -10 && clampedDifference <= 10
                                        ? "rgba(255, 255, 0, 0.3)" 
                                        : "rgba(0, 255, 0, 0.3)", 
                                    color: "black",
                                    padding: "5px 10px",
                                    borderRadius: "50%",
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                >
                                  {displayValue}
                                </span>
                              );
                            })()}
                            </b>
                                  </Table.Summary.Cell>
                                )}  */}
                                

                              </Table.Summary.Row>
                            </Table.Summary>
                          );
                        }}
                      >
                        <Column
                          title={() => {
                            return (
                              <Row align="middle" justify="space-between" >
                                <Col>Acesso_Gr_Conta</Col>
                                {/* <Col>
                                  <FiSettings
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setParam(!param)}
                                    size={16}
                                    color="#00CC66"
                                  />
                                </Col> */}
                              </Row>
                            );
                          }}
                          dataIndex="customer_type"
                          width={200}
                          fixed="left"
                        />
                        <Column
                          title={() => {
                            return (
                              <Row align="middle" justify="space-between">
                                <Col>Grupo do Cliente</Col>
                                {/* <Col>
                                  <FiSettings
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setParam(!param)}
                                    size={16}
                                    color="#00CC66"
                                  />
                                </Col> */}
                              </Row>
                            );
                          }}
                          dataIndex="customer"
                          width={200}
                          fixed="left"
                        />
                       

                        <ColumnGroup title="Potencial (KGL)" className="color">
                          {year0Active ? (
                            <Column
                              className="color"
                              title={`${year0}`}
                              align="center"
                              dataIndex="potential_base_line"
                              width={200}
                              render={(record: number) => formatBR(record)}
                            />
                          ) : (
                            <></>
                          )}
                          
                          {year5Active ? (
                            <Column
                              className="color"
                              title={`${year5}`}
                              align="center"
                              dataIndex="potential_year1"
                              width={200}
                              render={(record: number) => formatBR(record)}
                            />
                          ) : (
                            <></>
                          )}
                        </ColumnGroup>
                        <ColumnGroup title="Bayer (KGL)">
                          {year0Active ? (
                            <Column
                              title={`${year0}`}
                              align="center"
                              dataIndex="industry_base_line"
                              width={200}
                              render={(record: number) => formatBR(record)}
                            />
                          ) : (
                            <></>
                          )}
                          {year1Active ? (
                            <Column
                            title={year1}
                            align="center"
                            dataIndex="industry_sale_year2"
                            width={200}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "industry_sale_year2")
                            }
                          />
                          ) : (
                            <></>
                          )}
                          {year2Active ? (
                            <Column
                            title={year2}
                            align="center"
                            dataIndex="industry_sale_year3"
                            width={200}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "industry_sale_year3")
                            }
                          />
                          ) : (
                            <></>
                          )}
                          {year3Active ? (
                            <Column
                            title={year3}
                            align="center"
                            dataIndex="industry_sale_year4"
                            width={200}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "industry_sale_year4")
                            }
                          />
                          ) : (
                            <></>
                          )}
                          {year4Active ? (
                            <Column
                            title={year4}
                            align="center"
                            dataIndex="industry_sale_year1"
                            width={200}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "industry_sale_year1")
                            }
                            // render={(record: number, records: { level3_id?: number | null }) =>
                            //   records.level3_id ? renderInput(record, records, "industry_sale_year4") : "0"
                            // }
                          />
                          ) : (
                            <></>
                          )}
                          {year5Active ? (
                           <Column
                           title={year5}
                           align="center"
                           dataIndex="industry_sale_year5"
                           width={200}
                           render={(_, record: any) => {
                             const total =
                               (record.industry_sale_year1 || 0) +
                               (record.industry_sale_year2 || 0) +
                               (record.industry_sale_year3 || 0) +
                               (record.industry_sale_year4 || 0);
                             return formatBR(total);
                           }}
                         />
                          ) : (
                            <></>
                          )}
                        </ColumnGroup>
                        <ColumnGroup title="Variação"  align="center">
                          {year0Active ? (
                            <Column
                            className="color"
                            title={`${year0} vs ${year5}`}
                            align="center"
                            width={200}
                            render={(_, record: any) => {
                              const industrySum =
                                (record.industry_sale_year1 || 0) +
                                (record.industry_sale_year2 || 0) +
                                (record.industry_sale_year3 || 0) +
                                (record.industry_sale_year4 || 0);
                            
                              const base = record.industry_base_line || 0; // Ensure base starts at 0
                            
                              let percentageChange = 0;
                            
                              if (industrySum === 0 && base === 0) {
                                percentageChange = 0; // No change when both are zero
                              } else if (base === 0 && industrySum !== 0) {
                                percentageChange = 100; // Full increase if base is zero but industry has value
                              } else {
                                percentageChange = ((industrySum - base) / base) * 100;
                              }
                            
                              return `${formatPercent(percentageChange)}%`;
                            }}
                          />
                          ) : (
                            <></>
                          )}

                        </ColumnGroup>
                        {year6Active && (
                        <ColumnGroup title="Bayer (KGL)"  align="center">
                          <Column
                            title={`${year6}`}
                            align="center"
                            dataIndex="industry_sale_year5"
                            width={200}
                            render={(record: number, records: object) =>
                              renderInput(record, records, "industry_sale_year5")
                            }
                          />
                        </ColumnGroup>
                      )}

                        
                        <ColumnGroup title="Market Share" className="color">
                          {year0Active ? (
                            <Column
                              
                              title={`${year0}`}
                              align="center"
                              dataIndex="share_line"
                              width={200}
                              render={(record: number) =>
                                `${formatPercent(record)}%`
                              }
                            />
                          ) : (
                            <></>
                          )}
                          {year5Active ? (
                            <Column
                            className="color"
                            title={year5}
                            align="center"
                            dataIndex="share_year1"
                            width={200}
                            render={(_, record: any) => {
                              const industrySum =
                                (record.industry_sale_year1 || 0) +
                                (record.industry_sale_year2 || 0) +
                                (record.industry_sale_year3 || 0) +
                                (record.industry_sale_year4 || 0);
                          
                              const potentialYear1 = record.potential_year1 || 0; // Prevent division by zero by using 1 if potential_year1 is 0 or undefined
                              
                              const shareYear1 = industrySum > 0 && potentialYear1 > 0 
                                ? (industrySum / potentialYear1) * 100
                                : 0; // Calculate only if both sums are greater than 0
                          
                              return `${formatPercent(shareYear1)}%`;
                            }}
                          />
                          ) : (
                            <></>
                          )}
                       
                        </ColumnGroup>
                        <ColumnGroup title="Share Regional" className="" align="center">
                          {year5Active ? (
                            <Column
                              title={`${year5}`}
                              align="center"
                              className=""
                              dataIndex="industry_soc_year1"
                              width={200}
                              render={(record: number) =>
                                `${formatPercent(record)}%`
                              }
                             
                            />
                          ) : (
                            <></>
                          )}
                         
                        </ColumnGroup>
                    {/* <ColumnGroup title="Traffic Light" >
      
                    {year5Active ? (
                      <Column
                      title={year5}
                      align="center"
                      
                      dataIndex="traffic_year1"
                      width={200}
                      render={(record: number) => {       
                        let backgroundColor = "transparent"; 
                
                        
                          backgroundColor = "rgba(255, 255, 0, 0.3)"; 
                        
                        return (
                          <div
                            style={{
                              backgroundColor,
                              color: "black", 
                              padding: "5px 10px", 
                              borderRadius: "50%", 
                              display: "inline-block",
                              textAlign: "center",
                            }}
                          >
                            {formatPercent(0)}%
                          </div>
                        );
                      }}
                    />
                    ) : (
                      <></>
                    )}
                   
                  </ColumnGroup> */}
                       
                      </Table>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Breadcrumb>
  );
}
