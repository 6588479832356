import CompanyParameterService from "../../services/Company/Parameter";
import { store } from "../../store";
import { setRecordsParameter } from "../Reducer";

const { dispatch } = store;

export const parameter = async (companyId: number) => {
  try {
    const response = await CompanyParameterService.getParameter(companyId);
    dispatch(setRecordsParameter(response));
  } catch (err) {
    // Handle error appropriately
    console.error("Error fetching parameters:", err);
  }
};
