/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Statistic,
  Table,
  Tag,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import CompanyProductService from "../../../services/Company/Product";
import CustomerService from "../../../services/Pgc/Customer";
import ReactEcharts from "echarts-for-react";
import "./index.css";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FilterOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import Industry from "../../../services/Industry";
import { useTheme } from "../../../styles/ThemeContext";
import { MenuProps } from "antd/lib";
const Option = Select.Option;
export function ProductPGV() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const [valores, setValores] = useState<string>("KG/LT");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [OptionsPresidente, setOptionsPresidente] = useState<any>([]);
  const [filterPresidente, setFilterPresidente] = useState<number>(0);
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [OptionsFilial, setOptionsFilial] = useState<any>([]);
  const [OptionsVendedor, setOptionsVendedor] = useState<any>([]);

  const [filterFilial, setFilterFilial] = useState<number>(0);
  const [filterVendedor, setFilterVendedor] = useState<number>(0);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [newYears, setNewYears] = useState<any>([
    "2024/25",
    "2025/26",
    "2026/27",
  ]);
  const [DataProduto, setDataProduto] = useState<any>([]);
  const [DataCliente, setDataCliente] = useState<any>([]);
  const [filterCliente, setFilterCliente] = useState<number>(0);
  const [filterClienteName, setFilterClienteName] = useState<string>("Todos");
  const [LoadingRegional, setLoadingRegional] = useState<boolean>(false);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [Produto, setProduto] = useState<number>(0);
  const [OptionsRegional, setOptionsRegional] = useState<
    { level3_id: number; level3_external_name: string } | any
  >([]);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterDiretoria, setFilterDiretoria] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [valoresProdutos2, setValoresProdutos2] = useState<any>([]);
  const [valoresProdutos3, setValoresProdutos3] = useState<any>([]);
  const [valoresProdutos4, setValoresProdutos4] = useState<any>([]);
  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const [level4Label, setLevel4Label] = useState<string | undefined>("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [isFullScreen3, setIsFullScreen3] = useState(false);
  const [isFullScreen4, setIsFullScreen4] = useState(false);
  const [isFullScreen5, setIsFullScreen5] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [dataCabecalho, setDataCabecalho] = useState<any>([]);
  const [totalBaseLine, setTotalBaseLine] = useState<number>(0);
  const [totalProjection, setTotalProjection] = useState<number>(0);
  const [totalProjection2, setTotalProjection2] = useState<number>(0);
  const [industryCagr, setIndustryCagr] = useState<number>(0);
  const [diferenca, setDiferenca] = useState<number>(0);
  const [industrySoc, setIndustrySoc] = useState<number>(0);
  const [industrySocBaseLine, setIndustrySocBaseLine] = useState<number>(0);
  const [industryShareLine, setIndustryShareLine] = useState<number>(0);
  const [potencialy1, setPotencialy1] = useState<number>(0);
  console.log("🚀 ~ ProductPGV ~ potencialy1:", potencialy1);
  const [potencialy2, setPotencialy2] = useState<number>(0);
  const [industryShareTarget, setIndustryShareTarget] = useState<number>(0);
  const [marketShareBaseLine, setMarketShareBaseLine] = useState<number>(0);
  const [dataProfile, setDataProfile] = useState<any>("0");
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [filterProductSelected, setFilterProductSelected] =
    useState<string>("10");
  const [filterClienteSelected, setFilterClienteSelected] =
    useState<string>("10");
  const [contador, setContador] = useState<any>();
  const [numData, setNumData] = useState<any>([]);
  const [dataClient, setDataClient] = useState<any>([]);
  const [yearNow, setYearNow] = useState<string>(
    `${new Date().getFullYear() - 1}/${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}`
  );
  const [yearNest, setYearNest] = useState<string>(
    `${new Date().getFullYear()}/${(new Date().getFullYear() + 1)
      .toString()
      .slice(-2)}`
  );
  const [yearNest2, setYearNest2] = useState<string>(
    `${new Date().getFullYear() + 1}/${(new Date().getFullYear() + 2)
      .toString()
      .slice(-2)}`
  );
  const [yearNest3, setYearNest3] = useState<string>(
    `${new Date().getFullYear() + 2}/${(new Date().getFullYear() + 3)
      .toString()
      .slice(-2)}`
  );
  const [yearNest4, setYearNest4] = useState<string>(
    `${new Date().getFullYear() + 3}/${(new Date().getFullYear() + 4)
      .toString()
      .slice(-2)}`
  );
  const [fontSizeTitle, setFontSizeTitle] = useState<number>(
    (companyName || "").length > 15
      ? 14
      : (companyName || "").length > 12
      ? 15
      : (companyName || "").length > 8
      ? 16
      : (companyName || "").length > 4
      ? 17
      : 18
  );

  const user_id = useAppSelector((state) => state.auth.user.id);
  const getProfile = async () => {
    const response2 = await Industry.getProfile(companyId, user_id);
    setDataProfile(response2[0].profile);
  };
  const { theme } = useTheme();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  const toggleFullScreen3 = () => {
    setIsFullScreen3(!isFullScreen3);
  };
  const toggleFullScreen4 = () => {
    setIsFullScreen4(!isFullScreen4);
  };
  const toggleFullScreen5 = () => {
    setIsFullScreen5(!isFullScreen5);
  };
  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);
  const handleFilter = async () => {
    GetProduct();
    GetDataLevel0();
    GetCliente();
    GetDataLevel1(filterPresidente);
  };
  const GetCliente = async () => {
    const response = await CustomerService.getFindAll(companyId, "G");
    setDataCliente(response.sort(compareLabelsTables));
  };
  const atualizarDados = async (
    ano: any,
    TipoN: number,
    filterPre: number,
    filterDir: number,
    filterReg: number,
    filterFil: number,
    Produto: number,
    valores: string,
    permisionsao?: boolean,
    client_id?: string
  ) => {
    await GetDataTopdownFilial(
      ano,
      TipoN,
      filterPre,
      filterDir,
      filterReg,
      filterFil,
      Produto,
      valores,
      permisionsao,
      client_id
    );
  };

  const GetDataLevel0 = async () => {
    setFilterDiretoria(0);
    setFilterRegional(0);
    setFilterFilial(0);
    const response =
      await CompanyCommercialStructureService.getAllCommercialStrucuteLevel0(
        companyId
      );

    if (response.length === 1) {
      setOptionsPresidente(response);
      setFilterPresidente(response[0]?.level0_id);
      GetDataLevel1(response[0]?.level0_id);
    } else {
      setOptionsPresidente(response);
    }

    //  atualizarDados(years, TipoNegocio, filterPresidente, 0, 0, 0, 0, valores);
  };

  const GetDataLevel1 = async (value: number) => {
    setFilterRegional(0);
    setFilterFilial(0);
    if (value !== 0 && value !== undefined) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel1(
          companyId,
          value
        );

      setOptionsDiretoria(response);
    }
    atualizarDados(
      years,
      TipoNegocio,
      value,
      0,
      0,
      0,
      Produto,
      valores,
      true,
      filterClienteName
    );

    //getDataLevel2(value);
  };
  const GetDataLevel2 = async (value: number) => {
    setFilterFilial(0);
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel2(
          companyId,
          value
        );
      setFilterVendedor(0);
      setOptionsRegional(response);
    }

    atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      value,
      0,
      0,
      Produto,
      valores,
      true,
      filterClienteName
    );

    //GetDataLevel3(value);
  };
  const GetDataLevel3 = async (value: number) => {
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel3(
          companyId,
          value
        );
      setOptionsFilial(response);
    }
  };
  const GetDataLevel4 = async (value: number) => {
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel4(
          companyId,
          value
        );
      setOptionsVendedor(response);
    }
  };

  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  /*const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );*/
  /*const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );*/

  const items: MenuProps["items"] = [
    {
      key: "10",
      label: "Top 10",
    },
    {
      key: "20",
      label: "Top 20",
    },
    {
      key: "10000",
      label: "Todos",
    },
  ];
  const LimparFiltros = () => {
    setFilterFilial(0);
    setFilterRegional(0);
    setFilterDiretoria(0);
    setDataFilialTable([]);
    setContador(null);
    GetDataTopdownFilial(years, 0, 0, 0, 0, 0, 0);
    setDataClient([]);
    setFilterCliente(0);
    setDataProduto([]);
    setProduto(0);
    setFilterPresidente(0);
    // GetDataBottomUpFindRegional(0, Number(idUser));
  };

  const filtrarDados = (
    dados: any,
    filter: string,
    level: string,
    valores: string
  ) => {
    if (!dados || dados.length === 0) {
      return [];
    }
    return dados
      .reduce((acc: any[], item: any) => {
        const existingItem = acc.find((x) => x.name === item[level + "_name"]);
        if (existingItem) {
          existingItem.year0 +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"])
              : parseFloat(item[filter + "_base_line_volume"]);
          existingItem.year1 +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
              : parseFloat(item[filter + "_sale_year1_volume"]) || 0;
          existingItem.year5 +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year5_valor"]) || 0
              : parseFloat(item[filter + "_sale_year5_volume"]) || 0;
          existingItem.baseLine +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0;
          existingItem.projection +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) +
                parseFloat(item[filter + "_sale_year2_valor"]) +
                parseFloat(item[filter + "_sale_year3_valor"]) +
                parseFloat(item[filter + "_sale_year4_valor"])
              : parseFloat(item[filter + "_sale_year1_volume"]) +
                parseFloat(item[filter + "_sale_year2_volume"]) +
                parseFloat(item[filter + "_sale_year3_volume"]) +
                parseFloat(item[filter + "_sale_year4_volume"]);

          existingItem.pProjection +=
            valores === "R$"
              ? parseFloat(item["potential_year1_valor"]) +
                parseFloat(item["potential_year2_valor"]) +
                parseFloat(item["potential_year3_valor"]) +
                parseFloat(item["potential_year4_valor"])
              : parseFloat(item["potential_year1_volume"]) +
                parseFloat(item["potential_year2_volume"]) +
                parseFloat(item["potential_year3_volume"]) +
                parseFloat(item["potential_year4_volume"]);
          existingItem.mktBaseline +=
            valores === "R$"
              ? parseFloat(item["potential_base_line_valor"]) || 0
              : parseFloat(item["potential_base_line_volume"]) || 0;
        } else {
          acc.push({
            id: item[level + "_id"],
            name: item[level + "_name"],
            level3_name: item["level3_name"],
            level3_id: item["level3_id"],
            year0:
              valores === "R$"
                ? parseFloat(item[filter + "_base_line_valor"]) || 0
                : parseFloat(item[filter + "_base_line_volume"]) || 0,
            year1:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
                : parseFloat(item[filter + "_sale_year1_volume"]) || 0,
            year2:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year2_valor"]) || 0
                : parseFloat(item[filter + "_sale_year2_volume"]) || 0,
            year3:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year3_valor"]) || 0
                : parseFloat(item[filter + "_sale_year3_volume"]) || 0,
            year4:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year4_valor"]) || 0
                : parseFloat(item[filter + "_sale_year4_volume"]) || 0,
            year5:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year5_valor"]) || 0
                : parseFloat(item[filter + "_sale_year5_volume"]) || 0,
            baseLine:
              valores === "R$"
                ? parseFloat(item[filter + "_base_line_valor"]) || 0
                : parseFloat(item[filter + "_base_line_volume"]) || 0,

            mktBaseline:
              valores === "R$"
                ? parseFloat(item["potential_base_line_valor"]) || 0
                : parseFloat(item["potential_base_line_volume"]) || 0,
            projection:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) +
                  parseFloat(item[filter + "_sale_year2_valor"]) +
                  parseFloat(item[filter + "_sale_year3_valor"]) +
                  parseFloat(item[filter + "_sale_year4_valor"])
                : parseFloat(item[filter + "_sale_year1_volume"]) +
                  parseFloat(item[filter + "_sale_year2_volume"]) +
                  parseFloat(item[filter + "_sale_year3_volume"]) +
                  parseFloat(item[filter + "_sale_year4_volume"]),
            pProjection:
              valores === "R$"
                ? parseFloat(item["potential_year1_valor"]) +
                  parseFloat(item["potential_year2_valor"]) +
                  parseFloat(item["potential_year3_valor"]) +
                  parseFloat(item["potential_year4_valor"])
                : parseFloat(item["potential_year1_volume"]) +
                  parseFloat(item["potential_year2_volume"]) +
                  parseFloat(item["potential_year3_volume"]) +
                  parseFloat(item["potential_year4_volume"]),
            share_target: item["share_target"],
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => b.name - a.name);
  };

  const filterClientes = (
    dados: any,
    filter: string,
    level: string,
    valores: string
  ) => {
    if (!dados || dados.length === 0) {
      return [];
    }
    return dados.reduce((acc: any[], item: any) => {
      const existingItem = acc.find((x) => x.id === item["customer_id"]);
      if (existingItem) {
        existingItem.year1 +=
          valores === "R$"
            ? parseFloat(item[filter + "_sale_year1_valor"]) +
              parseFloat(item[filter + "_sale_year2_valor"]) +
              parseFloat(item[filter + "_sale_year3_valor"]) +
              parseFloat(item[filter + "_sale_year4_valor"])
            : parseFloat(item[filter + "_sale_year1_volume"]) +
              parseFloat(item[filter + "_sale_year2_volume"]) +
              parseFloat(item[filter + "_sale_year3_volume"]) +
              parseFloat(item[filter + "_sale_year4_volume"]);

        existingItem.baseLine +=
          valores === "R$"
            ? parseFloat(item[filter + "_base_line_valor"]) || 0
            : parseFloat(item[filter + "_base_line_volume"]) || 0;
      } else {
        acc.push({
          id: item["customer_id"],
          name:
            item["customer_name"] === "POTENCIAL"
              ? null
              : item["customer_name"].substring(0, 20),

          year1:
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) +
                parseFloat(item[filter + "_sale_year2_valor"]) +
                parseFloat(item[filter + "_sale_year3_valor"]) +
                parseFloat(item[filter + "_sale_year4_valor"])
              : parseFloat(item[filter + "_sale_year1_volume"]) +
                parseFloat(item[filter + "_sale_year2_volume"]) +
                parseFloat(item[filter + "_sale_year3_volume"]) +
                parseFloat(item[filter + "_sale_year4_volume"]),
          baseLine:
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0,
        });
      }
      return acc;
    }, []);
  };

  const filtrarProdutoporRgional = (
    dados: any,
    filter: string,
    level: string,
    valores: string
  ) => {
    if (!dados || dados.length === 0) {
      return [];
    }
    return dados
      .reduce((acc: any[], item: any) => {
        const existingItem = acc.find((x) => x.name === item["product_name"]);
        if (existingItem) {
          existingItem.year0 +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0;
          existingItem.year1 +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
              : parseFloat(item[filter + "_sale_year1_volume"]) || 0;
          existingItem.baseLine +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0;
          existingItem.projection +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) +
                parseFloat(item[filter + "_sale_year2_valor"]) +
                parseFloat(item[filter + "_sale_year3_valor"]) +
                parseFloat(item[filter + "_sale_year4_valor"])
              : parseFloat(item[filter + "_sale_year1_volume"]) +
                parseFloat(item[filter + "_sale_year2_volume"]) +
                parseFloat(item[filter + "_sale_year3_volume"]) +
                parseFloat(item[filter + "_sale_year4_volume"]);
        } else {
          acc.push({
            id: item[level + "_id"],
            name: item["product_name"],
            year0:
              valores === "R$"
                ? parseFloat(item[filter + "_base_line_valor"]) || 0
                : parseFloat(item[filter + "_base_line_volume"]) || 0,
            year1:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
                : parseFloat(item[filter + "_sale_year1_volume"]) || 0,
            year2:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year2_valor"]) || 0
                : parseFloat(item[filter + "_sale_year2_volume"]) || 0,
            year3:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year3_valor"]) || 0
                : parseFloat(item[filter + "_sale_year3_volume"]) || 0,
            year4:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year4_valor"]) || 0
                : parseFloat(item[filter + "_sale_year4_volume"]) || 0,
            baseLine:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_base_line_valor"]) || 0
                : parseFloat(item[filter + "_sale_base_line_volume"]) || 0,
            projection:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) +
                  parseFloat(item[filter + "_sale_year2_valor"]) +
                  parseFloat(item[filter + "_sale_year3_valor"]) +
                  parseFloat(item[filter + "_sale_year4_valor"])
                : parseFloat(item[filter + "_sale_year1_volume"]) +
                  parseFloat(item[filter + "_sale_year2_volume"]) +
                  parseFloat(item[filter + "_sale_year3_volume"]) +
                  parseFloat(item[filter + "_sale_year4_volume"]),
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const filtrarDadosProduto = (
    dados: any,
    filter: string,
    product_name: string,
    valores: string
  ) => {
    return dados
      .reduce((acc: any[], item: any) => {
        const existingItem = acc.find((x) => x.name === item[product_name]);
        if (existingItem) {
          existingItem.year0 +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0;

          existingItem.year1 +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
              : parseFloat(item[filter + "_sale_year1_volume"]) || 0;
          existingItem.baseLine +=
            valores === "R$"
              ? parseFloat(item[filter + "_base_line_valor"]) || 0
              : parseFloat(item[filter + "_base_line_volume"]) || 0;
          existingItem.projection +=
            valores === "R$"
              ? parseFloat(item[filter + "_sale_year1_valor"]) +
                parseFloat(item[filter + "_sale_year2_valor"]) +
                parseFloat(item[filter + "_sale_year3_valor"]) +
                parseFloat(item[filter + "_sale_year4_valor"])
              : parseFloat(item[filter + "_sale_year1_volume"]) +
                parseFloat(item[filter + "_sale_year2_volume"]) +
                parseFloat(item[filter + "_sale_year3_volume"]) +
                parseFloat(item[filter + "_sale_year4_volume"]);
        } else {
          acc.push({
            id: item[product_name],
            name: item[product_name],
            year0:
              valores === "R$"
                ? parseFloat(item[filter + "_base_line_valor"]) || 0
                : parseFloat(item[filter + "_base_line_volume"]) || 0,
            year1:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) || 0
                : parseFloat(item[filter + "_sale_year1_volume"]) || 0,
            baseLine:
              valores === "R$"
                ? parseFloat(item[filter + "_base_line_valor"]) || 0
                : parseFloat(item[filter + "_base_line_volume"]) || 0,
            projection:
              valores === "R$"
                ? parseFloat(item[filter + "_sale_year1_valor"]) +
                  parseFloat(item[filter + "_sale_year2_valor"]) +
                  parseFloat(item[filter + "_sale_year3_valor"]) +
                  parseFloat(item[filter + "_sale_year4_valor"])
                : parseFloat(item[filter + "_sale_year1_volume"]) +
                  parseFloat(item[filter + "_sale_year2_volume"]) +
                  parseFloat(item[filter + "_sale_year3_volume"]) +
                  parseFloat(item[filter + "_sale_year4_volume"]),
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => a.name.localeCompare(b.name));
  };
  const formataNome = (nome: string) => {
    return nome
      .split(" ")
      .map(
        (palavra) =>
          palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
      )
      .join(" ");
  };

  useEffect(() => {
    handleFilter();
    getProfile();
  }, [theme]);

  const handleValores = (value: any) => {
    setValores(value);
    atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial,
      Produto,
      value,
      true,
      filterClienteName
    );
  };

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)).slice(0, 3));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleRegionChange = async (value: any) => {
    setFilterRegional(value);
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      value,
      0,
      Produto,
      valores,
      true,
      filterClienteName
    );
    setFilterFilial(0);
    GetDataLevel3(value);
  };

  const handleClienteChange = async (value: number, option: any) => {
    setFilterCliente(value);
    setFilterClienteName(option.label);
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial,
      Produto,
      valores,
      true,
      option.label
    );
  };

  const handlePresidenteChange = async (value: number) => {
    GetDataLevel1(value);
    setFilterDiretoria(0);
    setFilterRegional(0);
    setFilterFilial(0);
    setOptionsVendedor([]);
    // await GetDataBottomUpFindRegional2(value, Number(idUser));
    // GetDataBottomUps(TipoNegocio, value, 0, 0, 0, valores);
    setFilterPresidente(value);
  };
  const handleDiretoriaChange = async (value: any) => {
    setFilterDiretoria(value);
    // await GetDataBottomUpFindRegional(value, Number(idUser), value);
    //setDataFilial([]);
    GetDataLevel2(value);
    setFilterRegional(0);
    setFilterFilial(0);
    setOptionsVendedor([]);
  };
  const handleVendedorChange = async (
    value: any,
    option: {
      l3name: string;
      l3value: number;
      label: string;
      value: number;
    }
  ) => {
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      option.l3value,
      Produto,
      valores,
      false,
      filterClienteName
    );
    setFilterVendedor(value);
    setFilterFilial(option.l3value !== undefined ? option.l3value : 0);
  };
  const handleFilialChangeModal = async (value: any) => {
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      value,
      Produto,
      valores,
      false,
      filterClienteName
    );
    setFilterVendedor(0);
    //GetDataLevel4(value);
    setFilterFilial(value);
  };

  useEffect(() => {
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12)?.toFixed(1)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9)?.toFixed(1)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6)?.toFixed(1)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3)?.toFixed(1)}K`;
    } else {
      return value?.toFixed(1);
    }
  };
  const columns = [
    {
      title: "RTV",
      dataIndex: "name",
      key: "name",
      width: "110px",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: yearNow,
      dataIndex: "baseLine",
      key: "baseLine",
      width: "100px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "0,00"}</span>
        );
      },
    },
    {
      title: yearNest,
      dataIndex: "projection",
      key: "projection",
      width: "100px",

      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "0,00"}
          </span>
        );
      },
    },
    {
      title: yearNest2,
      dataIndex: "year5",
      key: "year5",
      width: "100px",
      sorter: (a: any, b: any) => a.year5 - b.year5,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.year5 && record.year5 > 0
              ? formatValue(record.year5)
              : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "90px",
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation?.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter((column: Column) => {
    if (column.key === "year5") {
      return newYears.length === 3;
    }
    return true;
  });

  async function GetDataTopdownFilial(
    year?: any,
    TipoNegocio?: number,
    filterPresidente?: number,
    filterDiretoria?: number,
    filterRegional?: number,
    filterFilial?: number,
    Produto?: number,
    valores?: string,
    permisionsao?: boolean,
    client_id?: string
  ) {
    setDataFilialTable([]);

    const response = await PowerBi_all.getProductPotencialDashboard(
      companyId,
      year ? year : yearsOptions(Number(qdt_year)).slice(0, 2),
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial,
      "",
      Produto,
      client_id
    );

    if (response) {
      setDataCabecalho(response?.total);
      const TotalBaseline = response?.total?.industry_base_line_valor
        ? valores === "R$"
          ? response?.total?.industry_base_line_valor
          : response?.total?.industry_base_line_volume
        : 0;
      setTotalBaseLine(TotalBaseline);
      const TotalProjection =
        valores === "R$"
          ? response?.total?.industry_sale_year1_valor
            ? response?.total?.industry_sale_year1_valor +
              response?.total?.industry_sale_year2_valor +
              response?.total?.industry_sale_year3_valor +
              response?.total?.industry_sale_year4_valor
            : 0
          : response?.total?.industry_sale_year1_volume
          ? response?.total?.industry_sale_year1_volume +
            response?.total?.industry_sale_year2_volume +
            response?.total?.industry_sale_year3_volume +
            response?.total?.industry_sale_year4_volume
          : 0;

      setTotalProjection(TotalProjection);

      const TotalProjection2 =
        valores === "R$"
          ? response?.total?.industry_sale_year5_valor
            ? response?.total?.industry_sale_year5_valor
            : 0
          : response?.total?.industry_sale_year5_volume
          ? response?.total?.industry_sale_year5_volume
          : 0;
      setTotalProjection2(TotalProjection2);
      const Projetion =
        valores === "R$"
          ? response?.total?.industry_sale_year1_valor +
            response?.total?.industry_sale_year2_valor +
            response?.total?.industry_sale_year3_valor +
            response?.total?.industry_sale_year4_valor
          : response?.total?.industry_sale_year1_volume +
            response?.total?.industry_sale_year2_volume +
            response?.total?.industry_sale_year3_volume +
            response?.total?.industry_sale_year4_volume;

      setDiferenca(((Projetion - TotalBaseline) / TotalBaseline) * 100);
      setIndustryShareLine(
        response?.total?.share_target ? response?.total?.share_target : 0
      );
      if (valores === "R$") {
        console.log("response", response.total.potential_base_line_valor);
        setPotencialy1(
          response?.total?.potential_base_line_valor
            ? response?.total?.potential_base_line_valor
            : 0
        );
        setPotencialy2(
          response?.total?.potential_year1_valor !== null
            ? response?.total?.potential_year1_valor
            : 0
        );
        setIndustryShareTarget(
          ((response?.total?.industry_sale_year1_valor +
            response?.total?.industry_sale_year2_valor +
            response?.total?.industry_sale_year3_valor +
            response?.total?.industry_sale_year4_valor) /
            (response?.total?.potential_year1_valor +
              response?.total?.potential_year2_valor +
              response?.total?.potential_year3_valor +
              response?.total?.potential_year4_valor)) *
            100
        );
        setMarketShareBaseLine(
          (response?.total?.industry_base_line_valor /
            response?.total?.potential_base_line_valor) *
            100
        );
        permisionsao === true || permisionsao === undefined ? (
          setValoresProdutos(
            filterFilial !== undefined
              ? filtrarProdutoporRgional(
                  response.records,
                  "industry",
                  "level2",
                  "R$"
                )
              : []
          )
        ) : (
          <></>
        );
        setValoresProdutos2(
          filtrarDados(response.records, "industry", "level4", "R$")
        );

        if (OptionsVendedor.length === 0) {
          setOptionsVendedor(
            filtrarDados(response.records, "industry", "level4", "R$")
          );
        }

        setValoresProdutos3(
          filtrarDadosProduto(
            response.records,
            "industry",
            "product_name",
            "R$"
          )
        );

        setValoresProdutos4(response?.total);
        setNumData(filtrarDados(response.records, "industry", "level3", "R$"));
        setDataClient(
          filterClientes(response.records, "industry", "level4", "R$")
        );
      } else {
        console.log("response", response.total.potential_base_line_volume);
        setPotencialy1(
          response.total.potential_base_line_volume
            ? response.total.potential_base_line_volume
            : 0
        );
        setPotencialy2(
          response?.total?.potential_year1_volume
            ? response?.total?.potential_year1_volume
            : 0
        );

        setIndustryShareTarget(
          ((response?.total?.industry_sale_year1_volume +
            response?.total?.industry_sale_year2_volume +
            response?.total?.industry_sale_year3_volume +
            response?.total?.industry_sale_year4_volume) /
            (response?.total?.potential_year1_volume +
              response?.total?.potential_year2_volume +
              response?.total?.potential_year3_volume +
              response?.total?.potential_year4_volume)) *
            100
        );
        setMarketShareBaseLine(
          (response?.total?.industry_base_line_volume /
            response?.total?.potential_base_line_volume) *
            100
        );
        permisionsao === true || permisionsao === undefined ? (
          setValoresProdutos(
            filterFilial !== undefined
              ? filtrarProdutoporRgional(
                  response.records,
                  "industry",
                  "level2",
                  "KG/LT"
                )
              : []
          )
        ) : (
          <></>
        );

        setValoresProdutos2(
          filtrarDados(response.records, "industry", "level4", "KG/LT")
        );
        if (OptionsVendedor.length === 0) {
          setOptionsVendedor(
            filtrarDados(response.records, "industry", "level4", "KG/LT")
          );
        }

        setValoresProdutos3(
          filtrarDadosProduto(
            response.records,
            "industry",
            "product_name",
            "KG/LT"
          )
        );

        setValoresProdutos4(response?.total);
        setNumData(
          filtrarDados(response.records, "industry", "level3", "KG/LT")
        );
        setDataClient(
          filterClientes(response.records, "industry", "level4", "KG/LT")
        );
      }
    } else {
      filterFilial === undefined ||
        (filterFilial === 0 && setValoresProdutos([]));
      setValoresProdutos2([]);
      setValoresProdutos3([]);
      setValoresProdutos4([]);
      setOptionsVendedor([]);
      setDataCabecalho([]);
      setTotalBaseLine(0);
      setTotalProjection(0);
      setIndustryCagr(0);
      setIndustrySoc(0);
      setIndustrySocBaseLine(0);
      setIndustryShareLine(0);
      setIndustryShareTarget(0);
      setMarketShareBaseLine(0);
      setDiferenca(0);
    }
  }

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year - 1; i++) {
      yearss.push(`year${i}`);
    }
    return yearss;
  };

  const handleChange = async (value: any) => {
    setNewYears(value);

    if (filterRegional !== undefined) {
      await atualizarDados(
        value,
        TipoNegocio,
        filterPresidente,
        filterDiretoria,
        filterRegional,
        filterFilial,
        Produto,
        valores,
        true,
        filterClienteName
      );
    }
  };

  const handleProdutoChange = async (value: any) => {
    setProduto(value);
    await GetDataTopdownFilial(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial,
      value,
      valores,
      true,
      filterClienteName
    );
  };

  async function GetProduct() {
    const response: any[] = await CompanyProductService.getParameter(companyId);
    const sortedResponse = response.sort((a, b) =>
      a.product_name.localeCompare(b.product_name)
    );
    setDataProduto(sortedResponse);

    if (sortedResponse.length === 1) {
      setProduto(sortedResponse[0]?.id);
    }
  }
  const calculatePercentagebase = (item: {
    baseLine: number;
    mktBaseline: number;
  }): string => {
    if (item.baseLine === 0) return "0.0";
    return ((item.baseLine / item.mktBaseline) * 100).toFixed(1);
  };

  const calculatePercentage = (
    item: {
      projection: number;
      pProjection: number;
    },
    name: string
  ): string => {
    if (item.projection === 0) return "0.0";
    return ((item.projection / item.pProjection) * 100).toFixed(1);
  };

  /* const configVendasBayerRegional = {
    title: {
      text: `Vendas ${formataNome(companyName || "")} (${valores})/ Regional`,
      x: "center",
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Indústria" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: true,
      type: "scroll",
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
      textStyle: {
        fontSize: 10,
      },
    },
    series: [
      {
        name: "Cultivo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Cultivo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.projection,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;*/
  const configPorcentagemRegional = {
    title: {
      text: `${
        filterProductSelected !== "10000"
          ? `Top ${filterProductSelected} Produtos`
          : "Produtos"
      }`,
      x: "center",
      textStyle: {
        fontSize: fontSizeTitle, // Reduzindo o tamanho da fonte do título
      },
    },
    backgroundColor: "transparent",
    grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: { show: true, top: "25px" },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    xAxis: {
      type: "category",
      data: valoresProdutos3
        .sort((a: any, b: any) => b.year0 - a.year0)
        .filter((item: any) => item && item.name)
        .slice(0, filterProductSelected)
        .map((item: any) => item.name),
      axisLabel: {
        rotate: 90,
      },
    },
    yAxis: [
      {
        type: "value",
        axisTick: { alignWithLabel: true },
      },
    ],

    series: [
      {
        name: yearNow,
        type: "bar",
        data: valoresProdutos3
          .sort((a: any, b: any) => b.year0 - a.year0)
          .filter((item: any) => item && item.name)
          .slice(0, filterProductSelected)
          .map((item: any) => item.year0),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
      {
        name: yearNest,
        type: "bar",
        data: valoresProdutos3
          .sort((a: any, b: any) => b.year0 - a.year0)
          .filter((item: any) => item && item.name)
          .slice(0, filterProductSelected)
          .map((item: any) => item.projection),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
    ],
  } as any;

  const configClient = {
    title: {
      text: `${
        filterClienteSelected !== "10000"
          ? `Top ${filterClienteSelected} Clientes`
          : "Clientes"
      }`,
      x: "center",
      textStyle: {
        fontSize: fontSizeTitle,
      },
    },
    backgroundColor: "transparent",
    legend: { show: true, top: "25px" },
    grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
    xAxis: {
      type: "category",
      data: dataClient
        .sort((a: any, b: any) => b.baseLine - a.baseLine)
        .filter((item: any) => item.name !== null)
        .slice(0, filterClienteSelected)
        .map((item: any) => item.name),
      axisLabel: {
        rotate: 90,
      },
    },
    color: ["#66CCFF", "#0075BA", "#FF0000"], // Adicionei uma cor para a linha do target share
    yAxis: [
      {
        type: "value",
        axisTick: { alignWithLabel: true },
      },
      {
        type: "value",
        name: "Pareto",
        position: "right",
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => `${Math.round(value)} %`,
        },
        max: 100,
        min: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: `${yearNow}`,
        type: "bar",
        data: dataClient
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .slice(0, filterClienteSelected)
          .map((item: any) => item.baseLine),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
      {
        name: `${yearNest}`,
        type: "bar",
        data: dataClient
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .slice(0, filterClienteSelected)
          .map((item: any) => item.year1),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
      {
        name: `Pareto ${yearNest}`,
        type: "line",
        yAxisIndex: 1,
        data: (() => {
          const sortedData = dataClient
            .sort((a: any, b: any) => b.baseLine - a.baseLine)
            .filter((item: any) => item.name !== null)
            .slice(0, filterClienteSelected)
            .map((item: any) => item.year1);
          const total = sortedData.reduce(
            (sum: number, value: number) => sum + value,
            0
          );
          let cumulative = 0;
          return sortedData.map((value: number) => {
            cumulative += value;
            return (cumulative / total) * 100;
          });
        })(),
        itemStyle: {
          color: "#FF0000",
        },
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => `${Math.round(params.value)} %`,
        },
      },
    ],
  } as any;
  const configFilial = {
    title: {
      text: `Vendas  ${formataNome(companyName || "")} / Território`,
      x: "center",
    },
    backgroundColor: "transparent",
    legend: { show: true, top: "25px" },
    grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
    xAxis: {
      type: "category",
      data: numData
        .sort((a: any, b: any) => b.baseLine - a.baseLine)
        .filter((item: any) => item.name !== null)
        .map((item: any) => item.name),
      axisLabel: {
        rotate: 90,
      },
    },
    color: ["#66CCFF", "#0075BA", "#FF0000"], // Adicionei uma cor para a linha do target share
    yAxis: [
      {
        type: "value",
        axisTick: { alignWithLabel: true },
      },
      {
        type: "value",
        name: "",
        position: "right",
        alignTicks: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: (value: number) => `${Math.round(value)} %`,
        },
        max: 100,
        min: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: `${yearNow}`,
        type: "bar",
        data: numData
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .map((item: any) => item.baseLine),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
      {
        name: `${yearNest}`,
        type: "bar",
        data: numData
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .map((item: any) => item.projection),
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => formatValue(params.value),
          rotate: 90,
        },
      },
      {
        name: `Mkt Share Regional`,
        type: "line",
        yAxisIndex: 1,
        data: numData
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .map((item: any) => industryShareLine?.toFixed(1)),
      },
      {
        name: `Mkt Share ${yearNow}`,
        type: "line",
        color: "#ffa800",
        yAxisIndex: 1,
        data: numData
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .map((item: any) => calculatePercentagebase(item)),
      },
      {
        name: `Mkt Share ${yearNest}`,
        type: "line",
        yAxisIndex: 1,
        data: numData
          .sort((a: any, b: any) => b.baseLine - a.baseLine)
          .filter((item: any) => item.name !== null)
          .map((item: any) => calculatePercentage(item, item.name)),
      },
    ],
  } as any;

  const configTerritorio: any = valoresProdutos4
    ? {
        title: {
          text: `Vendas  ${formataNome(
            companyName || ""
          )} (${valores}) / Quarter`,
          x: "center",
        },
        backgroundColor: "transparent",
        legend: { show: true, top: "25px" },
        grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
        xAxis: [
          {
            type: "category",
            data: ["Q2", "Q3", "Q4", "Q1_26"],
            axisLabel: {
              rotate: 0,
            },
          },
        ],

        color: ["#66CCFF", "#0075BA", "#FFCC00", "#FF9900"], // Adicione mais cores conforme necessário
        yAxis: [
          {
            type: "value",
            axisTick: { alignWithLabel: true },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: { show: true, title: "Salvar" },
            magicType: { show: true, type: ["line", "bar"] },
            dataView: {
              show: true,
              readOnly: false,
              title: "Visualização de Dados",
              lang: ["Visualização de Dados", "Fechar", "Atualizar"],
            },
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            data: [
              valores === "R$"
                ? valoresProdutos4.industry_sale_year2_valor
                : valoresProdutos4.industry_sale_year2_volume,
              valores === "R$"
                ? valoresProdutos4.industry_sale_year3_valor
                : valoresProdutos4.industry_sale_year3_volume,
              valores === "R$"
                ? valoresProdutos4.industry_sale_year4_valor
                : valoresProdutos4.industry_sale_year4_volume,
              valores === "R$"
                ? valoresProdutos4.industry_sale_year1_valor
                : valoresProdutos4.industry_sale_year1_volume,
            ],
            itemStyle: {
              color: (params: any) => {
                const colors = ["#66CCFF", "#0075BA", "#FFCC00", "#FF9900"];
                return colors[params.dataIndex % colors.length];
              },
            },
            label: {
              show: true,
              position: "top",
              formatter: (params: any) => formatValue(params.value),
              rotate: 0,
            },
          },
        ],
      }
    : {};
  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={3} xs={3} sm={3}>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={4} xs={4} sm={4} />
                <Col xl={9} xs={9} sm={9}>
                  <div className="header-title">
                    <span>Projeção de Vendas: ACP</span>
                  </div>
                  <div className="header-title-sub">
                    <span></span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col xl={24} xs={24} sm={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Card
                    size="small"
                    title={`BaseLine ${yearNow}`}
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalBaseLine)}
                      />
                    </div>
                  </Card>

                  <Card
                    size="small"
                    title={`Projeção ${yearNest} `}
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalProjection)}
                      />
                    </div>
                  </Card>
                  {newYears.length > 2 && (
                    <Card
                      size="small"
                      title={`Projeção ${yearNest2} `}
                      className="card-statistic"
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{ color: "#3f8600" }}
                          value={formatValue(totalProjection2)}
                        />
                      </div>
                    </Card>
                  )}
                  <Card
                    title={`${yearNow} vs ${yearNest}`}
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            diferenca > 0
                              ? "green"
                              : diferenca < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          diferenca !== 0 && isFinite(diferenca)
                            ? `${diferenca?.toFixed(1)}%`
                            : "0%"
                        }
                        prefix={
                          diferenca > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : diferenca < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`Potencial ${yearNow}`}
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            potencialy1 > 0
                              ? "green"
                              : potencialy1 < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          potencialy1 !== 0 &&
                          potencialy1 !== undefined &&
                          potencialy1 !== null
                            ? isFinite(potencialy1)
                              ? formatValue(`${potencialy1.toFixed(1)}`)
                              : 0
                            : 0
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`Mkt Share ${yearNow}`}
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            marketShareBaseLine > 0
                              ? "green"
                              : marketShareBaseLine < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          marketShareBaseLine !== 0
                            ? isFinite(marketShareBaseLine)
                              ? formatValue(
                                  `${marketShareBaseLine?.toFixed(1)}`
                                ) + "%"
                              : "~%"
                            : "0%"
                        }
                        prefix={
                          marketShareBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : marketShareBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`Potencial ${yearNest}`}
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            potencialy2 > 0
                              ? "green"
                              : potencialy2 < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          potencialy2 !== 0 &&
                          potencialy2 !== undefined &&
                          potencialy2 !== null
                            ? isFinite(potencialy2)
                              ? formatValue(`${potencialy2?.toFixed(1)}`)
                              : 0
                            : 0
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`Mkt Share ${yearNest}`}
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industryShareTarget > 0
                              ? "green"
                              : industryShareTarget < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industryShareTarget !== 0
                            ? isFinite(industryShareTarget)
                              ? formatValue(
                                  `${industryShareTarget?.toFixed(1)}`
                                ) + "%"
                              : "~%"
                            : "0%"
                        }
                        prefix={
                          industryShareTarget > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industryShareTarget < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title="Share Regional"
                    size="small"
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industryShareLine > 0
                              ? "green"
                              : industryShareLine < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industryShareLine !== 0
                            ? `${industryShareLine?.toFixed(1)}` + "%"
                            : "0%"
                        }
                        prefix={
                          industryShareLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industryShareLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                </div>
                <div style={{ display: "flex" }}>
                  <Card title="Ano" size="small" className="card-filter">
                    <div className="report-card-content">
                      <Select
                        maxTagCount={2}
                        mode="multiple"
                        placeholder="Selecione o ano"
                        onChange={(value) => {
                          const fixedYears = ["2024/25", "2025/26"];
                          const updatedYears = [
                            ...new Set([...fixedYears, ...value]),
                          ].slice(0, 3);
                          handleChange(updatedYears);
                        }}
                        maxCount={3}
                        value={newYears}
                        style={{
                          width: "260px",
                          backgroundColor: "#fff",
                          borderRadius: "7px",
                          marginLeft: 5,
                        }}
                        options={[
                          { value: "2024/25", label: "2024/25" },
                          { value: "2025/26", label: "2025/26" },
                          { value: "2026/27", label: "2026/27" },
                        ]}
                      />
                    </div>
                  </Card>
                  <Card
                    title="Unidade de Medida"
                    size="small"
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Segmented
                        disabled={dataProfile === "RTV"}
                        options={["R$", "KG/LT"]}
                        value={valores}
                        onChange={handleValores}
                      />
                    </div>
                  </Card>
                  <Card
                    title="Cliente"
                    size="small"
                    className="card-filter"
                    extra={
                      <Dropdown
                        menu={{
                          items,
                          selectable: true,
                          defaultSelectedKeys: [filterClienteSelected],
                          onClick: (e) => {
                            setFilterClienteSelected(e.key);
                          },
                        }}
                        arrow
                        overlayClassName="dropdown-filter"
                      >
                        <Space>
                          <FilterOutlined className="icon" />
                        </Space>
                      </Dropdown>
                    }
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Cliente"
                        onChange={(value, option: any) =>
                          handleClienteChange(value, option)
                        }
                        value={filterCliente}
                        showSearch
                        defaultValue={filterCliente}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...DataCliente.sort((a: any, b: any) =>
                            compareLabelsTables("group_name")(a, b)
                          ).map((item: any) => ({
                            value: item.id,
                            label: item.group_name,
                          })),
                        ]}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title="Produto"
                    size="small"
                    className="card-filter"
                    extra={
                      <Dropdown
                        menu={{
                          items,
                          selectable: true,
                          defaultSelectedKeys: [filterProductSelected],
                          onClick: (e) => {
                            setFilterProductSelected(e.key);
                          },
                        }}
                        arrow
                        overlayClassName="dropdown-filter"
                      >
                        <Space>
                          <FilterOutlined className="icon" />
                        </Space>
                      </Dropdown>
                    }
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Produto"
                        onChange={handleProdutoChange}
                        value={Produto}
                        showSearch={true}
                        defaultValue={Produto}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...DataProduto.map((item: any) => ({
                            value: item.id,
                            label: item.product_name,
                          })),
                        ]}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </div>
                  </Card>

                  <Card
                    title={level0Label}
                    size="small"
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level0Label}
                        onChange={handlePresidenteChange}
                        value={filterPresidente}
                        showSearch
                        defaultValue={filterPresidente}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsPresidente.sort((a: any, b: any) =>
                            compareLabelsTables("level0_external_name")(a, b)
                          ).map((item: any) => ({
                            value: item.level0_id,
                            label: item.level0_external_name,
                          })),
                        ]}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </div>
                  </Card>

                  {filterPresidente !== 0 && (
                    <Card
                      title={level1Label}
                      size="small"
                      className="card-filter"
                    >
                      <div className="report-card-content">
                        <Select
                          placeholder={level1Label}
                          onChange={handleDiretoriaChange}
                          value={filterDiretoria}
                          showSearch
                          defaultValue={filterDiretoria}
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Todos" },
                            ...OptionsDiretoria.sort((a: any, b: any) =>
                              compareLabelsTables("level1_external_name")(a, b)
                            ).map((item: any) => ({
                              value: item.level1_id,
                              label: item.level1_external_name,
                            })),
                          ]}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </div>
                    </Card>
                  )}
                  {filterDiretoria !== 0 && (
                    <Card
                      title={level2Label}
                      size="small"
                      className="card-filter"
                    >
                      <div className="report-card-content">
                        <Select
                          placeholder={level2Label}
                          onChange={handleRegionChange}
                          value={filterRegional}
                          defaultValue={filterRegional}
                          showSearch
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Todos" },
                            ...OptionsRegional.sort((a: any, b: any) =>
                              compareLabelsTables("level2_external_name")(a, b)
                            ).map((item: any) => ({
                              value: item.level2_id,
                              label: item.level2_external_name,
                            })),
                          ]}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </div>
                    </Card>
                  )}
                  {filterRegional !== 0 && (
                    <>
                      <Card
                        title={level4Label}
                        size="small"
                        className="card-filter"
                      >
                        <div className="report-card-content">
                          <Select
                            placeholder={level4Label}
                            onChange={(value, option: any) =>
                              handleVendedorChange(value, option)
                            }
                            value={filterVendedor}
                            showSearch
                            defaultValue={filterVendedor}
                            style={{ width: "100%" }}
                            options={[
                              { value: 0, label: "Todos" },
                              ...OptionsVendedor.sort((a: any, b: any) =>
                                compareLabelsTables("name")(a, b)
                              ).map((item: any) => ({
                                value: item.id,
                                label: item.name,
                                l3value: item.level3_id,
                                l3name: item.level3_name,
                              })),
                            ]}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      </Card>

                      <Card
                        title={level3Label}
                        size="small"
                        className="card-filter"
                      >
                        <div className="report-card-content">
                          <Select
                            placeholder={level3Label}
                            defaultValue={{
                              label: "Todos",
                              value: 0,
                            }}
                            onChange={handleFilialChangeModal}
                            showSearch
                            value={filterFilial}
                            style={{ width: "100%" }}
                            options={[
                              { value: 0, label: "Todos" },
                              ...OptionsFilial.sort((a: any, b: any) =>
                                compareLabelsTables("level3_external_name")(
                                  a,
                                  b
                                )
                              ).map((item: any) => ({
                                label: item.level3_external_name,
                                value: item.level3_id,
                              })),
                            ]}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </div>
                      </Card>
                    </>
                  )}
                </div>
              </div>

              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  {/**<Col xl={8} xs={24}>
                    <Card style={{ width: "100%", marginBottom: 6 }}>
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configVendasBayerRegional}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen2 ? "100vh" : 400,
                            width: isFullScreen2 ? "100vw" : "100%",
                            padding: isFullScreen2 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col> */}

                  {/* Second Chart */}

                  {/* Third Chart */}
                  <Col xl={16} xs={24}>
                    <Card style={{ width: "100%", marginBottom: 6 }}>
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemRegional}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen3 ? "100vh" : 400,
                            width: isFullScreen3 ? "100vw" : "100%",
                            padding: isFullScreen3 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={8} xs={24}>
                    <Table
                      dataSource={valoresProdutos2}
                      columns={filteredColumns}
                      pagination={false}
                      style={{ width: "100%" }}
                      scroll={{ y: 323 }}
                      footer={() => (
                        <table style={{ width: "100%" }}>
                          <tbody>
                            <tr>
                              <td
                                style={{ fontWeight: "bold", width: "110px" }}
                              >
                                Total
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  width: "110px",
                                  paddingInlineStart: "20px",
                                  textAlign: "left",
                                }}
                              >
                                {formatValue(
                                  valoresProdutos2.reduce(
                                    (acc: any, item: any) => acc + item.year0,
                                    0
                                  )
                                )}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  width: "100px",
                                  paddingInlineStart: "20px",
                                  textAlign: "left",
                                }}
                              >
                                {formatValue(
                                  valoresProdutos2.reduce(
                                    (acc: any, item: any) =>
                                      acc + item.projection,
                                    0
                                  )
                                )}
                              </td>

                              <td
                                style={{
                                  fontWeight: "bold",
                                  width: "100px",
                                  paddingInlineStart: "20px",
                                  textAlign: "left",
                                }}
                              >
                                {formatValue(
                                  valoresProdutos2.reduce(
                                    (acc: any, item: any) => acc + item.year5,
                                    0
                                  )
                                )}
                              </td>
                              <td
                                style={{
                                  fontWeight: "bold",
                                  width: "100px",
                                  paddingInlineStart: "20px",
                                  textAlign: "center",
                                }}
                              />
                            </tr>
                          </tbody>
                        </table>
                      )}
                    />
                  </Col>
                </Row>

                {/* Bottom Chart - Full Width */}
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={16} xs={16}>
                    <Card style={{ height: 500, width: "100%" }}>
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingRegional ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : valoresProdutos3.length > 0 ? (
                          <ReactEcharts
                            option={configClient}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen ? "100vh" : 400,
                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {" Não há Dados para esse Filtro."}
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={8} xs={8}>
                    <Card style={{ height: 500, width: "100%" }}>
                      <div
                        className={
                          isFullScreen5
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configTerritorio}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen5 ? "100vh" : 400,
                            width: isFullScreen5 ? "100vw" : "100%",
                            padding: isFullScreen5 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen5
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen5 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen5}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={24} xs={24}>
                    <Card style={{ height: 500, width: "100%" }}>
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingRegional ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : valoresProdutos3.length > 0 ? (
                          <ReactEcharts
                            option={configFilial}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen ? "100vh" : 400,
                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {" Não há Dados para esse Filtro."}
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <br></br>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
