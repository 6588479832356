import {
  Row,
  Col,
  Card,
  Table,
  Button,
  message,
  Typography,
  Input,
} from "antd";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { TableExpansion } from "./TableExpansion";
import { useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useAppSelector,
  useButtonActiveYears,
  useParameterName,
} from "../../../hooks";

import TopDownCompanyService from "../../../services/SalesPlanning/TopDownCompany";
import { InputNumberTable } from "../../../components/TableDinamic/InputNumber";

import { Header, Buttons } from "./styles";
import { formatBR, formatPercent } from "../../../utils";
import { FiFilter, FiSearch, FiSettings } from "react-icons/fi";
import { NoticeType } from "antd/lib/message/interface";
const { Column, ColumnGroup } = Table;

interface TotalProps {
  channel_base_line: number;
  channel_cagr: number;
  channel_year1: number;
  channel_year2: number;
  channel_year3: number;
  channel_year4: number;
  channel_year5: number;
  industry_base_line: number;
  industry_cagr: number;
  industry_year1: number;
  industry_year2: number;
  industry_year3: number;
  industry_year4: number;
  industry_year5: number;
  market_growth: number;
  potential_base_line: number;
  potential_year1: number;
  potential_year2: number;
  potential_year3: number;
  potential_year4: number;
  potential_year5: number;
  soc_base_line: number;
  soc_year1: number;
  soc_year2: number;
  soc_year3: number;
  soc_year4: number;
  soc_year5: number;
}
export function TopDownCompany() {
  const parameters = useAppSelector((state) => state.entities.parameter);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const years = useParameterName("QTD_YEARS");
  const [status, setStatus] = useState<string>();
  if (status === "F") {
    var newStatus = "Reabrir";
    var disabled = true;
  } else {
    var newStatus = "Finalizar";
    var disabled = false;
  }
  const [loading, setLoading] = useState(false);
  const [param, setParam] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState<TotalProps | undefined>();
  const [year0, setYear0] = useState<string | undefined>("");
  const [year0Active, setYear0Active] = useState<boolean>(true);
  const [year1, setYear1] = useState<string | undefined>("");
  const [year1Active, setYear1Active] = useState<boolean>(
    useButtonActiveYears(2)
  );
  const [year2, setYear2] = useState<string | undefined>("");
  const [year2Active, setYear2Active] = useState<boolean>(
    useButtonActiveYears(3)
  );
  const [year3, setYear3] = useState<string | undefined>("");
  const [year3Active, setYear3Active] = useState<boolean>(
    useButtonActiveYears(4)
  );
  const [year4, setYear4] = useState<string | undefined>("");
  const [year4Active, setYear4Active] = useState<boolean>(
    useButtonActiveYears(5)
  );
  const [year5, setYear5] = useState<string | undefined>("");
  const [year5Active, setYear5Active] = useState<boolean>(
    useButtonActiveYears(6)
  );
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  useEffect(() => {
    TopDownCompanyStatus();
    setYear0(
      parameters.find((value:any) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value:any) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value:any) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value:any) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value:any) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value:any) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);
  async function TopDownCompanyStatus() {
    try {
      const response = await TopDownCompanyService.getTableCompanyStatus(
        companyId
      );
      setStatus(response.pgv_topdown_company);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
    }
  }
  const loadTableCompany = useCallback(
    async (load: boolean) => {
      try {
        setLoading(load);
        const response = await TopDownCompanyService.getTableCompany(companyId);
        setData(response.records);
        setTotal(response.total);
      } catch (err) {
        ShowMSG("error",err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  useEffect(() => {
    loadTableCompany(true);
  }, [loadTableCompany]);

  async function onSave(data: any) {
    const body = {
      company_id: companyId,
      business_type_id: data.records.business_type_id,
      channel_base_line: data.records.channel_base_line,
      industry_base_line: data.records.industry_base_line,
      field_changed: data.name,
      new_value: data.value,
    };
    await TopDownCompanyService.postTableCompany(companyId, body);

    loadTableCompany(false);
  }

  async function onChange(search: string) {
    try {
      setLoading(true);
      const response = await TopDownCompanyService.searchTableCompany(
        companyId,
        search
      );
      setData(response.records);
      setTotal(response.total);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoading(false);
    }
  }
  function renderInput(record: number, records: object, field: string) {
    return (
      <InputNumberTable
        disbled
        onSave={onSave}
        records={records}
        name={field}
        initialValue={record}
      />
    );
  }
  const { Title } = Typography;
  return (
    <Breadcrumb
      path="Projeções Empresa"
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
        },
      ]}
    >
      {contextHolder}
        <>
    <Row align="middle" style={{ marginBottom: 16 }}>
        {/* Breadcrumb on the left */}
      

        {/* Title in the center */}
        <Col  style={{ textAlign: "center", flexGrow: 1, background:"none" }}>
          <Title level={4} style={{ paddingBottom:"5px"  }}>Projeções Empresa</Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Header>
                  <Buttons>
                    <Row>
                      <Col>
                      <div style={{ display: "flex", flexWrap: "wrap",marginBottom: 16 }}>
                        <Button
                          disabled
                          onClick={() => setYear0Active(!year0Active)}
                          type={year0Active ? "primary" : "default"}
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 1
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year0}
                        </Button>
                        <Button
                          onClick={() => setYear1Active(!year1Active)}
                          type={year1Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 2
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year1}
                        </Button>
                        <Button
                          onClick={() => setYear2Active(!year2Active)}
                          type={year2Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 3
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year2}
                        </Button>
                        <Button
                          onClick={() => setYear3Active(!year3Active)}
                          type={year3Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 4
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year3}
                        </Button>
                        <Button
                          onClick={() => setYear4Active(!year4Active)}
                          type={year4Active ? "primary" : "default"}
                          style={{
                            borderRadius: 0,
                            display:
                              years?.value && parseInt(years.value) >= 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year4}
                        </Button>
                        <Button
                          onClick={() => setYear5Active(!year5Active)}
                          type={year5Active ? "primary" : "default"}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            display:
                              years?.value && parseInt(years.value) > 5
                                ? "inline"
                                : "none",
                          }}
                        >
                          {year5}
                        </Button>
                        </div>
                      </Col>
                    </Row>
                  </Buttons>
                  <Input
                    allowClear
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Pesquisar"
                    prefix={<FiSearch color="#00CC66" />}
                    suffix={<FiFilter color="#00CC66" />}
                    style={{ width: 350 }}
                  />
                </Header>
              </Col>
              <Col span={24}>
                <Table
                  rowKey={(record: any) =>
                    record.business_type_id + record.potential_base_line
                  }
                  loading={{ indicator: Icon, spinning: loading }}
                  scroll={{ y: "100vw", x: "100vw" }}
                  size="small"
                  pagination={false}
                  dataSource={data}
                  summary={() => {
                    return (
                      <Table.Summary.Row style={{ background: "#f5f5f5" }}>
                        <Table.Summary.Cell colSpan={param ? 3 : 1} index={0}>
                          <b>Total</b>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="right">
                          <Typography.Text>
                            <b>
                              {total?.potential_base_line &&
                                formatBR(total?.potential_base_line)}
                            </b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        {year1Active && (
                          <Table.Summary.Cell index={2} align="right">
                            <Typography.Text>
                              <b>
                                {total?.potential_year1 &&
                                  formatBR(total?.potential_year1)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year2Active && (
                          <Table.Summary.Cell index={3} align="right">
                            <Typography.Text>
                              <b>
                                {total?.potential_year2 &&
                                  formatBR(total?.potential_year2)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year3Active && (
                          <Table.Summary.Cell index={4} align="right">
                            <Typography.Text>
                              <b>
                                {" "}
                                {total?.potential_year3 &&
                                  formatBR(total?.potential_year3)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year4Active && (
                          <Table.Summary.Cell index={5} align="right">
                            <Typography.Text>
                              <b>
                                {total?.potential_year4 &&
                                  formatBR(total?.potential_year4)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year5Active && (
                          <Table.Summary.Cell index={6} align="right">
                            <Typography.Text>
                              <b>
                                {total?.potential_year5 &&
                                  formatBR(total?.potential_year5)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={7} align="right">
                          <Typography.Text>
                            <b>
                              {" "}
                              {total?.channel_base_line &&
                                formatBR(total?.channel_base_line)}
                            </b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        {year1Active && (
                          <Table.Summary.Cell index={8} align="right">
                            <Typography.Text>
                              <b>
                                {total?.channel_year1 &&
                                  formatPercent(total?.channel_year1)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year2Active && (
                          <Table.Summary.Cell index={9} align="right">
                            <Typography.Text>
                              <b>
                                {total?.channel_year2 &&
                                  formatPercent(total?.channel_year2)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year3Active && (
                          <Table.Summary.Cell index={10} align="right">
                            <Typography.Text>
                              <b>
                                {total?.channel_year3 &&
                                  formatPercent(total?.channel_year3)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year4Active && (
                          <Table.Summary.Cell index={11} align="right">
                            <Typography.Text>
                              <b>
                                {total?.channel_year4 &&
                                  formatPercent(total?.channel_year4)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year5Active && (
                          <Table.Summary.Cell index={12} align="right">
                            <Typography.Text>
                              <b>
                                {total?.channel_year5 &&
                                  formatPercent(total?.channel_year5)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={13} align="right">
                          <Typography.Text>
                            <b>
                              {total?.industry_base_line &&
                                formatBR(total?.industry_base_line)}
                            </b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        {year1Active && (
                          <Table.Summary.Cell index={14} align="right">
                            <Typography.Text>
                              <b>
                                {total?.industry_year1 &&
                                  formatBR(total?.industry_year1)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year2Active && (
                          <Table.Summary.Cell index={15} align="right">
                            <Typography.Text>
                              <b>
                                {total?.industry_year2 &&
                                  formatBR(total?.industry_year2)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year3Active && (
                          <Table.Summary.Cell index={16} align="right">
                            <Typography.Text>
                              <b>
                                {total?.industry_year3 &&
                                  formatBR(total?.industry_year3)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year4Active && (
                          <Table.Summary.Cell index={17} align="right">
                            <Typography.Text>
                              <b>
                                {total?.industry_year4 &&
                                  formatBR(total?.industry_year4)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year5Active && (
                          <Table.Summary.Cell index={18} align="right">
                            <Typography.Text>
                              <b>
                                {total?.industry_year5 &&
                                  formatBR(total?.industry_year5)}
                              </b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={19} align="right">
                          <Typography.Text>
                            <b>{formatPercent(total?.soc_base_line)}%</b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        {year1Active && (
                          <Table.Summary.Cell index={20} align="right">
                            <Typography.Text>
                              <b>{formatPercent(total?.soc_year1)}%</b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year2Active && (
                          <Table.Summary.Cell index={21} align="right">
                            <Typography.Text>
                              <b>{formatPercent(total?.soc_year2)}%</b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year3Active && (
                          <Table.Summary.Cell index={22} align="right">
                            <Typography.Text>
                              <b>{formatPercent(total?.soc_year3)}%</b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year4Active && (
                          <Table.Summary.Cell index={23} align="right">
                            <Typography.Text>
                              <b>{formatPercent(total?.soc_year4)}%</b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        {year5Active && (
                          <Table.Summary.Cell index={24} align="right">
                            <Typography.Text>
                              <b>{formatPercent(total?.soc_year5)}%</b>
                            </Typography.Text>
                          </Table.Summary.Cell>
                        )}
                        <Table.Summary.Cell index={25} align="right">
                          <Typography.Text>
                            <b>{formatPercent(total?.channel_cagr)}%</b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={26} align="right">
                          <Typography.Text>
                            <b>{formatPercent(total?.industry_cagr)}%</b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={27} align="right">
                          <Typography.Text>
                            <b>{formatPercent(total?.market_growth)}%</b>
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                >
                  <Column
                    title={() => {
                      return (
                        <Row align="middle" justify="space-between">
                          <Col>Negócio</Col>
                          <Col>
                            <FiSettings
                              style={{ cursor: "pointer" }}
                              onClick={() => setParam(!param)}
                              size={16}
                              color="#FFF"
                            />
                          </Col>
                        </Row>
                      );
                    }}
                    dataIndex="business_type_name"
                    width={120}
                    fixed="left"
                  />

                  {param && (
                    <ColumnGroup title="Parametros">
                      <Column
                        title="Canal Cagr"
                        dataIndex="channel_param_cagr"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <InputNumberTable
                                onSave={onSave}
                                disbled={disabled}
                                records={records}
                                name="channel_param_cagr"
                                initialValue={record}
                              />
                            </div>
                            <div>%</div>
                          </div>
                        )}
                      />
                      <Column
                        title="Industria Cagr"
                        dataIndex="industry_param_cagr"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <InputNumberTable
                                onSave={onSave}
                                disbled={disabled}
                                records={records}
                                name="industry_param_cagr"
                                initialValue={record}
                              />
                            </div>
                            <div>%</div>
                          </div>
                        )}
                      />
                    </ColumnGroup>
                  )}

                  <ColumnGroup title="Potencial" className="color">
                    {year0Active ? (
                      <Column
                        className="color"
                        title={`${year0}`}
                        align="right"
                        dataIndex="potential_base_line"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year1Active ? (
                      <Column
                        className="color"
                        title={year1}
                        align="right"
                        dataIndex="potential_year1"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year2Active ? (
                      <Column
                        className="color"
                        title={year2}
                        align="right"
                        dataIndex="potential_year2"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year3Active ? (
                      <Column
                        className="color"
                        title={year3}
                        align="right"
                        dataIndex="potential_year3"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year4Active ? (
                      <Column
                        className="color"
                        title={year4}
                        align="right"
                        dataIndex="potential_year4"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year5Active ? (
                      <Column
                        className="color"
                        title={year5}
                        align="right"
                        dataIndex="potential_year5"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                  </ColumnGroup>
                  <ColumnGroup title="Canal">
                    {year0Active ? (
                      <Column
                        title={`${year0}`}
                        align="right"
                        dataIndex="channel_base_line"
                        width={120}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year1Active ? (
                      <Column
                        title={year1}
                        align="right"
                        dataIndex="channel_year1"
                        width={120}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="channel_year1"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year2Active ? (
                      <Column
                        title={year2}
                        align="right"
                        dataIndex="channel_year2"
                        width={120}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="channel_year2"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year3Active ? (
                      <Column
                        title={year3}
                        align="right"
                        dataIndex="channel_year3"
                        width={120}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="channel_year3"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year4Active ? (
                      <Column
                        title={year4}
                        align="right"
                        dataIndex="channel_year4"
                        width={120}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="channel_year4"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year5Active ? (
                      <Column
                        title={year5}
                        align="right"
                        dataIndex="channel_year5"
                        width={120}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="channel_year5"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </ColumnGroup>
                  <ColumnGroup title="Industria" className="color">
                    {year0Active ? (
                      <Column
                        className="color"
                        title={`${year0}`}
                        align="right"
                        dataIndex="industry_base_line"
                        width={150}
                        render={(record: number) => formatBR(record)}
                      />
                    ) : (
                      <></>
                    )}
                    {year1Active ? (
                      <Column
                        className="color"
                        title={year1}
                        align="right"
                        dataIndex="industry_year1"
                        width={150}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="industry_year1"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year2Active ? (
                      <Column
                        className="color"
                        title={year2}
                        align="right"
                        dataIndex="industry_year2"
                        width={150}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="industry_year2"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year3Active ? (
                      <Column
                        className="color"
                        title={year3}
                        align="right"
                        dataIndex="industry_year3"
                        width={150}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="industry_year3"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year4Active ? (
                      <Column
                        className="color"
                        title={year4}
                        align="right"
                        dataIndex="industry_year4"
                        width={150}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="industry_year4"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                    {year5Active ? (
                      <Column
                        className="color"
                        title={year5}
                        align="right"
                        dataIndex="industry_year5"
                        width={150}
                        render={(record: number, records: object) => (
                          <InputNumberTable
                            onSave={onSave}
                            disbled={disabled}
                            records={records}
                            name="industry_year5"
                            initialValue={record}
                          />
                        )}
                      />
                    ) : (
                      <></>
                    )}
                  </ColumnGroup>
                  <ColumnGroup title="Soc">
                    {year0Active ? (
                      <Column
                        title={`${year0}`}
                        align="right"
                        dataIndex="soc_base_line"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_base_line")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                    {year1Active ? (
                      <Column
                        title={year1}
                        align="right"
                        dataIndex="soc_year1"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_year1")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                    {year2Active ? (
                      <Column
                        title={year2}
                        align="right"
                        dataIndex="soc_year2"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_year2")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                    {year3Active ? (
                      <Column
                        title={year3}
                        align="right"
                        dataIndex="soc_year3"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_year3")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                    {year4Active ? (
                      <Column
                        title={year4}
                        align="right"
                        dataIndex="soc_year4"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_year4")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                    {year5Active ? (
                      <Column
                        title={year5}
                        align="right"
                        dataIndex="soc_year5"
                        width={120}
                        render={(record: number, records: object) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              {renderInput(record, records, "soc_year5")}
                            </div>
                            <div>%</div>
                          </div>
                        )}
                        //  render={(record: number) => `${formatPercent(record)}%`}
                      />
                    ) : (
                      <></>
                    )}
                  </ColumnGroup>
                  <ColumnGroup title="Cagr" className="color">
                    <Column
                      className="color"
                      title="Canal"
                      align="right"
                      dataIndex="channel_cagr"
                      width={120}
                      render={(record: number) => `${formatPercent(record)}%`}
                    />
                    <Column
                      className="color"
                      title="Industria"
                      align="right"
                      dataIndex="industry_cagr"
                      width={120}
                      render={(record: number) => `${formatPercent(record)}%`}
                    />
                    <Column
                      className="color"
                      title="Mercado"
                      dataIndex="market_growth"
                      width={120}
                      align="right"
                      render={(record: number) => `${formatPercent(record)}%`}
                    />
                  </ColumnGroup>
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} style={{ marginBottom: 30 }}>
          <TableExpansion />
        </Col>
      </Row>
      </>
    </Breadcrumb>
  );
}
