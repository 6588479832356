import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Button,
  message,
  Modal,
  Typography,
  Tooltip,
} from "antd";
import { createRef, RefObject, useCallback, useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { FormInstance } from "antd/es/form";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import WalletService from "../../../services/Global/Wallet";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import UpdateSellerService from "../../../services/Global/UpdateSeller";
import { NoticeType } from "antd/lib/message/interface";
export function UpdateSeller() {
  const dispatch = useAppDispatch();
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [filter, setFilter] = useState(true);
  const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [nomeVendedor, setNomeVendedor] = useState<any>(undefined);
  const [nomeVendedorPara, setNomeVendedorPara] = useState<any>(undefined);
  const [data, setData] = useState<any>(undefined);
  const [level4, setLevel4] = useState<any>();
  const [level4_Para, setLevel4_Para] = useState<any>();
  const [contar, setContar] = useState<any>(0);
  const [showModal, setShowModal] = useState(false);

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  useEffect(() => {
    const body = {
      level4_de: level4,
      level4_para: level4_Para,
    };
    if (level4_Para !== undefined) {
      console.log("de" + level4);
      try {
        setTimeout(() => {
          UpdateSellerService.PostUpSeller(companyId, body);
          ShowMSG(
            "success",
            "Transferencia de dados entre vendedores realizada com sucesso!"
          );
          loadTableProduct(level4);
          setNomeVendedorPara(undefined);
        }, 2000);
      } catch (err) {
        ShowMSG("error", err.response.data.message);
      }
    }
  }, [contar]);
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "Cod. Grupo",
      dataIndex: "customer_group_id",
      key: "customer_group_id",
      sorter: (a: any, b: any) => a.customer_group_id - b.customer_group_id,
    },
    {
      title: "Grupo",
      dataIndex: "group_name",
      key: "group_name",
      sorter: (a: any, b: any) => a.group_name.localeCompare(b.group_name),
    },
    {
      title: "Cod. Cliente",
      dataIndex: "customer_id",
      key: "customer_id",
      sorter: (a: any, b: any) => a.customer_id - b.customer_id,
    },
    {
      title: "Categoria Cliente",
      dataIndex: "customer_category",
      key: "customer_category",
      sorter: (a: any, b: any) =>
        a.customer_category.localeCompare(b.customer_category),
    },
    {
      title: "Cliente",
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a: any, b: any) =>
        a.customer_name.localeCompare(b.customer_name),
    },

    {
      title: "Cidade",
      dataIndex: "customer_city_name",
      key: "customer_city_id",
      sorter: (a: any, b: any) =>
        a.customer_city_name.localeCompare(b.customer_city_name),
    },
    {
      title: "UF",
      dataIndex: "customer_state",
      key: "customer_state",
      sorter: (a: any, b: any) =>
        a.customer_state.localeCompare(b.customer_state),
    },
  ];
  async function WalletMappingStatus(level4: number) {
    try {
      const response = await WalletService.getWalletStatus(companyId, level4);
      console.log("de" + response.level4_external_name);
      setNomeVendedor(response.level4_external_name);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  async function WalletMappingStatusPara(level4: number) {
    try {
      const response = await WalletService.getWalletStatus(companyId, level4);
      console.log("para" + response.level4_external_name);
      setNomeVendedorPara(response.level4_external_name);
    } catch (err) {
      ShowMSG("error", err.response.data.message);
    }
  }
  const loadTableProduct = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        setData(undefined);
        const response = await WalletService.getWallet(companyId, id);
        setData(response);
      } catch (err) {
        ShowMSG("error", err.response.data.message);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    loadTableProduct(obj.level4);
    WalletMappingStatus(obj.level4);
    // setFilter(false);
  }
  async function onFinishPara(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    setLevel4_Para(obj.level4);
    WalletMappingStatusPara(obj.level4);
  }

  async function handleTransfer() {
    setContar(contar + 1);
    setShowModal(false);
  }

  const { Title } = Typography;

  return (
    <Breadcrumb
      path="Tranferir Clientes"
      actions={[
        {
          className: level4,
        },
      ]}
      child={nomeVendedor}
    >
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Tranferir Clientes
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {filter && (
            <Col span={4}>
              <Card bordered={false}>
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  initialValues={commercialStructure}
                >
                  <CommercialStructure formRef={formRef} level={4} />
                  <Button htmlType="submit" type="primary" block>
                    Buscar
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
          <Col span={filter ? 20 : 20}>
            <Card bordered={false}>
              <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col
                  span={filter ? 2.5 : 2}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Buttons>
                    {filter ? (
                      ""
                    ) : (
                      // <Tooltip title="Fechar Menu">
                      //   <Button
                      //     type="text"
                      //     style={{ marginRight: 16 }}
                      //     onClick={() => setFilter(!filter)}
                      //     icon={
                      //       <FiX
                      //         size={25}
                      //         color="#00CC66"
                      //         style={{
                      //           margin: "15px 10px 10px 10px",
                      //           cursor: "pointer",
                      //         }}
                      //       />
                      //     }
                      //   />
                      // </Tooltip>
                      <Tooltip title="Abrir Menu">
                        <Button
                          type="text"
                          style={{ marginRight: 16 }}
                          onClick={() => setFilter(!filter)}
                          icon={
                            <FiMenu
                              size={25}
                              color="#00CC66"
                              style={{
                                margin: "15px 10px 10px 10px",
                                cursor: "pointer",
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    )}
                  </Buttons>
                </Col>
                <Col span={1}>
                  <Tooltip title={filter ? "Abrir Menu" : "Fechar Menu"}>
                    <Button
                      onClick={() => setFilter(!filter)}
                      type="text"
                      style={{ marginRight: 16 }}
                      icon={
                        !filter ? (
                          <FiX
                            color="#00CC66"
                            size={25}
                            style={{
                              margin: "5px 10px 0px 10px",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <FiMenu
                            size={25}
                            color="#00CC66"
                            style={{
                              margin: "15px 10px 10px 10px",
                              cursor: "pointer",
                            }}
                          />
                        )
                      }
                    ></Button>
                  </Tooltip>
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={data}
                    loading={{ indicator: Icon, spinning: loading }}
                  />
                </Col>
              </Row>
            </Card>
            <Modal
              open={showModal}
              okText={"CONFIRMAR"}
              onOk={() => handleTransfer()}
              onCancel={() => setShowModal(false)}
            >
              <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col span={24}>
                  <ExclamationCircleOutlined /> CONFIRMAR
                  <Card bordered={false}>
                    Deseja realmente tranferir os Dados do vendedor{" "}
                    {nomeVendedor} para o vendedor {nomeVendedorPara} ?
                  </Card>
                </Col>
              </Row>
            </Modal>
          </Col>
          {!filter && (
            <Col span={4}>
              <Card bordered={false}>
                <Form onFinish={onFinishPara}>
                  <CommercialStructure formRef={formRef} level={4} />

                  <Button
                    htmlType="submit"
                    type="primary"
                    block
                    onClick={() => setShowModal(true)}
                  >
                    Transferir
                  </Button>
                </Form>
              </Card>
              {contextHolder}
            </Col>
          )}
          <Col span={7}></Col>
        </Row>
      </>
    </Breadcrumb>
  );
}
