import { useEffect, useState } from "react";
import { Select, Form } from "antd";
import { compareLabels, useAppSelector, useParameterName } from "../../../../hooks";
interface Level4Props {
  loading: boolean;
}
interface Level4ValueProps {
  label: string;
  value: number;
}

export function Level4({ loading }: Level4Props) {
  const recordsLevel4 = useAppSelector(
    (state) => state.entities.commercialStructure.level4
  );
  const labelLevel4 = useParameterName("LEVEL4_LABEL");
  const [level4, setLevel4] = useState<Level4ValueProps[]>([]);

  useEffect(() => {
    if (recordsLevel4) {
      const array = recordsLevel4.map((level) => {
        return {
          label: level?.level4_external_name,
          value: level?.level4_id,
        };
      });
      setLevel4(array);
    } else {
      setLevel4([]);
    }
  }, [recordsLevel4]);

  return (
    <Form.Item name="level4" rules={[{ required: true, message: "" }]}>
      <Select
        disabled={!level4.length}
        loading={loading}
        placeholder={labelLevel4?.value}
        allowClear
      >
        {level4.sort(compareLabels).map((type) => (
          <Select.Option key={type.value} value={type.value}>
            {type.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}
