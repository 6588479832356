import { Routes as RoutesDom, Route, BrowserRouter } from "react-router-dom";
import { AuthRoutes } from "../modules/Auth/Routes";
import { ParametersRoutes } from "../modules/Parameters/Routes";
import { CustomerManagementRoutes } from "../modules/Pgc/Routes";
import { SalesPlanningRoutes } from "../modules/SalesPlanning/Routes";
import { RebateRoutes } from "../modules/Rebate/Routes";
import { SetupRoutes } from "../modules/Setup/Routes";
import { useAppSelector } from "../hooks";

import { Layout } from "../components/Layout";
import { useEffect } from "react";
import { loadEntities } from "../entities";
import { Affix, Alert, Button, Popover } from "antd";
import { CommentOutlined, WhatsAppOutlined } from "@ant-design/icons";

export function Routes() {
  const { autorized } = useAppSelector((state) => state.auth);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const user = useAppSelector((state) => state.auth.user);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const currentHour = new Date().getHours();
  const isOutsideBusinessHours = currentHour < 8 || currentHour >= 18;
  const content = (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {isOutsideBusinessHours && (
        <div style={{ width: 300 }}>
          <Alert
            banner
            description={
              <div style={{ width: 300, marginLeft: "-50px" }}>
                <b>Atendemos apenas em horário comercial:</b>
                <p>
                  De segunda a sexta-feira: das 08:00 às 18:00. Sábados,
                  domingos e feriados: não realizamos atendimento. Se precisar
                  de algo fora desse horário, entre em contato durante o
                  próximo período útil.
                </p>
              </div>
            }
            message={"Horário de atendimento"}
          />
        </div>
      )}
      <Button
        icon={<CommentOutlined />}
        type="primary"
        onClick={() => {
          const message = encodeURIComponent(
            "> Tipo: `Suporte` \n\n" +
              "> Empresa: `" +
              companyName +
              "`\n" +
              "> Usuário: `" +
              user.name +
              "`\n" +
              "> ID do Usuário: `" +
              idUser +
              "`\n" +
              "> Tela: `" +
              window.location.pathname +
              "` \n \n \n \n \n" +
              `Olá, preciso de ajuda com Suporte.\n`
          );
          window.open(
            `https://wa.me/send?phone=553498072991&text=${message}`,
            "_blank"
          );
        }}
      >
        Suporte
      </Button>
    </div>
  );
  useEffect(() => {
    (async () => {
      if (autorized && companyId) await loadEntities(companyId);
    })();
  }, [companyId, autorized]);

  function publicRoutes() {
    return (
      <BrowserRouter>
        <RoutesDom>
          {AuthRoutes.map((props, index) => (
            <Route key={index} {...props} />
          ))}
        </RoutesDom>
      </BrowserRouter>
    );
  }

  function privateRoutes() {
    return (
      <BrowserRouter>
        <Layout>
          <>
            <Affix
              style={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}
            >
              <Popover content={content} title="Contatos" placement="topLeft">
                <Button
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: "50px 50px 10px 50px",
                  }}
                  icon={
                    <WhatsAppOutlined style={{ fontSize: 30, padding: 10 }} />
                  }
                  type="primary"
                />
              </Popover>
            </Affix>
            <RoutesDom>
              {ParametersRoutes.map((props, index) => (
                <Route key={index} {...props} />
              ))}
              {CustomerManagementRoutes.map((props, index) => (
                <Route key={index} {...props} />
              ))}
              {SalesPlanningRoutes.map((props, index) => (
                <Route key={index} {...props} />
              ))}
              {RebateRoutes.map((props, index) => (
                <Route key={index} {...props} />
              ))}
              {SetupRoutes.map((props, index) => (
                <Route key={index} {...props} />
              ))}
            </RoutesDom>
          </>
        </Layout>
      </BrowserRouter>
    );
  }

  return autorized ? privateRoutes() : publicRoutes();
}
