import {
  Button,
  Card,
  Col,
  Input,
  Radio,
  Row,
  Table,
  Modal,
  Tag,
  Form,
  Select,
  Typography,
  Popconfirm,
  Tooltip,
  message,
  Image,
  Upload,
} from "antd";
import moment from "moment";
import {
  createRef,
  useEffect,
  useState,
  RefObject,
  memo,
  useCallback,
} from "react";
import {
  FiEdit2,
  FiFilter,
  FiMenu,
  FiPlus,
  FiTrash,
  FiSearch,
  FiX,
} from "react-icons/fi";
import imageCompression from "browser-image-compression";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  compareLabelsTables,
  useAppDispatch,
  useAppSelector,
} from "../../../hooks";
import { FormInstance } from "antd/es/form";
import ActionPlanService from "../../../services/Pgc/ActionPlan";
import { Buttons } from "./styles";
import {
  resetFilter,
  setFiltercommercialStucture,
} from "../../SalesPlanning/Reducer";
import { StyledDatePicker } from "../../SalesPlanning/ActionPlan/styles";
import { Actions } from "../CustomerMapping/styles";
import { UploadFile } from "antd/lib";
import Industry from "../../../services/Industry";
import { NoticeType } from "antd/lib/message/interface";

const { Option } = Select;
const url = window.location.origin.split("/").slice(0, 3).join("/");
const urlMap: { [key: string]: string } = {
  "https://sistema-dev.cropland.com.br": "-dev",
  "https://sistema-hml.cropland.com.br": "-hml",
  "https://sistema.cropland.com.br": "",
};
export function ActionPlan() {
  const dispatch = useAppDispatch();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const user_id = useAppSelector((state) => state.auth.user.id);
  const [profile, setDataProfile] = useState<any>({});
  const [modalNewAction, setModalNewAction] = useState(false);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [status, setStatus] = useState<any>();
  const [level4, setLevel4] = useState<any>(undefined);
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [searchData, setSearchData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);
  const [filter, setFilter] = useState(false);
  const [initiative_id, setInitiative_id] = useState<any>();
  const [initial_date, setInitial_date] = useState<any>(null);
  const [final_date, setFinal_date] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [status_value, setStatus_value] = useState<any>();
  const [initiatives, setInitiatives] = useState<any[]>([]);
  const [customer_id, setCustomer_id] = useState<any[]>([]);
  const [id, setId] = useState<any>(null);
  const [data_customer, setData_customer] = useState<any[]>([]);
  const [level0Name, setlevel0Name] = useState<any>("");
  const [level0Value, setlevel0Value] = useState<any>("");
  const [modalFileView, setModalFileView] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [typeFile, setTypeFile] = useState("");
  const [fileExists, setFileExists] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleChangeAward = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = Number(value) / 100; // Convert to decimal value
    console.log(formattedValue);
    setlevel0Name(formattedValue);
    setlevel0Value(
      formattedValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    ); // Format as currency
  };

  const newUrl = urlMap[url] ?? "-dev";
const [messageApi, contextHolder] = message.useMessage();

    const ShowMSG = (type: NoticeType, test: string) => {
      messageApi.open({
        type,
        content: test,
      });
    };
  const getProfile = async () => {
    const response2 = await Industry.getProfile(companyId, user_id);
    setDataProfile(response2[0].profile);
  };
  if (status === "F") {
    var newStatus = "Reabrir";
  } else {
    var newStatus = "Finalizar";
  }

  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: "Tarefa",
      dataIndex: "description",
      key: "description",
      sorter: compareLabelsTables("description"),
    },
    {
      title: "Atividade",
      dataIndex: "Atividade",
      key: "Atividade",
      sorter: compareLabelsTables("Atividade"),
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
      sorter: compareLabelsTables("cliente"),
    },
    {
      title: "Data de início",
      dataIndex: "data_inicio",
      key: "data_inicio",
      sorter: (a: any, b: any) =>
        (a.data_inicio ?? "").localeCompare(b.data_inicio ?? ""),
    },
    {
      title: "Data de entrega",
      dataIndex: "data_fim",
      key: "data_fim",
      sorter: (a: any, b: any) =>
        (a.data_fim ?? "").localeCompare(b.data_fim ?? ""),
    },
    {
      title: "Valor Ação",
      dataIndex: "action_value",
      key: "action_value",
      sorter: (a: any, b: any) => (a.action_value ?? 0) - (b.action_value ?? 0),
      render: (text: string | null) => {
        return text
          ? Number(text).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) =>
        (a.status ?? "").localeCompare(b.status ?? ""),
      render: (text: string) => {
        return (
          <Tag color={getTagColor(text ?? "")}>{getTagText(text ?? "")}</Tag>
        );
      },
    },
    {
      title: "Arquivos",
      dataIndex: "id",
      key: "id",
      width: 210,
      align: "center" as const,
      render: (id: number) => !modalNewAction && <FileColumn id={id | 0} />,
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: any) => {
        return (
          <Actions>
            <Button
              type="text"
              onClick={() => handleClickEdit(record)}
              icon={
                <FiEdit2
                  size={18}
                  color={status === "F" ? "#9b9b9b" : "#00CC66"}
                  style={{
                    cursor: status === "F" ? "no-drop" : "pointer",
                  }}
                />
              }
            />

            <Popconfirm
              title="Tem certeza que deseja excluir?"
              onConfirm={() => DeleteActionPlan(record.id)}
              disabled={status === "F" ? true : false}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <Button
                type="text"
                icon={
                  <FiTrash
                    size={18}
                    color={status === "F" ? "#9b9b9b" : "#00CC66"}
                    style={{ cursor: status === "F" ? "no-drop" : "pointer" }}
                  />
                }
              />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];

  async function DeleteActionPlan(value: any) {
    try {
      await ActionPlanService.DeleteActionPlan(companyId, value);
      FindData(level4);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleClickEdit(task: any) {
    if (status === "A") {
      setDescription(task.description);
      setStatus_value(task.status);
      setCustomer_id(task.customer_id);
      setId(task.key);
      setInitiative_id(task.initiative_id);
      setInitial_date(task.initial_date ? moment(task.initial_date) : null);
      setFinal_date(task.final_date ? moment(task.final_date) : null);
      const formattedValue =
        Number(
          task.action_value ? String(task.action_value).replace(/\D/g, "") : 0
        ) / 100;
      setlevel0Name(formattedValue);
      setlevel0Value(
        formattedValue.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
      );
      setModalNewAction(true);
      const filespgc = await findFile(task.id);
      setFileExists(filespgc?.files?.length > 0);
      setFileList(filespgc?.files || []);
    }
  }

  useEffect(() => {
    findAllInitiatives();
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);
  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return " Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }
  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }
  async function FindData(level4_id: number) {
    try {
      setLoading(true);
      const response = await ActionPlanService.getFindAll(companyId, level4_id);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          ...rawTask,
          key: rawTask.id,
          level4: rawTask.level4.level4_external_name,
          cliente: rawTask.customer.customer_name,
          initiative_id: rawTask.initiative_id,
          data_inicio: moment(rawTask.initial_date).format("DD/MM/YYYY"),
          data_fim: moment(rawTask.final_date).format("DD/MM/YYYY"),
          Atividade: rawTask.initiative.name,
          action_value: rawTask.action_value,
        };
      });

      setSearchData(formattedTasks);
      setListTasks(formattedTasks);

      const status = await ActionPlanService.getStatus(companyId, level4_id);
      setStatus(status.pgc_action_plan);
    } catch (error) {
      ShowMSG("error", "Erro ao buscar dados.");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  async function PostActionPlain() {
    const formData = new FormData();

    try {
      await Promise.all(
        fileList.map(async (file) => {
          const isImage = file.type ? file.type.startsWith("image/") : false;
          const maxSize = isImage ? 2 * 1024 * 1024 : 8 * 1024 * 1024; // 2MB para imagens, 8MB para docs

          let fileToProcess = file.originFileObj || file;

          if (!(fileToProcess instanceof File)) {
            console.warn("⚠️ Arquivo não é válido.");
            return;
          }

          if (isImage) {
            try {
              console.log(
                "🔄 Convertendo imagem para WebP:",
                fileToProcess.name
              );
              const compressedBlob = await imageCompression(fileToProcess, {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: "image/webp",
              });

              const compressedFile = new File(
                [compressedBlob],
                fileToProcess.name.replace(/\.[^.]+$/, ".webp"),
                {
                  type: "image/webp",
                }
              );

              formData.append("files", compressedFile, compressedFile.name);
              ShowMSG("success",`Imagem ${compressedFile.name} adicionada.`);
              return;
            } catch (error) {
              console.error("❌ Erro ao converter imagem para WebP:", error);
              ShowMSG("error",`Erro ao converter ${file.name}.`);
            }
          } else {
            if (fileToProcess.size <= maxSize) {
              formData.append("files", fileToProcess, fileToProcess.name);
              ShowMSG("success",`Arquivo ${file.name} adicionado.`);
            } else {
              ShowMSG("error",`O arquivo ${file.name} excede o tamanho máximo.`);
            }
          }
        })
      );
    } catch (error) {
      console.error("❌ Erro ao processar arquivos:", error);
      ShowMSG("error","Erro ao processar arquivos.");
    }
    try {
      setLoading(true);
      let body = {
        id: id,
        company_id: companyId,
        initiative_id: initiative_id,
        customer_id: customer_id,
        initial_date: initial_date,
        final_date: final_date,
        description: description,
        status: status_value,
        level4_id: level4,
        action_value: level0Name,
      };
      const newTask = await ActionPlanService.PostActionPlain(companyId, body);
      if (newTask.length > 1 && id === null) {
        newTask.map(async (task: any) => {
          await ActionPlanService.uploadFiles(companyId, task.id, formData);
        });
      } else if (newTask.length > 1 && id !== null) {
        await ActionPlanService.uploadFiles(companyId, id, formData);
      } else {
        await ActionPlanService.uploadFiles(
          companyId,
          id !== null ? id : newTask[0].id,
          formData
        );
      }

      FindData(level4);
    } catch (error) {
      console.log(error);
      ShowMSG("error", "Erro ao salvar dados.");
    } finally {
      setLoading(false);
    }
  }
  async function findAllInitiatives() {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllInitiatives(companyId);
      setInitiatives(response);
    } catch (error) {
      console.log(error);
      ShowMSG("error", "Erro ao buscar dados.");
    } finally {
      setLoading(false);
    }
  }

  async function findAllCustomers(level4_id: number) {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllCustomers(
        companyId,
        level4_id
      );
      setData_customer(response);
    } catch (error) {
      console.log(error);
      ShowMSG("error", "Erro ao buscar dados.");
    } finally {
      setLoading(false);
    }
  }

  function disabledDate(current: any) {
    if (initial_date) {
      return current && current < initial_date;
    } else {
      return false;
    }
  }
  function showModalAction() {
    data_customer.length === 0
      ? messageApi.open({
          type: "error",
          content: " Não há nenhum cliente associado a esse vendedor",
        })
      : setModalNewAction(true);
    //findAllCustomers(level4);
  }

  function handleOk() {
    setModalNewAction(false);
    PostActionPlain();
    handleCancel();
    findAllCustomers(level4);
  }

  function handleCancel() {
    setModalNewAction(false);
    setDescription(undefined);
    setStatus_value(undefined);
    setCustomer_id([]);
    setId(null);
    setInitiative_id(undefined);
    setInitial_date(undefined);
    setFinal_date(undefined);
    setlevel0Name(null);
    setlevel0Value(null);
    setFileExists(false);
    setFileList([]);
  }

  function handleChangeStatus(e: any) {
    const { value } = e.target;
    if (value === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(value);
    }
  }
  function handleRemoveStatus() {
    setFilteredStatus(null);
  }
  useEffect(() => {
    getProfile();
    filterTable();
    function filterTable() {
      if (!search && !filteredStatus) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            (!filteredStatus ||
              (record["status"] &&
                record["status"]
                  ?.toUpperCase()
                  .indexOf(filteredStatus.toUpperCase()) > -1)) &&
            (!search ||
              record["description"]
                .toUpperCase()
                .indexOf(search.toUpperCase()) > -1 ||
              record["Atividade"].toLowerCase().indexOf(search.toLowerCase()) >
                -1 ||
              record["cliente"].toLowerCase().indexOf(search.toLowerCase()) >
                -1 ||
              record["level4"].toLowerCase().indexOf(search.toLowerCase()) > -1)
        );
        setSearchData(array);
      }
    }
  }, [search, filteredStatus, listTasks]);
  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  }) {
    dispatch(setFiltercommercialStucture(obj));
    setLevel4(obj.level4);
    // setFilter(false);
    FindData(obj.level4);
    findAllCustomers(obj.level4);
  }

  function disableSave(): boolean {
    return (
      !description ||
      !status_value ||
      !customer_id ||
      !initiative_id ||
      !initial_date ||
      !final_date ||
      description.trim().length === 0
    );
  }
  const { Title } = Typography;
  const findFile = async (id: number) => {
    const filespgc = await ActionPlanService.getFilesPGC(companyId, id);
    return filespgc || [];
  };
  const deleteFile = async (fileName: string) => {
    try {
      await ActionPlanService.deleteFilePGC(companyId, fileName);
    } catch (e) {
      console.error("Falha ao deletar arquivo");
      ShowMSG("error", "Falha ao deletar arquivo");
      console.error(e);
    } finally {
      await FindData(level4);
    }
  };
  const getLastWordAfterDot = (link: string) => {
    const parts = link.split(".");
    return parts[parts.length - 1];
  };
  const FileColumn = memo(({ id }: { id: number }) => {
    const [fileData, setFileData] = useState<
      | { id: number; filename: string; filetype: string; filepath: string }[]
      | null
    >(null);
    const fileViewr = async (fileName: string) => {
      try {
        setModalFileView(true);
        setFileLink(
          `https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgc/action-plan/download/${fileName}`
        );
        const lastWord = getLastWordAfterDot(
          `https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgc/action-plan/download/${fileName}`
        );
        setTypeFile(lastWord);
      } catch (e) {
        console.error("Falha ao buscar arquivos");
      //  ShowMSG("error", "Falha ao buscar arquivos");
        console.error(e);
      }
    };

    useEffect(() => {
      const fetchFiles = async () => {
        if (id === 0) return null;
        try {
          const filespgc = await findFile(id || 0);
          setFileData(filespgc?.files || []);
        } catch (e) {
          setFileData([]);
        }
      };

      fetchFiles();
    }, [id]);

    if (fileData === null)
      return (
        <div style={{ width: "180px" }}>
          <LoadingOutlined />
        </div>
      );
    if (fileData.length === 0) return <span></span>;

    return (
      <div style={{ width: "180px" }}>
        
        {fileData.map((file: any) => {
          const isImage = ["webp", "jpg", "jpeg", "png"].includes(
            file.filetype.toLowerCase()
          );
          const isPdf = ["pdf"].includes(file.filetype.toLowerCase());
          const isDoc = ["doc", "docx"].includes(file.filetype.toLowerCase());
          const isXls = ["xls", "xlsx"].includes(file.filetype.toLowerCase());
          return (
            <div
              key={file.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={isImage ? "Visializar Imagem" : "Visializar Documento"}
              >
                <Button
                  type="link"
                  onClick={() => {
                    if (isImage) {
                      fileViewr(file.filename);
                    } else {
                      fileViewr(file.filename);
                    }
                  }}
                >
                  {isImage && <FileImageOutlined style={{ color: "gray" }} />}
                  {isPdf && <FilePdfOutlined style={{ color: "red" }} />}
                  {isDoc && <FileWordOutlined style={{ color: "blue" }} />}
                  {isXls && <FileExcelOutlined style={{ color: "green" }} />}

                  {file.filename}
                </Button>
              </Tooltip>
              <a
                href={`https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgc/action-plan/download/${file.filename}`}
                style={{ marginLeft: "-5px" }}
              >
                <DownloadOutlined />
              </a>
              {profile === "Administrador" ? (
                <Tooltip title="Excluir arquivo">
                  <Popconfirm
                    title="Tem certeza que deseja excluir esse Arquivo?"
                    onConfirm={() => deleteFile(file.filename)}
                    okText="Excluir"
                    okButtonProps={{ danger: true }}
                    cancelText="Cancelar"
                  >
                    <Button type="text" icon={<FiTrash color="red" />} />
                  </Popconfirm>
                </Tooltip>
              ) : (
                <Tooltip title="Sem permissão para excluir">
                  <Button type="text" icon={<FiTrash color="red" />} disabled />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    );
  });
  return (
    <Breadcrumb
      path="Plano de Ação"
      child={[listTasks[0]?.level4]}
      actions={[
        {
          children: newStatus,
          type: newStatus === "Finalizar" ? "primary" : "default",
          className: level4,
          disabled: searchData.length === 0,
        },
      ]}
    >
      {contextHolder}
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Plano de Ação - PGC
            </Title>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          {filter && (
            <Col xl={4} xs={24} sm={12}>
              <Card bordered={false}>
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  initialValues={commercialStructure}
                >
                  <CommercialStructure formRef={formRef} level={4} />
                  <Button id="buttons" htmlType="submit" type="primary" block>
                    Buscar
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
          <Col xl={filter ? 20 : 24} xs={24}>
            <Card bordered={false}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col xl={8} xs={24}>
                  <Buttons>
                    {filter ? (
                      ""
                    ) : (
                      // <Tooltip title="Fechar Menu">
                      //   <Button
                      //     type="text"
                      //     style={{ marginRight: 16 }}
                      //     onClick={() => setFilter(!filter)}
                      //     icon={
                      //       <FiX
                      //         size={25}
                      //         color="#00CC66"
                      //         style={{
                      //           margin: "15px 10px 10px 10px",
                      //           cursor: "pointer",
                      //         }}
                      //       />
                      //     }
                      //   />
                      // </Tooltip>
                      <Tooltip title="Abrir Menu">
                        <Button
                          type="text"
                          style={{ marginRight: 16 }}
                          icon={
                            <FiMenu
                              size={25}
                              color="#00CC66"
                              style={{
                                margin: "15px 10px 10px 10px",
                                cursor: "pointer",
                              }}
                              onClick={() => setFilter(!filter)}
                            />
                          }
                        />
                      </Tooltip>
                    )}
                    <Button
                      icon={<FiPlus />}
                      type="primary"
                      style={{ marginLeft: "10px" }}
                      onClick={() => showModalAction()}
                      disabled={level4 === undefined || status === "F"}
                    >
                      Novo
                    </Button>
                    <Input
                      placeholder="Pesquisar"
                      prefix={<FiSearch color="#00CC66" />}
                      suffix={<FiFilter color="#00CC66" />}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Buttons>
                </Col>
                <Col>
                  <Radio.Group buttonStyle="solid" value={filteredStatus}>
                    <Radio.Button onClick={handleChangeStatus} value="PENDENTE">
                      PENDENTE
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="EM ANDAMENTO"
                    >
                      EM ANDAMENTO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="CANCELADO"
                    >
                      CANCELADO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="FINALIZADO"
                    >
                      FINALIZADO
                    </Radio.Button>
                    {filteredStatus !== null ? (
                      <Button
                        style={{
                          backgroundColor: "#fc5f5f",
                          color: "#000",
                        }}
                        onClick={handleRemoveStatus}
                      >
                        X
                      </Button>
                    ) : (
                      ""
                    )}
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={searchData}
                    loading={{ indicator: Icon, spinning: isLoading }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Modal
            //  footer={false}
            open={modalNewAction}
            okButtonProps={{ disabled: disableSave() }}
            okText={"Salvar"}
            onOk={handleOk}
            width={600}
            onCancel={handleCancel}
            title={id ? "Atualizar Plano de Ação" : "Novo Plano de Ação"}
          >
            <Row justify="center" gutter={20}>
              <Col span={12}>
                <Input
                  allowClear
                  placeholder="Descrição"
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />

                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Inicial"
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  value={initial_date}
                  onChange={(date: any) => setInitial_date(date)}
                  disabledDate={disabledDate}
                />
              </Col>

              <Col span={12}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Status"
                  onChange={(value) => setStatus_value(value)}
                  value={status_value}
                >
                  <Option value={"PENDENTE"}>Pendente</Option>
                  <Option value={"CANCELADO"}>Cancelado</Option>
                  <Option value={"EM ANDAMENTO"}>Em Andamento</Option>
                  <Option value={"FINALIZADO"}>Finalizado</Option>
                </Select>
                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Final"
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  onChange={(date: any) => setFinal_date(date)}
                  value={final_date}
                  disabledDate={disabledDate}
                />
              </Col>
              <Col span={24}>
                <Input
                  //disabled={selectedLevel !== 0}
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Valor Ação"
                  value={level0Value}
                  //onChange={(e) => setlevel0Name(e.target.value)}
                  onChange={handleChangeAward}
                />
              </Col>
              <Col span={24}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  showSearch
                  optionFilterProp="children"
                  placeholder="Iniciativa"
                  onChange={(value) => [setInitiative_id(value)]}
                  value={initiative_id}
                >
                  {initiatives.map((item) => (
                    <Option value={item.id}>{item.name}</Option>
                  ))}
                  )
                </Select>
                {id ? (
                  data_customer.length > 0 ? (
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      showSearch
                      placeholder="Cliente"
                      optionFilterProp="children"
                      onChange={(value) => setCustomer_id(value)}
                      value={customer_id}
                    >
                      {data_customer.map((item) => (
                        <Option value={item.customer_id}>
                          {item.customer_name}
                        </Option>
                      ))}
                      )
                    </Select>
                  ) : (
                    <> </>
                  )
                ) : data_customer.length > 0 ? (
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    showSearch
                    mode="multiple"
                    optionFilterProp="children"
                    placeholder="Cliente"
                    onChange={(value) => setCustomer_id(value)}
                    value={customer_id}
                  >
                    {data_customer.map((item) => (
                      <Option value={item.customer_id}>
                        {item.customer_name}
                      </Option>
                    ))}
                    )
                  </Select>
                ) : (
                  <> </>
                )}
              </Col>
              <Col span={24}>
                {fileExists ? (
                  <FileColumn id={id || 0} />
                ) : (
                  <Upload
                    fileList={fileList}
                    beforeUpload={(file) => {
                      setFileList([file]);
                      return false;
                    }}
                    onRemove={() => setFileList([])}
                    listType="picture"
                    accept=".jpg,.jpeg,.png,.webp,.pdf,.doc,.docx,.xls,.xlsx"
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                      showDownloadIcon: true,
                    }}
                  >
                    <Button icon={<UploadOutlined />}>IMG/DOC</Button>
                  </Upload>
                )}
                {fileList.length > 0 && id === null && (
                  <div>
                    {fileList[0].type?.startsWith("image/") ? (
                      <Image
                        src={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        alt="Imagem Selecionada"
                        style={{ marginTop: 10 }}
                      />
                    ) : fileList[0].type?.startsWith("application/pdf") ? (
                      <a
                        href={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 10, display: "block" }}
                      >
                        Visualizar Documento
                      </a>
                    ) : (
                      <a
                        href={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 10, display: "block" }}
                      >
                        Baixar Documento
                      </a>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Modal>
          <Modal
            title="Visualizar Arquivo"
            open={modalFileView}
            onCancel={() => {
              setModalFileView(false);
              setFileLink("");
              setTypeFile("");
            }}
            footer={null}
          >
            {typeFile === "webp" ? (
              <Image src={fileLink} />
            ) : (
              <iframe
                title="Visualizar Arquivo"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${fileLink}`}
                width="450px"
                height="450px"
              />
            )}
          </Modal>
        </Row>
      </>
    </Breadcrumb>
  );
}
