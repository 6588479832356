import styled from "styled-components";
import { Row as RowANTD } from "antd";
import { Input as InputANTD } from "antd";
import { Select as SelectANTD } from "antd";

export const Row = styled(RowANTD)`
  display: flex;
  gap: 0.6%;
  align-items: baseline;
`;

export const Col = styled.div`
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  width: calc(20% - 8px);
  padding: 12px;
  border-radius: 4px;
  justify-content: space-between;
`;

export const ColHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-
  align-items: center;
  width: 100%;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  overflow: auto;
  max-height: calc(82vh - 100px);
`;

interface StructureItemContainerProps {
  active: boolean;
}

export const StructureItemContainer = styled.div<StructureItemContainerProps>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  justify-content: space-between;
  transition: 0.5s all ease-in-out;
`;

export const ItemLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px;
  color: var(--text-color);
`;

export const ItemActions = styled.div`
 [data-theme="light"] {
    --primary-color: rgb(35, 217, 120);
  }

  /* Tema Escuro */
  [data-theme="dark"] {
    --primary-color: rgb(30, 148, 85);
  }
  display: flex;
  align-items: center;
  color: var(--primary-color);
  svg {
    margin: 5px;
  }
`;
export const Actions = styled.div`
  display: flex;
  justify-content: center;

  svg {
    cursor: pointer;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const Input = styled(InputANTD)`
  margin: 5px 0;
`;

export const Select = styled(SelectANTD)`
 [data-theme="light"] {
    --text-color: rgb(41, 41, 41);
  }

  /* Tema Escuro */
  [data-theme="dark"] {
    --text-color: #f5f5f5;
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px;
  color: var(--text-color);
`;

export const StructureItemContainerse = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  /* justify-content: space-between; */
  transition: 0.5s all ease-in-out;
`;
