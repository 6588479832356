import Http from "../../../utils/http";

class SalesForecastService extends Http {
  public constructor() {
    super();
  }

  public getBudgetLevel4 = (companyId: number, level4_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget/level4-customers?level4_id=${level4_id}`
    );
  };

  public getBudgetLevel4Category = (
    companyId: number,
    level4_id: number,
    Category: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget/level4-customersCategory?level4_id=${level4_id}&category_id=${Category}`
    );
  };

  public GetCategory = async (company_id: number) =>
    await this.instance.get(`/company/${company_id}/customercategory`);

  public getBudget = (
    companyId: number,
    customer_id: number,
    category_id: number,
    level4_id: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget?customer_group_id=${customer_id}&level4_id=${level4_id}&category_id=${category_id}`
    );
  };
  public PostBudget = (companyId: number, body: object) => {
    return this.instance.post(`/company/${companyId}/pgc/budget`, body);
  };

  public putBudgetStatus = (companyId: number, body: object) => {
    return this.instance.put(`/company/${companyId}/pgc/budget/status`, body);
  };

  public getBudgetStatus = (companyId: number, level4_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget/status?level4_id=${level4_id}`
    );
  };

  public getBudgetDashboard = (
    companyId: number,
    customer_group_id: number,
    category_id?: number,
    level4_id?: number,
    level3_id?: number,
    level2_id?: number,
    level1_id?: number,
    level0_id?: number,
    TipoNegocio?: number,
    customer_category_id?: number,
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/budget/dashboard?customer_group_id=${customer_group_id}&level4_id=${level4_id}&category_id=${category_id}&level3_id=${level3_id}&level2_id=${level2_id}&level1_id=${level1_id}&level0_id=${level0_id}&TipoNegocio=${TipoNegocio}&customer_category_id=${customer_category_id}`
    );
  };

  public GetButtomUpFilial = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    filterFilial?: number,
    business_type_id?: number,
    filterDiretoria?: number,
    TipoNegocio?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(`/company/${companyId}/pgc/dashboard/filial`, {
      params: {
        years: yearsParam,
        filterRegional: filterRegional,
        filterFilial: filterFilial,
        business_type_id: business_type_id,
        filterDiretoria: filterDiretoria,
        TipoNegocio: TipoNegocio,
      },
    });
  };
  public getRegionalDashboard = (
    companyId: number,
    customer_group_id: number,
    category_id?: number,
    level4_id?: number,
    level3_id?: number,
    level2_id?: number,
    level1_id?: number,
    level0_id?: number,
    TipoNegocio?: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/regional/dashboard?customer_group_id=${customer_group_id}&level4_id=${level4_id}&category_id=${category_id}&level3_id=${level3_id}&level2_id=${level2_id}&level1_id=${level1_id}&level0_id=${level0_id}&TipoNegocio=${TipoNegocio}`
    );
  };
  public getFilialDashboard = (
    companyId: number,
    customer_group_id: number,
    category_id?: number,
    level4_id?: number,
    level3_id?: number,
    level2_id?: number,
    level1_id?: number,
    TipoNegocio?: number,
    customer_category_id?: number,
    cliente_id?: number,
    level0_id?: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgc/filial/dashboard?customer_group_id=${customer_group_id}&level4_id=${level4_id}&category_id=${category_id}&level3_id=${level3_id}&level2_id=${level2_id}&level1_id=${level1_id}&TipoNegocio=${TipoNegocio}&customer_category_id=${customer_category_id}&cliente_id=${cliente_id}&level0_id=${level0_id}`
    );
  };
}

export default new SalesForecastService();
