/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Table,
  Tag,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import ReactEcharts from "echarts-for-react";
import "./index.css";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../../styles/ThemeContext";
const Option = Select.Option;
export function BottomUPDashboardPGV() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const [valores, setValores] = useState<string>("R$");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [OptionsPresidente, setOptionsPresidente] = useState<any>([]);
  const [filterPresidente, setFilterPresidente] = useState<number>(0);
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataTipoNegocio, setDataTipoNegocio] = useState<any>([]);
  const [LoadingRegional, setLoadingRegional] = useState<boolean>(false);
  const [LoadingFilial, setLoadingFilial] = useState<boolean>(false);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const [level4Label, setLevel4Label] = useState<string | undefined>("");
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterDiretoria, setFilterDiretoria] = useState<number>(0);
  const [filterFilialModal, setFilterFilialModal] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [isFullScreen3, setIsFullScreen3] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [contador, setContador] = useState<any>();
  const { theme } = useTheme();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  const toggleFullScreen3 = () => {
    setIsFullScreen3(!isFullScreen3);
  };
  const handleValores = (value: any) => {
    setValores(value);
    GetDataBottomUps(
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilialModal,
      value
    );
    GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      years,
      TipoNegocio,
      value,
      filterPresidente
    );
    GetDataButtomUpFilial(
      filterRegional,
      filterFilialModal,
      years,
      TipoNegocio,
      value,
      filterDiretoria || 0
    );
  };
  const handleFilter = async () => {
    GetTipoNegocio();
    GetDataLevel1(filterPresidente);
    GetDataLevel0();
    /*if (filterRegional !== 0 && filterRegional === undefined) {
      GetDataBottomUp(
        filterRegional,
        filterDiretoria,
        false,
        years,
        TipoNegocio,
        valores
      );
    }
    if (filterRegional !== 0 && filterRegional !== undefined) {
      GetDataBottomUp(
        filterRegional,
        filterDiretoria,
        false,
        years,
        TipoNegocio,
        valores
      );
    }*/
  };

  const GetDataLevel0 = async () => {
    const response = await PowerBi_all.GetButtomUpLevel0(
      companyId,
      Number(idUser)
    );
    if (response.length === 1) {
      setOptionsPresidente(response);
      setFilterPresidente(response[0]?.id);
    } else {
      setOptionsPresidente(response);
    }
  };

  const GetDataLevel1 = async (value: number) => {
    const response = await PowerBi_all.GetButtomUpLevel1(
      companyId,
      Number(idUser),
      value
    );
    setOptionsDiretoria(response);
    if (filterFilialModal !== 0) {
      GetDataButtomUpFilial(
        filterRegional,
        filterFilialModal,
        years,
        TipoNegocio,
        valores,
        value
      );
    } else if (filterRegional !== 0) {
      GetDataButtomUpFilial(
        filterRegional,
        0,
        years,
        TipoNegocio,
        valores,
        value
      );
    }
  };

  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );
  const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );
  const totalProjection1 = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection1 || 0),
    0
  );

  const LimparFiltros = () => {
    setFilialOptions([]);
    setFilterRegional(0);
    setFilterDiretoria(0);
    setDataFilial([]);
    setDataFilialTable([]);
    setDataProjecaodevendas([]);
    setFilterFilialModal(0);
    setValoresProdutos([]);
    setContador(null);
    GetDataBottomUps(0, 0, 0, 0, 0, valores);
    GetDataBottomUp(0, 0, false, years, 0, valores, 0);
    GetDataButtomUpFilial(0, 0, years, 0, valores, filterDiretoria || 0);
    GetDataBottomUpFindRegional(0, Number(idUser));
  };
  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);
  useEffect(() => {
    handleFilter();
    GetDataBottomUps(
      0,
      filterPresidente ? filterPresidente : 0,
      filterDiretoria ? filterDiretoria : 0,
      filterRegional,
      filterFilialModal,
      valores
    );
    GetDataButtomUpFilial(
      0,
      0,
      years,
      TipoNegocio,
      valores,
      filterDiretoria ? filterDiretoria : 0
    );
    GetDataBottomUp(
      filterRegional,
      filterDiretoria ? filterDiretoria : 0,
      false,
      years,
      0,
      valores,
      filterPresidente
    );
  }, [years]);
  useEffect(() => {
    GetDataBottomUps(
      0,
      filterPresidente ? filterPresidente : 0,
      filterDiretoria ? filterDiretoria : 0,
      filterRegional,
      filterFilialModal,
      valores
    );
  }, [theme]);

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)).slice(0, 3));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleRegionChange = async (value: any) => {
    await GetDataBottomUp(
      value,
      filterDiretoria,
      true,
      years,
      TipoNegocio,
      valores,
      filterPresidente
    );
    GetDataBottomUps(
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      value,
      0,
      valores
    );
    setFilterRegional(value);
    await GetDataButtomUpFilial(
      value,
      0,
      years,
      TipoNegocio,
      valores,
      filterDiretoria || 0
    );
    setFilterFilialModal(0);
  };
  const handlePresidenteChange = async (value: number) => {
    GetDataLevel1(value);
    setFilterDiretoria(0);
    setFilterRegional(0);
    setFilterFilialModal(0);
    setFilialOptions([]);
    // await GetDataBottomUpFindRegional2(value, Number(idUser));
    GetDataBottomUps(TipoNegocio, value, 0, 0, 0, valores);
    setFilterPresidente(value);
    await GetDataButtomUpFilial(0, 0, years, TipoNegocio, valores, 0, 0, value);
    GetDataBottomUp(0, 0, false, years, TipoNegocio, valores, value);
  };
  const handleDiretoriaChange = async (value: any) => {
    await GetDataBottomUp(
      0,
      value,
      false,
      years,
      TipoNegocio,
      valores,
      filterPresidente
    );
    await GetDataBottomUps(TipoNegocio, filterPresidente, value, 0, 0, valores);
    setFilterDiretoria(value);
    await GetDataBottomUpFindRegional(value, Number(idUser), value);
    await GetDataButtomUpFilial(0, 0, years, TipoNegocio, valores, value);
    //setDataFilial([]);
  };

  const handleFilialChangeModal = async (value: any) => {
    await GetDataButtomUpFilial(
      filterRegional,
      value,
      years,
      TipoNegocio,
      valores,
      filterDiretoria || 0
    );
    setFilterFilialModal(value);
    GetDataBottomUps(
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      value,
      valores
    );
  };

  async function GetDataBottomUps(
    Business_type_id?: number,
    level0_id?: number,
    level1_id?: number,
    level2_id?: number,
    level3_id?: number,
    valores?: any
  ) {
    setValoresProdutos([]);
    const response = await BottomUpService.getTableProductDash(
      companyId,
      Business_type_id,
      level0_id,
      level1_id,
      level2_id,
      level3_id
    );

    // Transformar os dados no formato desejado
    const transformedData = response.records
      .map((item: any) => {
        // Mapeamento dos anos e valores correspondentes
        const years = [
          {
            year_label: "year0",
            total_volume:
              valores === "R$" ? item.total_base_line : item.volume_base_line,
          },
          {
            year_label: "year1",
            total_volume:
              valores === "R$" ? item.total_year1 : item.volume_year1,
          },
        ];

        // Criar o array de objetos com os dados transformados
        return years.map((yearData) => ({
          name: item.product_name,
          year_label: yearData.year_label,
          year0: yearData.year_label === "year0" ? yearData.total_volume : 0,
          year1: yearData.year_label === "year1" ? yearData.total_volume : 0,
          total_volume: yearData.total_volume,
        }));
      })
      .flat(); // Usando .flat() para achatar o array de arrays em um único array

    // Agrupar os dados pelo 'name' e somar os volumes por ano
    const groupedData = Object.values(
      transformedData.reduce((acc: any, curr: any) => {
        const key = curr.name;
        if (!acc[key]) {
          acc[key] = {
            name: curr.name,
            total_volume: 0,
            year0: 0,
            year1: 0,
          };
        }

        acc[key].total_volume = parseFloat(
          acc[key].total_volume + curr.total_volume
        );
        acc[key].year0 = parseFloat(acc[key].year0 + curr.year0);
        acc[key].year1 = parseFloat(acc[key].year1 + curr.year1);

        if (!isFinite(acc[key].year0)) {
          acc[key].year0 = 0;
        }
        if (!isFinite(acc[key].year1)) {
          acc[key].year1 = 0;
        }
        return acc;
      }, {})
    ).sort((a: any, b: any) => b.year1 - a.year1);

    // Obter os valores agrupados como um array
    const finalData = Object.values(groupedData);

    // Retornar ou configurar os valores do produto
    setValoresProdutos(finalData.splice(0, 10));
  }

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const [DataFilial, setDataFilial] = useState<any>([]);

  const [filialOptions, setFilialOptions] = useState([]);
  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12).toFixed(1)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(1)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`;
    } else {
      return value.toFixed(1);
    }
  };
  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      width: "35%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Base Line",
      dataIndex: "baseLine",
      key: "baseLine",
      width: "110px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "0,00"}</span>
        );
      },
    },
    {
      title: "Proj Y+1",
      dataIndex: "projection",
      key: "projection",
      width: "90px",

      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Proj Y+2",
      dataIndex: "projection1",
      key: "projection1",
      width: "90px",
      sorter: (a: any, b: any) => a.projection1 - b.projection1,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection1 && record.projection1 > 0
              ? formatValue(record.projection1)
              : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "90px",
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter(
    (column: Column) =>
      column.key !== "projection1" ||
      DataFilialTable.some((record: any) => record.projection1 > 0)
  );
  async function GetDataButtomUpFilial(
    filtro: number,
    filterFilial?: number,
    year?: any,
    Business_type_id?: number,
    valores?: any,
    filterDiretoria?: number,
    TipoNegocio?: number,
    filterPresidencia?: number
  ) {
    setDataFilialTable([]);
    setDataFilial([]);
    setLoadingFilial(true);

    const response = await PowerBi_all.GetButtomUpFilial(
      companyId,
      year ? year : yearsOptions(Number(qdt_year)).slice(0, 3),
      filtro,
      filterFilial,
      Business_type_id,
      filterDiretoria,
      TipoNegocio,
      filterPresidencia
    );

    const updatedData = response.map((item: any) => {
      let newYearLabel = item.year_label;

      if (year && year.includes(item.year_label)) {
        newYearLabel = eval(item.year_label);
      }

      const abbreviatedTotalVolume =
        valores === "R$" ? item.total_volume : item.total_vol;

      return {
        ...item,
        year_label: newYearLabel,
        value: abbreviatedTotalVolume,
      };
    });

    const groupedData = updatedData.reduce((acc: any, item: any) => {
      if (!year || item.id === null) {
        return acc;
      }

      const existing = acc.find((i: any) => i.id === item.id);

      if (existing) {
        if (year.includes("year0") && item.year_label === year0)
          existing.baseLine = item.value;
        if (year.includes("year1") && item.year_label === year1)
          existing.projection = item.value;
        if (year.includes("year2") && item.year_label === year2)
          existing.projection1 = item.value;
        if (year.includes("year3") && item.year_label === year3)
          existing.projection2 = item.value;
        if (year.includes("year4") && item.year_label === year4)
          existing.projection3 = item.value;
        if (year.includes("year5") && item.year_label === year5)
          existing.projection4 = item.value;
      } else {
        acc.push({
          id: item.id,
          name: item.name,
          baseLine:
            year.includes("year0") && item.year_label === year0
              ? item.value
              : null,
          projection:
            year.includes("year1") && item.year_label === year1
              ? item.value
              : null,
          projection1:
            year.includes("year2") && item.year_label === year2
              ? item.value
              : null,
          projection2:
            year.includes("year3") && item.year_label === year3
              ? item.value
              : null,
          projection3:
            year.includes("year4") && item.year_label === year4
              ? item.value
              : null,
          projection4:
            year.includes("year5") && item.year_label === year5
              ? item.value
              : null,
        });
      }

      return acc;
    }, []);
    setDataFilialTable(groupedData);
    setDataFilial(updatedData);
    setLoadingFilial(false);
    const seen = new Set();
    const uniqueFilialOptions = updatedData.filter((item: any) => {
      if (item.name !== null && !seen.has(item.name)) {
        seen.add(item.name); // Marca o nome como visto
        return true;
      }
      return false;
    });
    if (filialOptions.length === 0) {
      setFilialOptions(uniqueFilialOptions);
    }
    filterFilial !== 0 ||
      (filterFilial !== undefined &&
        filtro !== 0 &&
        setFilialOptions(uniqueFilialOptions));
  }

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year - 1; i++) {
      yearss.push(`year${i}`);
    }
    return yearss;
  };

  const handleChange = async (value: any) => {
    setYears(value);
    setDataProjecaodevendas([]);
    await GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      value,
      TipoNegocio,
      valores,
      filterPresidente
    );
    if (filterRegional !== undefined) {
      await GetDataButtomUpFilial(
        filterRegional,
        filterFilialModal,
        value,
        TipoNegocio,
        valores,
        filterDiretoria || 0
      );
    }
  };
  const handleChangeTipoNegocio = async (value: any) => {
    setTipoNegocio(value);
    await GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      years,
      value,
      valores,
      filterPresidente
    );
    await GetDataBottomUps(
      value,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilialModal,
      valores
    );
    await GetDataButtomUpFilial(
      filterRegional,
      filterFilialModal,
      years,
      value,
      valores,
      filterDiretoria || 0
    );
  };
  async function GetDataBottomUpFindRegional(
    level1: number,
    idUser: number,
    diretoria?: number
  ) {
    setFilterRegional(0);
    setOptionsRegional([]);
    const response = await PowerBi_all.getButtomUpFindRegional(
      companyId,
      level1,
      idUser
    );

    if (response.length === 1) {
      setFilterRegional(response[0]?.id);
      setOptionsRegional(response);
      await GetDataBottomUp(
        response[0]?.id,
        diretoria,
        true,
        years,
        TipoNegocio,
        valores,
        filterPresidente
      );
    } else {
      setOptionsRegional(response);
      await GetDataBottomUp(
        0,
        diretoria,
        true,
        years,
        TipoNegocio,
        valores,
        filterPresidente
      );
      await GetDataButtomUpFilial(
        0,
        0,
        years,
        TipoNegocio,
        valores,
        diretoria ? diretoria : filterDiretoria
      );
    }
  }

  async function GetDataBottomUp(
    filtro?: any,
    filterDiretoria?: any,
    valid?: boolean,
    years?: any,
    Business_type_id?: number,
    valores?: string,
    level0_id?: number
  ) {
    setDataProjecaodevendas([]);
    setLoadingRegional(true);
    const response = await PowerBi_all.GetButtomUpRegional(
      companyId,
      years.length > 1 ? years : yearsOptions(Number(qdt_year)).slice(0, 3),
      filtro !== undefined ? filtro : filterRegional,
      filterDiretoria,
      Business_type_id,
      level0_id
    );
    valid &&
      (await GetDataButtomUpFilial(
        filtro !== undefined ? filtro : filterRegional,
        filterFilialModal,
        years,
        Business_type_id,
        valores,
        filterDiretoria
      ));
    // Agrupar e somar os valores de total_volume por name e year_label
    const groupedData = response.reduce((acc: any[], item: any) => {
      const existing = acc.find(
        (entry: any) =>
          entry.year_label === item.year_label && entry.name === item.name
      );
      if (existing) {
        valores !== "R$"
          ? (existing.total_vol += item.total_vol)
          : (existing.total_volume += item.total_volume);
      } else {
        acc.push({
          id: item.id,
          name: item.name,
          year_label: item.year_label,
          total_volume: item.total_volume,
          total_vol: item.total_vol,
        });
      }
      return acc;
    }, []);

    const updatedData = groupedData.map((item: any) => {
      let newYearLabel = item.year_label;

      // Substitui o year_label com base nos anos
      if (item.year_label === "year0") newYearLabel = year0;
      if (item.year_label === "year1") newYearLabel = year1;
      if (item.year_label === "year2") newYearLabel = year2;
      if (item.year_label === "year3") newYearLabel = year3;
      if (item.year_label === "year4") newYearLabel = year4;
      if (item.year_label === "year5") newYearLabel = year5;
      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume =
        valores !== "R$" ? item.total_vol : item.total_volume;

      return {
        ...item,
        year_label: newYearLabel,
        value: abbreviatedTotalVolume,
      };
    });
    setDataProjecaodevendas(updatedData);
    setLoadingRegional(false);
  }
  async function GetTipoNegocio() {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    setDataTipoNegocio(response);
    if (response.length === 1) {
      setTipoNegocio(response[0]?.id);
    }
  }

  const configPorcentagemBottomUP = {
    title: {
      text: "Produto",
      x: "center",
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Base Line" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Produto",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year1 - a.year1)
          .map((item: any) => ({
            name: item.name,
            value: item.year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;

  const sortedCategories = DataProjecaodevendas.map((item: any) => item.name)
    .filter((item: any) => item !== null)
    .filter((item: any, index: number, self: any) => {
      return self.indexOf(item) === index;
    })
    .sort((a: any, b: any) => {
      const yearA = parseInt(a.replace("year", ""));
      const yearB = parseInt(b.replace("year", ""));
      return yearA - yearB;
    });

  const configRegional: any = {
    title: {
      text: "Regional",
      x: "center",
    },
    backgroundColor: "transparent",
    legend: { show: true, left: "left", top: "25px" },
    grid: { left: "3%", right: "8%", bottom: "10%", containLabel: true },
    xAxis: {
      type: "category",
      inverse: false,
      axisLabel: { interval: 0, rotate: 30 },
      data: sortedCategories,
    },
    yAxis: {
      show: false,
      type: "value",
      axisLabel: {
        formatter: "{value}",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: { type: "shadow" },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: true, type: ["stack", "line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: DataProjecaodevendas.map((item: any) => item.year_label)
      .filter(
        (year: any, index: number, self: any) => self.indexOf(year) === index
      ) // Remove duplicates
      .sort((a: any, b: any) => {
        const yearA = parseInt(a.replace("year", ""));
        const yearB = parseInt(b.replace("year", ""));
        return yearA - yearB;
      })
      .map((year: any, index: number) => ({
        name: year === "year0" ? year0 : year,
        type: "bar",

        data: DataProjecaodevendas.filter(
          (item: any) => item.year_label === year
        ).map((item: any) =>
          valores !== "R$" ? item.total_vol : item.total_volume
        ),
        emphasis: {
          focus: "series",
        },

        animationDelay: (idx: any) => {
          return idx * 10;
        },
        animationDelayUpdate: (idx: any) => {
          return idx * 10;
        },
        animationDuration: 1000,
        animationDurationUpdate: 1000,
        animationEasing: "cubicInOut",
        animationEasingUpdate: "cubicInOut",
        animation: true,
        animationUpdate: true,
        animationThreshold: 2000,
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            const formattedValue = formatValue(params.value);
            return `${formattedValue}`;
          },
        },
      })),
  };
  const configFilial: any =
    DataFilial.length > 0
      ? {
          title: {
            text: "Filial",
            x: "center",
          },
          backgroundColor: "transparent",
          legend: { show: true, top: "25px" },
          grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
          yAxis: {
            type: "category",
            inverse: true,
            data: DataFilialTable.sort(
              (a: any, b: any) => b.projection - a.projection
            )
              .filter((item: any) => item.name !== null)
              .map((item: any) => item.name),
          },
          xAxis: {
            show: false,
            axisTick: {
              alignWithLabel: true,
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "Salvar" },
              magicType: { show: true, type: ["line", "bar"] },
              dataView: {
                show: true,
                readOnly: false,
                title: "Visualização de Dados",
                lang: ["Visualização de Dados", "Fechar", "Atualizar"],
              },
            },
          },
          series: [
            {
              name: "Base Line",
              type: "bar",
              data: DataFilialTable.sort(
                (a: any, b: any) => b.projection - a.projection
              )
                .filter((item: any) => item.name !== null)
                .map((item: any) => item.baseLine),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            {
              name: "Projeção Y+1",
              type: "bar",
              data: DataFilialTable.sort(
                (a: any, b: any) => b.projection1 - a.projection1
              )
                .filter((item: any) => item.name !== null)
                .map((item: any) => item.projection),
              label: {
                show: true,
                position: "right",
                formatter: (params: any) => {
                  const formattedValue = formatValue(params.value);
                  return `${formattedValue}`;
                },
              },
            },
            ...[1].map((i) => {
              const projectionKey = `projection${i}`;
              if (DataFilialTable.some((item: any) => item[projectionKey])) {
                return {
                  name: `Projeção Y+${i + 1}`,
                  type: "bar",
                  data: DataFilialTable.sort(
                    (a: any, b: any) => b.projection1 - a.projection1
                  )
                    .filter((item: any) => item.name !== null)
                    .map((item: any) => item[projectionKey]),
                  label: {
                    show: true,
                    position: "right",
                    formatter: (params: any) => {
                      const formattedValue = formatValue(params.value);
                      return `${formattedValue}`;
                    },
                  },
                };
              }
            }),
          ],
        }
      : {};

  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={3} xs={3} sm={3}>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={4} xs={4} sm={4} />
                <Col xl={9} xs={9} sm={9}>
                  <div className="header-title">
                    <span>Base Line vs Projeção de Vendas</span>
                  </div>
                  <div className="header-title-sub">
                    <span>Modúlo: Projeção de Vendas Botton up</span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col xl={24} xs={24} sm={24}>
              <div style={{ display: "flex" }}>
                <Card
                  title="Ano"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      defaultValue="0"
                      maxTagCount={2}
                      mode="multiple"
                      placeholder="Selecione o ano"
                      onChange={handleChange}
                      maxCount={3}
                      value={years.slice(0, 3)}
                      style={{
                        width: years.length > 2 ? 280 : 230,
                        backgroundColor: "#fff",
                        borderRadius: "7px",
                        marginLeft: 5,
                      }}
                      options={yearsOptions(Number(qdt_year)).map(
                        (year: string, index: number) => ({
                          value: year,
                          label: eval(`year${index}`),
                          disabled: index < 2,
                        })
                      )}
                    />
                  </div>
                </Card>

                <Card
                  title="Unidade de Medida"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Segmented
                      options={["R$", "KG/LT"]}
                      value={valores}
                      onChange={handleValores}
                    />
                  </div>
                </Card>

                <Card
                  title="Negócio"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Negócio"
                      onChange={handleChangeTipoNegocio}
                      value={TipoNegocio}
                      defaultValue={TipoNegocio}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...DataTipoNegocio.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                        })),
                      ]}
                    />
                  </div>
                </Card>
                <Card
                  title={level0Label}
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder={level0Label}
                      onChange={handlePresidenteChange}
                      value={filterPresidente}
                      defaultValue={filterPresidente}
                      style={{ width: "100%" }}
                      options={[
                        { value: 0, label: "Todos" },
                        ...OptionsPresidente.sort((a: any, b: any) =>
                          compareLabelsTables("level0_external_name")(a, b)
                        ).map((item: any) => ({
                          value: item.id,
                          label: item.level0_external_name,
                        })),
                      ]}
                    />
                  </div>
                </Card>
                {filterPresidente !== 0 && (
                  <Card
                    title={level1Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level1Label}
                        onChange={handleDiretoriaChange}
                        value={filterDiretoria}
                        defaultValue={filterDiretoria}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsDiretoria.sort((a: any, b: any) =>
                            compareLabelsTables("level1_external_name")(a, b)
                          ).map((item: any) => ({
                            value: item.id,
                            label: item.level1_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {filterDiretoria !== 0 && (
                  <Card
                    title={level2Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level2Label}
                        onChange={handleRegionChange}
                        value={filterRegional}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsRegional.sort((a: any, b: any) =>
                            compareLabelsTables("level2_external_name")(a, b)
                          ).map((item: any) => ({
                            value: item.level2_id,
                            label: item.level2_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                )}
                {filterRegional !== 0 && (
                  <Card
                    title={level3Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level3Label}
                        defaultValue={{
                          label: "Todos",
                          value: 0,
                        }}
                        onChange={handleFilialChangeModal}
                        value={filterFilialModal}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...filialOptions
                            .sort((a, b) => compareLabelsTables("name")(a, b))
                            .map((item: any) => ({
                              label: item.name,
                              value: item.id,
                            })),
                        ]}
                      />
                    </div>
                  </Card>
                )}

                <Card
                  bordered={true}
                  size="small"
                  title="Base Line"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalBaseLine)}
                    />
                  </div>
                </Card>
                <Card
                  bordered={true}
                  size="small"
                  title="Projeção Y+1"
                  className="card-statistic"
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{ color: "#3f8600" }}
                      value={formatValue(totalProjection)}
                    />
                  </div>
                </Card>
                {totalProjection1 !== 0 && (
                  <Card
                    bordered={true}
                    size="small"
                    title="Projeção Y+2"
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalProjection1)}
                      />
                    </div>
                  </Card>
                )}
                <Card
                  title="BL vs Y+1"
                  size="small"
                  bordered={true}
                  className="card-statistic"
                  style={{ minWidth: 150 }}
                >
                  <div className="report-card-content">
                    <Statistic
                      valueStyle={{
                        color:
                          totalProjection - totalBaseLine > 0
                            ? "green"
                            : totalProjection - totalBaseLine < 0
                            ? "red"
                            : "gray",
                      }}
                      value={
                        totalBaseLine !== 0
                          ? `${(
                              ((totalProjection - totalBaseLine) /
                                totalBaseLine) *
                              100
                            ).toFixed(1)}%`
                          : "0%"
                      }
                      prefix={
                        totalProjection - totalBaseLine > 0 ? (
                          <ArrowUpOutlined style={{ color: "green" }} />
                        ) : totalProjection - totalBaseLine < 0 ? (
                          <ArrowDownOutlined style={{ color: "red" }} />
                        ) : null
                      }
                    />
                  </div>
                </Card>
                {totalProjection1 !== 0 && (
                  <Card
                    title="BL vs Y+2"
                    size="small"
                    bordered={true}
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            totalProjection1 - totalBaseLine > 0
                              ? "green"
                              : totalProjection1 - totalBaseLine < 0
                              ? "red"
                              : "yellow",
                        }}
                        value={
                          totalBaseLine !== 0
                            ? `${(
                                ((totalProjection1 - totalBaseLine) /
                                  totalBaseLine) *
                                100
                              ).toFixed(2)}%`
                            : "0%"
                        }
                        prefix={
                          totalProjection1 - totalBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : totalProjection1 - totalBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                )}
              </div>

              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={8} xs={8}>
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemBottomUP}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen3 ? "100vh" : 400,
                            width: isFullScreen3 ? "100vw" : "100%",
                            padding: isFullScreen3 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>

                    <Card
                      bordered={true}
                      style={{
                        height: 400,
                      }}
                    >
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingRegional ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : DataProjecaodevendas.length > 0 ? (
                          <ReactEcharts
                            option={configRegional}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen ? "100vh" : 400,

                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={7} xs={7}>
                    <Card
                      bordered={true}
                      style={{
                        height: 840,
                      }}
                    >
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingFilial ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : DataFilial.length > 0 ? (
                          <ReactEcharts
                            option={configFilial}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen2 ? "100vh" : 840,
                              width: isFullScreen2 ? "100vw" : "100%",
                              padding: isFullScreen2 ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a
                                esta Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={9} xs={9}>
                    <Table
                      columns={filteredColumns}
                      size="small"
                      pagination={false}
                      dataSource={DataFilialTable}
                      scroll={{ y: 760 }}
                      footer={() => {
                        return (
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td
                                  style={{ fontWeight: "bold", width: "35%" }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    width: "110px",
                                  }}
                                >
                                  {formatValue(totalBaseLine)}
                                </td>
                                <td
                                  style={{
                                    textAlign: "start",
                                    fontWeight: "bold",
                                    width: "90px",
                                  }}
                                >
                                  {formatValue(totalProjection)}
                                </td>
                                {totalProjection1 > 0 && (
                                  <td
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      width: "90px",
                                    }}
                                  >
                                    {formatValue(totalProjection1)}
                                  </td>
                                )}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    width: "90px",
                                  }}
                                >
                                  {totalProjection1 > 0 ? (
                                    totalProjection +
                                      totalProjection1 -
                                      totalBaseLine >
                                    0 ? (
                                      <ArrowUpOutlined
                                        style={{ color: "green" }}
                                      />
                                    ) : totalProjection +
                                        totalProjection1 -
                                        totalBaseLine <
                                      0 ? (
                                      <ArrowDownOutlined
                                        style={{ color: "red" }}
                                      />
                                    ) : null
                                  ) : totalProjection - totalBaseLine > 0 ? (
                                    <ArrowUpOutlined
                                      style={{ color: "green" }}
                                    />
                                  ) : totalProjection - totalBaseLine < 0 ? (
                                    <ArrowDownOutlined
                                      style={{ color: "red" }}
                                    />
                                  ) : null}

                                  {totalProjection1 > 0
                                    ? `${(
                                        ((totalProjection +
                                          totalProjection1 -
                                          totalBaseLine) /
                                          totalBaseLine) *
                                        100
                                      ).toFixed(2)}%`
                                    : totalBaseLine !== 0
                                    ? `${(
                                        ((totalProjection - totalBaseLine) /
                                          totalBaseLine) *
                                        100
                                      ).toFixed(2)}%`
                                    : "~%"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
