import styled from "styled-components";
import { Row as RowANTD } from "antd";

export const Row = styled(RowANTD)`
  display: flex;
  justify-content: space-around;
  align-items: baseline;
`;

export const Col = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  width: calc(20% - 8px);
  padding: 12px;
  border-radius: 4px;
`;

export const ColHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button {
    display: flex;
    align-items: center;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  overflow: auto;
  max-height: calc(82vh - 100px);
`;

interface StructureItemContainerProps {
  active: boolean;
}

export const StructureItemContainer = styled.div<StructureItemContainerProps>`
  display: flex;
  flex-direction: row;
  background: ${(props) =>
    props.active ? "var(--primary-color2)" : "var(--bg-color)"};
  cursor: pointer;
  padding: 10px;
  margin: 5px 0;
  justify-content: space-between;
  transition: 0.5s all ease-in-out;
`;

export const ItemLabel = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 5px;
  color: var(--text-color);
`;

export const ItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #00cc66;

  svg {
    margin: 5px;
  }
`;
