import { useState } from "react";
import { Drawer, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
//import { Auth } from "aws-amplify";

import { ForgotPassword, SigninButton, Signins } from "./styles";
import { FormAuth } from "../../../components/Auth/FormAuth";
import { useAppDispatch } from "../../../hooks";
import { signin } from "../Reducer";
import UserService from "../../../services/Global/User";
import { NoticeType } from "antd/lib/message/interface";

interface FormLoginProps {
  email: string;
  password: string;
}

interface FormNewPassword {
  newPassword: string;
  confirmPassword: string;
}

interface FormConfirmSignUp {
  code: string;
}

export function Signin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [user, setUSer] = useState({});
  const [showConfirmPAssword, setShowConfirmPAssword] = useState(false);
  const [showConfirmSignUp, setShowConfirmSignup] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showNewPasswordForm, setShowNewPasswordForm] =
    useState<boolean>(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  interface FormDataNewPassword {
    email: string;
  }

  interface FormDataSetNewPassword {
    code: string;
    Senha: string;
    confirmar_Senha: string;
  }

  async function findUser(email: string) {
    try {
      const user = await UserService.findByEmail(email.replace(/ /g, ""));
      dispatch(signin(user));
      navigate("/");
    } catch (err) {
      ShowMSG("error", err.response.data.message);
      setLoading(false);
    }
  }

  async function onFinish(data: FormLoginProps) {
    try {
      setLoading(true);

      const response = await UserService.Login(data.email, data.password);

      if (response.signInUserSession) {
        localStorage.clear();
        localStorage.setItem(
          "token",
          response.signInUserSession.idToken.jwtToken
        );
      }

      if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUSer(response);
        setShowConfirmPAssword(true);
        setLoading(false);
      } else {
        await findUser(data.email);
      }
    } catch (err) {
      console.log(err);
      if (err.message === "User is not confirmed.") {
        setShowConfirmSignup(true);
      }

      ShowMSG("error", "Usuário ou senha inválidos");
      setLoading(false);
    }
  }

  async function handleCompletePassword(data: FormNewPassword) {
    if (data.newPassword === data.confirmPassword) {
      await UserService.ChangePassword(email, data.newPassword);
      ShowMSG("success", "Senha alterada com sucesso");
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      ShowMSG("error", "Senhas não conferem");
    }
  }

  async function handleConfirmSignup(data: FormConfirmSignUp) {
    // await Auth.confirmSignUp(email, data.code);
    await findUser(email.replace(/ /g, ""));
  }

  function renderLoginForm() {
    return (
      <Form onFinish={onFinish}>
        <Form.Item name="email" rules={[{ required: true }]}>
          <Input
            size="large"
            placeholder="E-mail"
            value={email.replace(/ /g, "")}
            onChange={(e) =>
              setEmail(e.target.value.replace(/ /g, "").toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="password" rules={[{ required: true }]}>
          <Input.Password size="large" placeholder="Senha" />
        </Form.Item>
        <div>
          {/**<FistLogin onClick={() => showDrawer()}>Primeiro acesso?</FistLogin> */}
          <ForgotPassword onClick={() => navigate("/forgot-password")}>
            Esqueceu sua senha?
          </ForgotPassword>
        </div>

        <Form.Item>
          <SigninButton
            loading={isLoading}
            htmlType="submit"
            size="large"
            block
          >
            Acessar
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  function renderConfirmPassword() {
    return (
      <Form onFinish={handleCompletePassword}>
        <Form.Item
          name="newPassword"
          rules={[{ required: true, min: 8, max: 16 }]}
        >
          <Input.Password size="large" placeholder="Nova Senha" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          rules={[{ required: true, min: 8, max: 16 }]}
        >
          <Input.Password size="large" placeholder="Confirmar Senha" />
        </Form.Item>
        <Form.Item>
          <SigninButton
            loading={isLoading}
            htmlType="submit"
            size="large"
            block
          >
            Alterar senha
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  function renderConfirmSignUp() {
    return (
      <Form onFinish={handleConfirmSignup}>
        <Form.Item name="code" rules={[{ required: true }]}>
          <Input size="large" placeholder="Código de verificação" />
        </Form.Item>
        <Form.Item>
          <SigninButton
            loading={isLoading}
            htmlType="submit"
            size="large"
            block
          >
            Verificar conta
          </SigninButton>
        </Form.Item>
      </Form>
    );
  }

  async function handleNewPassword(data: FormDataNewPassword) {
    try {
      setLoading(true);
      setEmail(data.email.toLowerCase());
      const url = window.location.origin;
      await UserService.SendEmail(data.email, url);
      setShowNewPasswordForm(true);
    } catch (err) {
      // ShowMSG("error","Usuário não encontrado!");
      console.log("Error caught:", err);
      form.setFields([
        {
          name: "email",
          errors: ["E-mail não encontrado!"],
        },
      ]);
    } finally {
      setLoading(false);
    }
  }

  async function handleSetPassword(data: FormDataSetNewPassword) {
    if (data.Senha === data.confirmar_Senha) {
      await setNewPassword(data);
    } else {
      ShowMSG(
        "error",
        "As senhas não conferem. Por favor, verifique e tente novamente."
      );
    }
  }

  async function setNewPassword(data: FormDataSetNewPassword) {
    try {
      setLoading(true);
      await UserService.verifyPassword(data.code, data.Senha);
      onClose();
    } catch (err) {
      ShowMSG(
        "error",
        "Algo deu errado, Verifique o codigo ou tente mais tarde."
      );
    } finally {
      setLoading(false);
      onClose();
      ShowMSG("success", "Senha alterada com sucesso");
    }
  }

  function renderData() {
    if (showConfirmSignUp) {
      return renderConfirmSignUp();
    }
    if (showConfirmPAssword) {
      return renderConfirmPassword();
    } else {
      return renderLoginForm();
    }
  }

  function renderData2() {
    if (showNewPasswordForm) {
      return redndercodiverification();
    } else {
      return renderEmailForm();
    }
  }

  function renderEmailForm() {
    return (
      <Form form={form} onFinish={handleNewPassword}>
        <b>
          Para acessa o sistema pela primeira vez, informe seu e-mail para que
          possamos enviar um código de verificação.
        </b>
        <br />

        <Form.Item
          name="email"
          rules={[
            { required: true, message: "Por favor, insira seu e-mail!" },
            { type: "email", message: "Por favor, insira um e-mail válido!" },
          ]}
        >
          <Input size="large" placeholder="E-mail" />
        </Form.Item>
        <Form.Item>
          <Signins loading={isLoading} htmlType="submit" size="large" block>
            Enviar e-mail
          </Signins>
        </Form.Item>
      </Form>
    );
  }

  function redndercodiverification() {
    return (
      <Form onFinish={handleSetPassword}>
        <b>
          Informe o código de verificação enviado para seu e-mail e crie uma
          nova senha.
        </b>
        <p>
          Caso não tenha recebido o e-mail, verifique sua caixa de spam ou
          clique em reenviar.
        </p>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: "Código de verificação é obrigatório" },
            {
              pattern: /^[0-9]{6}$/,
              message:
                "Código de verificação deve ter 6 dígitos e conter apenas números",
            },
          ]}
        >
          <Input
            size="large"
            type="text"
            maxLength={6}
            placeholder="Código de verificação"
            onInput={(e) => {
              e.currentTarget.value = e.currentTarget.value.replace(
                /[^0-9]/g,
                ""
              );
            }}
          />
        </Form.Item>
        <Form.Item
          name="Senha"
          rules={[
            { required: true, message: "Senha é obrigatória" },
            { min: 8, message: "Senha deve ter no mínimo 8 caracteres" },
          ]}
        >
          <Input.Password size="large" placeholder="Nova senha" />
        </Form.Item>
        <Form.Item
          name="confirmar_Senha"
          rules={[
            { required: true, message: "Confirmação de senha é obrigatória" },
            { min: 8, message: "Senha deve ter no mínimo 8 caracteres" },
          ]}
        >
          <Input.Password size="large" placeholder="Confirmar senha" />
        </Form.Item>
        <Form.Item>
          <Signins loading={isLoading} htmlType="submit" size="large" block>
            Cadastrar nova senha
          </Signins>
        </Form.Item>
      </Form>
    );
  }

  return (
    <FormAuth
      title="Bem vindo"
      subTitle="Informe seus dados de acesso para continuar"
    >
      {contextHolder}
      {renderData()}
      <Drawer title="Primerio Acesso" onClose={onClose} open={open}>
        {renderData2()}
      </Drawer>
    </FormAuth>
  );
}
