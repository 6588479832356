import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  message,
  Select,
  DatePicker,
  Tag,
  Tooltip,
  Radio,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  FiEdit2,
  FiFilter,
  FiPlus,
  FiSearch,
  FiTrash,
  FiGrid,
  FiUser,
  FiDownload
} from "react-icons/fi";
import { Breadcrumb } from "../../components/Breadcrumb";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../hooks";
import ParameterService from "../../services/SalesPlanning/Setup/Parameter";
import { ParameterData } from "../../services/SalesPlanning/Setup/Parameter/ParameterDataTypes";
import GlobalService from "../../services/Global";
import moment, { Moment } from "moment";
import { UserData } from "../../services/Global/User/userDataTypes";
import UserService from "../../services/Global/User";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import create from "../../services/Global";
// import getglCompanies from "../../services/Global";

const { Option } = Select;
export function Relatorio() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const [level4Label, setLevel4Label] = useState<string | undefined>("");
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const parameters = useAppSelector((state) => state.entities.parameter);

  const [isLoading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");

  const [selecteds, setSelecteds] = useState<string[]>([]);

  const [dataRelatorio, setDataRelatorio] = useState<any>([]);

  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
  }, [parameters]);
  // const getListParameter = useCallback(async () => {
  //   localStorage.setItem("historico", "buscou companies");
  //   try {
  //     setLoading(true);
  //     const response = await GlobalService.getglCompanies();
  //     console.log(response);
  //     setListParameters(response);
  //     setSearchData(response);
  //     setSearch("");
  //   } catch (e) {
  //     console.error("Falha ao buscar parâmetros: ");
  //     console.error(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [companyId]);

  const handleRowClick = async (record: any) => {
    console.log("Downloading report for:", record);
  
    if (record.type === "bottomup") {
      try {
        const response = await GlobalService.relatorios(companyId, record.type);
        console.log("Fetched Data:", response);
  
        if (response && response.length > 0) {
          exportExcelBottomUp(response);
        } else {
          console.warn("No data available for export.");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    } else if (record.type === "acp") {
      try {
        const response = await GlobalService.relatorios(companyId, record.type);
        console.log("Fetched Data:", response);
  
        if (response && response.length > 0) {
          exportExcelacp(response);
        } else {
          console.warn("No data available for export.");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    }
    else if (record.type === "acpa") {
      try {
        const response = await GlobalService.relatorios(companyId, "acp");
        console.log("Fetched Data:", response);
  
        if (response && response.length > 0) {
          exportExcelacpAnos(response);
        } else {
          console.warn("No data available for export.");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    }
  };

  const handleRowClick2 = async (record: any) => {
    console.log("Downloading report for:", record);
  
    if (record.type === "bottomup") {
      try {
        const response = await GlobalService.relatorios(companyId, record.type);
        console.log("Fetched Data:", response);
  
        if (response && response.length > 0) {
          exportExcelBottomUp(response);
        } else {
          console.warn("No data available for export.");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    } else if (record.type === "acp") {
      try {
        const response = await GlobalService.relatorios(companyId, record.type);
        console.log("Fetched Data:", response);
  
        if (response && response.length > 0) {
          exportExcelacpAnos(response);
        } else {
          console.warn("No data available for export.");
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    }
  };

  const exportExcelBottomUp = (data: any[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("BottomUp Report");
  
    // Define Column Headers
    const columns = [
      { header: level0Label, key: "level0", width: 20 },
      { header: level1Label, key: "level1", width: 25 },
      { header: level2Label, key: "level2", width: 25 },
      { header: level3Label, key: "level3", width: 25 },
    //  { header: "ID", key: "id", width: 10 },
     // { header: "Product ID", key: "product_id", width: 15 },
      { header: "Produto", key: "produto", width: 20 },
      { header: "Volume_"+year0, key: "volume_base_line", width: 18 },
      { header: "Total_"+year0+" (R$)", key: "total_base_line", width: 25 },
      { header: "Volume_"+year1, key: "volume_proj", width: 18 },
      { header: "Total_"+year1+" (R$)", key: "total_proj", width: 25 },
    ];
  
    worksheet.columns = columns;
  
    // Add Header Styling
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" }, // Light gray
      };
    });
  
    // Add Data to Worksheet
    data.forEach((row) => {
      worksheet.addRow({
        level0: row.level0,
        level1: row.level1,
        level2: row.level2,
        level3: row.level3,
        id: row.id,
        product_id: row.product_id,
        produto: row.produto,
        volume_base_line: Number(row.volume_base_line),
        total_base_line: Number(row.total_base_line),
        volume_proj: Number(row.volume_proj),
        total_proj: Number(row.total_proj),
      });
    });
  
    // Write File & Trigger Download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "BottomUp_Report.xlsx");
    });
  
    console.log("Excel export completed.");
  };


  const exportExcelacpAnos = (data: Array<{ [key: string]: any }>) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Industry Totals");
  
    // Define headers and map to actual data keys
    const industryColumns: string[] = [
      "2024/25",
      "Q2",
      "Q3",
      "Q4",
      "Q1_26",
      "2026/27",
    ];
  
    const dataKeys: string[] = [
      "industry_base_line_volume",
      "industry_sale_year2_volume",
      "industry_sale_year3_volume",
      "industry_sale_year4_volume",
      "industry_sale_year1_volume",
      "industry_sale_year5_volume",
    ];
  
    // Set column headers
    worksheet.columns = [
      { header: level1Label, key: "level1_name", width: 25 },
    //  { header: level3Label, key: "level3_name", width: 25 },
    //  { header: "Produto", key: "product_name", width: 20 },
      { header: "Períodos", key: "metric", width: 30 },
      { header: "Total_KGL", key: "total", width: 20 },
    ];
  
    // Group data by level1_name, level3, and produto
    const groupedData: Record<string, any[]> = data.reduce((acc, row) => {
      const level1 = row.level1_name || "Unknown";
      const level3 = row.level3_name || "Unknown";
      const produto = row.product_name || "Unknown";
      
      const groupKey = `${level1}`; // Create unique group key
      if (!acc[groupKey]) acc[groupKey] = [];
      acc[groupKey].push(row);
      
      return acc;
    }, {} as Record<string, any[]>);
  
    // Compute totals for each group
    Object.entries(groupedData).forEach(([groupKey, rows]) => {
      const [level1] = groupKey.split("||");
  
      industryColumns.forEach((header, index) => {
        const key = dataKeys[index] as keyof typeof rows[0];
        const total = rows.reduce((sum: number, row) => sum + (Number(row[key]) || 0), 0);
  
        worksheet.addRow({ level1_name: level1, metric: header, total });
      });
  
      // Add an empty row for spacing
      worksheet.addRow({});
    });
  
    // Apply styling to headers
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" }, // Light gray
      };
    });
  
    // Write file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ACP_Ano_Coluna.xlsx");
    });
  
    console.log("Excel export completed.");
  };


  const exportExcelacp = (data: any[]) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("acp Report");
  
    // Define Column Headers
    const columns = [
      { header: level0Label, key: "level0_name", width: 20 },
      { header: level1Label, key: "level1_name", width: 25 },
      { header: level2Label, key: "level2_name", width: 25 },
      { header: level3Label, key: "level3_name", width: 25 },
      { header: level4Label, key: "level4_name", width: 35 },
    //  { header: "ID", key: "id", width: 10 },
     // { header: "Product ID", key: "product_id", width: 15 },
      { header: "Produto", key: "product_name", width: 25 },
      { header: "Grupo do Cliente", key: "group_name", width: 30 },
      { header: "Acesso_Gr_Conta", key: "tipo", width: 25 },
      { header: "KGL_"+year0, key: "industry_base_line_volume", width: 22 },
      { header: "Valor_Produto_"+year0+ "(R$)", key: "produto_base", width: 32 },
      { header: "Total_"+year0+ "(R$)", key: "industry_base_line_valor", width: 32 },
      { header: "KGL_Q2", key: "industry_sale_year2_volume", width: 22 },
      { header: "KGL_Q3", key: "industry_sale_year3_volume", width: 22 },
      { header: "KGL_Q4", key: "industry_sale_year4_volume", width: 22 },
      { header: "KGL_Q1_26", key: "industry_sale_year1_volume", width: 22 },
      { header: "KGL_"+year1, key: "sum", width: 22 },
      { header: "Valor_Produto_"+year1+ "(R$)", key: "produto_proj1", width: 32 },
      { header: "Total_"+year1+ "(R$)", key: "sumvalor", width: 22 },
      { header: "Variação_"+year0+"_vs_"+year1, key: "variacao", width: 35 },
      { header: "KGL_"+year2, key: "industry_sale_year5_volume", width: 22 },
      { header: "Valor_Produto_"+year2+ "(R$)", key: "produto_proj2", width: 32 },
      { header: "Total_"+year2+ "(R$)", key: "industry_sale_year5_valor", width: 22 },
      { header: "Potencial_"+year0, key: "potential_base_line_volume", width: 22 },
      { header: "Potencial_"+year0+ "(R$)", key: "potential_base_line_valor", width: 22 },
      { header: "Potencial_"+year1, key: "potential_year1_volume", width: 22 },
      { header: "Potencial_"+year1+ "(R$)", key: "potential_year1_valor", width: 22 },
      { header: "Market_Share_"+year0, key: "share_line", width: 22 },
      { header: "Market_Share_"+year1, key: "shareyear1", width: 22 },
      { header: "Share_Regional_"+year1, key: "industry_soc_year1", width: 22 },
      // { header: "Total_"+year0, key: "total_base_line", width: 15 },
      // { header: "Volume_"+year1, key: "volume_proj", width: 15 },
      // { header: "Total_"+year1, key: "total_proj", width: 15 },
    ];
  
    worksheet.columns = columns;
  
    // Add Header Styling
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" }, // Light gray
      };
    });
  
    // Add Data to Worksheet
    data.forEach((row) => {
      const sum = Number(row.industry_sale_year1_volume) + 
      Number(row.industry_sale_year2_volume) + 
      Number(row.industry_sale_year3_volume) + 
      Number(row.industry_sale_year4_volume);

      const sumvalor = Number(row.industry_sale_year1_valor) + 
      Number(row.industry_sale_year2_valor) + 
      Number(row.industry_sale_year3_valor) + 
      Number(row.industry_sale_year4_valor);

const industryBaseLine = Number(row.industry_base_line_volume);
const tpPotentialYear1 = Number(row.potential_year1_volume);

// Prevent division by zero
const variacao = industryBaseLine !== 0 
? ((sum - industryBaseLine) / industryBaseLine) * 100 
: 0;

const share_year1 = tpPotentialYear1 !== 0 
    ? (sum / tpPotentialYear1) * 100 
    : 0;
      worksheet.addRow({
        level0_name: row.level0_name, // FIXED KEYS
      level1_name: row.level1_name,
      level2_name: row.level2_name,
      level3_name: row.level3_name,
      level4_name: row.level4_name,
      product_name: row.product_name,
      group_name: row.group_name,
      tipo: row.tipo,
      industry_base_line_volume: Number(row.industry_base_line_volume),
      produto_base: Number(row.produto_base),
      industry_base_line_valor: Number(row.industry_base_line_valor),
      industry_sale_year2_volume: Number(row.industry_sale_year2_volume),
      industry_sale_year3_volume: Number(row.industry_sale_year3_volume),
      industry_sale_year4_volume: Number(row.industry_sale_year4_volume),
      industry_sale_year1_volume: Number(row.industry_sale_year1_volume),
      sum: Number(row.industry_sale_year1_volume) + 
         Number(row.industry_sale_year2_volume) + 
         Number(row.industry_sale_year3_volume) + 
         Number(row.industry_sale_year4_volume),
         produto_proj1: Number(row.produto_proj1),
         sumvalor: Number(row.industry_sale_year1_valor) + 
         Number(row.industry_sale_year2_valor) + 
         Number(row.industry_sale_year3_valor) + 
         Number(row.industry_sale_year4_valor),
         variacao: variacao.toFixed(0),
      industry_sale_year5_volume: Number(row.industry_sale_year5_volume),
      produto_proj2: Number(row.produto_proj2),
      industry_sale_year5_valor: Number(row.industry_sale_year5_valor),
      potential_base_line_volume: Number(row.potential_base_line_volume),
      potential_base_line_valor: Number(row.potential_base_line_valor),
      potential_year1_volume: Number(row.potential_year1_volume),
      potential_year1_valor: Number(row.potential_year1_valor),
      share_line: Number(row.share_line),
      shareyear1: share_year1.toFixed(0),
      industry_soc_year1: Number(row.industry_soc_year1),
  
        // total_base_line: Number(row.total_base_line),
        // volume_proj: Number(row.volume_proj),
        // total_proj: Number(row.total_proj),
      });
      
    });

    
  
    // Write File & Trigger Download
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "ACP_Report.xlsx");
    });
  
    console.log("Excel export completed.");
  };

  const data = [
    { key: "1", name: "Projeções de Vendas Filial e Produto", modulo: "PGV", type: "bottomup", status: "Ativo" },
    { key: "2", name: "Projeção ACP", modulo: "PGV", type: "acp", status: "Ativo" },
    //{ key: "3", name: "Projeção ACP - Resumo Coluna/Ano", modulo: "PGV", type: "acpa", status: "Inativo" },
    // { key: "4", name: "Usuário Maria", registered_number: "", type: "usuario", status: "Inativo" },
  ];
  const [filteredData, setFilteredData] = useState(data);

  const columns = [
    { title: "Relatório", dataIndex: "name", key: "name" },
    {
      title: "Módulo",
      dataIndex: "modulo",
      key: "modulo",
      // render: (text: string) => <Tag color={text === "empresa" ? "blue" : "purple"}>{text}</Tag>,
    },
    {
      title: "Ações",
      key: "actions",
      render: (_: any, record: any) => (
        <div style={{  }}>
        <Button type="primary" icon={<FiDownload />} onClick={() => handleRowClick(record)}>
          Baixar Relatório
        </Button>
        </div>
      ),
    },
  ];

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
  
      const array = data.filter((record) => {
        // Check if the value exists in `name` or `modulo`
        const nameMatch = record["name"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
        const moduleMatch = record["modulo"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
  
        return nameMatch || moduleMatch;
      });
  
      setFilteredData(array); // Update the table with the filtered data
    } else {
      setSearch("");
      setFilteredData(data); // Reset to the full list when search is cleared
    }
  }
  async function handleChange(value: string | string[] | any) {
    setSelecteds(await value);

    console.log(selecteds);
  }

 
  return (
    <Breadcrumb path="Empresas" child={["Parâmetro"]}>
      <>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  searchTable(e.target.value)
                }
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={filteredData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

      </>
    </Breadcrumb>
  );
}
