import styled from "styled-components";
import { Col, ColProps, Button, ButtonProps } from "antd";

import backgroundImage from "../../../assets/background.png";
import textureImage from "../../../assets/texture.svg";

export const Container = styled(Col)<ColProps>`
  display: flex;
  height: 100vh;
  background-color: #00cc66;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;

  background: url(${textureImage}) no-repeat center;
  background-size: cover;
`;

export const Image = styled(Col)<ColProps>`
  flex: 1;
  height: 100vh;
  background: url(${backgroundImage}) no-repeat center;
  background-size: cover;
`;

export const Title = styled.h3`
  color: #ffffff;
  font-weight: bold;
  font-size: 32px;
  margin-top: 70px;
  margin-bottom: 5px;
  line-height: 24px;
`;

export const SubTitle = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
`;

export const ForgotPassword = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
`;

export const FistLogin = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
`;

export const SigninButton = styled(Button)<ButtonProps>`
  background-color: #008e47;
  color: #ffffff;
  border: 1px solid #008e47;

  &:hover {
    background-color: #008e47;
    color: #ffffff;
    border: 1px solid #008e47;
  }
`;

export const Signins = styled(Button)<ButtonProps>`
  background-color: #008e47;
  color: #ffffff;
  border: 1px solid #008e47;

  &:hover {
    background-color: #008e47;
    color: #ffffff;
    border: 1px solid #008e47;
  }
`
