import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { MapContainer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import PowerBi_all from "../../../services/Global/PowerBi";
import {
  Select,
  Layout,
  Spin,
  Collapse,
  Row,
  Col,
  Divider,
  Card,
  Button,
  Empty,
  Typography,
} from "antd";
import PQueue from "p-queue";
import debounce from "lodash.debounce";

const { Option } = Select;
const { Panel } = Collapse;

interface DadosMaps {
  ibge: number;
  uf: string;
  municipio: string;
  cod_fantasia: string;
  cor?: string;
  LATITUDE: string;
  LONGITUDE: string;
}

const estados = [
  { codigo: "11", nome: "Rondônia", sigla: "RO" },
  { codigo: "12", nome: "Acre", sigla: "AC" },
  { codigo: "13", nome: "Amazonas", sigla: "AM" },
  { codigo: "14", nome: "Roraima", sigla: "RR" },
  { codigo: "15", nome: "Pará", sigla: "PA" },
  { codigo: "16", nome: "Amapá", sigla: "AP" },
  { codigo: "17", nome: "Tocantins", sigla: "TO" },
  { codigo: "21", nome: "Maranhão", sigla: "MA" },
  { codigo: "22", nome: "Piauí", sigla: "PI" },
  { codigo: "23", nome: "Ceará", sigla: "CE" },
  { codigo: "24", nome: "Rio Grande do Norte", sigla: "RN" },
  { codigo: "25", nome: "Paraíba", sigla: "PB" },
  { codigo: "26", nome: "Pernambuco", sigla: "PE" },
  { codigo: "27", nome: "Alagoas", sigla: "AL" },
  { codigo: "28", nome: "Sergipe", sigla: "SE" },
  { codigo: "29", nome: "Bahia", sigla: "BA" },
  { codigo: "31", nome: "Minas Gerais", sigla: "MG" },
  { codigo: "32", nome: "Espírito Santo", sigla: "ES" },
  { codigo: "33", nome: "Rio de Janeiro", sigla: "RJ" },
  { codigo: "35", nome: "São Paulo", sigla: "SP" },
  { codigo: "41", nome: "Paraná", sigla: "PR" },
  { codigo: "42", nome: "Santa Catarina", sigla: "SC" },
  { codigo: "43", nome: "Rio Grande do Sul", sigla: "RS" },
  { codigo: "50", nome: "Mato Grosso do Sul", sigla: "MS" },
  { codigo: "51", nome: "Mato Grosso", sigla: "MT" },
  { codigo: "52", nome: "Goiás", sigla: "GO" },
  { codigo: "53", nome: "Distrito Federal", sigla: "DF" },
];

const getColorByCategory = (category: string) => {
  const colors: { [key: string]: string } = {
    Branco: "#FFFFFF",
    Vermelho: "#FF0000",
    Amarelo: "#FFFF00",
    Azul: "#0000FF",
    Rosa: "#FFC0CB",
    Verde: "#008000",
    Preto: "#000000",
    Cinza: "#808080",
    Laranja: "#FFA500",
    Marrom: "#8B4513",
    Roxo: "#800080",
  };
  return colors[category] || "#008000";
};

const Maps = () => {
  const [geoData, setGeoData] = useState<GeoJSON.FeatureCollection | null>(
    null
  );
  const [dadosGerais, setDadosGerais] = useState<DadosMaps[]>([]);
  const [selectedUF, setSelectedUF] = useState<string | undefined>(undefined);
  const [selectedMunicipio, setSelectedMunicipio] = useState<
    string | undefined
  >(undefined);
  const [marker, setMarker] = useState<L.Marker | null>(null);
  const mapRef = useRef<L.Map>(null);
  const cache = useRef<Map<string, any>>(new Map());

  const queue = new PQueue({ concurrency: 10 });

  const getDataMaps = async () => {
    const response = await PowerBi_all.getDataMapa();
    setDadosGerais(response);
    await loadMunicipiosData(response);
    return response;
  };

  const fetchGeoData = async (url: string, codigo: string) => {
    if (cache.current.has(codigo)) {
      return cache.current.get(codigo);
    }
    try {
      const response = await fetch(url);
      if (!response.ok) {
        console.error(`Erro ao carregar dados: ${codigo}`);
        return null;
      }
      const data = await response.json();
      cache.current.set(codigo, data);
      return data;
    } catch (error) {
      console.error(`Erro ao carregar dados: ${codigo}`, error);
      return null;
    }
  };

  const fetchMunicipioData = (codigo: string) =>
    fetchGeoData(
      `https://servicodados.ibge.gov.br/api/v3/malhas/municipios/${codigo}?formato=application/vnd.geo+json&qualidade=minima`,
      codigo
    );

  const fetchEstadoData = (codigo: string) =>
    fetchGeoData(
      `https://servicodados.ibge.gov.br/api/v3/malhas/estados/${codigo}?formato=application/vnd.geo+json&qualidade=maxima`,
      codigo
    );

  const loadMunicipiosData = useCallback(
    async (dadosGeral: DadosMaps[]) => {
      try {
        const filteredDadosGeral = selectedUF
          ? dadosGeral.filter((dado) => dado.uf === selectedUF)
          : dadosGeral;

        const estadoCodigosMap = filteredDadosGeral.reduce(
          (acc: any, dado: any) => {
            const estado = estados.find((e) => e.sigla === dado.uf);
            if (estado) acc[estado.codigo] = true;
            return acc;
          },
          {} as { [key: string]: boolean }
        );

        const uniqueEstadoCodigos = Object.keys(estadoCodigosMap);

        const uniqueMunicipioCodigos = Array.from(
          new Set(filteredDadosGeral.map((dado: any) => dado.ibge.toString()))
        );

        const [municipioData, estadoData] = await Promise.all([
          queue.addAll(
            uniqueMunicipioCodigos.map(
              (codigo) => () => fetchMunicipioData(codigo)
            )
          ),
          queue.addAll(
            uniqueEstadoCodigos.map((codigo) => () => fetchEstadoData(codigo))
          ),
        ]);

        const combinedGeoData: GeoJSON.FeatureCollection = {
          type: "FeatureCollection",
          features: [
            ...estadoData.flatMap((d) => d?.features || []),
            ...municipioData.flatMap((d) => d?.features || []),
          ],
        };

        setGeoData(combinedGeoData);

        if (mapRef.current && combinedGeoData.features.length > 0) {
          const bounds = L.geoJSON(combinedGeoData).getBounds();
          mapRef.current.fitBounds(bounds);
        }
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      }
    },
    [selectedUF]
  );

  useEffect(() => {
    getDataMaps();
  }, []);

  useEffect(() => {
    if (dadosGerais.length > 0) {
      loadMunicipiosData(dadosGerais);
    }
  }, [selectedUF, dadosGerais, loadMunicipiosData]);

  useEffect(() => {
    if (selectedMunicipio) {
      const municipio = dadosGerais.find(
        (dado) => dado.municipio === selectedMunicipio
      );
      if (municipio) {
        const municipioCodigo = municipio.ibge.toString();
        fetchMunicipioData(municipioCodigo).then((data) => {
          if (data) {
            setGeoData({
              type: "FeatureCollection",
              features: data.features,
            });
            if (mapRef.current) {
              const bounds = L.geoJSON(data).getBounds();
              mapRef.current.fitBounds(bounds);
            }
          }
        });
      }
    }
  }, [selectedMunicipio, dadosGerais]);

  const onEachFeature = useCallback(
    (feature: GeoJSON.Feature, layer: L.Layer) => {
      const codarea = feature?.properties?.codarea;
      if (codarea?.length === 7) {
        const municipioData = dadosGerais.find(
          (dado: any) => dado.ibge.toString() === codarea
        );
        const tooltipContent = municipioData
          ? ["ibge", "uf", "municipio"]
              .map((key) => `${key}: ${municipioData[key as keyof DadosMaps]}`)
              .join("<br>")
          : "Dados não disponíveis";
        layer.bindTooltip(tooltipContent);
        if (layer instanceof L.Path && municipioData) {
          layer.setStyle({
            color: getColorByCategory(municipioData["cor"] || ""),
          });
        }
      }
      layer.on({
        click: (e) => {
          console.log("Dados do município:", feature.properties);
          if (mapRef.current && feature.geometry) {
            const bounds = L.geoJSON(feature).getBounds();
            mapRef.current.fitBounds(bounds);
          }
          const municipio = dadosGerais.find(
            (dado) => dado.ibge.toString() === codarea
          );
          if (municipio) {
            setSelectedUF(municipio.uf);
            setSelectedMunicipio(municipio.municipio);
          }
          e.target.closePopup();
        },
      });
    },
    [dadosGerais]
  );

  const style = useCallback(
    (feature: GeoJSON.Feature | undefined) => {
      const codarea = feature?.properties?.codarea;
      if (codarea?.length === 7) {
        const municipioData = dadosGerais.filter(
          (dado: any) => dado.ibge.toString() === codarea
        );
        return municipioData.length
          ? {
              color: getColorByCategory(municipioData[0]["cor"] || ""),
              weight: 1,
            }
          : { color: "orange", weight: 1 };
      }
      return { color: "#000000", weight: 1 };
    },
    [dadosGerais]
  );

  const estadosDisponiveis = useMemo(
    () =>
      estados.filter((estado) =>
        dadosGerais.some((dado) => dado.uf === estado.sigla)
      ),
    [dadosGerais]
  );

  const municipiosDisponiveis = useMemo(
    () =>
      selectedUF
        ? Array.from(
            new Set(
              dadosGerais
                .filter((dado) => dado.uf === selectedUF)
                .map((dado) => dado.municipio)
            )
          )
        : [],
    [selectedUF, dadosGerais]
  );

  const municipiosParaMostrar = useMemo(
    () =>
      selectedMunicipio
        ? municipiosDisponiveis.filter(
            (municipio) => municipio === selectedMunicipio
          )
        : municipiosDisponiveis,
    [selectedMunicipio, municipiosDisponiveis]
  );

  const handleUFChange = debounce((value: any) => {
    setSelectedUF(value);
    setSelectedMunicipio(undefined);
  }, 300);

  const handleMunicipioChange = debounce((value: any) => {
    setSelectedMunicipio(value);
  }, 300);

  const addMarker = (LATITUDE: number, LONGITUDE: number) => {
    if (mapRef.current) {
      const marker = L.marker([LATITUDE, LONGITUDE], {
        icon: L.icon({
          iconUrl:
            "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
          iconSize: [25, 25],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
      }).addTo(mapRef.current);
      marker
        .bindPopup(
          `<b>LATITUDE:</b> ${LATITUDE}<br><b>LONGITUDE:</b> ${LONGITUDE}`
        )
        .openPopup();
      mapRef.current.setView([LATITUDE, LONGITUDE], 12);
    }
  };

  return (
    <Layout style={{ minWidth: 1400 }} className="fundo">
      <main className="main-container">
        <div className="sales-projection-header">
          <Col span={24} style={{ width: "100%" }}>
            <Row>
              <Col xl={3} xs={3} sm={3}>
                <Button
                  type="primary"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: 50,
                  }}
                  size="large"
                  onClick={() => [
                    setSelectedUF(undefined),
                    setSelectedMunicipio(undefined),
                  ]}
                >
                  Limpar
                </Button>
              </Col>
              <Col xl={4} xs={4} sm={4} />
              <Col xl={9} xs={9} sm={9}>
                <div className="header-title">
                  <span>Mapa Geologico do Brasil</span>
                </div>
                <div className="header-title-sub">
                  <span>Demonstração para Vendas</span>
                </div>
              </Col>
              <Col xl={8} xs={8} sm={8}></Col>
            </Row>
          </Col>
        </div>
      </main>
      {!geoData ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
            height: "708px",
          }}
        >
          <Spin tip="Carregando..." />
        </div>
      ) : (
        <Row style={{ width: "100%" }}>
          <Col span={17}>
            <Col xl={24} xs={24} sm={24}>
              <div style={{ display: "flex" }}>
                <Card
                  title="Estado"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Selecione um estado"
                      onChange={handleUFChange}
                      allowClear
                      showSearch
                      value={selectedUF}
                      style={{
                        width: "100%",
                      }}
                    >
                      {estadosDisponiveis
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((estado) => (
                          <Option key={estado.sigla} value={estado.sigla}>
                            {estado.nome}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Card>
                <Card
                  title="Município"
                  size="small"
                  bordered={false}
                  className="card-filter"
                >
                  <div className="report-card-content">
                    <Select
                      placeholder="Selecione um município"
                      onChange={handleMunicipioChange}
                      allowClear
                      showSearch
                      disabled={!selectedUF}
                      value={selectedMunicipio}
                      style={{
                        width: "100%",
                      }}
                    >
                      {municipiosDisponiveis
                        .sort((a, b) => a.localeCompare(b))
                        .map((municipio, index) => (
                          <Option key={index} value={municipio}>
                            {municipio}
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Card>
              </div>
            </Col>
            <Col span={24}>
              <Card>
                <div style={{ minHeight: 380 }}>
                  <MapContainer
                    ref={mapRef}
                    zoom={12}
                    style={{ width: "100%", height: "640px" }}
                    zoomControl={false}
                    attributionControl={false}
                  >
                    <GeoJSON
                      data={geoData}
                      style={style}
                      onEachFeature={onEachFeature}
                    />
                  </MapContainer>
                </div>
              </Card>
            </Col>
          </Col>
          <Col span={7}>
            <Col span={24}>
              <Card
                title="Dados do Município"
                size="small"
                bordered={false}
                className="card-filter"
                style={{ height: "758px", overflowY: "scroll" }}
              >
                {selectedUF ? (
                  <Collapse accordion>
                    {municipiosParaMostrar.map((municipio, index) => {
                      const dadosFiltrados = dadosGerais.filter(
                        (dado) => dado.municipio === municipio
                      );
                      return (
                        <Panel
                          header={`${municipio} (${dadosFiltrados.length})`}
                          key={index}
                          style={{
                            borderRadius: 4,
                            border: 0,
                            overflow: "hidden",
                          }}
                        >
                          {dadosFiltrados.map((dado, index) => (
                            <div key={index} style={{ padding: "10px 0" }}>
                              {index > 0 && <Divider />}
                              {Object.entries(dado).map(([key, value]) => (
                                <p key={key} style={{ margin: 0 }}>
                                  <strong>{key}:</strong> {value}
                                </p>
                              ))}
                              {/*dado.LATITUDE && dado.LONGITUDE && (
                                <Button
                                  type="primary"
                                  style={{ marginTop: 10 }}
                                  onClick={() => {
                                    const latitude = parseFloat(
                                      dado.LATITUDE.replace(",", ".")
                                    );
                                    const longitude = parseFloat(
                                      dado.LONGITUDE.replace(",", ".")
                                    );
                                    addMarker(latitude, longitude);
                                  }}
                                >
                                  Mostrar no Mapa
                                </Button>
                              )*/}
                            </div>
                          ))}
                        </Panel>
                      );
                    })}
                  </Collapse>
                ) : (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ padding: 150 }}
                    description={
                      <Typography.Text>
                        Selecione um estado para visualizar os Dados do
                        municípios
                      </Typography.Text>
                    }
                  />
                )}
              </Card>
            </Col>
          </Col>
        </Row>
      )}
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100%", height: 30 }}
      />
    </Layout>
  );
};

export default Maps;
