import Http from "../../../utils/http";

class PowerBi_all extends Http {
  public constructor() {
    super();
  }

  public getProductPotencialDashboard = async (
    companyId: number,
    years?: string[],
    level0_id?: number,
    level1_id?: number,
    level2_id?: number,
    level3_id?: number,
    texteFilter?: string,
    product_id?: number,
    client_id?: string
  ) => {
    const yearsParam = years?.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/product-potential/dashboard`,
      {
        params: {
          years: yearsParam,
          texteFilter,
          product_id,
          level0_id,
          level1_id,
          level2_id,
          level3_id,
          client_id,
        },
      }
    );
  };

  public getPowerBi = async (companyId: number) => {
    return this.instance.get(`/company/${companyId}/parameter/type/3`);
  };

  public getDataMapa = async () => {
    const response = await this.instance.get(`/global/base/pdv`);
    return response;
  };

  public getButtomUpFindRegional = async (
    companyId: number,
    level1_id?: number,
    user_id?: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional/find`,
      { params: { level1_id: level1_id, user_id } }
    );
  };

  public GetButtomUpRegional = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    level1_id: number,
    Business_type_id?: number,
    level0_id?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional`,
      {
        params: {
          years: yearsParam,
          filterRegional: filterRegional,
          level1_id: level1_id,
          business_type_id: Business_type_id,
          level0_id: level0_id,
        },
      }
    );
  };
  public GetButtomUpLevel0 = async (companyId: number, user_id: number) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/level0`,
      { params: { user_id: user_id } }
    );
  };
  public GetButtomUpLevel1 = async (
    companyId: number,
    user_id: number,
    level0_id: number
  ) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/level1`,
      { params: { user_id: user_id, level0_id: level0_id } }
    );
  };

  public GetButtomUpFilial = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    filterFilial?: number,
    business_type_id?: number,
    filterDiretoria?: number,
    TipoNegocio?: number,
    filterPresidencia?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/filial`,
      {
        params: {
          years: yearsParam,
          filterRegional: filterRegional,
          filterFilial: filterFilial,
          business_type_id: business_type_id,
          filterDiretoria: filterDiretoria,
          TipoNegocio: TipoNegocio,
          filterPresidencia: filterPresidencia,
        },
      }
    );
  };
  public GetDashboardTopdown = async (
    companyId: number,
    years: string[],
    TipoNegocio: number,
    filterPresidencia?: number,
    filterDiretoria?: number,
    filterRegional?: number,
    filterFilial?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/topdown`,
      {
        params: {
          years: yearsParam,
          business_type_id: TipoNegocio,
          level0_id: filterPresidencia,
          level1_id: filterDiretoria,
          level2_id: filterRegional,
          level3_id: filterFilial,
        },
      }
    );
  };

  public GetAdmDashboard = async (
    companyId: number,
    years: string[],
    filterRegional: string,
    filterFilial?: string,
    filterDiretoria?: string,
    filterPresidencia?: string,
    TipoNegocio?: string
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/filialadm`,
      {
        params: {
          years: yearsParam,
          filterPresidencia,
          filterDiretoria,
          filterRegional,
          filterFilial,
          business_type_id: TipoNegocio,
        },
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PowerBi_all();
