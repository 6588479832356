/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Segmented,
  Select,
  Spin,
  Statistic,
  Table,
  Tag,
} from "antd";
import { compareLabelsTables, useAppSelector } from "../../../hooks";
import { useState, useEffect, JSX } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import SalesForecastService from "../../../services/Global/SalesForecast";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import ReactEcharts from "echarts-for-react";
import "./index.css";
//import { formatBR } from "../../../utils";
import CompanyBusinessTypeService from "../../../services/Company/BusinessType";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from "@ant-design/icons";
import { useTheme } from "../../../styles/ThemeContext";
const Option = Select.Option;
export function TopDownPGV() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const idUser = useAppSelector((state) => state.auth.user.id);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const [valores, setValores] = useState<string>("R$");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [OptionsPresidente, setOptionsPresidente] = useState<any>([]);
  const [filterPresidente, setFilterPresidente] = useState<number>(0);
  const [OptionsDiretoria, setOptionsDiretoria] = useState<any>([]);
  const [OptionsFilial, setOptionsFilial] = useState<any>([]);
  const [filterFilial, setFilterFilial] = useState<number>(0);
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataTipoNegocio, setDataTipoNegocio] = useState<any>([]);
  const [LoadingRegional, setLoadingRegional] = useState<boolean>(false);
  const [LoadingFilial, setLoadingFilial] = useState<boolean>(false);
  const [TipoNegocio, setTipoNegocio] = useState<number>(0);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [OptionsRegional, setOptionsRegional] = useState<
    { level3_id: number; level3_external_name: string } | any
  >([]);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterDiretoria, setFilterDiretoria] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [valoresProdutos2, setValoresProdutos2] = useState<any>([]);
  const [valoresProdutos3, setValoresProdutos3] = useState<any>([]);
  const [valoresProdutos4, setValoresProdutos4] = useState<any>([]);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen2, setIsFullScreen2] = useState(false);
  const [isFullScreen3, setIsFullScreen3] = useState(false);
  const [isFullScreen4, setIsFullScreen4] = useState(false);
  const [DataFilialTable, setDataFilialTable] = useState<any>([]);
  const [dataCabecalho, setDataCabecalho] = useState<any>([]);
  const [level0Label, setLevel0Label] = useState<string | undefined>("");
  const [level1Label, setLevel1Label] = useState<string | undefined>("");
  const [level2Label, setLevel2Label] = useState<string | undefined>("");
  const [level3Label, setLevel3Label] = useState<string | undefined>("");
  const [level4Label, setLevel4Label] = useState<string | undefined>("");
  const [totalBaseLine, setTotalBaseLine] = useState<number>(0);
  const [totalProjection, setTotalProjection] = useState<number>(0);
  const [industryCagr, setIndustryCagr] = useState<number>(0);
  const [diferenca, setDiferenca] = useState<number>(0);
  const [industrySoc, setIndustrySoc] = useState<number>(0);
  const [industrySocBaseLine, setIndustrySocBaseLine] = useState<number>(0);
  const [industryShareLine, setIndustryShareLine] = useState<number>(0);
  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [contador, setContador] = useState<any>();
  const [linhas, setLinhas] = useState<number>(40);

  const [pages, setPages] = useState<any>([linhas - linhas, linhas - 1]);
  const [numData, setNumData] = useState<number>(0);
  const [yearNow, setYearNow] = useState<string>(
    `${new Date().getFullYear() - 1}/${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}`
  );
  const [yearNest, setYearNest] = useState<string>(
    `${new Date().getFullYear()}/${(new Date().getFullYear() + 1)
      .toString()
      .slice(-2)}`
  );
  const [fontSizeTitle, setFontSizeTitle] = useState<number>(
    (companyName || "").length > 15
      ? 14
      : (companyName || "").length > 12
      ? 15
      : (companyName || "").length > 8
      ? 16
      : (companyName || "").length > 4
      ? 17
      : 18
  );
  const { theme } = useTheme();
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const toggleFullScreen2 = () => {
    setIsFullScreen2(!isFullScreen2);
  };

  const toggleFullScreen3 = () => {
    setIsFullScreen3(!isFullScreen3);
  };
  const toggleFullScreen4 = () => {
    setIsFullScreen4(!isFullScreen4);
  };
  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);
  const handleFilter = async () => {
    GetTipoNegocio();
    GetDataLevel0();
    GetDataLevel1(filterPresidente);

    /*if (filterRegional !== 0 && filterRegional === undefined) {
      GetDataBottomUp(
        filterRegional,
        filterDiretoria,
        false,
        years,
        TipoNegocio,
        valores
      );
    }
    if (filterRegional !== 0 && filterRegional !== undefined) {
      GetDataBottomUp(
        filterRegional,
        filterDiretoria,
        false,
        years,
        TipoNegocio,
        valores
      );
    }*/
  };
  const atualizarDados = async (
    ano: any,
    TipoN: number,
    filterPre: number,
    filterDir: number,
    filterReg: number,
    filterFil: number
  ) => {
    await GetDataTopdownFilial(
      ano,
      TipoN,
      filterPre,
      filterDir,
      filterReg,
      filterFil
    );
  };

  const GetDataLevel0 = async () => {
    setFilterDiretoria(0);
    setFilterRegional(0);
    setFilterFilial(0);
    const response =
      await CompanyCommercialStructureService.getAllCommercialStrucuteLevel0(
        companyId
      );

    if (response.length === 1) {
      setOptionsPresidente(response);
      setFilterPresidente(response[0]?.id);
    } else {
      setOptionsPresidente(response);
    }
    GetDataLevel1(filterPresidente);

    atualizarDados(years, TipoNegocio, filterPresidente, 0, 0, 0);
  };

  const GetDataLevel1 = async (value: number) => {
    setFilterRegional(0);
    setFilterFilial(0);
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel1(
          companyId,
          value
        );

      setOptionsDiretoria(response);
    }
    atualizarDados(years, TipoNegocio, value, 0, 0, 0);

    //getDataLevel2(value);
  };
  const GetDataLevel2 = async (value: number) => {
    setFilterFilial(0);
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel2(
          companyId,
          value
        );
      setOptionsRegional(response);
    }
    atualizarDados(years, TipoNegocio, filterPresidente, value, 0, 0);

    //GetDataLevel3(value);
  };
  const GetDataLevel3 = async (value: number) => {
    if (value !== 0) {
      const response =
        await CompanyCommercialStructureService.getAllCommercialStrucuteLevel3(
          companyId,
          value
        );
      setOptionsFilial(response);
    }
    atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      value,
      0
    );
  };

  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };
  /*const totalBaseLine = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.baseLine || 0),
    0
  );*/
  /*const totalProjection = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection || 0),
    0
  );*/
  const totalProjection1 = DataFilialTable.reduce(
    (acc: any, curr: any) => acc + (curr.projection1 || 0),
    0
  );

  const LimparFiltros = () => {
    setFilialOptions([]);
    setFilterFilial(0);
    setFilterRegional(0);
    setFilterDiretoria(0);
    setDataFilial([]);
    setDataFilialTable([]);
    setDataProjecaodevendas([]);
    setValoresProdutos([]);
    setContador(null);
    GetDataBottomUps(0, 0, 0, 0, 0, valores);
    GetDataBottomUp(0, 0, false, years, 0, valores);
    GetDataTopdownFilial(years, 0, 0, 0, 0);
    // GetDataBottomUpFindRegional(0, Number(idUser));
  };

  const filtrarDados = (dados: any, filter: string, level: string) => {
    return dados
      .reduce((acc: any[], item: any) => {
        const existingItem = acc.find((x) => x.name === item[level]);
        if (existingItem) {
          existingItem.year0 += item[filter + "_base_line"];
          existingItem.year1 += item[filter + "_year1"];
          existingItem.baseLine += parseInt(item[filter + "_base_line"]) || 0;
          existingItem.projection += item[filter + "_year1"];
        } else {
          acc.push({
            id: item[level + "_id"],
            name: item[level],
            year0: item[filter + "_base_line"],
            year1: item[filter + "_year1"],
            baseLine: parseInt(item[filter + "_base_line"]) || 0,
            projection: item[filter + "_year1"],
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => b.year1 - a.year1);
  };
  const formataNome = (nome: string) => {
    return nome
      .split(" ")
      .map(
        (palavra) =>
          palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
      )
      .join(" ");
  };

  useEffect(() => {
    handleFilter();
    GetDataBottomUps(
      0,
      filterPresidente ? filterPresidente : 0,
      filterDiretoria ? filterDiretoria : 0,
      filterRegional ? filterRegional : 0,
      filterFilial ? filterFilial : 0,
      valores
    );
    atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial
    );

    GetDataBottomUp(
      filterRegional,
      filterDiretoria ? filterDiretoria : 0,
      false,
      years,
      0,
      valores
    );
  }, [years,theme]);

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)).slice(0, 3));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleRegionChange = async (value: any) => {
    await GetDataBottomUp(
      value,
      filterDiretoria,
      true,
      years,
      TipoNegocio,
      valores
    );
    GetDataBottomUps(
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      value,
      0,
      valores
    );
    setFilterRegional(value);
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      value,
      0
    );
    setFilterFilial(0);
    GetDataLevel3(value);
  };

  const handlePresidenteChange = async (value: number) => {
    GetDataLevel1(value);
    setFilterDiretoria(0);

    setFilterRegional(0);
    setFilterFilial(0);

    setFilialOptions([]);
    // await GetDataBottomUpFindRegional2(value, Number(idUser));
    GetDataBottomUps(TipoNegocio, value, 0, 0, 0, valores);
    setFilterPresidente(value);
  };
  const handleDiretoriaChange = async (value: any) => {
    await GetDataBottomUp(0, value, false, years, TipoNegocio, valores);
    await GetDataBottomUps(TipoNegocio, filterPresidente, value, 0, 0, valores);
    setFilterDiretoria(value);
    // await GetDataBottomUpFindRegional(value, Number(idUser), value);
    //setDataFilial([]);
    GetDataLevel2(value);
    setFilterRegional(0);
    setFilterFilial(0);
  };

  const handleFilialChangeModal = async (value: any) => {
    GetDataBottomUps(
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      value,
      valores
    );
    await atualizarDados(
      years,
      TipoNegocio,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      value
    );
    setFilterFilial(value);
  };

  async function GetDataBottomUps(
    Business_type_id?: number,
    level0_id?: number,
    level1_id?: number,
    level2_id?: number,
    level3_id?: number,
    valores?: any
  ) {
    setValoresProdutos([]);

    // setValoresProdutos();
  }

  useEffect(() => {
    setYear0(
      parameters.find((value: any) => value.name === "YEAR_BASE_LINE_LABEL")
        ?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    const qtdYears =
      parameters.find((value) => value.name === "QTD_YEARS")?.value || "1";
    setQdt_year(qtdYears);
  }, [parameters]);

  const [DataFilial, setDataFilial] = useState<any>([]);

  const [filialOptions, setFilialOptions] = useState([]);
  const formatValue = (value: any) => {
    if (isNaN(value)) {
      return "NaN";
    }
    value = Number(value);
    if (value === 0) {
      return "0";
    }
    if (value >= 1e12) {
      return `${(value / 1e12)?.toFixed(1)}T`;
    } else if (value >= 1e9) {
      return `${(value / 1e9)?.toFixed(1)}B`;
    } else if (value >= 1e6) {
      return `${(value / 1e6)?.toFixed(1)}M`;
    } else if (value >= 1e3) {
      return `${(value / 1e3)?.toFixed(1)}K`;
    } else {
      return value?.toFixed(1);
    }
  };
  const columns = [
    {
      title: "Filial",
      dataIndex: "name",
      key: "name",
      width: "35%",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Indústria",
      dataIndex: "baseLine",
      key: "baseLine",
      width: "110px",
      sorter: (a: any, b: any) => a.baseLine - b.baseLine,
      render: (text: any, record: any) => {
        return (
          <span>{record.baseLine ? formatValue(record.baseLine) : "0,00"}</span>
        );
      },
    },
    {
      title: "Proj Y+1",
      dataIndex: "projection",
      key: "projection",
      width: "90px",

      sorter: (a: any, b: any) => a.projection - b.projection,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection ? formatValue(record.projection) : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Proj Y+2",
      dataIndex: "projection1",
      key: "projection1",
      width: "90px",
      sorter: (a: any, b: any) => a.projection1 - b.projection1,
      render: (text: any, record: any) => {
        return (
          <span>
            {record.projection1 && record.projection1 > 0
              ? formatValue(record.projection1)
              : "0,00"}
          </span>
        );
      },
    },
    {
      title: "Variação",
      dataIndex: "variation",
      key: "variation",
      width: "90px",
      render: (text: any, record: any) => {
        const variation =
          record.baseLine !== null && record.projection !== null
            ? ((record.projection - record.baseLine) / record.baseLine) * 100
            : 0.0;
        const displayVariation = isFinite(variation)
          ? `${variation?.toFixed(1)}%`
          : "~%";
        return (
          <span>
            {variation > 0 && displayVariation !== "~%" ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : variation < 0 && displayVariation !== "~%" ? (
              <ArrowDownOutlined style={{ color: "red" }} />
            ) : null}
            {` ${displayVariation}`}
          </span>
        );
      },
    },
  ];
  interface Column {
    title: string;
    dataIndex: string;
    key: string;
    render?: (text: any, record: any) => JSX.Element;
  }

  const filteredColumns: Column[] = columns.filter(
    (column: Column) =>
      column.key !== "projection1" ||
      DataFilialTable.some((record: any) => record.projection1 > 0)
  );

  console.log("tipo" + TipoNegocio);

  async function GetDataTopdownFilial(
    year?: any,
    TipoNegocio?: number,
    filterPresidente?: number,
    filterDiretoria?: number,
    filterRegional?: number,
    filterFilial?: number
  ) {
    setDataFilialTable([]);
    setDataFilial([]);
    setLoadingFilial(true);

    console.log(year ? year : yearsOptions(Number(qdt_year)).slice(0, 3));
    const response = await PowerBi_all.GetDashboardTopdown(
      companyId,
      year ? year : yearsOptions(Number(qdt_year)).slice(0, 3),
      TipoNegocio ? TipoNegocio : 0,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial
    );
    //console.log(response?.records.length);

    if (response?.records?.length > 0) {
      setDataCabecalho(response?.total);
      setTotalBaseLine(
        response?.total?.industry_base_line
          ? response?.total?.industry_base_line
          : 0
      );
      setTotalProjection(
        response?.total?.industry_year1 ? response?.total?.industry_year1 : 0
      );
      setIndustryCagr(
        response?.total?.industry_cagr ? response?.total?.industry_cagr : 0
      );
      setDiferenca(response?.total?.diferenca ? response?.total?.diferenca : 0);
      setIndustrySocBaseLine(
        response?.total?.industry_soc_base_line
          ? response?.total?.industry_soc_base_line
          : 0
      );
      setIndustrySoc(
        response?.total?.industry_soc_year1
          ? response?.total?.industry_soc_year1
          : 0
      );
      setIndustryShareLine(
        response?.total?.share_line ? response?.total?.share_line : 0
      );

      setValoresProdutos(filtrarDados(response?.records, "industry", "level0"));
      setValoresProdutos3(
        filtrarDados(response?.records, "industry", "level1")
      );
      setValoresProdutos2(
        filtrarDados(response?.records, "industry", "level2")
      );
      setValoresProdutos4(
        filtrarDados(response?.records, "industry", "level3")
      );

      setNumData(filtrarDados(response?.records, "industry", "level0").length);
    }
  }

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year - 1; i++) {
      yearss.push(`year${i}`);
    }
    return yearss;
  };

  const handleChange = async (value: any) => {
    setYears(value);
    setDataProjecaodevendas([]);
    await GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      value,
      TipoNegocio,
      valores
    );
    if (filterRegional !== undefined) {
      await atualizarDados(
        value,
        TipoNegocio,
        filterPresidente,
        filterDiretoria,
        filterRegional,
        filterFilial
      );
    }
  };
  const handleChangeTipoNegocio = async (value: any) => {
    setTipoNegocio(value);
    await GetDataBottomUp(
      filterRegional,
      filterDiretoria,
      false,
      years,
      value,
      valores
    );
    await GetDataBottomUps(
      value,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial,
      valores
    );
    await atualizarDados(
      years,
      value,
      filterPresidente,
      filterDiretoria,
      filterRegional,
      filterFilial
    );
  };

  async function GetDataBottomUp(
    filtro?: any,
    filterDiretoria?: any,
    valid?: boolean,
    years?: any,
    Business_type_id?: number,
    valores?: string
  ) {
    setDataProjecaodevendas([]);
    setLoadingRegional(true);

    // setDataProjecaodevendas();
    setLoadingRegional(false);
  }
  async function GetTipoNegocio() {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    setDataTipoNegocio(response);
    if (response.length === 1) {
      setTipoNegocio(response[0]?.id);
    }
  }

  const configPorcentagemBottomUP = {
    title: {
      text: `Vendas - ${formataNome(
        companyName || ""
      )} - Cultivo \n ${yearNow.slice(2, 8)} (int.) ${yearNest.slice(
        2,
        8
      )} (Ext.)`,
      x: "center",
      textStyle: {
        fontSize: fontSizeTitle, // Reduzindo o tamanho da fonte do título
      },
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Indústria" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
      textStyle: {
        fontSize: 10, // Reduzindo o tamanho da fonte do tooltip
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos.map((item: any) => item.name),
      textStyle: {
        fontSize: 10, // Reduzindo o tamanho da fonte da legenda
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
      textStyle: {
        fontSize: 10, // Reduzindo o tamanho da fonte do toolbox
      },
    },
    series: [
      {
        name: "Cultivo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Cultivo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos
          .sort((a: any, b: any) => b.year1 - a.year1)
          .map((item: any) => ({
            name: item.name,
            value: item.year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;

  const configPorcentagemDiretoria = {
    title: {
      text: `Vendas - ${formataNome(
        companyName || ""
      )} - Polo \n ${yearNow.slice(2, 8)} (int.) ${yearNest.slice(
        2,
        8
      )} (Ext.)`,
      x: "center",
      textStyle: {
        fontSize: fontSizeTitle, // Reduzindo o tamanho da fonte do título
      },
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Indústria" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos2.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Polo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos2
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Polo",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos2
          .sort((a: any, b: any) => b.year1 - a.year1)
          .map((item: any) => ({
            name: item.name,
            value: item.year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;

  const configPorcentagemRegional = {
    title: {
      text: `Vendas - ${formataNome(
        companyName || ""
      )} - Regional \n ${yearNow.slice(2, 8)} (int.) ${yearNest.slice(
        2,
        8
      )} (Ext.)`,
      x: "center",
      textStyle: {
        fontSize: fontSizeTitle, // Reduzindo o tamanho da fonte do título
      },
    },
    backgroundColor: "transparent",
    color: [
      "#00cc66",
      "#0075ba",
      "#8d66c1",
      "#ffcc00",
      "#ff9900",
      "#66ccff",
      "#ff66cc",
      "#ccff66",
      "#ffcc66",
      "#66ffcc",
    ],
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        const formattedValue = formatValue(params.value);
        const year = params.seriesIndex === 0 ? "Indústria" : "Projeção";
        return `${params.seriesName} <br/>${params.name} : ${formattedValue} (${params.percent}%)<br/>Ano: ${year}`;
      },
    },
    legend: {
      show: true,
      orient: "horizontal",
      left: "scroll",
      top: "bottom",
      data: valoresProdutos3.map((item: any) => item.name),
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true, title: "Salvar" },
        magicType: { show: false, type: ["line", "bar"] },
        dataView: {
          show: true,
          readOnly: false,
          title: "Visualização de Dados",
          lang: ["Visualização de Dados", "Fechar", "Atualizar"],
        },
      },
    },
    series: [
      {
        name: "Regional",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["20%", "40%"],
        label: false,
        data: valoresProdutos3
          .sort((a: any, b: any) => b.year0 - a.year0)
          .map((item: any) => ({
            name: item.name,
            value: item.year0,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
      {
        name: "Regional",
        type: "pie",
        center: ["50%", "40%"],
        radius: ["45%", "60%"],
        label: false,
        data: valoresProdutos3
          .sort((a: any, b: any) => b.year1 - a.year1)
          .map((item: any) => ({
            name: item.name,
            value: item.year1,
          })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  } as any;

  const sortedCategories = Array.from(
    new Set(DataProjecaodevendas.map((item: any) => item.name as string))
  )
    .map((name) => ({
      name,
      total: DataProjecaodevendas.filter(
        (item: any) => item.name === name
      ).reduce(
        (sum: any, item: any) =>
          sum + (valores !== "R$" ? item.total_vol : item.total_volume),
        0
      ),
    }))
    .sort((a, b) => a.total - b.total) // Ordena pelo total de menor para maior
    .map((item) => item.name);

  const configFilial: any =
    valoresProdutos4.length > 0
      ? {
          title: {
            text: `Vendas  ${formataNome(companyName || "")} - Filial`,
            x: "center",
          },
          backgroundColor: "transparent",
          legend: { show: true, top: "25px" },
          grid: { left: "3%", right: "10%", bottom: "3%", containLabel: true },
          xAxis: {
            type: "category",
            data: valoresProdutos4
              .sort((a: any, b: any) => b.projection - a.projection)
              .filter((item: any) => item.name !== null)
              .slice(pages[0], pages[1])
              .map((item: any) => item.name),
            axisLabel: {
              rotate: 90,
            },
          },
          color: ["#66CCFF", "#0075BA"],
          yAxis: {
            type: "value",
            axisTick: { alignWithLabel: true },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: { type: "shadow" },
          },
          toolbox: {
            show: true,
            feature: {
              saveAsImage: { show: true, title: "Salvar" },
              magicType: { show: true, type: ["line", "bar"] },
              dataView: {
                show: true,
                readOnly: false,
                title: "Visualização de Dados",
                lang: ["Visualização de Dados", "Fechar", "Atualizar"],
              },
            },
          },
          series: [
            {
              name: `${yearNow}`,
              type: "bar",
              data: valoresProdutos4
                .sort((a: any, b: any) => b.projection - a.projection)
                .filter((item: any) => item.name !== null)
                .slice(pages[0], pages[1])
                .map((item: any) => item.baseLine),
              label: {
                show: true,
                position: "top",
                formatter: (params: any) => formatValue(params.value),
                rotate: 90,
              },
            },
            {
              name: `${yearNest}`,
              type: "bar",
              data: valoresProdutos4
                .sort((a: any, b: any) => b.projection1 - a.projection1)
                .filter((item: any) => item.name !== null)
                .slice(pages[0], pages[1])
                .map((item: any) => item.projection),
              label: {
                show: true,
                position: "top",
                formatter: (params: any) => formatValue(params.value),
                rotate: 90,
              },
            },
            ...[1].map((i) => {
              const projectionKey = `projection${i}`;
              if (valoresProdutos4.some((item: any) => item[projectionKey])) {
                return {
                  name: `Projeção Y+${i + 1}`,
                  type: "bar",
                  data: valoresProdutos4
                    .sort((a: any, b: any) => b.projection1 - a.projection1)
                    .filter((item: any) => item.name !== null)
                    .slice(pages[0], pages[1])
                    .map((item: any) => item[projectionKey]),
                  label: {
                    show: true,
                    position: "top",
                    formatter: (params: any) => formatValue(params.value),
                    rotate: 90,
                  },
                };
              }
            }),
          ],
        }
      : {};

  return (
    <Row style={{ minWidth: 1400 }}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Col span={24} style={{ width: "100%" }}>
              <Row>
                <Col xl={3} xs={3} sm={3}>
                  <Button
                    type="primary"
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: 50,
                    }}
                    size="large"
                    onClick={() => LimparFiltros()}
                  >
                    Limpar
                  </Button>
                </Col>
                <Col xl={4} xs={4} sm={4} />
                <Col xl={9} xs={9} sm={9}>
                  <div className="header-title">
                    <span>PROJEÇÃO DE VENDAS - DIRETORIA</span>
                  </div>
                  <div className="header-title-sub">
                    <span>Modúlo: Projeções Diretoria-Regional</span>
                  </div>
                </Col>
                <Col xl={8} xs={8} sm={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <b style={{ color: "#fff" }}>Atualizar: </b>
                    <Select
                      defaultValue="0"
                      style={{ width: 120 }}
                      value={timeAtualizarTelaAuto}
                      onChange={(e) => {
                        setTimeAtualizarTelaAuto(e);
                      }}
                    >
                      <Option value="0">Não</Option>
                      <Option value="5">5 Sec</Option>
                      <Option value="10">10 Sec</Option>
                      <Option value="20">20 Sec</Option>
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </main>
        <div className="report-container">
          <Row style={{ width: "100%" }}>
            {timeAtualizarTelaAuto !== "0" && (
              <div className="divtimer">
                <div className="timer">Atualiza em {contador} segundos</div>
              </div>
            )}
          </Row>

          <Row style={{ width: "100%", paddingTop: 5 }}>
            <Col xl={24} xs={24} sm={24}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Card
                    bordered={true}
                    size="small"
                    title={`Indústria ${yearNow}`}
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalBaseLine)}
                      />
                    </div>
                  </Card>

                  <Card
                    bordered={true}
                    size="small"
                    title={`Projeção ${yearNest}`}
                    className="card-statistic"
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{ color: "#3f8600" }}
                        value={formatValue(totalProjection)}
                      />
                    </div>
                  </Card>
                  {/*totalProjection1 !== 0 && (
                    <Card
                      bordered={true}
                      size="small"
                      title="Projeção Y+2"
                      className="card-statistic"
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{ color: "#3f8600" }}
                          value={formatValue(industryCagr)}
                        />
                      </div>
                    </Card>
                  )} */}
                  <Card
                    title={`${yearNow} vs ${yearNest}`}
                    size="small"
                    bordered={true}
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            diferenca > 0
                              ? "green"
                              : diferenca < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          diferenca !== 0 ? `${diferenca?.toFixed(1)}%` : "0%"
                        }
                        prefix={
                          diferenca > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : diferenca < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`SOC ${yearNow}`}
                    size="small"
                    bordered={true}
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industrySocBaseLine > 0
                              ? "green"
                              : industrySocBaseLine < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industrySocBaseLine !== 0
                            ? `${industrySocBaseLine?.toFixed(1)}%`
                            : "0%"
                        }
                        prefix={
                          industrySocBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industrySocBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`SOC ${yearNest}`}
                    size="small"
                    bordered={true}
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industrySoc > 0
                              ? "green"
                              : industrySoc < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industrySoc !== 0
                            ? `${industrySoc?.toFixed(1)}%`
                            : "0%"
                        }
                        prefix={
                          industrySocBaseLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industrySocBaseLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title="Market Share"
                    size="small"
                    bordered={true}
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industryShareLine > 0
                              ? "green"
                              : industryShareLine < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industryShareLine !== 0
                            ? `${industryShareLine?.toFixed(1)}%`
                            : "0%"
                        }
                        prefix={
                          industryShareLine > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industryShareLine < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  <Card
                    title={`Indústria Cagr`}
                    size="small"
                    bordered={true}
                    className="card-statistic"
                    style={{ minWidth: 150 }}
                  >
                    <div className="report-card-content">
                      <Statistic
                        valueStyle={{
                          color:
                            industryCagr > 0
                              ? "green"
                              : industryCagr < 0
                              ? "red"
                              : "gray",
                        }}
                        value={
                          industryCagr !== 0
                            ? `${industryCagr?.toFixed(1)}%`
                            : "0%"
                        }
                        prefix={
                          industryCagr > 0 ? (
                            <ArrowUpOutlined style={{ color: "green" }} />
                          ) : industryCagr < 0 ? (
                            <ArrowDownOutlined style={{ color: "red" }} />
                          ) : null
                        }
                      />
                    </div>
                  </Card>
                  {/*totalProjection1 !== 0 && (
                    <Card
                      title="BL vs Y+2"
                      size="small"
                      bordered={true}
                      style={{ minWidth: 150 }}
                    >
                      <div className="report-card-content">
                        <Statistic
                          valueStyle={{
                            color:
                              totalProjection1 - totalBaseLine > 0
                                ? "green"
                                : totalProjection1 - totalBaseLine < 0
                                  ? "red"
                                  : "yellow",
                          }}
                          value={
                            totalBaseLine !== 0
                              ? `${(
                                ((totalProjection1 - totalBaseLine) /
                                  totalBaseLine) *
                                100
                              ).toFixed(2)}%`
                              : "0%"
                          }
                          prefix={
                            totalProjection1 - totalBaseLine > 0 ? (
                              <ArrowUpOutlined style={{ color: "green" }} />
                            ) : totalProjection1 - totalBaseLine < 0 ? (
                              <ArrowDownOutlined style={{ color: "red" }} />
                            ) : null
                          }
                        />
                      </div>
                    </Card>
                      )*/}
                </div>
                <div style={{ display: "flex" }}>
                  <Card
                    title="Ano"
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        defaultValue="0"
                        maxTagCount={2}
                        mode="multiple"
                        placeholder="Selecione o ano"
                        onChange={handleChange}
                        maxCount={3}
                        value={years.slice(0, 3)}
                        style={{
                          width: years.length > 2 ? 280 : 230,
                          backgroundColor: "#fff",
                          borderRadius: "7px",
                          marginLeft: 5,
                        }}
                        options={yearsOptions(Number(qdt_year)).map(
                          (year: string, index: number) => ({
                            value: year,
                            label: eval(`year${index}`),
                            disabled: index < 2,
                          })
                        )}
                      />
                    </div>
                  </Card>

                  <Card
                    title="Negócio"
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Negócio"
                        onChange={handleChangeTipoNegocio}
                        value={TipoNegocio}
                        defaultValue={TipoNegocio}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...DataTipoNegocio.map((item: any) => ({
                            value: item.id,
                            label: item.name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                  <Card
                    title={level0Label}
                    size="small"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder={level0Label}
                        onChange={handlePresidenteChange}
                        value={filterPresidente}
                        defaultValue={filterPresidente}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" },
                          ...OptionsPresidente.sort((a: any, b: any) =>
                            compareLabelsTables("level0_external_name")(a, b)
                          ).map((item: any) => ({
                            value: item.level0_id,
                            label: item.level0_external_name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                  {filterPresidente !== 0 && (
                    <Card
                      title={level1Label}
                      size="small"
                      bordered={false}
                      className="card-filter"
                    >
                      <div className="report-card-content">
                        <Select
                          placeholder={level1Label}
                          onChange={handleDiretoriaChange}
                          value={filterDiretoria}
                          defaultValue={filterDiretoria}
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Todos" },
                            ...OptionsDiretoria.sort((a: any, b: any) =>
                              compareLabelsTables("level1_external_name")(a, b)
                            ).map((item: any) => ({
                              value: item.level1_id,
                              label: item.level1_external_name,
                            })),
                          ]}
                        />
                      </div>
                    </Card>
                  )}
                  {filterDiretoria !== 0 && (
                    <Card
                      title={level2Label}
                      size="small"
                      bordered={false}
                      className="card-filter"
                    >
                      <div className="report-card-content">
                        <Select
                          placeholder={level2Label}
                          onChange={handleRegionChange}
                          value={filterRegional}
                          defaultValue={filterRegional}
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Todos" },
                            ...OptionsRegional.sort((a: any, b: any) =>
                              compareLabelsTables("level2_external_name")(a, b)
                            ).map((item: any) => ({
                              value: item.level2_id,
                              label: item.level2_external_name,
                            })),
                          ]}
                        />
                      </div>
                    </Card>
                  )}
                  {filterRegional !== 0 && (
                    <Card
                      title={level4Label}
                      size="small"
                      bordered={false}
                      className="card-filter"
                    >
                      <div className="report-card-content">
                        <Select
                          placeholder={level3Label}
                          defaultValue={{
                            label: "Todos",
                            value: 0,
                          }}
                          onChange={handleFilialChangeModal}
                          value={filterFilial}
                          style={{ width: "100%" }}
                          options={[
                            { value: 0, label: "Todos" },
                            ...OptionsFilial.sort((a: any, b: any) =>
                              compareLabelsTables("level3_external_name")(a, b)
                            ).map((item: any) => ({
                              label: item.level3_external_name,
                              value: item.level3_id,
                            })),
                          ]}
                        />
                      </div>
                    </Card>
                  )}
                </div>
              </div>

              <Row style={{ width: "100%" }}>
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={8} xs={24}>
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen2
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemBottomUP}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen2 ? "100vh" : 400,
                            width: isFullScreen2 ? "100vw" : "100%",
                            padding: isFullScreen2 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen2
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen2 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen2}
                        />
                      </div>
                    </Card>
                  </Col>

                  {/* Second Chart */}

                  {/* Third Chart */}
                  <Col xl={8} xs={24}>
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen3
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemRegional}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen3 ? "100vh" : 400,
                            width: isFullScreen3 ? "100vw" : "100%",
                            padding: isFullScreen3 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen3
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen3 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen3}
                        />
                      </div>
                    </Card>
                  </Col>
                  <Col xl={8} xs={24}>
                    <Card
                      bordered={true}
                      style={{ width: "100%", marginBottom: 6 }}
                    >
                      <div
                        className={
                          isFullScreen4
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        <ReactEcharts
                          option={configPorcentagemDiretoria}
                          lazyUpdate={true}
                          notMerge={true}
                          theme={localStorage.getItem("theme") || "light"}
                          style={{
                            height: isFullScreen4 ? "100vh" : 400,
                            width: isFullScreen4 ? "100vw" : "100%",
                            padding: isFullScreen4 ? 50 : 0,
                          }}
                        />
                        <Button
                          style={
                            isFullScreen4
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen4 ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen4}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row
                  style={{
                    width: "100%",
                    height: "45px",
                    marginBottom: "10px",
                  }}
                >
                  <Col span={24}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          fontSize: "20px",
                          padding: "0 15px",
                          height: "40px",
                          display: pages[0] > 0 ? "block" : "none",
                        }}
                        onClick={() =>
                          pages[0] > 0
                            ? setPages([pages[0] - linhas, pages[1] - linhas])
                            : null
                        }
                      >
                        &larr;
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        style={{
                          fontSize: "20px",
                          padding: "0 15px",
                          height: "40px",
                          display:
                            pages[0] < numData - linhas ? "block" : "none",
                        }}
                        onClick={() =>
                          setPages([pages[0] + linhas, pages[1] + linhas])
                        }
                      >
                        &rarr;
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* Bottom Chart - Full Width */}
                <Row style={{ width: "100%" }} gutter={[8, 8]}>
                  <Col xl={24} xs={24}>
                    <Card
                      bordered={true}
                      style={{ height: 500, width: "100%" }}
                    >
                      <div
                        className={
                          isFullScreen
                            ? "iframe-container fullscreen"
                            : "iframe-container"
                        }
                      >
                        {LoadingRegional ? (
                          <Spin
                            size="large"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              marginTop: 150,
                            }}
                          />
                        ) : valoresProdutos3.length > 0 ? (
                          <ReactEcharts
                            option={configFilial}
                            lazyUpdate={true}
                            notMerge={true}
                            theme={localStorage.getItem("theme") || "light"}
                            style={{
                              height: isFullScreen ? "100vh" : 400,
                              width: isFullScreen ? "100vw" : "100%",
                              padding: isFullScreen ? 50 : 0,
                            }}
                          />
                        ) : (
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            style={{ padding: 10 }}
                            description={
                              <b style={{ fontSize: 20 }}>
                                Não há registro de movimentos associados a esta
                                Diretoria. <br />
                                <Tag color="gray" style={{ fontSize: 20 }}>
                                  {
                                    OptionsDiretoria.find(
                                      (item: any) => item.id === filterDiretoria
                                    )?.level1_external_name
                                  }
                                </Tag>
                              </b>
                            }
                          />
                        )}
                        <Button
                          style={
                            isFullScreen
                              ? {
                                  marginTop: 50,
                                  marginLeft: "-50px",
                                  width: 32,
                                  right: 20,
                                }
                              : {
                                  width: 32,
                                  padding: "4px 15px",
                                  position: "absolute",
                                  right: 10,
                                }
                          }
                          icon={
                            isFullScreen ? (
                              <FullscreenExitOutlined />
                            ) : (
                              <FullscreenOutlined />
                            )
                          }
                          onClick={toggleFullScreen}
                        />
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
      <br></br>
      <footer
        className="main-container"
        style={{ minWidth: 1400, width: "100vw", height: 30 }}
      />
    </Row>
  );
}
