import { createGlobalStyle } from "styled-components";

// .ant-table-body::-webkit-scrollbar {
//   width: 15px;
//   height: 13px;
// }

export default createGlobalStyle`
 [data-theme="light"] {
  --bg-color: #f5f5f5;
  --text-color:rgb(41, 41, 41);
  --bg-color-ligth: rgb(160, 160, 160);
  --bg-color-ligth3: rgb(185, 185, 185);
  --white-color: #FFF;
  --primary-color:rgb(35, 217, 120);
  --primary-color2: #c7f7d4;
  --secondary-color: #FFF;
  --logo-drop-shadow: #000;
}

/* Tema Escuro */
[data-theme="dark"] {
  --bg-color: rgb(41, 41, 41);
  --text-color: #f5f5f5;
  --bg-color-ligth: rgb(99, 99, 99);
  --bg-color-ligth3: rgb(19, 19, 19);
  --white-color: #1f1f1f;
  --primary-color:rgb(30, 148, 85);
  --primary-color2: #1e9455;
  --secondary-color: #1f1f1f;
  --logo-drop-shadow: drop-shadow(0px 0px 1px rgb(35, 217, 120));
}
  .logo{
  filter: var(--logo-drop-shadow);
  transition: all 0.5s ease-in-out !important;
  }

.ant-pagination-item a{
  color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
  }
.leaflet-container{
  background-color: var(--bg-color) !important;
  }
  ::-webkit-scrollbar-track {
    background: red;        
  }

  ::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 20px;       
    border: 3px solid red;
  }

 .fundo{
 background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  
}
  .ant-card-body{
    padding: 16px !important;
  }


  
  .ant-input-number-input {
    text-align: right !important;
  }

  .color {
    background-color: var(--white-color) !important;
  }
  .icon{
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
    }

  .iframe-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--bg-color);
  z-index: 9999;
}

  .ant-radio-inner{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--text-color);
    border-color: #a3a3a3;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: all 0.5s;
  }

  .colss {
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  width: calc(20% - 8px);
  padding: 12px;
  border-radius: 4px;
}

  .textModel{
  display: flex;
  text-align: center;
  justify-content: center;
  }

  .required{
    color:rgb(0, 160, 80);
    margin-left: 5px;
  }
    
/* Aplicando no body */
body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.5s ease-in-out,color 0.5s ease-in-out;
}
.ant-layout-content {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.5s ease-in-out;
}
  .ant-empty-description{
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
    .ant-select-selection-item-content{
      color: var(--text-color) !important;
      transition: all 0.5s ease-in-out !important;
    }
      .ant-select-selection-item-disabled{
        background-color: var(--bg-color) !important;
        transition: all 0.5s ease-in-out !important;
      }
/* ==================== BUTTON ==================== */
.ant-btn {
  //background-color: var(--primary-color) !important;
 // color: white !important;
  //border: 1px solid var(--primary-color) !important;
}
.ant-btn:hover {
//  background-color: var(--primary-color) !important;
  color: var(--text-color) !important;
 // border: 1px solid var(--primary-color) !important;
}

.ant-btn[disabled], 
.ant-btn-disabled {
  color: #aaa !important; /* Cor do texto para desativado */
  cursor: not-allowed !important;
  opacity: 0.6;
}


/* ==================== INPUTS ==================== */
.ant-input,
.ant-input-affix-wrapper,
.ant-picker,
.ant-select-selector,
.ant-textarea {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
 // border: 1px solid var(--primary-color) !important;
  border-radius: 4px !important;
}
.ant-select-dropdown {
  background-color: var(--bg-color) !important;
  color: var(--white-color) !important;
}
  .ant-select-single.ant-select-open .ant-select-selection-item { 
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
  .ant-select-item-option-selected {
    background-color: var(--primary-color2) !important;
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
  .ant-select-item-option-content{
    color: var(--text-color) !important;
    }
  .ant-select-selection-placeholder {
    color: var(--bg-color-ligth) !important;
    transition: all 0.5s ease-in-out !important;
  }
 .ant-input::placeholder,
.ant-input-affix-wrapper input::placeholder {
  color: var(--bg-color-ligth) !important; 
  transition: all 0.5s ease-in-out !important;
}

.ant-picker-input input::placeholder {
  color: var(--bg-color-ligth) !important; 
  transition: all 0.5s ease-in-out !important;
}

.ant-input-affix-wrapper .anticon,
.ant-picker .anticon,
.ant-select .anticon {
  color: var(--bg-color-ligth) !important; 
  transition: all 0.5s ease-in-out !important;
}

/* ==================== TABELAS ==================== */
.ant-table {
  background-color: var(--secondary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: var(--text-color) !important;
  //border: 1px solid var(--bg-color-ligth) !important;
  border-radius: 10px 10px 0px 0px !important;
}

.ant-table-thead > tr > th {
  background-color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: white !important;
}

  .ant-table-cell-fix-left {
    background-color: var(--bg-color-ligth) !important;
    transition: all 0.5s ease-in-out !important;
  }
.ant-table-cell-fix-right {
  background-color: var(--bg-color-ligth) !important;
  transition: all 0.5s ease-in-out !important;
  }
.ant-table-tbody > tr:hover > td {
  background-color: var(--bg-color-ligth) !important;
  transition: all 0.5s ease-in-out !important;
  cursor: pointer;
}
  td.ant-table-cell {
    background-color: var(--secondary-color) !important;
    transition: all 0.5s ease-in-out !important;
    color: var(--text-color) !important;
}

/* ==================== MENU ==================== */
.ant-menu {
  background-color: var(--secondary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: var(--text-color) !important;
}

.ant-menu-item {
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
}

.ant-menu-item:hover {
  background-color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: white !important;
}

/* ==================== CARD ==================== */
.ant-card {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
 // border: 1px solid var(--primary-color) !important;
}
 .ant-card-head-title {
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
  //background-color: var(--secondary-color) !important;
}

/* ==================== MODAL ==================== */
.ant-modal-content {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
  border: 1px solid var(--primary-color) !important;
}
  .ant-modal-title {
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
    background-color: var(--secondary-color) !important;
  }

/* ==================== DROPDOWN ==================== */
.ant-dropdown-menu {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
  border: 1px solid var(--primary-color) !important;
}
.ant-dropdown-menu-item {
  color: var(--text-color) !important;
  border: 1px solid var(--bg-color) !important;
}
  .ant-dropdown-menu-item-selected{
    background-color: var(--primary-color2) !important;
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
    }
.ant-dropdown-menu-item:hover {
  background-color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

/* ==================== AVATAR ==================== */
.ant-avatar {
  background-color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
  color: white !important;
}

/* ==================== TABS ==================== */
.ant-tabs-tab {
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
}

.ant-tabs-tab-active {
  color: var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
}

/* ==================== BADGE ==================== */
.ant-badge-status-text {
transition: all 0.5s ease-in-out !important;
  color: var(--text-color) !important;
}

/* ==================== ALERT ==================== */
.ant-alert {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
  border: 1px solid var(--primary-color) !important;
}

/* ==================== PROGRESS ==================== */
.ant-progress-outer {
transition: all 0.5s ease-in-out !important;
  background-color: var(--secondary-color) !important;
}

/* ==================== SPINNER (LOADER) ==================== */
.ant-spin-dot-item {
transition: all 0.5s ease-in-out !important;
  background-color: var(--primary-color) !important;
}

/* ==================== TOOLTIP ==================== */
.ant-tooltip {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 4px !important;
  transition: all 0.5s ease-in-out !important;
}
  .ant-tooltip-inner{
    background-color: var(--bg-color-ligth)!important;
    transition: all 0.5s ease-in-out !important;
  }

/* ==================== DRAWER ==================== */

.ant-drawer-content {
  background-color: var(--secondary-color) !important;
  color: var(--text-color) !important;
  border: 1px solid var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
}
.ant-drawer-header {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
}

/* ==================== SEGMENTED ==================== */

.ant-segmented {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  border: 1px solid var(--primary-color2) !important;
  transition: all 0.5s ease-in-out !important;
}
  .ant-segmented-item {
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
  .ant-segmented-item-selected {
    background-color: var(--primary-color2) !important;
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
   

.ant-segmented-item-active {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
}
.ant-segmented-item:hover {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: 0px solid var(--primary-color) !important;
  transition: all 0.5s ease-in-out !important;
}

.ant-typography {
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
}

  /* ==================== RADIOBUTTON ==================== */
label.ant-radio-button-wrapper{
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease-in-out !important;
}
  .ant-radio-button-checked {
    background-color: var(--primary-color) !important;
    color: white !important;
    transition: all 0.5s ease-in-out !important;
  }
  .ant-radio-label{
    color: var(--text-color) !important;
    transition: all 0.5s ease-in-out !important;
  }
  /* ==================== HEADER ==================== */

  .ant-layout-header{
    background-color: var(--white-color) !important;
    color: var(--text-color) !important;
    //border-bottom: 1px solid var(--bg-color-ligth) !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    transition: all 0.5s ease-in-out !important;
}
    .desktop-menu{
      background-color: var(--white-color) !important;
      transition: all 0.5s ease-in-out !important;
      }

`;
