import { Row, Col, Card, message, Modal, Button ,Typography} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import { CardProgress } from "../../components/CardProgress";
import { Loading } from "../../components/Loading";
import { TableStatus } from "../../components/TableStatus";
import { useAppSelector } from "../../hooks";
import PgcService from "../../services/Pgc";
import { NoticeType } from "antd/lib/message/interface";

interface DashboardProps {
  [name: string]: {
    permission?: boolean;
    status: string;
    label: string;
    color: string;
    percent: number;
    period_open: boolean;
    period_past: boolean;
    initial_date: string;
    final_date: string;
  };
}
interface RecordsProps {
  id: number;
  name: string;
  filial: string;
  status: string;
}
interface StatusProps {
  id: number;
  level4_external_name: string;
  level3_external_name: string;
  pgc_action_plan: string;
  pgc_wallet_customer_mapping: string;
  color: string;
}

export function Pgc() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<DashboardProps>({});
  const [records, setRecords] = useState<RecordsProps[]>([]);
  const [loadingRecordsBudget, setLoadingRecordsBudget] = useState(false);
  const [localName, setLocalName] = useState("");
  const [loadingRecordsLevel3Mapping, setLoadingRecordsLevel3Mapping] =
    useState(false);
  const [loadingRecordsActionPlan, setLoadingRecordsActionPlan] =
    useState(false);
  const [loadingRecordsCustomerMapping, setLoadingRecordsCustomerMapping] =
    useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    
        const ShowMSG = (type: NoticeType, test: string) => {
          messageApi.open({
            type,
            content: test,
          });
        };
  useEffect(() => {
    loadDashboard();

    async function loadDashboard() {
      try {
        setLoading(true);
        const response = await PgcService.getDashboard(companyId);
        setDashboard(response);
      } catch (err) {
        ShowMSG("error",err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId]);

  async function handleDetailActionPlan() {
    setRecords([]);
    setLocalName("Plano de Ação");
    try {
      setLoadingRecordsActionPlan(true);
      const response: StatusProps[] = await PgcService.getActionPlainStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsActionPlan(false);
    }
  }

  async function handleBudget() {
    setRecords([]);
    setLocalName("Previsão de Vendas");
    try {
      setLoadingRecordsBudget(true);
      const response: StatusProps[] = await PgcService.getBudgetStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsBudget(false);
    }
  }

  async function handleCustomerMapping() {
    try {
      setLoadingRecordsCustomerMapping(true);
      const response: StatusProps[] = await PgcService.getCustomerMappingStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsCustomerMapping(false);
    }
  }

  async function handleLevel3Mapping() {
    setLocalName("Mapeamento de Cliente");
    setRecords([]);
    try {
      setLoadingRecordsLevel3Mapping(true);
      const response: StatusProps[] = await PgcService.getCustomMappingStatus(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });

      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsLevel3Mapping(false);
    }
  }
  async function handleWalletMapping() {
    setLocalName("Mapeamento de Carteira");
    setRecords([]);
    try {
      setLoadingRecordsCustomerMapping(true);
      const response: StatusProps[] = await PgcService.getWalletMappingDetail(
        companyId
      );
      const array: RecordsProps[] = response.map((value) => {
        return {
          id: value.id,
          name: value.level4_external_name,
          filial: value.level3_external_name,
          status: value.color,
        };
      });
      setRecords(array);
    } catch (err) {
      ShowMSG("error",err.response.data.message);
    } finally {
      setLoadingRecordsCustomerMapping(false);
    }
  }
  const { Title } = Typography;
  return (
    <Breadcrumb path="Planejamento Gestão de Cliente">
      {contextHolder}
      <>
      {loading ? (
        <Loading />
      ) : (
        <>
        <Row align="middle" style={{ marginBottom: 16 }}>
        {/* Breadcrumb on the left */}
      

        {/* Title in the center */}
        <Col  style={{ textAlign: "center", flexGrow: 1, background:"none" }}>
          <Title level={4} style={{ paddingBottom:"5px"  }}>Planejamento Gestão de Cliente</Title>
        </Col>
      </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Card bordered={false}>
              <Row justify="center" gutter={[8, 8]}>
                <Col xl={4} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.setup}
                    disabled={!dashboard?.setup?.permission}
                    actions={[
                      {
                        children: "Setup",
                        type: "primary",
                        onClick: () => navigate("/pgc/setup"),
                      },
                    ]}
                  />
                </Col>

                {/** <Col xl={5} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.customer_mapping}
                    actions={[
                      {
                        children: "Mapeamento de filial",
                        type: "primary",
                        onClick: () => navigate("/pgc/branch-mapping"),
                      },
                      {
                        children: "Detalhes",
                        onClick: handleCustomerMapping,
                        loading: loadingRecordsCustomerMapping,
                        style: { marginTop: 8 },
                      },
                    ]}
                  />
                </Col>  */}
                <Col xl={5} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.wallet_customer_mapping}
                    disabled={!dashboard?.wallet_customer_mapping?.permission}
                    actions={[
                      {
                        children: "Mapeamento de Carteira",
                        type: "primary",
                        about:
                          "Permite trocar o consultor de um cliente ou incluir um cliente não alocado na carteira de um consultor.",
                        onClick: () => navigate("/pgc/wallet-mapping"),
                      },
                      {
                        children: "Migração de Carteira",
                        onClick: () => navigate("/setup/update-seller"),
                        type: "primary",
                        about:
                          "Permite remanejar a carteira completa de um consultor para outro.",
                        style: { marginTop: 8 },
                      },
                      {
                        children: "Detalhes",
                        onClick: handleWalletMapping,
                        loading: loadingRecordsCustomerMapping,
                        style: { marginTop: 8 },
                      },
                    ]}
                  />
                </Col>
                <Col xl={5} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.customer_mapping}
                    disabled={!dashboard?.customer_mapping?.permission}
                    actions={[
                      {
                        children: "Mapeamento de Cliente",
                        type: "primary",
                        about: "",
                        onClick: () => navigate("/pgc/customer-mapping"),
                      },
                      {
                        children: "Detalhes",
                        onClick: handleLevel3Mapping,
                        loading: loadingRecordsLevel3Mapping,
                        style: { marginTop: 8 },
                      },
                    ]}
                  />
                </Col>
                <Col xl={5} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.budget}
                    disabled={!dashboard?.budget?.permission}
                    actions={[
                      {
                        children: "Previsão de vendas",
                        type: "primary",
                        about: "",
                        onClick: () => navigate("/pgc/sales-forecast"),
                      },
                      {
                        children: "Detalhes",
                        onClick: handleBudget,
                        loading: loadingRecordsBudget,
                        style: { marginTop: 8 },
                      },
                    ]}
                  />
                </Col>
                <Col xl={5} xs={24}>
                  <CardProgress
                    cardInfo={dashboard?.action_plan}
                    disabled={!dashboard?.action_plan?.permission}
                    actions={[
                      {
                        children: "Plano de Ação",
                        type: "primary",
                        about: "",
                        onClick: () => navigate("/pgc/action-plain"),
                      },
                      {
                        children: "Detalhes",
                        onClick: handleDetailActionPlan,
                        loading: loadingRecordsActionPlan,
                        style: { marginTop: 8 },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            {records.length > 0 && (
              <TableStatus
                loading={
                  loadingRecordsBudget ||
                  loadingRecordsActionPlan ||
                  loadingRecordsCustomerMapping ||
                  loadingRecordsLevel3Mapping
                }
                records={records}
                name={localName}
              />
            )}
          </Col>
        </Row>
        </>
      )}
      </>
    </Breadcrumb>
  );
}
