import styled from "styled-components";

interface ContainerProps {
  height?: number;
}

interface ContentProps {
  color: string;
}

export const Container = styled.div<ContainerProps>`
 [data-theme="light"] {
    --bg-color: #f5f5f5;
    --text-color: rgb(41, 41, 41);
    --bg-color-ligth: rgb(160, 160, 160);
    --white-color: #fff;
    --primary-color: rgb(35, 217, 120);
    --secondary-color: #fff;
  }

  /* Tema Escuro */
  [data-theme="dark"] {
    --bg-color: rgb(41, 41, 41);
    --text-color: #f5f5f5;
    --bg-color-ligth: rgb(99, 99, 99);
    --white-color: #1f1f1f;
    --primary-color: rgb(30, 148, 85);
    --secondary-color: #1f1f1f;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => (height ? `${height}px` : "280px")};
  width: 100%;

  background: var(--bg-color);
  border-radius: 8px;
`;

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${({ color }) => color};
`;

export const Percent = styled.p`
  font-size: 30px;
  line-height: 25px;
  color: #15243e;
`;

export const label = styled.p`
  font-size: 30px;
  line-height: 25px;
  color: #15243e;
`;
export const Label = styled.span<{ fontSize: number }>`
font-size: ${(props) => props.fontSize}px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`;
