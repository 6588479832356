import { useEffect, useState } from "react";
import { Row, Col, Button, message } from "antd";
import { useNavigate } from "react-router-dom";

import pgtIcon from "../../../assets/pgt_icon.svg";
import rebateIcon from "../../../assets/icon_rebate_2_.svg";
import gcIcon from "../../../assets/gc_icon.svg";
import pvIcon from "../../../assets/pv_icon.svg";

import { Container, Card, Icon, Title, SubTitle } from "./styles";
import { useAppSelector } from "../../../hooks";
import ParameterService from "../../../services/Global";
import { Loading } from "../../../components/Loading";
import { NoticeType } from "antd/lib/message/interface";

interface ModuleProps {
  id: number;
  name: string;
  initials: string;
  description: string;
}

interface ModulesProps {
  id: number;
  module: ModuleProps;
}

export function SelectModule() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  const [loading, setLoading] = useState(false);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [modules, setModules] = useState<ModulesProps[]>([]);

  useEffect(() => {
    Promise.all([loadModules()]);

    async function loadModules() {
      try {
        setLoading(true);
        const response = await ParameterService.getModules(companyId);
        setModules(response);
      } catch (err) {
        ShowMSG("error", err.response.data.message);
      } finally {
        setLoading(false);
      }
    }
  }, [companyId]);

  function getIcon(initials: string) {
    switch (initials) {
      case "PGT":
        return pgtIcon;
      case "PGC":
        return gcIcon;
      case "PGV":
        return pvIcon;
      case "REBATE":
        return rebateIcon;

      default:
        return pgtIcon;
    }
  }

  function getLink(initials: string) {
    switch (initials) {
      case "PGT":
        return "";
      case "PGC":
        return "/pgc";
      case "PGV":
        return "/pgv";
      case "REBATE":
        return "/rebate";

      default:
        return pgtIcon;
    }
  }

  return (
    <Container>
      {contextHolder}
      {loading ? (
        <Loading />
      ) : (
        <Row gutter={10}>
          <Col span={24}>
            <Title>Módulo</Title>
            <SubTitle>Selecione o tipo de módulo</SubTitle>
          </Col>
          {modules.map((value) => (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              key={value.id}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Card style={{ width: "100%", textAlign: "center" }}>
                <Icon src={getIcon(value.module.initials)} />
                {value.module.name}
                <Button
                  onClick={() => navigate(getLink(value.module.initials))}
                  type="primary"
                  children="Acessar"
                />
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}
