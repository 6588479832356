import { message, Typography } from "antd";
import { useEffect, useState } from "react";
import { Input } from "./styles";
import { NoticeType } from "antd/lib/message/interface";

interface RecordsProps {
  editable?: boolean;
}

interface InputNumberTableProps {
  initialValue: number | string;
  name: string;
  records: RecordsProps;
  max?: boolean;
  onSave: Function;
  disbled?: boolean;
}

export function InputNumberTableBr({
  initialValue,
  name,
  records,
  onSave,
  disbled,
  max,
}: InputNumberTableProps) {
  const [value, setValue] = useState<number | string>(0);
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  function handleValueFormat(format: string | number) {
    if (typeof format === "string") {
      // if (format.toString().includes(".") && format.toString().includes(",")) {
      //   const formatArray = format.toString().split(",");
      //   const int = formatArray[0].toString().replaceAll(/\./gm, "");
      //   return `${int}.${formatArray[1]}`;
      // }
      // if (format.toString().includes(".")) {
      //   return format.toString().replaceAll(/\./gm, "");
      // }
      // if (format.toString().includes(",")) {
      //   return format.toString().replaceAll(/,/gm, ".");
      // }
    }

    return format;
  }

  function onChange(e: any) {
    setValue(handleValueFormat(e));
  }

  async function onBlur() {
    if ((max && Number(value) > 100) || Number(value) < 0) {
      ShowMSG("error", "Valor invalido insira um valor de 0 A 100");
      setValue(initialValue);
    } else {
      try {
        await onSave({
          initialValue,
          name,
          records,
          disbled,
          value: value,
        });
      } catch (err) {
        ShowMSG("error", err.response.data.message);
        setValue(initialValue);
      }
    }
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return !records?.editable ? (
    <>
      {contextHolder}
      <Input
        style={{
          fontSize: 12,
          width: "100%",
          textAlign: name === "variation" ? "left" : "right",
        }}
        value={value}
        disabled={disbled}
        onChange={(e: any) => onChange(e.target.value)}
        onBlur={onBlur}
        // decimalScale={0}
        allowNegative={false}
        thousandSeparator={"."}
        displayType={"text"}
        // thousandSeparator={true}
        decimalSeparator={","}
      />
    </>
  ) : (
    <Typography.Text>{initialValue}</Typography.Text>
  );
}
