import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 8px;
    margin-right: 8px;
  }

  svg {
    cursor: pointer;
  }
`;
