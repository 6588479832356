import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Radio,
  Input,
  Popconfirm,
  message,
  Select,
  RadioChangeEvent,
  Typography,
  Tooltip,
  Divider,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import CompanyProductService from "../../../services/Company/Product";
import Customer from "../../../services/Pgc/Customer";
import CommercialStructure from "../../../services/Company/CommercialStructure";
import GeographyService from "../../../services/Global/Geography";

import TopDownBoardService from "../../../services/SalesPlanning/TopDownBoard";
import {
  FiEdit2,
  FiEye,
  FiFilter,
  FiPlus,
  FiSearch,
  FiTrash,
} from "react-icons/fi";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions } from "./styles";
//import { mask } from "../../../components/MaskCPF_CNPJ/MaskCPF_CNPJ";
import { useAppSelector } from "../../../hooks";
import { NoticeType } from "antd/lib/message/interface";
const { Option } = Select;
export function ProductPotential() {
  const [modalNewProduct, setModalNewProduct] = useState(false);
  const [modalNewProduct2, setModalNewProduct2] = useState(false);
  const [slected, setSlected] = useState<string>("C");
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [RegName, setRegName] = useState<string>("");
  const [RegGroupName, setRegGroupName] = useState<any>(undefined);
  const [NomeGrupo, setNomeGrupo] = useState<string>("");
  const [nameCategory, setNameCategory] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [listTasks2, setListTasks2] = useState<any[]>([]);
  const [states, setStates] = useState<any>(undefined);
  const [cities, setCities] = useState<any>(undefined);
  const [estado, setEstado] = useState<any>(undefined);
  const [accessTypes, setAccessTypes] = useState<any>(undefined);
  const [accessType, setAccessType] = useState<any>(undefined);
  const [category, setCategory] = useState<any>(undefined);
  const [structure, setStructure] = useState<any>(undefined);
  const [geography, setGeography] = useState<any>(undefined);
  const [category_id, setCategory_id] = useState<any>(undefined);
  const [city_Latitude, setCity_Latitude] = useState<any>(undefined);
  const [city_Longitude, setCity_Longitude] = useState<any>(undefined);
  const [register_number, setRegister_number] = useState<any>(undefined);
  const [city_id, setCity_id] = useState<any>(undefined);
  const [groupList, setGroupList] = useState<any>(undefined);
  const [searchData, setSearchData] = useState<any>([]);
  const [searchData2, setSearchData2] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const [showTable, setShowTable] = useState(false);
   const [tableLoading, setTableLoading] = useState<boolean>(false);
   const [showModalImportar, setShowModalImportar] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [data2, setData2] = useState<any[]>([]);
    const [dataerro, setDataDerro] = useState<any[]>([]);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [emails, setEmails] = useState<any[]>([]);
    const [totalValues, setTotalValues] = useState(0);
    const [totalRegistrados, setTotalRegistrados] = useState(0);
    const [duplicateValues, setDuplicateValues] = useState(0);
    const [errorEntries, setErrorEntries] = useState(0);
    const [totalImportar, setTotalImportar] = useState(0);
    const [dataDuplicados, setDataDuplicados] = useState<any[]>([]);
    const [dataRegistrados, setDataRegistrados] = useState<any[]>([]);


    const [products, setProducts] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const ShowMSG = (type: NoticeType, test: string) => {
      messageApi.open({
        type,
        content: test,
      });
    };
  useEffect(() => {
    onAccessTypes();
    // getCategory();
    getStructure();
    // getGeography();
    getListgroup();
    getProducts();
   
  }, []);
  async function getListTasks(value: string) {
    try {
      setLoading(true);
      
     
      const response = await TopDownBoardService.getTableSetupProductPotential(companyId)
      setSearchData(response.records);
      setList(response.records)
      setSearch("");
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  async function getListTasksOnlyGroup(id_group: number) {
    try {
      setLoading(true);
      const response: any[] = await Customer.getCustomerGroup(
        companyId,
        id_group
      );
      console.log(response);
      const formattedTasks2 = response.map((rawTask) => {
        return {
          id: rawTask.id,
          company_id: rawTask.company_id,
          access_type_id: rawTask.access_type_id,
          customer_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          customer_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          state_initials: rawTask.state_initials ? rawTask.state_initials : "",
          customer_group_id: rawTask.customer_group_id
            ? rawTask.customer_group_id
            : "",
          customer_latitude: rawTask.customer_latitude
            ? rawTask.customer_latitude
            : "",
          customer_longitude: rawTask.customer_longitude
            ? rawTask.customer_longitude
            : "",
          customer_group_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          Customer_category_id: rawTask.Customer_category_id
            ? rawTask.Customer_category_id
            : "",
          customer_name: rawTask.customer_name ? rawTask.customer_name : "",
          customer_registered_number: rawTask.customer_registered_number
            ? rawTask.customer_registered_number
            : "",
          access_name: rawTask.access_name ? rawTask.access_name : "",
          customer_type: rawTask.customer_type ? rawTask.customer_type : "",
          customer_group_category: rawTask.customer_category
            ? rawTask.customer_category
            : "",
          group_city_id: rawTask.customer_city_id
            ? rawTask.customer_city_id
            : "",
          group_latitude: rawTask.group_latitude ? rawTask.group_latitude : "",
          group_longitude: rawTask.group_longitude
            ? rawTask.group_longitude
            : "",
          group_name: rawTask.customer_name ? rawTask.customer_name : "",
          group_registered_number: rawTask.group_registered_number
            ? rawTask.group_registered_number
            : "",
          city_name: rawTask.city_name ? rawTask.city_name : "",
        };
      });
      setListTasks2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData2(
        formattedTasks2.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      //  console.log(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    } finally {
      setModalNewProduct2(true);
      setLoading(false);
    }
  }
  async function getListgroup() {
    try {
      const response: any[] = await Customer.getFindAll(companyId, "G");
      console.log(response);
      setGroupList(response);
    } catch (e) {
      console.error("Falha ao buscar");
      ShowMSG("error",e.response.data.message);
      console.error(e);
    }
  }
  async function PostClinte() {
    const body_c = {
      access_type_id: accessType,
      customer_city_id: city_id,
      customer_name: RegName,
      customer_registered_number: register_number,
      customer_category_id: category_id,
      customer_latitude: city_Latitude,
      customer_longitude: city_Longitude,
      customer_type: slected,
      customer_group_id: RegGroupName ? RegGroupName : null,
    };
    const body_g = {
      access_type_id: accessType,
      group_city_id: city_id,
      group_name: NomeGrupo,
      group_registered_number: register_number,
      customer_group_category_id: category_id,
      group_latitude: city_Latitude,
      group_longitude: city_Longitude,
      customer_type: slected,
      //  customer_group_id: RegGroupName,
    };
    try {
      if (!selectedTaskId) {
        if (slected === "C") {
          await Customer.PostCustomerClient(companyId, body_c);
          ShowMSG("success","Cliente cadastrado com sucesso!");
        } else {
          await Customer.PostCustomerClient(companyId, body_g);
          ShowMSG("success","Grupo cadastrado com sucesso!");
        }
      } else {
        if (slected === "C") {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_c);
          ShowMSG("success","Cliente cadastrado com sucesso!");
        } else {
          await Customer.PutCustomerClient(companyId, selectedTaskId, body_g);
          ShowMSG("success","Grupo cadastrado com sucesso!");
        }
      }
    } catch (error) {
      console.log(error);
      ShowMSG("error",error.response.data.message);
    } finally {
      getListTasks(slected);
    }
  }
  const onAccessTypes = useCallback(async () => {
    try {
      const response = await Customer.GetAccessType();
      setAccessTypes(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getCategory = useCallback(async () => {
    try {
      const response = await Customer.GetCategory(companyId);
      setCategory(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getProducts = useCallback(async () => {
    try {
      const response = await CompanyProductService.getParameter(companyId);
      setProducts(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getStructure = useCallback(async () => {
    try {
      const response = await CommercialStructure.getAllStructure(companyId);
      setStructure(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getGeography = useCallback(async () => {
    try {
      const response = await GeographyService.getCitiesall();
      setGeography(response);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    setSlected(value);
    getListTasks(value);
  };
  async function States() {
    try {
      const response = await GeographyService.getStates();
      setStates(response);
    } catch (error) {
      console.log(error);
    }
  }
  async function Cities(estados: any, value: any) {
    try {
      setCity_id(undefined);
      setCities(undefined);
      const response = await GeographyService.getCities(estados);
      setCities(response);
    } catch (error) {
      console.log(error);
    } finally {
      if (value) {
        setCity_id(
          slected === "G" ? value.group_city_id : value.customer_city_id
        );
      }
    }
  }

  //  function handleChangeMask(event: any) {
  //    const { value } = event.target;
  //    setRegister_number(mask(value));
  //  }

  async function confirmDelete(task: any) {
    try {
      if (modalNewProduct2) {
        await Customer.PatchGroup(companyId, +task.id);
      }
    } catch (e) {
      console.error("Falha ao excluir: ");
      ShowMSG("error",e.response.data.message + "Falha ao excluir.");
      console.error(e);
    } finally {
      if (modalNewProduct2) {
        await getListTasksOnlyGroup(task.customer_group_id);
      } else {
        await getListTasks(slected);
      }
    }
  }
  useEffect(() => {
    getListTasks(slected);
    // States();
  }, [companyId]);

  // useEffect(() => {
  //   filterTable();
  //   function filterTable() {
  //     if (!search) {
  //       setSearchData(listTasks);
  //     } else {
  //       const array = listTasks.filter(
  //         (record) =>
  //           !search ||
  //           (record["customer_type"].includes(slected) &&
  //             (record["customer_name"]
  //               .toLowerCase()
  //               .includes(search.toLowerCase()) ||
  //               record["group_name"]
  //                 .toLowerCase()
  //                 .includes(search.toLowerCase()) ||
  //               record["group_registered_number"]
  //                 .toLowerCase()
  //                 .includes(search.toLowerCase()) ||
  //               record["customer_category"]
  //                 .toLowerCase()
  //                 .includes(search.toLowerCase()) ||
  //               record["city_name"]
  //                 ?.toLowerCase()
  //                 .includes(search.toLowerCase())))
  //       );
  //       return setSearchData(array);
  //     }
  //   }
  // }, [search, listTasks, slected]);
  // useEffect(() => {
  //   filterTable2();
  //   function filterTable2() {
  //     if (!search2) {
  //       setSearchData2(listTasks2);
  //     } else {
  //       const array = listTasks2.filter(
  //         (record) =>
  //           !search2 ||
  //           record["customer_name"]
  //             .toLowerCase()
  //             .includes(search2.toLowerCase()) ||
  //           record["group_registered_number"]
  //             .toLowerCase()
  //             .includes(search2.toLowerCase()) ||
  //           record["customer_category"]
  //             .toLowerCase()
  //             .includes(search2.toLowerCase()) ||
  //           record["city_name"]?.toLowerCase().includes(search2.toLowerCase())
  //       );
  //       return setSearchData2(array);
  //     }
  //   }
  // }, [search2, listTasks2]);
  function showModalNewProduct() {
    setModalNewProduct(true);
  }
  function handleShowModalImportar() {
    setShowModalImportar(true);
  }

  function handleClickEdit(task: any) {
    console.log("Edite:");
    console.log(task);
    setEstado(task.state_initials);
    Cities(task.state_initials, task);
    if (cities) {
      setCity_id(slected === "G" ? task.group_city_id : task.customer_city_id);
    }
    setCategory_id(
      slected === "G"
        ? task.customer_group_category_id
        : task.customer_category_id
    );
    setRegGroupName(task.customer_group_id);
    setNomeGrupo(task.group_name);
    setRegister_number(
      slected === "G"
        ? task.group_registered_number
        : task.customer_registered_number
    );
    setSelectedTaskId(task.id);
    setRegName(slected === "G" ? task.group_name : task.customer_name);
    setNameCategory(
      slected === "G" ? task.group_category : task.customer_category
    );
    setAccessType(task.access_type_id);
    setModalNewProduct(true);
  }
  function handleCancel() {
    setCities(undefined);
    setEstado(undefined);
    setCity_id(undefined);
    setRegister_number(undefined);
    setRegName("");
    setRegGroupName(undefined);
    setCategory_id(undefined);
    setNameCategory("");
    setAccessType(undefined);
    setSelectedTaskId(null);
    setUploadedFile(null);
    setShowTable(false)
    getListgroup();
    if (modalNewProduct === true && modalNewProduct2 === true) {
      setModalNewProduct(false);
    } else {
      setModalNewProduct(false);
      setModalNewProduct2(false);
    }
    setShowModalImportar(false);
  }
  async function handleOk() {
    setModalNewProduct(false);
    PostClinte();
    handleCancel();

  }

  // function handleCancel() {
  //   // setShowModalNewUser(false);
  //   setShowModalImportar(false);
  //   // clearData();
  //   // setShowTable(false);
  //   // setUploadedFile(null);
  // }
  
 
  function removeAccents(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  function disableSave(): boolean {
    return !estado || !city_id || !register_number || !category_id;
  }

  

        const parseExcel = (file: File): Promise<any[]> =>
            new Promise((resolve, reject) => {
             
              try {
                const reader = new FileReader();
                reader.onload = async (e) => {
                  try {
                    if (e.target?.result) {
                      const workbook = new ExcelJS.Workbook();
                      await workbook.xlsx.load(e.target.result as ArrayBuffer);
                      const worksheet = workbook.worksheets[0];
                      const data: any[] = [];
                      let headers: string[] = [];
        
                      worksheet.eachRow((row, rowNumber) => {
                        const rowValues = Array.isArray(row.values)
                          ? row.values.slice(1) // Ignore the first value, usually metadata
                          : []; // Safeguard against null/undefined
        
                        if (rowNumber === 1) {
                          headers = rowValues.map((value) =>
                            typeof value === "string" ? value.trim() : String(value)
                          );
                        } else {
                          const obj: any = {};
                          headers.forEach((header, idx) => {
                            obj[header] = rowValues[idx] || null;
                          });
                          data.push(obj);
                        }
                      });
        
                      resolve(data);
                    } else {
                      alert("Formato de arquivo não suportado.");
                      //reject(new Error("File content could not be read."));
                    }
                  } catch (error) {
                    alert(`Erro ao processar o arquivo: ${error}`);
                    // reject(error);
                  }
                };
        
                reader.onerror = (error) => {
                  alert(`Erro ao carregar o arquivo: ${error}`);
                  //reject(error);
                };
        
                reader.readAsArrayBuffer(file);
              } catch (error) {
                alert(`Erro inesperado: ${error}`);
                // reject(error);
              }
            });


            const nameVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(removeAccents);
                  const cidadeVariants = ["cidade", "cidades", "municipio", "municipios"].map(removeAccents);
                  const cnpjVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
                  const codeVariants = ["codigo", "code"].map(removeAccents);
                  const categoriaVariants = ["categoria", "tipo de produto", "segmento"].map(removeAccents); // Fixed
                  const diretoriaVariants = ["diretoria", "departamento", "setor"].map(removeAccents); // Fixed
                  const tipoVariants = ["tipo", "modelo", "formato"].map(removeAccents); // Fixed



                  const validateColumns = (data: any[]): boolean => {
                    const requiredColumns: { key: string; variants: string[] }[] = [
                      { key: "estrutura_0", variants: ["estrutura_0"] },
                      { key: "estrutura_1", variants: ["estrutura_1"] },
                      { key: "estrutura_2", variants: ["estrutura_2"] },
                      { key: "estrutura_3", variants: ["estrutura_3"] },
                      { key: "produto", variants: ["produto"] },
                      { key: "grupo_cliente", variants: ["grupo_cliente"] },
                      { key: "potencial_base", variants: ["potencial_base"] },
                      { key: "tipo_acesso", variants: ["tipo_acesso","tipo de acesso"] },
                      { key: "potential_projecao", variants: ["potencial_projecao"] },
                      { key: "industria_base", variants: ["industria_base"] },
                      { key: "share_target_base", variants: ["share_target_base"] },
                      { key: "share_target_projecao", variants: ["share_target_projecao"] },
                      { key: "industria_q1", variants: ["industria_q1"] },
                      { key: "industria_q2", variants: ["industria_q2"] },
                      { key: "industria_q3", variants: ["industria_q3"] },
                      { key: "industria_q4", variants: ["industria_q4"] },
                    ];
                  
                    if (data.length === 0) return false;
                  
                    // Normalize and remove accents from data headers
                    const dataHeaders: string[] = Object.keys(data[0] || {}).map((header) =>
                      removeAccents(header.trim().toLowerCase())
                    );
                  
                    // Check if every required column has at least one matching header
                    const areAllRequiredColumnsPresent = requiredColumns.every((col) =>
                      Array.isArray(col.variants) && col.variants.some((variant) => dataHeaders.includes(variant))
                    );
                  
                    return areAllRequiredColumnsPresent;
            };

            type EmailType = string | { text?: string; [key: string]: any };
            type RowData = {
              Nome?: string;
              cidade?: string;
              cnpj?: string;
              codigo?: string;
              categoria?: string;
              diretoria?: string;
              tipo?: string;
              erro?:string;
              [key: string]: any; // Allow extra properties
            };

            interface StructureItem {
              id: number | string; // Adjust based on actual type
              level0_external_name: string;
              level1_external_name: string;
              level2_external_name: string;
              level3_external_name: string;
              level_type: string;
            }
            interface CitiesItem {
              id: number | string; // Adjust based on actual type
              city_name: string;
            
            }

            interface CustomerItem {
              id: number | string; // Adjust based on actual type
              customer_name: string;
              group_external_code: string;
              group_name: string;
            }

            interface ProdutoItem {
              id: number | string; // Adjust based on actual type
              product_name: string;
            
            }



            interface TipoItem {
              id: number | string; // Adjust based on actual type
              name: string;
            
            }

            const safeReplace = (value: any, search: string, replacement: string) => {
              // Ensure value is a string before calling replace
              return typeof value === 'string' ? value.replace(search, replacement) : value;
            };

        const processFile = async (file: File) => {
          setTableLoading(true);
      
          let parsedData: any[] = [];
          try {
            const fileExtension = file.name.split(".").pop()?.toLowerCase();
            if (fileExtension === "csv") {
              alert("Formato de arquivo não suportado, trocar xlsx.");
            } else if (fileExtension === "xlsx") {
              parsedData = await parseExcel(file);
            } else {
              alert("Formato de arquivo não suportado.");
              setTableLoading(false);
              return;
            }

            if (!parsedData || parsedData.length === 0) {   
              alert("O arquivo não contém dados");
              }
      
            
            if (validateColumns(parsedData)) {
              const normalizedData = (parsedData as RowData[])
                .map((row) => {
                  const lowerCaseRow = Object.fromEntries(
                    Object.entries(row).map(([key, value]) => [
                      removeAccents(key.toLowerCase()),
                      value,
                    ])
                  );
      

                  const findValue = (variants: string[]) => {
                    const matchedKey = Object.keys(lowerCaseRow).find((key) =>
                      variants.some((variant) => key.includes(variant))
                    );
                    return matchedKey ? lowerCaseRow[matchedKey] : "";
                  };
                  
                  // const nameVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(removeAccents);
                  // const cidadeVariants = ["cidade", "cidades", "municipio", "municipios"].map(removeAccents);
                  // const cnpjVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
                  // const codeVariants = ["nome", "names", "nomes", "name", "cliente", "clientes"].map(removeAccents);
                  // const categoriaVariants = ["cidade", "cidades", "municipio", "municipios"].map(removeAccents);
                  // const diretoriaVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
                  // const tipoVariants = ["cnpjs", "numeros", "cnpj", "numero"].map(removeAccents);
      

                  const estrutura0Variants = ["estrutura_0"].map(removeAccents);
                  const estrutura1Variants = ["estrutura_1"].map(removeAccents);
                  const estrutura2Variants = ["estrutura_2"].map(removeAccents);
                  const estrutura3Variants = ["estrutura_3"].map(removeAccents);
                  const produtoVariants = ["produto"].map(removeAccents);
                  const clienteVariants = ["grupo_cliente", "grupo_do_cliente"].map(removeAccents);
                  const potencialBaseVariants = ["potencial_base"].map(removeAccents);
                  const accesstypevariants = ["tipo_acesso"];
                  const potencialProjVariants = ["potencial_projecao"];
                  const industriaBaseVariants = ["industria_base"];
                  const shareTargetBaseVariants = ["share_target_base"];
                  const shareTargetProjVariants = ["share_target_projecao"].map(removeAccents);
                  const industriaQ1Variants = ["industria_q1"];
                  const industriaQ2Variants = ["industria_q2"];
                  const industriaQ3Variants = ["industria_q3"];
                  const industriaQ4Variants = ["industria_q4"];
              
                  // Extract values for each required column (making sure each "Estrutura" is treated separately)
                  const estrutura_0 = findValue(estrutura0Variants);
                  const estrutura_1 = findValue(estrutura1Variants);
                  const estrutura_2 = findValue(estrutura2Variants);
                  const estrutura_3 = findValue(estrutura3Variants);
                  const produto = findValue(produtoVariants);
                  const cliente = findValue(clienteVariants);
                  const potencialBase = findValue(potencialBaseVariants);
                  const accesstype = findValue(accesstypevariants);
                  const potencialProjecao = findValue(potencialProjVariants);
                  const industriaBase = findValue(industriaBaseVariants);
                  const shareTargetBase = findValue(shareTargetBaseVariants);
                  const shareTargetProjecao = findValue(shareTargetProjVariants);
                  const industriaQ1 = findValue(industriaQ1Variants);
                  const industriaQ2 = findValue(industriaQ2Variants);
                  const industriaQ3 = findValue(industriaQ3Variants);
                  const industriaQ4 = findValue(industriaQ4Variants);
             

                  const parseAsFloat = (value: string) => {
                    const number = parseFloat(value.replace(/[^\d.-]/g, "")); // Remove non-numeric characters
                    return isNaN(number) ? 0 : number;
                  };
              
                  // Function to safely parse share target base and remove "%"
                  const parseShareTargetBase = (value: any): number => {
                    if (value == null) return 0;
                    
                    const number = parseFloat(value.toString().replace('%', '').replace(/[^\d.-]/g, "")); // Remove non-numeric characters
                    return isNaN(number) ? 0 : number * 100; // Convert 0.01 → 1
                  };
                  return {
                  estrutura_0: estrutura_0?.toString().trim() || "",
                  estrutura_1: estrutura_1?.toString().trim() || "",
                  estrutura_2: estrutura_2?.toString().trim() || "",
                  estrutura_3: estrutura_3?.toString().trim() || "",
                  produto: produto?.toString().trim() || "",
                  cliente: cliente?.toString().trim() || "",
                  accesstype: accesstype?.toString().trim() || "",
                  potencial_base: potencialBase?.toString().trim() || "",
                  potencial_projecao: potencialProjecao? potencialProjecao : "",
                  industria_base: industriaBase?.toString().trim() || "",
                  share_target_base: shareTargetBase? parseShareTargetBase(shareTargetBase):"",
                  share_target_projecao: shareTargetProjecao? parseShareTargetBase(shareTargetProjecao):"",
                  industria_q1: industriaQ1?.toString().trim() || "",
                  industria_q2: industriaQ2?.toString().trim() || "",
                  industria_q3: industriaQ3?.toString().trim() || "",
                  industria_q4: industriaQ4?.toString().trim() || "",
                  };
                })
                .filter((row) => row.estrutura_0 !== "");
      
               
              const emailOccurrences = new Map<string, number>();
              const duplicates: { estrutura_0: string, estrutura_1: string, estrutura_2: string, estrutura_3: string, produto: string, cliente: string, accesstype:string, potencial_base: number,potencial_projecao: number, industria_base: number, share_target_base: any,share_target_projecao: any, industria_q1: number, industria_q2: number, industria_q3: number, industria_q4: number }[] = [];
              const uniqueEntries: { estrutura_0: string, estrutura_1: string, estrutura_2: string, estrutura_3: string, produto: string, cliente: string, accesstype:string,potencial_base: number, potencial_projecao: number,industria_base: number, share_target_base: any,share_target_projecao: any, industria_q1: number, industria_q2: number, industria_q3: number, industria_q4: number }[] = [];
              const erroEntries: { estrutura_0: string, estrutura_1: string, estrutura_2: string, estrutura_3: string, produto: string, cliente: string, accesstype:string,potencial_base: number, potencial_projecao: number,industria_base: number, share_target_base: any, share_target_projecao: any,industria_q1: number, industria_q2: number, industria_q3: number, industria_q4: number, erro: string }[] = [];

              // Function to parse and return float
              const parseAsFloat = (value: any): number => {
                const parsedValue = parseFloat(value);
                return isNaN(parsedValue) ? 0 : parsedValue;
              }

              // First pass: Identify unique and duplicate entries
              for (const row of normalizedData) {
                console.log(normalizedData)
                // Normalize and trim the data for each column
                // const estrutura_0 = row.estrutura_0.toLowerCase();
                // const estrutura_1 = row.estrutura_1.toLowerCase();
                // const estrutura_2 = row.estrutura_2.toLowerCase();
                // const estrutura_3 = row.estrutura_3.toLowerCase();
                // const produto = row.produto.toLowerCase();
                // const cliente = row.cliente.toLowerCase();
                // const potencial_base = parseAsFloat(row.potencial_base);
                // const industria_base = parseAsFloat(row.industria_base);
                // const industria_q1 = parseAsFloat(row.industria_q1);
                // const industria_q2 = parseAsFloat(row.industria_q2);
                // const industria_q3 = parseAsFloat(row.industria_q3);
                // const industria_q4 = parseAsFloat(row.industria_q4);

                // Create a composite key for the row based on those fields
                //const compositeKey = `${estrutura_0}-${estrutura_1}-${estrutura_2}-${estrutura_3}-${produto}-${cliente}-${potencial_base}-${industria_base}-${share_target_base}-${industria_q1}-${industria_q2}-${industria_q3}-${industria_q4}`;

                // Check if the composite key exists in the map
                // Mark this row as seen
                uniqueEntries.push(row);  // Add the first occurrence to unique entries
                
              }

              console.log(uniqueEntries)

              const filteredStructure = structure
            .filter((item: StructureItem) => item.level_type === "3")
            .map(({ id, level0_external_name,level1_external_name ,level2_external_name ,level3_external_name  }: StructureItem) => ({ id, level0_external_name,level1_external_name ,level2_external_name ,level3_external_name  }));
          

            // Filter geography to only include city_name and id
            

            const toRemove2: any[] = [];
            const registrados: any[] = [];

          // for (const row of uniqueEntries) {
          //   const nome = removeAccents(row.nome.toLowerCase());
            

          //   const nomeExists = searchData.some(({ customer_name }: CustomerItem) =>
          //     removeAccents(customer_name.toLowerCase()) === removeAccents(nome)
          //   );
           
          //   if (nomeExists) {
              
          //     // Push the row with the error message into erroEntries
          //     registrados.push(row);
          //     toRemove2.push(row); // Collect row to remove later
          //   }
          // }

          const vuniqueEntries = uniqueEntries.filter((row) => !toRemove2.includes(row));


          const toRemove: any[] = [];

          for (const row of vuniqueEntries) {
            const produto = removeAccents(row.produto.toLowerCase());
            const estrutura_0 = removeAccents(row.estrutura_0.toLowerCase());
            const estrutura_1 = removeAccents(row.estrutura_1.toLowerCase());
            const estrutura_2 = removeAccents(row.estrutura_2.toLowerCase());
            const estrutura_3 = removeAccents(row.estrutura_3.toLowerCase());
            const cliente = removeAccents(row.cliente.toLowerCase());
            const tipo = removeAccents(row.accesstype.toLowerCase());
            
            const tipoExists = accessTypes.some(({ name }: TipoItem) =>
              removeAccents(name.toLowerCase()) === removeAccents(tipo)
            );

            const diretoriaExists = filteredStructure.some(
              ({ level0_external_name, level1_external_name, level2_external_name, level3_external_name }: StructureItem) => {
                const structureValues = [level0_external_name, level1_external_name, level2_external_name, level3_external_name]
                  .map(removeAccents)
                  .map(val => val.toLowerCase()) 
                  .join(''); 
            
                const targetValues = [estrutura_0, estrutura_1, estrutura_2, estrutura_3]
                  .map(removeAccents) 
                  .map(val => val.toLowerCase()) 
                  .join(''); 
            
                return structureValues === targetValues; 
              }
            );
            const clienteExists = groupList.some(({ group_name }: CustomerItem) => {
              // Check if group_external_code is defined and not empty
              const normalizedCode = group_name ? removeAccents(group_name.toLowerCase()) : "";
              return normalizedCode === removeAccents(cliente.toLowerCase());
            });

            const ProdutoExists = products.some(({ product_name }: ProdutoItem) =>
              removeAccents(product_name.toLowerCase()) === removeAccents(produto)
            );

            if (!ProdutoExists || !diretoriaExists || !clienteExists || !tipoExists) {
              const erroMessage = [
                !ProdutoExists && "Não encontrado produto",
                !diretoriaExists && "Não encontrado estrutura",
                !clienteExists && "Não encontrado cliente",
                !tipoExists && "Não encontrado tipo"
              ].filter(Boolean).join(", "); // Filter out falsy values and join with commas
          
              // Push the row with the error message into erroEntries
              erroEntries.push({
                ...row,  // Spread the original row to keep all original properties
                erro: erroMessage  // Add the erro column with the error message
              });
              toRemove.push(row); // Collect row to remove later
            }
          }

          const validUniqueEntries = vuniqueEntries.filter((row) => !toRemove.includes(row));
          let conterrors = erroEntries.length;
          setErrorEntries(conterrors)

      
              setTotalValues(normalizedData.length);
              setDuplicateValues(duplicates.length);
              setTotalRegistrados(registrados.length);
              // setErrorEntries(erroEntries);
      
              setTotalImportar(validUniqueEntries.length)
              setData(validUniqueEntries);
              setData2(duplicates);
              setDataRegistrados(registrados)
              setDataDerro(erroEntries)
              setTableLoading(true);
              setShowTable(true);
            } else {
              setShowTable(false);
              setUploadedFile(null);
              alert("O arquivo não contém colunas com os títulos 'Nome', 'Cidade/Munícipio','Categoria','Diretoria'.");
            }
          } catch (error) {
            setShowTable(false);
            setUploadedFile(null);
            alert(`Erro ao processar o arquivo: ${error.message || error}`);
        }
        
      
          setTableLoading(false);
        };


        async function handleImportar() {
          try {
            for (const row of data) {
              try {
             
      
              
                const cliente = groupList.find(({ group_name }: CustomerItem) => {
                  
                  const normalizedCode = group_name ? removeAccents(group_name.toLowerCase()) : "";
                  return normalizedCode === removeAccents(row.cliente.toLowerCase());
                })?.id;
                
                const produto = products.find(({ product_name }: ProdutoItem) =>
                  removeAccents(product_name.toLowerCase()) === removeAccents(row.produto.toLowerCase())
                )?.id;
                
                const filteredStructure = structure
                  .filter((item: StructureItem) => item.level_type === "3")
                  .map(({ id, level0_external_name, level1_external_name, level2_external_name, level3_external_name }: StructureItem) => ({
                    id, level0_external_name, level1_external_name, level2_external_name, level3_external_name
                  }));
                
                const filial = filteredStructure.find(
                  ({ level0_external_name, level1_external_name, level2_external_name, level3_external_name }: StructureItem) => {
                    const structureValues = [level0_external_name, level1_external_name, level2_external_name, level3_external_name]
                      .map(removeAccents)
                      .map(val => val.toLowerCase()) 
                      .join(''); 
                
                    const targetValues = [row.estrutura_0, row.estrutura_1, row.estrutura_2, row.estrutura_3]
                      .map(removeAccents) 
                      .map(val => val.toLowerCase()) 
                      .join(''); 
                
                    return structureValues === targetValues; 
                })?.id;

                const tipo_id = accessTypes.find(({ name }: TipoItem) =>
                  removeAccents(name.toLowerCase()) === removeAccents(row.accesstype.toLowerCase())
                )?.id;

                console.log(filial,produto,cliente)


              
               const body_c = {
                business_type_id: 1,
                level3_id: filial,
                customer_id: cliente,
                access_type_id: tipo_id,
                product_id: produto,
                city_id: 2480,
                crop_id: 14,
                potential_base_line: row.potencial_base ?row.potencial_base:0,
                potential_year1: row.potencial_projecao ?row.potencial_projecao:0,
                industry_base_line: row.industria_base ?row.industria_base:0,
                share_target: row.share_target_base ?row.share_target_base:0,
                industry_soc_year1: row.share_target_projecao ?row.share_target_projecao:0,
                industry_sale_year1: row.industria_q1 ?row.industria_q1:0,
                industry_sale_year2: row.industria_q2 ?row.industria_q2:0,
                industry_sale_year3: row.industria_q3 ?row.industria_q3:0,
                industry_sale_year4: row.industria_q4 ?row.industria_q4:0,
                
              };
              console.log(body_c)
               await TopDownBoardService.saveTablelevel3ProductPotential(companyId, body_c);
      
      
      
      
    
             
              ShowMSG("success","Level3 Cadastrado");
              
            } catch (error) {
              alert(error.message)
              console.error(`Erro ao processar `, error);
              ShowMSG("error","erro em "+row.nome);
              // Continue to the next row even if there's an error
          }
        }
        getListTasks(slected);
        ShowMSG("success","Importação concluida");
        setUploadedFile(null);
        setShowModalImportar(false);
        setShowTable(false);
        handleCancel();
      } catch (e) {
        console.error("Erro geral durante a importação:", e);
        
        setShowModalImportar(false);
        handleCancel();
      } finally {
      
        setShowModalImportar(false);
        handleCancel();
        setTableLoading(false);
      }
    }

        const columns2 = [
          {
            title: "Estrutura_0",
            dataIndex: "estrutura_0",
            key: "estrutura_0",
            width:150,
          },
          {
            title: "Estrutura_1",
            dataIndex: "estrutura_1",
            key: "estrutura_1",
            width:150,
          },
          {
            title: "Estrutura_2",
            dataIndex: "estrutura_2",
            key: "estrutura_2",
            width:150,
          },
          {
            title: "Estrutura_3",
            dataIndex: "estrutura_3",
            key: "estrutura_3",
            width: 150,
          },
          {
            title: "Produto",
            dataIndex: "produto",
            key: "produto",
            width: 150,
          },
          {
            title: "tipo_acesso",
            dataIndex: "accesstype",
            key: "accesstype",
            width: 150,
          },
          
          {
            title: "grupo_cliente",
            dataIndex: "cliente",
            key: "cliente",
            width: 150
          },
          {
            title: "Potencial_base",
            dataIndex: "potencial_base",
            key: "potencial_base",
            width: 160,
          },
          {
            title: "potencial_projecao",
            dataIndex: "potencial_projecao",
            key: "potencial_projecao",
            width: 160,
          },
          {
            title: "Industria_base",
            dataIndex: "industria_base",
            key: "industria_base",
            width: 160,
          },
          {
            title: "Share_target_base",
            dataIndex: "share_target_base",
            key: "share_target_base",
            width: 160,
          },
          {
            title: "share_target_projecao",
            dataIndex: "share_target_projecao",
            key: "share_target_projecao",
            width: 160,
          },
          {
            title: "Industria_q1",
            dataIndex: "industria_q1",
            key: "industria_q1",
            width: 160,
          },
          {
            title: "Industria_q2",
            dataIndex: "industria_q2",
            key: "industria_q2",
            width: 160,
          },
          {
            title: "Industria_q3",
            dataIndex: "industria_q3",
            key: "industria_q3",
            width: 160,
          },
          {
            title: "Industria_q4",
            dataIndex: "industria_q4",
            key: "industria_q4",
            width: 160,
          },
          // {
          //   title: "",
          //   dataIndex: "erro",
          //   key: "erro",
          // },
        ];

        const columnsErro = [
          {
            title: "Error",  // You can change the title as needed
            dataIndex: "erro",
            key: "erro",
            render: (text: string) => {
              // Check if there is an error message and apply the red color
              return (
                <span style={{ color: 'red' }}>
                  {text || "No error"}  {/* Display the error message in red, or a default message if there is no error */}
                </span>
              );
            }
          },
          {
            title: "Estrutura_0",
            dataIndex: "estrutura_0",
            key: "estrutura_0",
            width: 160
          },
          {
            title: "Estrutura_1",
            dataIndex: "estrutura_1",
            key: "estrutura_1",
            width: 160
          },
          {
            title: "Estrutura_2",
            dataIndex: "estrutura_2",
            key: "estrutura_2",
            width: 160
          },
          {
            title: "Estrutura_3",
            dataIndex: "estrutura_3",
            key: "estrutura_3",
            width: 160
          },
          {
            title: "Produto",
            dataIndex: "produto",
            key: "produto",
            width: 160
          },
          {
            title: "tipo_acesso",
            dataIndex: "accesstype",
            key: "accesstype",
            width: 150,
          },
          {
            title: "grupo_cliente",
            dataIndex: "cliente",
            key: "cliente",
            width: 160
          },
          {
            title: "Potencial_base",
            dataIndex: "potencial_base",
            key: "potencial_base",
            width: 160,
          },
          
        ];
        const capitalizeFirstLetter = (string:any) => {
          if (!string) return '';
          return string.charAt(0).toUpperCase() + string.slice(1);
        };

  const columns = [
    {
      title: "Estrutura_0",
      dataIndex: "level0_name",
      key: "level0_name",
      sorter: (a: any, b: any) => a.id - b.id,
      width: 200,
    },
    {
      title: "Estrutura_1",
      dataIndex: "level1_name",
      key: "level1_name",
      width: 200,
    },
    {
      title: "Estrutura_2",
      dataIndex: "level2_name",
      key: "level2_name",
      width: 200,
    },
    {
      title: "Estrutura_3",
      dataIndex: "level3_name",
      key: "level3_name",
      width: 200,
    },
    {
      title: "Produto",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
    },
    {
      title: "tipo_acesso",
      dataIndex: "acesso",
      key: "acesso",
      width: 150,
    },
    {
      title: "grupo_cliente",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 200,
    },
    {
      title: "Potencial_Base",
      dataIndex: "potential_base_line_volume",
      key: "potential_base_line_volume",
      width: 200,
    },
    {
      title: "Potencial_Projeção",
      dataIndex: "potential_year1_volume",
      key: "potential_year1_volume",
      width: 200,
    },
    {
      title: "Industria_Base",
      dataIndex: "industry_base_line_volume",
      key: "industry_base_line_volume",
      width: 200,
    },
    {
      title: "Share_target_base",
      dataIndex: "share_target",
      key: "share_target",
      width: 200,
    },
    {
      title: "Share_target_Projeção",
      dataIndex: "industry_soc_year1",
      key: "industry_soc_year1",
      width: 200,
    },
    // {
    //   title: "Potencial_Q1",
    //   dataIndex: "potential_base_line_volume",
    //   key: "potential_base_line_volume",
    //   width: 200,
    // },
    {
      title: "Industria_Q1",
      dataIndex: "industry_base_line_volume",
      key: "industry_base_line_volume",
      width: 200,
    },
    // {
    //   title: "Potencial_Q2",
    //   dataIndex: "potential_year2_volume",
    //   key: "potential_year2_volume",
    //   width: 200,
    // },
    {
      title: "Industria_Q2",
      dataIndex: "industry_sale_year2_volume",
      key: "industry_sale_year2_volume",
      width: 200,
    },
    // {
    //   title: "Potencial_Q3",
    //   dataIndex: "potential_year3_volume",
    //   key: "potential_year3_volume",
    //   width: 200,
    // },
    {
      title: "Industria_Q3",
      dataIndex: "industry_sale_year3_volume",
      key: "industry_sale_year3_volume",
      width: 200,
    },
    // {
    //   title: "Potencial_Q4",
    //   dataIndex: "potential_year4_volume",
    //   key: "potential_year4_volume",
    //   width: 200,
    // },
    {
      title: "Industria_Q4",
      dataIndex: "industry_sale_year4_volume",
      key: "industry_sale_year4_volume",
      width: 200,
    },
     
     
    
    
    
   
    // {
    //   title: "Ações",
    //   dataIndex: "uf",
    //   key: "uf",
    //   width: 150,
    //   align: "center" as const,
    //   render: (_: null, record: any) => {
    //     return (
    //       <Actions>
    //         {slected === "G" && !modalNewProduct2 ? (
    //           <Tooltip title="Visualizar">
    //             <Button
    //               type="text"
    //               icon={
    //                 <FiEye
    //                   size={18}
    //                   color="#00CC66"
    //                   onClick={() => getListTasksOnlyGroup(record.id)}
    //                 />
    //               }
    //             />
    //           </Tooltip>
    //         ) : (
    //           <></>
    //         )}
    //         {slected === "G" && !modalNewProduct2 ? (
    //           <Tooltip title="Editar">
    //             <Button
    //               type="text"
    //               onClick={() => handleClickEdit(record)}
    //               icon={<FiEdit2 size={18} color="#00CC66" />}
    //             />
    //           </Tooltip>
    //         ) : (
    //           <></>
    //         )}
    //         {slected === "C" ? (
    //           <Tooltip title="Editar">
    //             <Button
    //               type="text"
    //               onClick={() => handleClickEdit(record)}
    //               icon={<FiEdit2 size={18} color="#00CC66" />}
    //             />
    //           </Tooltip>
    //         ) : (
    //           <></>
    //         )}
    //         {slected === "G" && modalNewProduct2 ? (
    //           <Tooltip title="Excluir">
    //             <Popconfirm
    //               title="Tem certeza que deseja excluir essa tarefa?"
    //               onConfirm={() => confirmDelete(record)}
    //               okText="Excluir"
    //               okButtonProps={{ danger: true }}
    //               cancelText="Cancelar"
    //             >
    //               <Button
    //                 type="text"
    //                 icon={<FiTrash size={18} color="#00CC66" />}
    //               />
    //             </Popconfirm>
    //           </Tooltip>
    //         ) : (
    //           <></>
    //         )}
    //       </Actions>
    //     );
    //   },
    // },
  ];

  function searchTable(value: string) {
    if (value) {
      setSearch(value);

      const array = list.filter((record:any) => {
        // Check if the value exists in `name` or `module_names`
        const nameMatch = record["product_name"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
        const moduleNamesMatch = record["customer_name"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
          const filialNamesMatch = record["level3_name"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
        return nameMatch || moduleNamesMatch | filialNamesMatch; // Include records matching either condition
      });

      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(list); // Reset to the full list if no value is entered
    }
  }

  const exportEmptyExcel = (columns2:any) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Template");
  

    const headers = columns2.map((col: any) => col.title);

  const headerRow = worksheet.addRow(headers);


  headerRow.eachCell((cell) => {
    cell.style = {
      font: { bold: true }, 
      alignment: { horizontal: "center" }, 
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D3D3D3" }, 
      },
    };
  });
    worksheet.columns = columns2.map((col:any) => ({
      header: col.title,
      key: col.dataIndex,
      width: col.width / 8 || 20, // Convert width to Excel format
    }));
  
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
  
      saveAs(blob, "template_ProdutovsPotencial.xlsx");
    });
  };
  const { Title } = Typography;

  return (
    <Breadcrumb path="Cadastro de Clientes" child={["Produto"]}>
      <>
      {contextHolder}
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Setup - ACP
            </Title>
          </Col>
        </Row>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
          {/* <Col xs={12} sm={6} md={6} lg={6}>
    <Button type="primary" icon={<FiPlus />} onClick={showModalNewProduct}>
      Novo
    </Button>
  </Col> */}

  

  <Col xs={20} sm={20} md={20} lg={20} style={{ textAlign: "right" }}>
    <Button
      type="primary"
      onClick={handleShowModalImportar}
      style={{ width: "100%", maxWidth: "150px" }}
    >
      Importar
    </Button>
  </Col>

  <Col xs={4} sm={4} md={4} lg={4}>
    <Input
      allowClear
      placeholder="Pesquisar"
      value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  searchTable(e.target.value)
                }
      prefix={<FiSearch color="#00CC66" />}
      suffix={<FiFilter color="#00CC66" />}
      // onChange={(e) => setSearch(e.target.value)}
    />
  </Col>
  <Col span={24} style={{ overflowX: "auto", maxHeight: "800px", overflowY: "auto", whiteSpace: "nowrap" }}>
  <Table
    size="large"
    columns={columns}
    dataSource={searchData}
    loading={isLoading}
    scroll={{ x: "max-content", y: 800 }} // Adiciona scroll horizontal e vertical
  />
</Col>
          </Row>
        </Card>
        <Modal
          title="Clientes nesse grupo"
          open={modalNewProduct2}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Input
                allowClear
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
                onChange={(e) => setSearch2(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData2}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Modal>
        <Modal
          open={modalNewProduct}
          onOk={handleOk}
          okText={"Salvar"}
          width={600}
          onCancel={handleCancel}
          okButtonProps={{ disabled: disableSave() }}
          title={
            selectedTaskId
              ? slected === "G"
                ? "Atualizar Grupo"
                : "Atualizar Cliente"
              : slected === "G"
              ? "Novo Grupo"
              : "Novo Cliente"
          }
        >
          <Row justify="center">
          
            <Col span={12} style={{ textAlign: "center" }}>
              {states !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Estado
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      popupMatchSelectWidth={false}
                      //  disabled={modalShowEdite}
                      showSearch
                      placeholder="Estado"
                      optionFilterProp="children"
                      onChange={(value) => [
                        setEstado(value),
                        Cities(value, null),
                      ]}
                      value={estado}
                    >
                      {states.map((initiative: any) => (
                        <Option value={initiative.state_initials}>
                          {initiative.state_initials}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {cities !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cidade
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      popupMatchSelectWidth={false}
                      showSearch
                      placeholder="Cidade"
                      optionFilterProp="children"
                      onChange={(value) => setCity_id(value)}
                      value={city_id}
                    >
                      {cities.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.city_name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              {accessTypes !== undefined ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Tipo_Acesso
                    </Typography.Text>
                    <Select
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      //  disabled={modalShowEdite}
                      popupMatchSelectWidth={false}
                      showSearch
                      placeholder="Tipo de Acesso"
                      optionFilterProp="children"
                      onChange={(value) => setAccessType(+value)}
                      value={accessType}
                    >
                      {accessTypes.map((initiative: any) => (
                        <>
                          <Option value={initiative.id}>
                            {initiative.name}
                          </Option>
                        </>
                      ))}
                    </Select>
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  Registro
                </Typography.Text>

                <Input
                  onChange={(e) => setRegister_number(e.target.value)}
                  size="large"
                  style={{ width: "100%", margin: "10px 0" }}
                  placeholder="Registro"
                  maxLength={18}
                  value={register_number}
                />
              </Col>
              {slected === "C" ? (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Cliente
                    </Typography.Text>

                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Cliente"
                      value={
                        RegName != ""
                          ? RegName[0].toUpperCase() +
                            RegName.slice(1).toLowerCase()
                          : RegName
                      }
                      onChange={(e) => setRegName(e.target.value)}
                    />
                  </Col>
                  {groupList !== undefined ? (
                    <>
                      <Col
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text
                          style={{
                            whiteSpace: "nowrap",
                            marginRight: 10,
                            fontWeight: "bold",
                          }}
                        >
                          Nome do grupo
                        </Typography.Text>
                        <Select
                          style={{ width: "100%", margin: "10px 0" }}
                          size="large"
                          popupMatchSelectWidth={false}
                          showSearch
                          placeholder="Nome do grupo"
                          optionFilterProp="children"
                          onChange={(value) => setRegGroupName(+value)}
                          value={RegGroupName}
                        >
                          {groupList.map((initiative: any) => (
                            <>
                              <Option value={initiative.id}>
                                {initiative.group_name}
                              </Option>
                            </>
                          ))}
                        </Select>
                      </Col>
                    </>
                  ) : (
                    <> </>
                  )}
                </>
              ) : (
                <>
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text
                      style={{
                        whiteSpace: "nowrap",
                        marginRight: 10,
                        fontWeight: "bold",
                      }}
                    >
                      Nome do grupo
                    </Typography.Text>
                    <Input
                      style={{ width: "100%", margin: "10px 0" }}
                      size="large"
                      placeholder="Nome do grupo"
                      value={
                        NomeGrupo != ""
                          ? NomeGrupo[0].toUpperCase() +
                            NomeGrupo.slice(1).toLowerCase()
                          : NomeGrupo
                      }
                      onChange={(e) => setNomeGrupo(e.target.value)}
                    />
                  </Col>
                </>
              )}
              {category !== undefined ? (
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Categoria
                  </Typography.Text>
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    popupMatchSelectWidth={false}
                    showSearch
                    placeholder="Categoria"
                    optionFilterProp="children"
                    onChange={(value) => setCategory_id(+value)}
                    value={category_id}
                  >
                    {category.map((initiative: any) => (
                      <>
                        <Option value={initiative.id}>{initiative.name}</Option>
                      </>
                    ))}
                  </Select>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal>
         <Modal
                  style={{
                    width: "900px",
                    height: "auto",
                  }}
                  title={
                    <div style={{ textAlign: "center" }}>
                      <b>Importar Valores ACP</b>
                    </div>
                  }
                  open={showModalImportar}
                  width={900}
                  onCancel={handleCancel}
                  footer={null} // Footer managed manually for customization
                >
                  {!showTable ? (
                    <>
                      {/* File upload UI */}
                      <div style={{ textAlign: "left", marginBottom: "20px" }}>
                        
                      <Button type="primary" onClick={() => exportEmptyExcel(columns2)}>
                          Exportar template
                        </Button>
                      </div>


                      
                   <Typography.Text
  style={{
    fontSize: "14px",
    lineHeight: 1.5,
    color: "#666",
    display: "block",
    marginBottom: "10px",
  }}
>
  <div>Escolha um arquivo no formato <strong>.xlsx</strong> contendo as seguintes colunas:</div>
  <div><strong>Obs:</strong> Valores (KGL)</div>
  <div><strong>1.</strong> Estrutura_0</div>
  <div><strong>2.</strong> Estrutura_1</div>
  <div><strong>3.</strong> Estrutura_2</div>
  <div><strong>4.</strong> Estrutura_3</div>
  <div><strong>5.</strong> Produto</div>
  <div><strong>5.</strong> tipo_acesso</div>
  <div><strong>6.</strong> grupo_cliente</div>
  <div><strong>7.</strong> Potencial_Base</div>
  <div><strong>8.</strong> Potencial_Projeção</div>
  <div><strong>9.</strong> Industria_Base</div>
  <div><strong>10.</strong> Share_target_base</div>
  <div><strong>11.</strong> Share_target_Projeção</div>
  <div><strong>12.</strong> Industria_Q1</div>
  <div><strong>13.</strong> Industria_Q2</div>
  <div><strong>14.</strong> Industria_Q3</div>
  <div><strong>15.</strong> Industria_Q4</div>
</Typography.Text>
        
                      {tableLoading && (
                        <div style={{ textAlign: "center", margin: "20px 0" }}>
                          <Typography.Text>Carregando...</Typography.Text>
                        </div>
                      )}
        
                      {!tableLoading && (
                        <div>
                          {/* File upload input */}
                          <input
                            type="file"
                            accept=".csv, .xlsx"
                            onChange={(e) =>
                              setUploadedFile(e.target.files?.[0] || null)
                            }
                            style={{ marginBottom: "10px", display: "block" }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end", // Align to bottom-left
                              gap: "10px",
                              marginTop: "20px",
                            }}
                          >
                            <Button type="default" onClick={handleCancel}>
                              Cancelar
                            </Button>
                            <Button
                              type="primary"
                              disabled={!uploadedFile}
                              onClick={() => uploadedFile && processFile(uploadedFile)}
                            >
                              Seguir
                            </Button>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>Total: {totalValues}</Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total de duplicados: {duplicateValues}
                        </Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total de Erros: {errorEntries}
                        </Typography.Text>
                      </div>
                      <div style={{ marginBottom: "10px" }}>
                        <Typography.Text>
                          Total já cadastrados na empresa: {totalRegistrados}
                        </Typography.Text>
                      </div>
                      {/* Table Section */}
                      
                      {totalRegistrados > 0 && (
                    <>
                     <Divider style={{ marginBottom: "20px" }} />
                      <Typography.Title level={4} style={{ marginBottom: "10px", color:"red" }}>
                        Dados já cadastrados na empresa
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <Col xs={24}>
                            <Table
                              dataSource={dataRegistrados}
                              columns={columns2}
                              size="small"
                              scroll={{ y: 100 }}
                              pagination={false}
                            />
                          </Col>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "20px" }} />
                    </>
                  )}
                      {errorEntries > 0 && (
                    <>
                      <Typography.Title level={4} style={{ marginBottom: "10px", color: "red"}}>
                        Dados com Erro
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <Col span={24} style={{ overflowX: "auto", maxHeight: "800px", overflowY: "auto", whiteSpace: "nowrap" }}>
                            <Table
                              dataSource={dataerro}
                              columns={columnsErro}
                              size="small"
                              scroll={{x: "max-content", y: 100 }}
                              pagination={false}
                            />
                          </Col>
                        )}
                      </div>
                      <Divider style={{ marginBottom: "20px" }} />
                    </>
                  )}
                      <Typography.Title level={4} style={{ marginBottom: "10px" }}>
                        Dados a Importar - {totalImportar}
                      </Typography.Title>
                      <Divider style={{ marginBottom: "20px" }} />
                      <div
                        style={{
                          maxHeight: "200px", // Adjust as per modal height
                          overflowY: "auto",
                          border: "1px solid #ddd",
                          padding: "10px",
                          borderRadius: "4px",
                          marginTop: "10px",
                        }}
                      >
                        {tableLoading ? (
                          <div style={{ textAlign: "center", padding: "20px" }}>
                            <Typography.Text>Carregando...</Typography.Text>
                          </div>
                        ) : (
                          <>
                            <Col span={24} style={{ overflowX: "auto", maxHeight: "800px", overflowY: "auto", whiteSpace: "nowrap" }}>
                              <Table
                                // Unique key generation
                                dataSource={data}
                                columns={columns2}
                                size="large"
                                scroll={{x: "max-content", y: 160 }} 
                                pagination={false} 
                              />
                            </Col>
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end", // Align to bottom-left
                          gap: "10px",
                          marginTop: "20px",
                        }}
                      >
                        <Button type="default" onClick={handleCancel}>
                          Cancelar
                        </Button>
                        <Button type="primary" onClick={handleImportar}>
                          importar
                        </Button>
                      </div>
                    </div>
                  )}
                </Modal>
      </>
    </Breadcrumb>
  );
}
