import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import {
  createRef,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  DownloadOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  FiEdit2,
  FiFilter,
  FiMenu,
  FiPlus,
  FiSearch,
  FiTrash,
  FiX,
} from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Buttons, StyledDatePicker } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import ActionPlanService from "../../../services/SalesPlanning/ActionPlan";
import { Actions } from "../../Setup/Parameter/styles";
import moment from "moment";
import { CommercialStructure } from "../../../components/Filter/CommercialStructure";
import { clearCommercialStructure } from "../../../entities/Reducer";
import { resetFilter, setFiltercommercialStucture } from "../Reducer";
import { FormInstance } from "antd/lib";
import imageCompression from "browser-image-compression";
import Industry from "../../../services/Industry";
import { NoticeType } from "antd/lib/message/interface";

interface TasksProps {
  id: number;
  description: string;
  status?: string;
  initiative_id: number;
  initiative_name: string;
  level3_id: number;
  level3_name: string;
  initial_date: string;
  final_date: string;
  action_value: string;
}

interface Level3Props {
  id: number;
  level3_external_name: string;
}

interface InitiativeProps {
  id: number;
  company_id: number;
  name: string;
  thematic_name: string;
}

const { Option } = Select;

export function ActionPlan() {
  const user_id = useAppSelector((state) => state.auth.user.id);
  const [profile, setDataProfile] = useState<any>({});
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [menu, setMenu] = useState(true);
  const [modalNewAction, setModalNewAction] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const formRef = createRef<FormInstance>() as RefObject<FormInstance<any>>;
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [initialDate, setInitialDate] = useState<any>(null);
  const [finalDate, setFinalDate] = useState<any>(null);
  const [Level3, setLevel3] = useState<any>(null);
  const [listInitiatives, setListInitiatives] = useState<InitiativeProps[]>([]);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<
    number | null
  >(null);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [listLevel3, setListLevel3] = useState<Level3Props[]>([]);
  const [selectedLevel3Id, setSelectedLevel3Id] = useState<number | null>(null);

  const [searchData, setSearchData] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [modalFileView, setModalFileView] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [typeFile, setTypeFile] = useState("");
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);
  const [level0Name, setlevel0Name] = useState<any>("");
  const [level0Value, setlevel0Value] = useState<any>("");
  const [fileExists, setFileExists] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const handleChangeAward = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value.replace(/\D/g, "");
    const newValue = rawValue ? parseFloat(rawValue) / 100 : 0;
    setlevel0Value(newValue);
    console.log(rawValue);
    setlevel0Name(newValue);
    // setlevel0Value(
    //   formattedValue.toLocaleString("pt-BR", {
    //     style: "currency",
    //     currency: "BRL",
    //   })
    // ); // Format as currency
  };
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  const getProfile = async () => {
    const response2 = await Industry.getProfile(companyId, user_id);
    setDataProfile(response2[0].profile);
  };
  const getLastWordAfterDot = (link: string) => {
    const parts = link.split(".");
    return parts[parts.length - 1];
  };

  const getListTasks = useCallback(async () => {
    try {
      setLoading(true);
      const response: any[] = await ActionPlanService.findLevel3(
        companyId,
        commercialStructure.level3
      );

      const formattedTasks = await Promise.all(
        response.map(async (rawTask) => {
          return {
            id: rawTask.id,
            description: rawTask.description,
            initiative_id: rawTask.initiative_id,
            initiative_name: rawTask.initiative_name ?? "",
            level3_id: rawTask.level3_id,
            level3_name: rawTask.level3_external_name ?? "",
            status: rawTask.status,
            initial_date: rawTask.initial_date,
            final_date: rawTask.final_date,
            action_value: rawTask.action_value,
          };
        })
      );

      setListTasks(formattedTasks);
      //setSearchData(formattedTasks);
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);
  const url = window.location.origin.split("/").slice(0, 3).join("/");
  const urlMap: { [key: string]: string } = {
    "https://sistema-dev.cropland.com.br": "-dev",
    "https://sistema-hml.cropland.com.br": "-hml",
    "https://sistema.cropland.com.br": "",
  };

  const newUrl = urlMap[url] ?? "-dev";
  const getListInitiatives = useCallback(async () => {
    const response: InitiativeProps[] =
      await ActionPlanService.findAllInitiatives(companyId);
    setListInitiatives(response);
  }, [companyId]);

  const getListLevel3 = useCallback(async () => {
    const response: Level3Props[] = await ActionPlanService.findAllLevel3(
      companyId
    );
    setListLevel3(response);
  }, [companyId, Level3]);

  useEffect(() => {
    getListInitiatives();
    getListLevel3();
  }, [getListInitiatives, getListLevel3, Level3]);

  const columns = [
    {
      title: "Descriçao",
      dataIndex: "description",
      key: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: "Iniciativa",
      dataIndex: "initiative_name",
      key: "initiative_name",
      sorter: (a: any, b: any) =>
        a.initiative_name.localeCompare(b.initiative_name),
    },
    {
      title: "Data de início",
      dataIndex: "initial_date",
      key: "initial_date",
      sorter: (a: any, b: any) => a.initial_date.localeCompare(b.initial_date),
      render: (date: string | null) => {
        return date ? date.split("-").reverse().join("/") : "";
      },
    },
    {
      title: "Data de entrega",
      dataIndex: "final_date",
      key: "final_date",
      sorter: (a: any, b: any) => a.final_date.localeCompare(b.final_date),
      render: (date: string | null) => {
        return date ? date.split("-").reverse().join("/") : "";
      },
    },
    {
      title: "Valor Ação",
      dataIndex: "action_value",
      key: "action_value",
      sorter: (a: any, b: any) => a.action_value - b.action_value,
      render: (text: string | null) => {
        return text
          ? Number(text).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
    {
      title: "Responsável",
      dataIndex: "level3_name",
      key: "level3_name",
      sorter: (a: any, b: any) => a.level3_name.localeCompare(b.level3_name),
    },
    {
      title: "Arquivos",
      dataIndex: "id",
      key: "id",
      width: 200,
      align: "center" as const,
      render: (id: number) => !modalNewAction && <FileColumn id={id | 0} />,
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: TasksProps) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                onClick={() => handleClickEdit(record)}
                icon={<FiEdit2 size={18} color="#00CC66" />}
              />
            </Tooltip>
            {profile === "Administrador" ? (
              <Tooltip title="Excluir">
                <Popconfirm
                  title="Tem certeza que deseja excluir essa tarefa?"
                  onConfirm={() => confirmDelete(record)}
                  okText="Excluir"
                  okButtonProps={{ danger: true }}
                  cancelText="Cancelar"
                >
                  <Button
                    type="text"
                    icon={<FiTrash size={18} color="#00CC66" />}
                  />
                </Popconfirm>
              </Tooltip>
            ) : (
              <Tooltip title="Sem permissão para excluir">
                <Button
                  type="text"
                  icon={<FiTrash size={18} color="#00CC66" />}
                  disabled
                />
              </Tooltip>
            )}
          </Actions>
        );
      },
    },
  ];

  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return " Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }
  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }
  const dispatch = useAppDispatch();

  async function confirmDelete(task: TasksProps) {
    try {
      const filespgv = await findFile(task.id);
      if (filespgv?.files?.length > 0) {
        for (const file of filespgv.files) {
          await deleteFile(file.filename);
        }
      }
      await ActionPlanService.deleteTask(companyId, task.id);
      ShowMSG("success", "Tarefa excluída com sucesso");
    } catch (e) {
      console.error("Falha ao excluir Plano de Ação ");
      ShowMSG("error", "Falha ao excluir tarefa");
      console.error(e);
    } finally {
      await getListTasks();
      loadTableProduct(Level3, true);
    }
  }

  function handleShowModalNewAction() {
    // const formattedValue = Number(0) / 100; // Convert to decimal value
    // setlevel0Value(formattedValue.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }));
    setFileList([]);
    setModalNewAction(true);
  }

  async function handleSave() {
    setModalNewAction(false);
    const formData = new FormData();

    try {
      await Promise.all(
        fileList.map(async (file) => {
          const isImage = file.type ? file.type.startsWith("image/") : false;
          const maxSize = isImage ? 2 * 1024 * 1024 : 8 * 1024 * 1024; // 2MB para imagens, 8MB para docs

          let fileToProcess = file.originFileObj || file;

          if (!(fileToProcess instanceof File)) {
            console.warn("⚠️ Arquivo não é válido.");
            return;
          }

          if (isImage) {
            try {
              console.log(
                "🔄 Convertendo imagem para WebP:",
                fileToProcess.name
              );
              const compressedBlob = await imageCompression(fileToProcess, {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: "image/webp",
              });

              const compressedFile = new File(
                [compressedBlob],
                fileToProcess.name.replace(/\.[^.]+$/, ".webp"),
                {
                  type: "image/webp",
                }
              );

              formData.append("files", compressedFile, compressedFile.name);
              return;
            } catch (error) {
              console.error("❌ Erro ao converter imagem para WebP:", error);
            }
          } else {
            if (fileToProcess.size <= maxSize) {
              formData.append("files", fileToProcess, fileToProcess.name);
            } else {
              console.warn(
                `⚠️ Arquivo ${fileToProcess.name} excede o tamanho máximo de ${
                  maxSize / 1024 / 1024
                }MB.`
              );
            }
          }
        })
      );
    } catch (error) {
      console.error("❌ Erro ao processar arquivos:", error);
    }

    if (selectedTaskId) {
      try {
        await ActionPlanService.updateTask(companyId, {
          id: selectedTaskId,
          description:
            description[0].toUpperCase() + description.slice(1).toLowerCase(),
          status,
          level3_id: selectedLevel3Id,
          initiative_id: selectedInitiativeId,
          initial_date: initialDate,
          final_date: finalDate,
          action_value: level0Name,
        });
        await ActionPlanService.uploadFiles(
          companyId,
          selectedTaskId,
          formData
        );
        await loadTableProduct(Level3, true);
        ShowMSG("success", "Tarefa atualizada com sucesso");
      } catch (e) {
        ShowMSG("error", "Falha ao atualizar tarefa");
        console.error(e);
      } finally {
        await getListTasks();
      }
    } else {
      try {
        const newTask = await ActionPlanService.createTask(companyId, {
          description:
            description[0].toUpperCase() + description.slice(1).toLowerCase(),
          status,
          level3_id: selectedLevel3Id,
          initiative_id: selectedInitiativeId,
          initial_date: initialDate,
          final_date: finalDate,
          action_value: level0Name,
        });
        ShowMSG("success", "Tarefa criada com sucesso");
        try {
          await ActionPlanService.uploadFiles(companyId, newTask.id, formData);
        } catch (e) {
          // ShowMSG("error", "Falha ao enviar arquivo");
          console.error(e);
        }
      } catch (e) {
        ShowMSG("error", "Falha ao criar tarefa");
        console.error(e);
      } finally {
        clearSelecteds();
        await loadTableProduct(Level3, true);
        await loadTableProduct(Level3, true);
      }
    }

    closeModal();
  }

  function clearSelecteds() {
    setSelectedTaskId(null);
    setSelectedInitiativeId(null);
    // setSelectedLevel3Id(null);
    setInitialDate(null);
    setFinalDate(null);
    setDescription("");
    setlevel0Name(null);
    setlevel0Value(null);
    setStatus(undefined);
  }

  function closeModal() {
    setModalNewAction(false);
    setFileList([]);
    setFileExists(false);
    clearSelecteds();
  }

  const formatToBRL = (value: any) => {
    const numericValue = Number(value) || 0; // Ensure it's a valid number
    return numericValue.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  async function handleClickEdit(task: TasksProps) {
    setSelectedTaskId(task.id ?? null);
    setDescription(task.description);
    setStatus(task.status);
    setSelectedLevel3Id(task.level3_id);
    setSelectedInitiativeId(task.initiative_id);
    setInitialDate(task.initial_date ? moment(task.initial_date) : null);
    setFinalDate(task.final_date ? moment(task.final_date) : null);
    const rawValue = String(task.action_value ?? "0").replace(/\D/g, "");
    const newValue = rawValue ? parseFloat(rawValue) : 0;

    // const formattedValue =
    //   Number(
    //     task.action_value ? String(task.action_value).replace(/\D/g, "") : 0
    //   );
    setlevel0Name(newValue);
    setlevel0Value(newValue);
    // setlevel0Value((prev:any) => {
    //  console.log("Previous state:", prev, "New value:", formattedValue);
    //   return formattedValue.toLocaleString("pt-BR", {
    //     style: "currency",
    //     currency: "BRL",
    //   });
    // });
    handleShowModalNewAction();
    const filespgv = await findFile(task.id);
    setFileExists(filespgv?.files?.length > 0);
    setFileList(filespgv?.files || []);
  }

  useEffect(() => {
    getProfile();
    filterTable();

    function filterTable() {
      if (!search && !filteredStatus) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            (!filteredStatus ||
              (record["status"] &&
                record["status"]
                  ?.toUpperCase()
                  .indexOf(filteredStatus.toUpperCase()) > -1)) &&
            (!search ||
              record["initiative_name"]
                .toLowerCase()
                .indexOf(search.toLowerCase()) > -1)
        );
        setSearchData(array);
      }
    }
  }, [filteredStatus, search, listTasks]);

  function disableSave(): boolean {
    return !selectedInitiativeId ||
      !description ||
      description?.trim().length === 0 ||
      status === "FINALIZADO"
      ? fileList.length === 0
      : false;
  }

  function handleChangeStatus(e: any) {
    const { value } = e.target;
    if (value === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(value);
    }
  }
  function handleRemoveStatus() {
    setFilteredStatus(null);
  }

  function disabledDate(current: any) {
    if (initialDate) {
      return current && current < initialDate;
    } else {
      return false;
    }
  }
  const findFile = async (id: number) => {
    const filespgv = await ActionPlanService.getFilesPGV(companyId, id);
    return filespgv || [];
  };

  const deleteFile = async (fileName: string) => {
    try {
      await ActionPlanService.deleteFilePGV(companyId, fileName);
      ShowMSG("success", "Arquivo excluído com sucesso");
    } catch (e) {
      ShowMSG("error", "Falha ao excluir arquivo");
      console.error(e);
    } finally {
      await loadTableProduct(Level3, true);
    }
  };

  const loadTableProduct = useCallback(
    async (Level3: number, showLoading: boolean) => {
      try {
        setStatus(undefined);
        setLoading(showLoading);
        const response = await ActionPlanService.findLevel3(companyId, Level3);
        setLevel3(Level3);
        setSelectedLevel3Id(Level3);
        const formattedTasks = response.map((rawTask: any) => {
          // findFile(rawTask.id);
          return {
            id: rawTask.id,
            description: rawTask.description,
            initiative_id: rawTask.initiative_id,
            initiative_name: rawTask.initiative_name ?? "",
            level3_id: rawTask.level3_id,
            level3_name: rawTask.level3_external_name ?? "",
            status: rawTask.status,
            initial_date: rawTask.initial_date,
            final_date: rawTask.final_date,
            action_value: rawTask.action_value,
          };
        });
        setListTasks(formattedTasks);
      } catch (err) {
        ShowMSG("error", "Falha ao buscar tarefas");
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );
  const commercialStructure = useAppSelector(
    (state) => state.salesPlanning.filter
  );

  useEffect(() => {
    return function clean() {
      dispatch(resetFilter());
      dispatch(clearCommercialStructure());
    };
  }, [dispatch]);

  async function onFinish(obj: {
    level0: number;
    level1: number;
    level2: number;
    level3: number;
  }) {
    setStatus(undefined);
    dispatch(setFiltercommercialStucture(obj));
    loadTableProduct(obj.level3, true);
    // setMenu(false);
  }
  const { Title } = Typography;

  const FileColumn = memo(({ id }: { id: number }) => {
    const [fileData, setFileData] = useState<
      | { id: number; filename: string; filetype: string; filepath: string }[]
      | null
    >(null);
    const fileViewr = async (fileName: string) => {
      try {
        setModalFileView(true);
        setFileLink(
          `https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgv/action-plan/download/${fileName}`
        );
        const lastWord = getLastWordAfterDot(
          `https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgv/action-plan/download/${fileName}`
        );
        setTypeFile(lastWord);
      } catch (e) {
        console.error("Falha ao buscar arquivos");

        console.error(e);
      }
    };

    useEffect(() => {
      const fetchFiles = async () => {
        if (id === 0) return null;
        try {
          const filespgv = await findFile(id || 0);
          setFileData(filespgv?.files || []);
        } catch (e) {
          setFileData([]);
        }
      };

      fetchFiles();
    }, [id]);

    if (fileData === null)
      return (
        <div style={{ width: "180px" }}>
          <LoadingOutlined />
        </div>
      );
    if (fileData.length === 0) return <span></span>;

    return (
      <div style={{ width: "180px" }}>
        {fileData.map((file: any) => {
          const isImage = ["webp", "jpg", "jpeg", "png"].includes(
            file.filetype.toLowerCase()
          );
          const isPdf = ["pdf"].includes(file.filetype.toLowerCase());
          const isDoc = ["doc", "docx"].includes(file.filetype.toLowerCase());
          const isXls = ["xls", "xlsx"].includes(file.filetype.toLowerCase());
          return (
            <div
              key={file.id}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tooltip
                title={isImage ? "Visializar Imagem" : "Visializar Documento"}
              >
                <Button
                  type="link"
                  onClick={() => {
                    if (isImage) {
                      fileViewr(file.filename);
                    } else {
                      fileViewr(file.filename);
                    }
                  }}
                >
                  {isImage && <FileImageOutlined style={{ color: "gray" }} />}
                  {isPdf && <FilePdfOutlined style={{ color: "red" }} />}
                  {isDoc && <FileWordOutlined style={{ color: "blue" }} />}
                  {isXls && <FileExcelOutlined style={{ color: "green" }} />}

                  {file.filename}
                </Button>
              </Tooltip>
              <a
                href={`https://api-cropland-sa-saopaulo-1${newUrl}.cropland.com.br/v1/company/20/pgv/action-plan/download/${file.filename}`}
                style={{ marginLeft: "-5px" }}
              >
                <DownloadOutlined />
              </a>
              {profile === "Administrador" ? (
                <Tooltip title="Excluir arquivo">
                  <Popconfirm
                    title="Tem certeza que deseja excluir esse Arquivo?"
                    onConfirm={() => deleteFile(file.filename)}
                    okText="Excluir"
                    okButtonProps={{ danger: true }}
                    cancelText="Cancelar"
                  >
                    <Button type="text" icon={<FiTrash color="red" />} />
                  </Popconfirm>
                </Tooltip>
              ) : (
                <Tooltip title="Sem permissão para excluir">
                  <Button type="text" icon={<FiTrash color="red" />} disabled />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
    );
  });
  return (
    <Breadcrumb path="Plano de Ação">
      <>
        {contextHolder}
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col style={{ textAlign: "center", flexGrow: 1, background: "none" }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Plano de Ação - PGV
            </Title>
          </Col>
        </Row>
        <Row gutter={8}>
          {menu && (
            <Col xl={4} xs={24} sm={12}>
              <Card bordered={false}>
                <Form
                  ref={formRef}
                  onFinish={onFinish}
                  initialValues={commercialStructure}
                >
                  <CommercialStructure formRef={formRef} level={3} />
                  <Button htmlType="submit" type="primary" block>
                    Buscar
                  </Button>
                </Form>
              </Card>
            </Col>
          )}
          <Col xl={menu ? 20 : 24} xs={24}>
            <Card bordered={false}>
              <Row justify="space-between" gutter={[16, 16]}>
                <Col xl={8} xs={24}>
                  <Buttons>
                    {menu ? (
                      <Tooltip title="Fechar Menu">
                        <Button
                          type="text"
                          style={{ marginRight: 16 }}
                          onClick={() => setMenu(!menu)}
                          icon={
                            <FiX
                              size={25}
                              color="#00CC66"
                              style={{
                                margin: "15px 10px 10px 10px",
                                cursor: "pointer",
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Abrir Menu">
                        <Button
                          type="text"
                          style={{ marginRight: 16 }}
                          onClick={() => setMenu(!menu)}
                          icon={
                            <FiMenu
                              size={25}
                              color="#00CC66"
                              style={{
                                margin: "15px 10px 10px 10px",
                                cursor: "pointer",
                              }}
                            />
                          }
                        />
                      </Tooltip>
                    )}
                    <Button
                      icon={<FiPlus />}
                      type="primary"
                      onClick={handleShowModalNewAction}
                      disabled={Level3 === null}
                    >
                      Novo
                    </Button>
                    <Input
                      allowClear
                      value={search}
                      placeholder="Pesquisar"
                      prefix={<FiSearch color="#00CC66" />}
                      suffix={<FiFilter color="#00CC66" />}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Buttons>
                </Col>
                <Col>
                  <Radio.Group buttonStyle="solid" value={filteredStatus}>
                    <Radio.Button onClick={handleChangeStatus} value="PENDENTE">
                      PENDENTE
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="EM ANDAMENTO"
                    >
                      EM ANDAMENTO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="CANCELADO"
                    >
                      CANCELADO
                    </Radio.Button>
                    <Radio.Button
                      onClick={handleChangeStatus}
                      value="FINALIZADO"
                    >
                      FINALIZADO
                    </Radio.Button>
                    {filteredStatus !== null ? (
                      <Button
                        style={{
                          backgroundColor: "#fc5f5f",
                          color: "#000",
                        }}
                        onClick={handleRemoveStatus}
                      >
                        X
                      </Button>
                    ) : (
                      ""
                    )}
                  </Radio.Group>
                </Col>
                <Col span={24}>
                  <Table
                    rowKey="id"
                    size="small"
                    columns={columns}
                    // pagination={false}
                    // scroll={{ y: 430, x: "100vw" }}
                    dataSource={searchData}
                    loading={{ indicator: Icon, spinning: isLoading }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Modal
            open={modalNewAction}
            okButtonProps={{ disabled: disableSave() }}
            okText={"Salvar"}
            onOk={handleSave}
            onCancel={closeModal}
            maskClosable={false}
            title={selectedTaskId ? "Atualizar Ação" : "Nova Ação"}
          >
            <Row justify="center" gutter={20}>
              <Col span={12}>
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Descrição"
                  value={
                    description !== ""
                      ? description[0].toUpperCase() +
                        description.slice(1).toLowerCase()
                      : undefined
                  }
                  onChange={(e) => setDescription(e.target.value)}
                />

                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Inicial"
                  size="large"
                  value={initialDate}
                  onChange={(date: any) => setInitialDate(date)}
                />

                <Input
                  //disabled={selectedLevel !== 0}
                  key={selectedTaskId}
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Valor Ação"
                  value={formatToBRL(level0Value ?? 0)}
                  //onChange={(e) => setlevel0Name(e.target.value)}
                  onChange={handleChangeAward}
                />
              </Col>

              <Col span={12}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Status"
                  onChange={(value) => setStatus(value)}
                  value={status}
                >
                  <Option value={"PENDENTE"}>Pendente</Option>
                  <Option value={"CANCELADO"}>Cancelado</Option>
                  <Option value={"EM ANDAMENTO"}>Em Andamento</Option>
                  <Option value={"FINALIZADO"}>Finalizado</Option>
                </Select>

                <StyledDatePicker
                  format="DD/MM/YYYY"
                  placeholder="Data Final"
                  size="large"
                  value={finalDate}
                  onChange={(date: any) => setFinalDate(date)}
                  disabledDate={disabledDate}
                />
                <Input
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  placeholder="Responsável"
                  value={
                    Level3
                      ? listLevel3.find((level3) => level3.id === Level3)
                          ?.level3_external_name
                      : ""
                  }
                  disabled
                />
              </Col>
            </Row>
            <Row justify="center" gutter={20} style={{ marginBottom: 40 }}>
              <Col span={24}>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  showSearch
                  placeholder="Iniciativa"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiativeId}
                >
                  {listInitiatives.map((initiative) => (
                    <Option value={initiative.id}>{initiative.name}</Option>
                  ))}
                </Select>
              </Col>
              <Col span={24}>
                {fileExists ? (
                  <FileColumn id={selectedTaskId || 0} />
                ) : (
                  <Upload
                    fileList={fileList}
                    beforeUpload={(file) => {
                      setFileList([file]);
                      return false;
                    }}
                    onRemove={() => setFileList([])}
                    listType="picture"
                    accept=".jpg,.jpeg,.png,.webp,.pdf,.doc,.docx,.xls,.xlsx"
                    maxCount={1}
                    showUploadList={{
                      showPreviewIcon: true,
                      showRemoveIcon: true,
                      showDownloadIcon: true,
                    }}
                  >
                    <Button icon={<UploadOutlined />}>IMG/DOC</Button>
                  </Upload>
                )}
                {fileList.length > 0 && selectedTaskId === null && (
                  <div>
                    {fileList[0].type?.startsWith("image/") ? (
                      <Image
                        src={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        alt="Imagem Selecionada"
                        style={{ marginTop: 10 }}
                      />
                    ) : fileList[0].type?.startsWith("application/pdf") ? (
                      <a
                        href={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 10, display: "block" }}
                      >
                        Visualizar Documento
                      </a>
                    ) : (
                      <a
                        href={URL.createObjectURL(
                          fileList[0] as unknown as File
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginTop: 10, display: "block" }}
                      >
                        Baixar Documento
                      </a>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </Modal>
          <Modal
            title="Visualizar Arquivo"
            open={modalFileView}
            onCancel={() => {
              setModalFileView(false);
              setFileLink("");
              setTypeFile("");
            }}
            footer={null}
          >
            {typeFile === "webp" ? (
              <Image src={fileLink} />
            ) : (
              <iframe
                title="Visualizar Arquivo"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${fileLink}`}
                width="450px"
                height="450px"
              />
            )}
          </Modal>
        </Row>
      </>
    </Breadcrumb>
  );
}
