import { createSlice } from "@reduxjs/toolkit";

interface FilterProps {
  businessType: number;
  productType: number;
  accessType: number;
  level0: number;
  level1: number;
  level2: number;
  level3: number;
  level4: number;
}

const initialState = {
  filter: {} as FilterProps,
};

export const SalesPlanningReducer = createSlice({
  name: "salesPlanning",
  initialState,
  reducers: {
    setFilterBusinessType: (state, action) => {
      state.filter = action.payload;
    },
    setFilterProductType: (state, action) => {
      state.filter = action.payload;
    },
    setFilterAccesType: (state, action) => {
      state.filter = action.payload;
    },
    setFiltercommercialStucture: (state, action) => {
      state.filter = action.payload;
    },
    setFilterLevel0: (state, action) => {
      state.filter.level0 = action.payload;
    },
    setFilterLevel1: (state, action) => {
      state.filter.level1 = action.payload;
    },
    resetFilter: (state) => {
      state.filter = {} as FilterProps;
    },
  },
});

export const {
  setFilterBusinessType,
  setFilterProductType,
  setFilterAccesType,
  setFiltercommercialStucture,
  setFilterLevel0,
  setFilterLevel1,
  resetFilter,
} = SalesPlanningReducer.actions;

export default SalesPlanningReducer.reducer;
