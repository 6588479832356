import CompanyBusinessTypeService from "../../services/Company/BusinessType";
import { store } from "../../store";
import { setRecordsBusinessType } from "../Reducer";

const { dispatch } = store;

export const businessType = async (companyId: number) => {
  try {
    const response = await CompanyBusinessTypeService.getCompanyBusinessType(
      companyId
    );
    dispatch(setRecordsBusinessType(response));
  } catch (err) {
    // Handle error appropriately
    console.error("Error fetching business types:", err);
  }
};
