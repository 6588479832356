import { Breadcrumb } from "../../../components/Breadcrumb";
import { Col, Row, ColHeader, ItemsContainer, Input ,Select,StructureItemContainerse} from "./styles";
import { Button, message, Modal, Typography } from "antd";
import { FiPlus } from "react-icons/fi";
import { StructureItem, StructureItemProduct,StructureItemAward } from "./StructureItem";
import { CommercialStructureProps,CompanyCampaign,Industry } from "../../../entities/DTO";

import { useEffect, useState } from "react";
import CommercialStructureService from "../../../services/Company/CommercialStructure";
import ElectProductService from "../../../services/ElectProduct";
import { useAppSelector } from "../../../hooks";
import { Loading } from "../../../components/Loading";
import { NoticeType } from "antd/lib/message/interface";

interface TasksProps {
  id: number;
  industry_id:number;
  campaign_name:string;
  campaign_id:number;
  product: {
    id: number;
    company_id: number;
    industry_id: number;
    business_type_id: number;
    segment_name: string;
    sub_segment_name: string;
    brand_name: string;
    product_name: string;
    product_external_code: string | null;
    sku_external_code: string | null;
    sku_name: string;
    price_base_line: number;
    price_year1: number;
    price_year2: number;
    price_year3: number;
    price_year4: number;
    price_year5: number;
    type_name: string;
    KG_L: string | null;
    company_product_group_id: number;
    createdAt: string;
    updatedAt: string;
  };
}

interface CompanyAward {
  id: number;
  campaign_id: number;
  name: string;
  
}

interface Product {
  id: number;
  company_id: number;
  industry_id: number;
  business_type_id: number;
  segment_name: string;
  sub_segment_name: string;
  brand_name: string;
  product_name: string;
  product_external_code: null | string;
  sku_external_code: null | string;
  sku_name: string;
  price_base_line: number;
  price_year1: number;
  price_year2: number;
  price_year3: number;
  price_year4: number;
  price_year5: number;
  type_name: string;
  KG_L: null | string;
  company_product_group_id: number;

}

const { Option } = Select;


export function CampaignAwards() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);

  //campaign
  const [isCampagnLoading, setCampagnLoading] = useState<boolean>(false);
  const [isIndustryLoading, setIndustryLoading] = useState<boolean>(false);
  const [isProductsLoading, setProductsLoading] = useState<boolean>(false);
  const [isAwardLoading, setAwardLoading] = useState<boolean>(false);
  const [CampaignList, setCampaignList] = useState<CompanyCampaign[]>([]);
  const [CampaignproductList, setCampaignproductList] = useState<CompanyAward[]>([]);
  const [IndustryproductList, setIndustryproductList] = useState<Product[]>([]);
  const [listInitiatives, setListInitiatives] = useState<Product[]>([]);
  const [IndustryList, setIndustryList] = useState<string | null>(null);
  const [IndustryId, setIndustryId] = useState<number | null>(null);
  // const [selectedCampaign, setselectedCampaign] =
  //   useState<CompanyCampaign | null>(null);

    const [selectedProduct, setselectedProduct] =
    useState<CompanyAward | null>(null);

    const [selectedCampaignse, setselectedCampaignse] =
    useState<number | null>(null);

 
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>([]);
  const [selectedCampaignId, setSelectedCampaignId] = useState<any>([]);

  const [selectedCampaign, setselectedCampaign] =
    useState<any>([]);


  const [showModal, setShowModal] = useState<boolean>(false);
 
  const [level0Name, setlevel0Name] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };




  async function handleClickCampaign(campaign: CompanyCampaign) {
    

    if (selectedCampaign?.campaign_id === campaign.campaign_id) {
      setselectedCampaign(null);
    } else {
      setselectedCampaign(campaign);
      
    }
  }

  async function handleClickProduct(product: CompanyAward) {
   

    if (selectedProduct?.id === product.id) {
      setselectedProduct(null);
    } else {
      setselectedProduct(product);
      
     
    }
  }

  async function handleSelectCampaign(campaign: CompanyCampaign) {
  
    setIndustryList(null)
    setIndustryId(null)
    setSelectedInitiativeId(null)

    

    if (selectedCampaign?.campaign_id === campaign.campaign_id) {
      setSelectedCampaignId(campaign.campaign_id);
      setselectedCampaign(campaign);
      await getProductList(campaign.campaign_id);
      await getClientList(campaign.industry_id);

      await getProductIndustryList(campaign.industry_id);
      
    } else {
      setSelectedCampaignId(campaign.campaign_id);
      setselectedCampaign(campaign);
      await getProductList(campaign.campaign_id);
      await getClientList(campaign.industry_id);

      await getProductIndustryList(campaign.industry_id);
      

    }
  }

  async function getProductList(campaignid: number) {
    try {
      console.log("0000000000"+campaignid);
      setProductsLoading(true);
      const response: CompanyAward[] =
        await ElectProductService.getAwardProducts(
          campaignid,
          
        );
        console.log("aasdasdasds"+[...response]);
        setCampaignproductList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de produtos");
      console.error(e);
      ShowMSG("error","Falha ao buscar lista de Produtos");
    } finally {
      setProductsLoading(false);
    }
  }

  async function getClientList(Industry_id: number) {
    try {
     // console.log("che"+Industry_id);
      setIndustryLoading(true);
      const response: Industry =
        await ElectProductService.getIndustry(
          Industry_id,
          
        );
      //  console.log("as"+response.name);
        setIndustryList(response.name);
        setIndustryId(response.id);

    } catch (e) {
      console.error("Falha ao buscar lista de level 2");
      console.error(e);
      ShowMSG("error","Falha ao buscar lista de Diretorias");
    } finally {
      setIndustryLoading(false);
    }
  }


  async function getCampaignList() {
    try {
      setCampagnLoading(true);
      const response: CompanyCampaign[] =
        await ElectProductService.getCampaign(
          companyId
        );
      setCampaignList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de Campanhas");
      console.error(e);
      ShowMSG("error","Falha ao buscar lista de Campanhas");
    } finally {
      setCampagnLoading(false);
    }
  }

  
  useEffect(() => {
    getCampaignList();
  }, [companyId]);


  function renderCampaignList() {
    if (isCampagnLoading) {
      return <Loading />;
    }

    console.log(CampaignList)

    return CampaignList.map((item, i) => (
      <StructureItem
        isActive={selectedCampaign?.campaign_id === item.campaign_id}
        handleClick={() => handleClickCampaign(item)}
        key={i}
        campaign={item}
        level={0}
        handleUpdate={() => handleUpdateCampaign(item)}
        showUpdate={
          selectedCampaign?.campaign_id === item.campaign_id &&
          CampaignList.length === 0 &&
          !isCampagnLoading
        }
        showDelete={
          selectedCampaign?.campaign_id === item.campaign_id &&
          CampaignList.length === 0 &&
          !isCampagnLoading
        }
        handleDelete={async () => await handleDeleteCampaign(item.campaign_id, 0)}
      />
    ));
  }

  function renderProductList() {
    if (isProductsLoading) {
      return <Loading />;
    }

    console.log(CampaignproductList)

    return CampaignproductList.map((item, i) => (
      <StructureItemAward
        isActive={selectedProduct?.id === item.id}
        handleClick={() => handleClickProduct(item)}
        key={i}
        award={item}
        level={0}
        
        // showUpdate={
        //   selectedCampaign?.campaign_id === item.campaign_id &&
        //   CampaignList.length === 0 &&
        //   !isCampagnLoading
        // }
        showDelete={
          //selectedProduct?.campaign_id === item.campaign_id &&
          //CampaignproductList.length === 0 &&
          !isProductsLoading
        }
        handleDelete={async () => await handleDeleteProduct(item.id)}
      />
    ));
  }

  function renderAwarList() {
    if (isAwardLoading) {
      return <Loading />;
    }

    console.log(CampaignproductList)

    return CampaignproductList.map((item, i) => (
      <StructureItemAward
        isActive={selectedProduct?.id === item.id}
        handleClick={() => handleClickProduct(item)}
        key={i}
        award={item}
        level={0}
        
        // showUpdate={
        //   selectedCampaign?.campaign_id === item.campaign_id &&
        //   CampaignList.length === 0 &&
        //   !isCampagnLoading
        // }
        showDelete={
          //selectedProduct?.campaign_id === item.campaign_id &&
          //CampaignproductList.length === 0 &&
          !isAwardLoading
        }
        handleDelete={async () => await handleDeleteProduct(item.id)}
      />
    ));
  }

  function renderIndustryList() {
    if (isIndustryLoading) {
      return <Loading />;
    }

   console.log("??"+IndustryList)

    return <span>{IndustryList?IndustryList:"" }</span>
  }

  function renderCampaignListSelect() {
    if (isCampagnLoading) {
      return <Loading />;
    }

    console.log(CampaignList)

    return <Select
    placeholder="Selecione "
    value={selectedCampaign?.campaign_id}
    onChange={(event) => {
      const campaignId = parseInt(event as string);
      const selected = CampaignList.find((c) => c.campaign_id === campaignId!)!;
      if (selectedCampaign?.campaign_id === campaignId) {
        setselectedCampaign(selected);
        console.log(selectedCampaign)
        // getLevel1List(campaign.campaign_id);
      } else {
        setselectedCampaign(selected);
         handleSelectCampaign(selected)
        console.log(selectedCampaign)
      }
    }}
  >
   
    {CampaignList.map((campaign) => (
      <Option key={campaign.campaign_id} value={campaign.campaign_id}>
        {campaign.campaign_name}
      </Option>
    ))}
  </Select>
 
  }


  function handleUpdateCampaign(item: CompanyCampaign) {

  
    setShowModal(true);
  }

  async function handleDeleteCampaign(id: number, level: number) {
    try {
      await CommercialStructureService.deleteCommercialStrucuteItem(
        companyId,
        id
      );
      ShowMSG("success","Item deletado com sucesso");
     
    } catch (e) {
      console.error("Falha ao salvar item");
      ShowMSG("error","Falha ao deletar item");
      console.error(e);
    }
  }

  async function handleDeleteProduct(ProductId: number) {

    try {
      console.log("delete"+ProductId);
      await ElectProductService.DeleteCompanyAward(
        selectedCampaignId,
        ProductId
      );

      clearData();

      ShowMSG("success","Item deletado com sucesso");
    } catch (e) {
      console.error("Falha ao deletar item");
      ShowMSG("error","Falha ao deletar item");
      console.error(e);
    }
  }

  async function handleSave() {
    try {
      if (level0Name) {
       
        await ElectProductService.createCompanyAward(
          selectedCampaignId, {
            campaign_id: selectedCampaignId,
            name: level0Name,
          }
          
        );
      } 
      setShowModal(false);
      clearData();
      ShowMSG("success","Item salvo com sucesso");
      
    } catch (e) {
      console.error("Falha ao salvar item");
      ShowMSG("error","Falha ao salvar item");
      console.error(e);
    }
  }

  function handleCancel() {
    setShowModal(false);
    clearData();
  }


  async function getProductIndustryList(industry_id: number) {
    try {
      console.log("0000000000"+companyId);
     // setProductsLoading(true);
      const response: Product[] =
        await ElectProductService.getIndustryProducts(
          companyId,
          industry_id
          
        );
        setIndustryproductList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de produtos");
      console.error(e);
      ShowMSG("error","Falha ao buscar lista de Produtos");
    } 
  }

  function handleOpenNewModal() {
    
    setShowModal(true);

  }

  function clearData() {
    // setSelectedId(null);
    handleSelectCampaign(selectedCampaign)
  }



  return (
    <Breadcrumb path="Campanha" child={["Elect Product"]}>
      {contextHolder}
      <>
        <Row>
          <Col>
            <ColHeader>
              <span>CAMPANHA</span>
         
            </ColHeader>

            <ItemsContainer>
              
              {renderCampaignListSelect()}
             
              </ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>INDUSTRIA</span>
          
            </ColHeader>

            <ItemsContainer>
              
              {renderIndustryList()}
              </ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>PREMIOS</span>
              <Button
                onClick={() => handleOpenNewModal()}
                disabled={!IndustryList}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderProductList()}</ItemsContainer>
          </Col>

          
        
        </Row>

        <Modal
          open={showModal}
          okText={"Adicionar"}
          onOk={handleSave}
         // okButtonProps={{ disabled: disableSave() }}
          onCancel={handleCancel}
        >
          <Typography.Title level={4}>
            Adicionar Premiação a Campanha
          </Typography.Title>
          <Input
            //disabled={selectedLevel !== 0}
            size="large"
            placeholder="Valor Premiação"
            value={level0Name}
            onChange={(e) => setlevel0Name(e.target.value)}
          />
          {/* <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  popupMatchSelectWidth={false}
                  showSearch
                  placeholder="Produtos"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiativeId}
                >
                  {IndustryproductList.map((initiative) => (
                    <Option value={initiative.id}>{initiative.product_name}</Option>
                  ))}
                </Select> */}

        </Modal>
      </>
    </Breadcrumb>
  );
}
