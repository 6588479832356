import Http from "../../../utils/http";
import { UserData } from "./userDataTypes";

class UserService extends Http {
  public constructor() {
    super();
  }

  public findAll = (): Promise<UserData[]> => {
    return this.instance.get(`/global/user`);
  };

  public findByEmail = (email: string): Promise<UserData> => {
    return this.instance.get(`/global/user/${email}`);
  };

  public findByEmailImportar = (email: string): Promise<any> => {
    return this.instance.get(`/global/user/check/${email}`);
  };

  public create = (user: UserData): Promise<UserData> => {
    return this.instance.post(`/global/user`, user);
  };

  public update = (user: UserData): Promise<UserData> => {
    return this.instance.put(`/global/user/${user.id}`, user);
  };

  public deleteById = (userId: number | null | undefined): Promise<void> => {
    return this.instance.delete(`/global/user/${userId}`);
  };

  public getCompanyLinks = (companyId: number): Promise<any> => {
    return this.instance.get(`/company/${companyId}/user`);
  };

  public getCompanyUserWithGlobalUser = (companyId: number): Promise<any> => {
    return this.instance.get(`/company/${companyId}/user/withglobal`);
  };
  public linkToCompany = (
    companyId: number,
    userId: number | undefined,
    profile_id: any
  ): Promise<void> => {
    return this.instance.post(`/company/${companyId}/user`, {
      user_id: userId,
      profile_id: profile_id,
    });
  };
  public updateCompanyUser = (
    companyId: number,
    userId: number | undefined,
    body: any
  ): Promise<void> => {
    return this.instance.put(`/company/${companyId}/user/${userId}`, body);
  };

  public unlinkToCompany = (
    companyId: number,
    userId: number | undefined
  ): Promise<void> => {
    return this.instance.delete(`/company/${companyId}/user/${userId}`);
  };

  public Login = (user: string, password: string): any => {
    return this.instance.post(`/global/user/login`, {
      email: user,
      password: password,
    });
  };
  
  public FistLogin = (user: string): any => {
    return this.instance.post(`/global/user/fistlogin`, {
      email: user,
    });
  };

  public ChangePassword = (user: string, password: string): any => {
    return this.instance.post(`/global/user/changepassword`, {
      email: user,
      newPassword: password,
    });
  };

  public SendEmail = (email: string, url: string): any => {
    return this.instance.post(`/global/user/sendemail`, {
      email: email,
      url: url,
    });
  };
  public SendEmailWelcome = (email: string, comanyName: string): any => {
    return this.instance.post(`/global/user/sendemailWelcome`, {
      email: email,
      comanyName: comanyName,
    });
  };

  public verifyPassword = (code: string, password: string): any => {
    return this.instance.post(`/global/user/VerifyPassword`, {
      code: code,
      newPassword: password,
    });
  };

  public ResetPassword = (email: string, newPassword: string): any => {
    return this.instance.post(`/global/user/resetadmin`, {
      email: email,
      newPassword: newPassword,
    });
  };
}

export default new UserService();
