import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Popconfirm,
  message,
  Select,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import { Actions, Input } from "./styles";
import { useAppSelector } from "../../../hooks";
import ParameterService from "../../../services/SalesPlanning/Setup/Parameter";
import { ParameterData } from "../../../services/SalesPlanning/Setup/Parameter/ParameterDataTypes";
import { NoticeType } from "antd/lib/message/interface";

const { Option } = Select;
export function Parameter() {
  const companyId = useAppSelector((state) => state.parameters.company.id);

  const [modalNewParameter, setShowModalNewParameter] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedParameterId, setSelectedParameterId] = useState<number | null>(
    null
  );
  const [listParameters, setListParameters] = useState<ParameterData[]>([]);
  const [name, setName] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [searchData, setSearchData] = useState<any>([]);

  const [selectedInitiativeId, setSelectedInitiativeId] = useState<any>([]);
  const [selectedInitiative, setSelectedInitiative] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const ShowMSG = (type: NoticeType, test: string) => {
    messageApi.open({
      type,
      content: test,
    });
  };
  const getListParameter = useCallback(async () => {
    try {
      localStorage.setItem("historico", "buscout parametros");
      setLoading(true);
      const response: ParameterData[] = await ParameterService.findAll(
        companyId
      );
      console.log(response);
      setListParameters(response);
      setSearchData(response);
      setSearch("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      ShowMSG("error", "Falha ao buscar parâmetros");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [companyId]);

  useEffect(() => {
    getListParameter();
  }, [getListParameter]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      sorter: (a: any, b: any) => a.value.localeCompare(b.value),
      render: (text: any) => {
        if (text.length > 50) {
          return text.substring(0, 50) + "...";
        }
        return text;
      },
    },
    {
      title: "Tipo",
      dataIndex: "type_id",
      key: "type_id",
      sorter: (a: any, b: any) => a.type_id - b.type_id,
      render: (text: any) => {
        if (text === 1) {
          return "Data";
        }
        if (text === 2) {
          return "Estrutura Comercial";
        }
        if (text === 3) {
          return "Link Dashboard";
        }
        if (text === 4) {
          return "Outros";
        }
        return text;
      },
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: ParameterData) => {
        return (
          <Actions>
            <Tooltip title="Editar">
              <Button
                type="text"
                onClick={() => handleClickEdit(record)}
                icon={<FiEdit2 size={18} color="#00CC66" />}
              />
            </Tooltip>
            <Tooltip title="Excluir">
              <Popconfirm
                title="Tem certeza que deseja excluir esse parâmetro?"
                onConfirm={() => confirmDelete(record)}
                okText="Excluir"
                okButtonProps={{ danger: true }}
                cancelText="Cancelar"
              >
                <Button
                  type="text"
                  icon={<FiTrash size={18} color="#00CC66" />}
                />
              </Popconfirm>
            </Tooltip>
          </Actions>
        );
      },
    },
  ];

  async function confirmDelete(parameter: ParameterData) {
    try {
      await ParameterService.deleteById(companyId, parameter.id);
      ShowMSG("success", "Parâmetro excluído com sucesso!");
    } catch (e) {
      console.error("Falha ao excluir parâmetro: ");
      ShowMSG("error", "Falha ao excluir parâmetro");
      console.error(e);
    }
    await getListParameter();
  }

  const onChangeHandler = (value: any) => (event: any) => {
    // setSelectedInitiative({ value: event.target.value });
    setSelectedInitiativeId({ value: event.target.value });
  };

  useEffect(() => {
    let val = "Nenhum";
    if (selectedInitiativeId == 0) {
      val = "Nenhum";
    }
    if (selectedInitiativeId == 1) {
      val = "Data";
    }
    if (selectedInitiativeId == 2) {
      val = "Estrutura Comercial";
    }
    if (selectedInitiativeId == 3) {
      val = "Link Dashboard";
    }
    if (selectedInitiativeId == 4) {
      val = "Outros";
    }
    setSelectedInitiative({ value: val });
  }, [selectedInitiativeId]);

  function handleClickEdit(parameter: ParameterData) {
    console.log(parameter);
    setSelectedParameterId(parameter.id ?? null);
    setName(parameter.name);
    setValue(parameter.value);
    setSelectedInitiativeId(parameter.type_id);
    // setSelectedInitiative(parameter.type_id);
    setShowModalNewParameter(true);
  }

  function showModalNewParameter() {
    setShowModalNewParameter(true);
  }

  async function handleSave() {
    try {
      if (selectedParameterId) {
        await ParameterService.update(companyId, {
          id: selectedParameterId,
          name: name.toUpperCase(),
          value,
          type_id: selectedInitiativeId,
        });
      } else {
        await ParameterService.create(companyId, {
          name: name.toUpperCase(),
          value,
          type_id: selectedInitiativeId,
        });
      }
      ShowMSG("success", "Parâmetro salvo com sucesso!");
    } catch (e) {
      console.error("Falha ao salvar parâmetro: ");
      ShowMSG("error", "Falha ao salvar parâmetro");
      console.error(e);
    }

    await getListParameter();
    setShowModalNewParameter(false);
    clearData();
  }

  function clearData() {
    setSelectedParameterId(null);
    setName("");
    setValue("");
  }

  function handleCancel() {
    setShowModalNewParameter(false);
    setSelectedInitiativeId(null);
    setSelectedInitiative(null);
    clearData();
  }

  function searchTable(value: string) {
    if (value) {
      setSearch(value);
      const array = listParameters.filter(
        (record) =>
          record["name"]?.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setSearchData(array);
    } else {
      setSearch("");
      setSearchData(listParameters);
    }
  }

  const { Title } = Typography;
  return (
    <Breadcrumb separator="" path="Parâmetro" child={["Setup", "Parâmetro"]}>
      {contextHolder}
      <>
        <Row align="middle" style={{ marginBottom: 16 }}>
          {/* Breadcrumb on the left */}

          {/* Title in the center */}
          <Col flex="none" style={{ textAlign: "center", flexGrow: 1 }}>
            <Title level={4} style={{ paddingBottom: "5px" }}>
              Parâmetro
            </Title>
          </Col>
        </Row>
        <Card bordered={false}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Button
                type="primary"
                icon={<FiPlus />}
                onClick={showModalNewParameter}
              >
                Novo
              </Button>
            </Col>
            <Col span={6}>
              <Input
                allowClear
                value={search}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchData}
                loading={isLoading}
              />
            </Col>
          </Row>
        </Card>

        <Modal
          open={modalNewParameter}
          okText={"Salvar"}
          cancelText={"Cancelar"}
          okButtonProps={{
            disabled: !name || !value || selectedInitiativeId < 1,
          }}
          onOk={handleSave}
          onCancel={handleCancel}
        >
          <Row justify="center">
            <Col span={16} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedParameterId ? "Atualizar" : "Novo"} Parâmetro
              </Typography.Title>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Nome</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Nome"
                  value={name != "" ? name.toUpperCase() : name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Valor</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Input
                  size="large"
                  placeholder="Valor"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: 10,
                    fontWeight: "bold",
                  }}
                >
                  <div className="textModel">
                    <b>Tipo de Parâmetro</b> <b className="required">*</b>
                  </div>
                </Typography.Text>
                <Select
                  style={{ width: "100%", margin: "10px 0" }}
                  size="large"
                  showSearch
                  placeholder="Nenhum"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedInitiativeId(value)}
                  value={selectedInitiative}
                >
                  <Option value="1">Data</Option>
                  <Option value="2">Estrutura Comercial</Option>
                  <Option value="3">Link Dashboard</Option>
                  <Option value="4">Outros</Option>
                </Select>
              </Col>
            </Col>
          </Row>
        </Modal>
      </>
    </Breadcrumb>
  );
}
