import Http from "../../../utils/http";

class BottomUpService extends Http {
  public constructor() {
    super();
  }

  public getTableProduct = (id: number, level3_id: number) =>
    this.instance.get(`/company/${id}/pgv/bottom-up?level3_id=${level3_id}`);

  public getTableProductDash = (
    id: number,
    business_type_id?: number,
    level0_id?: number,
    level1_id?: number,
    level2_id?: number,
    level3_id?: number
  ) =>
    this.instance.get(
      `/company/${id}/pgv/bottom-upDash?business_type_id=${business_type_id}&level0_id=${level0_id}&level1_id=${level1_id}&level2_id=${level2_id}&level3_id=${level3_id}`
    );

  public searchTableProduct = (id: number, level3_id: number, search: string) =>
    this.instance.get(
      `/company/${id}/pgv/bottom-up?level3_id=${level3_id}&filter_text=${search}`
    );

  public seveTableData = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgv/bottom-up`, body);

  public putBottomUpStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgv/bottom-up/status`, body);

  public getMiniDashboard = (id: number, level3_id: number) =>
    this.instance.get(
      `/company/${id}/pgv/bottom-up/mini-dash?level3_id=${level3_id}`
    );
}

export default new BottomUpService();
